import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import EmptyState from "./EmptyState";
import AnswerCard from "./AnswerCard";
import update from "immutability-helper";
import { updateSq3r, updateTask } from "../../redux/firebaseMiddleware";
import CitationDialog from "../Tasks/CreateTask/CitationDialog";
import { Box } from "@material-ui/core";
import ScrollBox from "../SharedComponents/ScrollBox";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  answer: {
    width: "66.666%", // 8 columns
    position: "relative",
    marginBlock: theme.spacing(1)
  }
}));

export function Cards({
  cards = [],
  dragItemType,
  onTitleChange,
  moveCard,
  hideCard,
  showCard,
  showInText,
  disableDrag
}) {
  const classes = useStyles();
  const visibleCards = cards && cards.filter && cards.filter(el => !el.hidden);
  const hiddenCards = cards && cards.filter && cards.filter(el => el.hidden);
  const grMode = useSelector(state => state.gr.mode);
  const stage = useSelector(state => state.gr.stage);

  return cards.length ? (
    <Box className={classes.container}>
      {visibleCards &&
        visibleCards.map((item, index) => (
          <Box className={classes.answer} key={item.cfi}>
            <AnswerCard
              disableDrag={disableDrag}
              showInText={showInText}
              drag_item={dragItemType}
              answer={item}
              onTitleChange={onTitleChange(item)}
              index={index}
              moveCard={moveCard}
              hideAnswer={
                grMode === "light" && stage === 0
                  ? undefined
                  : () => hideCard(item)
              }
            />
          </Box>
        ))}
      {hiddenCards &&
        hiddenCards.map((item, index) => (
          <Box className={classes.answer} key={item.cfi}>
            <AnswerCard
              disableDrag={disableDrag}
              showInText={showInText}
              drag_item={dragItemType}
              answer={item}
              index={index}
              onTitleChange={onTitleChange(item)}
              hideAnswer={() => showCard(item)}
            />
          </Box>
        ))}
    </Box>
  ) : (
    <EmptyState />
  );
}

function CardsManager({
  readText,
  context,
  disableDrag = false,
  removeHighlightCb,
  cards = [],
  hideCardCb,
  showCardCb,
  onTitleChange
}) {
  const dispatch = useDispatch();

  const DRAG_ITEM_TYPE = `GR.${context}`;
  const task = useSelector(state => state.task.taskRow);
  const taskAnswers = useSelector(state => state.task.answers);
  const highlights = useSelector(state => state.gr.highlights);
  const questions = useSelector(state => {
    return state.gr.questions;
  });
  const selectedTextId = useSelector(state => state.texts.selectedTextId);

  const selectedQuestion = useSelector(state => {
    let filtered = state.gr?.questions?.filter(
      q => q.id === state.gr.selectedQuestionId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });

  const answers = selectedQuestion && selectedQuestion.answers;

  const visibleAnswers =
    answers && answers.filter && answers.filter(el => !el.hidden);
  const hiddenAnswers =
    answers && answers.filter && answers.filter(el => el.hidden);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = visibleAnswers[dragIndex];

      const updatedAnswers = update(visibleAnswers, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      });
      const updatedQuestions = questions.map(el => {
        return el.id === selectedQuestion.id
          ? {
              ...selectedQuestion,
              answers: [...updatedAnswers, ...hiddenAnswers]
            }
          : el;
      });
      const updatedTaskAnswers = taskAnswers.map(tAns => ({
        ...tAns,
        quotes: [...updatedAnswers, ...hiddenAnswers]
      }));
      dispatch(
        task.task_type === "guidedReading"
          ? updateTask({
              answers: updatedTaskAnswers,
              grHighlights: highlights
            })
          : updateSq3r({
              textId: selectedTextId,
              questions: updatedQuestions,
              highlights: highlights
            })
      );
    },
    [
      dispatch,
      hiddenAnswers,
      highlights,
      questions,
      selectedQuestion,
      selectedTextId,
      visibleAnswers
    ]
  );

  const [showCitation, setShowCitation] = useState(false);

  return (
    <>
      <Cards
        disableDrag={disableDrag}
        cards={cards}
        dragItemType={DRAG_ITEM_TYPE}
        onTitleChange={onTitleChange}
        moveCard={moveCard}
        hideCard={hideCardCb}
        showInText={answer => {
          setShowCitation(answer);
        }}
        showCard={showCardCb}
      />

      {showCitation && (
        <CitationDialog
          color="green"
          setOpenDialog={setShowCitation}
          url={readText.url}
          location={readText.file_location}
          highlights={[showCitation]}
          openDialog={!!showCitation}
          context={context}
          removeHighlight={() => {
            removeHighlightCb(showCitation);
            setShowCitation(false);
          }}
        />
      )}
    </>
  );
}

CardsManager.propTypes = {
  cards: PropTypes.array, // TODO: Ofer add shape definitian of array items
  hideCardCb: PropTypes.func.isRequired,
  showCardCb: PropTypes.func.isRequired,
  onTitleChange: PropTypes.func.isRequired
};

export default CardsManager;
