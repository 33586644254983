// Dependencies
import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import clsx from "clsx";
import { useGetTheme } from "../../../hooks";
import ReactReaderActions from "../../annotations/ReactReaderActions";
import EpubView from "../../reader/EpubView/EpubView";
import { FormattedMessage } from "react-intl";
import ePub from "epubjs";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  openAnnotatorBar,
  closeAnnotatorBar
} from "../../../redux/highlightSlice";

// Components
import { getHighlightColor } from "../../../utils/colors";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography
} from "@material-ui/core";

//Style
const useStyles = makeStyles(() => ({
  dialogTitle: {
    background: "#168FEE",
    color: "#ffffff"
  },
  green: {
    background: "#5ec891"
  },
  dialogHeader: {},
  dialogActions: {
    justifyContent: "left",
    position: "relative"
  },
  dialogActionsRtl: {
    justifyContent: "right",
    position: "relative"
  },

  blue: {
    color: "#168FEE"
  },
  dialogBtn: {
    position: "absolute",
    right: "16px",
    color: "#ffffff"
  },
  dialog: {
    zIndex: 10
  },
  expandMsg: {
    cursor: "pointer",
    textAlign: "left",
    paddingLeft: "24px",
    paddingTop: "8px"
  },
  span: {
    textAlign: "left",
    paddingLeft: "24px",
    paddingBottom: "8px"
  },
  modal: {
    position: "relative",
    zIndex: 1,
    width: "90%",
    maxWidth: "90%"
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  modalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },
  buttonDiscard: {
    color: "#787877"
  },
  epubViewContainer: {
    width: "50%",
    margin: "0 auto"
  }
}));

export default function CitationDialog({
  openDialog,
  highlights,
  addHighlight,
  removeHighlight,
  setOpenDialog,
  url,
  location,
  color,
  ...props
}) {
  //Hooks
  const classes = useStyles();
  const epubRef = React.useRef(null);
  const epubContainerRef = React.useRef(null);
  const dispatch = useDispatch();

  //Redux state
  const darkMode = useSelector(state => state.user.userProfile.darkMode);
  const fontSizeValue = useSelector(state => state.user.userProfile.fontSize);
  const fontSize = useSelector(state => state.user.fontSizeOptions);
  const rtlValue = useSelector(state => state.user.userProfile.rtl);

  //Ephemeral state
  const [scrollTop, setScrollTop] = useState(false);
  const [highlightsElements, setHighlightsElements] = useState([]);
  const [rendition, setRendition] = useState(null);

  //Variables
  const EpubCFI = new ePub.CFI();
  const rendManager = rendition && rendition.manager;
  const containerScrollTop =
    epubContainerRef &&
    epubContainerRef.current &&
    epubContainerRef.current.scrollTop;

  //Behavior
  useEffect(() => {
    const setExistingElements = items => {
      let toRemove = [];
      highlightsElements &&
        Object.entries(highlightsElements).forEach(([cfi, elem]) => {
          let highlightColl =
            highlights &&
            highlights.filter &&
            highlights.filter(hl => elem.highlight.cfi === hl.cfi);
          if (highlightColl && highlightColl[0]) {
            elem &&
              elem.mark &&
              elem.mark.element &&
              (elem.mark.element.style.fill = getHighlightColor(
                highlightColl[0].color,
                darkMode
              ));
          } else {
            toRemove.push(elem.highlight.cfi);
            rendition.annotations.remove(elem.highlight.cfi, "highlight");
          }
        });
      if (toRemove.length > 0) {
        const filtered = Object.keys(items)
          .filter(key => !toRemove.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: items[key]
            };
          }, {});

        return filtered;
      } else return items;
    };

    const addMissingHighlights = items => {
      let timeAdding = new Date().getTime();

      highlights &&
        rendition &&
        rendition.manager &&
        highlights.forEach(highlight => {
          let range = rendition.getRange(highlight.cfi);
          if (range && !(highlight.cfi in items)) {
            let addedHighlight = rendition.annotations.highlight(
              highlight.cfi,
              {},
              e => {
                let timeClick = new Date().getTime();
                if (timeClick - timeAdding < 500) return;
                let contents = rendition.getContents();

                contents.forEach(content => {
                  if (range) {
                    let pos = rendition
                      .getContents()[0]
                      .locationOf(highlight.cfi);
                    if (pos.x !== 0 || pos.y !== 0) {
                      let clientRect = range.getBoundingClientRect();
                      const frameBounds =
                        content.document.defaultView.frameElement.getBoundingClientRect();
                      // `target` can be an element or a range
                      let newRect = {
                        x: clientRect.x + frameBounds.x,
                        y: clientRect.y + frameBounds.y,
                        width: clientRect.width,
                        height: clientRect.height,
                        top: clientRect.top + frameBounds.y,
                        left: clientRect.left + frameBounds.x,
                        bottom:
                          clientRect.top + frameBounds.y + clientRect.height,
                        right:
                          clientRect.left + frameBounds.x + clientRect.width
                      };
                      if (removeHighlight)
                        // setTimeout(() => {
                        dispatch(
                          openAnnotatorBar({
                            clientRectangle: newRect,
                            selectedText: highlight,
                            isHover: true
                          })
                        );
                      // }, 0);
                    }
                  }
                });
              },
              "highlightClass",
              {
                "z-index": 10,
                "mix-blend-mode": "multiply",
                "fill-opacity": 1,
                fill: getHighlightColor(highlight.color, darkMode)
              }
            );

            items[highlight.cfi] = { ...addedHighlight, highlight: highlight };
          }
        });
      return items;
    };

    const renderHighlights = () => {
      let items = highlightsElements;

      items = setExistingElements(items);
      items = addMissingHighlights(items);

      setHighlightsElements(items);
    };
    if (openDialog && rendition) {
      renderHighlights();
    }
  }, [
    highlights,
    openDialog,
    rendition,
    rendManager,
    highlightsElements,
    dispatch,
    darkMode,
    removeHighlight
  ]);

  useEffect(() => {
    if (
      scrollTop &&
      epubContainerRef &&
      epubContainerRef.current &&
      Math.abs(epubContainerRef.current.scrollTop - scrollTop) > 2
    ) {
      epubContainerRef.current.scrollTop = scrollTop;
    }
    if (
      epubContainerRef &&
      epubContainerRef.current &&
      scrollTop &&
      Math.abs(epubContainerRef.current.scrollTop - scrollTop) < 2
    ) {
      setScrollTop(false);
    }
  }, [scrollTop, containerScrollTop]);

  const addHighlightToAnswer = (quote, shouldDelete = false) => {
    let adjustedHighlight = {
      ...quote,
      source: "taskFindAnswer",
      color: getHighlightColor("#00B9FB", darkMode)
    };
    if (addHighlight || removeHighlight) {
      setScrollTop(0);
    }
    if (!shouldDelete) {
      addHighlight && addHighlight(adjustedHighlight);
    } else removeHighlight && removeHighlight(quote);
  };

  const removeHighlightFromAnswer = highlight => {
    addHighlightToAnswer(highlight, true);
  };

  const onTextSelected = params => {
    if (params.shouldClose) {
      dispatch(closeAnnotatorBar());
    } else {
      addHighlightToAnswer(params.selection);
      let content = rendition && rendition.getContents()[0];

      if (content && content.window && content.window.getSelection()) {
        content.window.getSelection().empty();
      }
    }
  };

  const onRenditionCreated = rendition => {
    setRendition(rendition);

    const gotoQuote = (rendition, target) => {
      let section = rendition.book.spine.get(target);
      var visible = section && rendition.manager.views.find(section); // View is already shown, just move to correct location in view

      if (target === section.href) {
        target = undefined;
      }
      if (visible && section) {
        let offset = target ? visible.locationOf(target) : visible.offset();
        let destTop = offset.top;
        setScrollTop(destTop);
      }
    };

    let sortedQuotes =
      highlights &&
      highlights.slice().sort(function (a, b) {
        return EpubCFI.compare(a.cfi, b.cfi);
      });
    sortedQuotes &&
      rendition &&
      epubContainerRef &&
      epubContainerRef.current &&
      sortedQuotes[0] &&
      rendition.display(sortedQuotes[0].cfi).then(() => {
        gotoQuote(rendition, sortedQuotes[0].cfi);
      });
  };

  const theme = useGetTheme();

  if (openDialog) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog
          open={openDialog}
          PaperProps={{
            style: {
              direction: rtlValue ? "rtl" : "ltr",
              // backgroundColor: "white",
              width: "90%",

              maxWidth: "90%"
            }
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            className={clsx(
              classes.dialogTitle,
              color === "green" && classes.green
            )}
            id="form-dialog-title"
          >
            <DialogActions className={clsx(classes.dialogActions)}>
              <Typography className={classes.dialogHeader}>
                <FormattedMessage
                  id="text.mark"
                  defaultMessage="Mark in Text"
                />
              </Typography>
              <Button
                variant="outlined"
                className={classes.dialogBtn}
                onClick={e => {
                  setOpenDialog(false);

                  setHighlightsElements([]);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
              </Button>
            </DialogActions>
          </DialogTitle>
          <DialogContent ref={epubContainerRef} className={classes.dialog}>
            <ReactReaderActions
              id="reactReaderActions"
              minimal={true}
              highlightFunc={addHighlightToAnswer}
              removeHighlightCb={removeHighlightFromAnswer}
            >
              <div id="epubViewContainer" className={classes.epubViewContainer}>
                <EpubView
                  ref={epubRef}
                  fullSize={true}
                  fontSize={fontSize[fontSizeValue]}
                  epubOptions={{ flow: "scrolled-doc" }}
                  // loadingView={inputRef}
                  rtl={rtlValue}
                  url={url}
                  location={location}
                  doNotMove={true}
                  // locationChanged={locationChanged}
                  handleTextSelected={onTextSelected}
                  onRenditionCreated={onRenditionCreated}
                />
              </div>
            </ReactReaderActions>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
  } else return null;
}
