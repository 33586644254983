// Dependencies
import React, { useRef } from "react";
import { useIntl } from "react-intl";
import { useDrag, useDrop } from "react-dnd";
import clsx from "clsx";

// Redux Dependencies
import { useSelector } from "react-redux";
// Components
import QuestionTitle from "../SharedComponents/InlineEditTextarea";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Link,
  Typography
} from "@material-ui/core";

//Styles
const useStyles = makeStyles(theme => ({
  card: {
    flexBasis: "100%",
    width: "100%",
    background: theme.palette.background.paper,
    "& $hoverButton": {
      // visibility: "hidden",
      // color: "black",
      fontSize: "16px",
      position: "absolute",
      bottom: "2px"
    },
    "&:hover ,&:focus-within": {
      background: theme.palette.card.background.hover,
      boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)"
      // "& $hoverButton": {
      //   visibility: "visible"
      // }
    }
  },

  CardContent: {
    paddingBottom: 0
  },
  cardActions: {
    justifyContent: "space-between"
  },

  titleInput: {
    minHeight: "36px",
    padding: "0",
    fontFamily: '"Open Sans Hebrew", "Rosario", "Helvetica", "Arial"',
    fontSize: "14px",
    background: "none",
    opacity: "0.7",
    marginBottom: "8px"
  },

  dragOpacity: {
    opacity: 0
  },
  defaultOpacity: {
    opacity: 1
  },

  ltr: {
    textAlign: "left"
  },
  // Don't delete this is referenced somewhere else
  hoverButton: {},
  quoteText: {
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"',
    fontSize: "16px",
    maxHeight: "148px",
    "-webkit-line-clamp": "4",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    textAlign: "start"
  },

  removeQuoteLtr: {
    right: "8px"
  },

  hidden: {
    background: theme.palette.card.background.disabled,
    // color: theme.palette.text.disabled,
    "& $hoverButton": {
      // visibility: "hidden",
      fontSize: "16px",
      position: "absolute",
      bottom: "2px"
    }

    // "&:hover": {
    //   "& $hoverButton": {
    //     visibility: "visible"
    //   }

    // }
  },
  showQuoteBtn: { padding: "8px" }
}));

export default function AnswerCard({ index, ...props }) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const ref = useRef(null);

  // Redux state
  const sourceTextLanguage = useSelector(
    state => state.texts.selectedText?.text_language
  );

  //variables
  const itemId = props.answer.cfi;

  //Behavior
  const [, drop] = useDrop({
    accept: props.drag_item,
    hover(item, monitor) {
      if (!ref.current || !props.moveCard) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveCard && props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: props.drag_item, itemId, index, item: props.answer },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    canDrag: !props.disableDrag
  });

  props.moveCard && drag(drop(ref));

  // Render
  return (
    <Card
      ref={ref}
      className={clsx(
        classes.card,
        props.answer.hidden && classes.hidden,
        isDragging && classes.dragOpacity,
        !isDragging && classes.defaultOpacity
      )}
      elevation={1}
    >
      <CardContent className={classes.CardContent}>
        <Box className={classes.titleInput}>
          <QuestionTitle
            text={props.answer.title ? props.answer.title : ""}
            onChange={value => props.onTitleChange(value)}
            placeholder={intl.formatMessage({
              id: "card.title",
              defaultMessage: "title"
            })}
          />
        </Box>
        <Box
          className={classes.quoteText}
          style={{ direction: sourceTextLanguage === "he" ? "rtl" : "ltr" }}
        >
          {props.answer.text}
        </Box>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Link
          color="secondary"
          component="button"
          className={classes.showQuoteBtn}
          onClick={() => props.showInText(props.answer)}
        >
          {intl.formatMessage({
            id: "show.text",
            defaultMessage: "show in text"
          })}
        </Link>

        {props.hideAnswer && (
          <IconButton
            onClick={() => props.hideAnswer(props.answer)}
            className={clsx(
              classes.hoverButton,
              classes.removeQuote,
              classes.removeQuoteLtr
            )}
          >
            {props.answer.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        )}
      </CardActions>
    </Card>
  );
}
