// Dependencies
import React from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import moment from "moment-timezone";
import { Draggable } from "react-beautiful-dnd";

// Redux Dependencies
import { useSelector } from "react-redux";

//Components
import TaskListItemDetails from "./TaskListItemDetails";
import ToggleTriangle from "../../SharedComponents/ToggleTriangle";
import useConvertCourseTimezone from "../../../hooks/useConvertCourseTimezone";
// Material UI
import DeleteFilledIcon from "@material-ui/icons/Delete";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import EditIcon from "@material-ui/icons/Edit";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  IconButton,
  Collapse,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Tooltip
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  task: {
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px"
  },
  taskHeader: {
    padding: "8px 16px"
  },
  taskSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  tooltip: {
    // opacity:'1',
    backgroundColor: "rgba(97, 97, 97, 1);"
  },
  taskName: {
    flexBasis: "33%",
    fontWeight: "bold",
    color: theme.palette.text.primary,
    textAlign: "start",
    cursor: "pointer"
  },
  taskDates: {
    display: "inline-block",
    color: theme.palette.text.secondary,
    fontWeight: "100",
    flexShrink: "0",
    "& span:first-child": {
      marginRight: "48px"
    }
  },
  actionContainer: {
    flexShrink: "0"
  },
  actionIcon: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  taskStatus: {
    color: theme.palette.text.secondary,
    margin: "0 48px"
  }
}));

export default function TasksListItem({
  taskIndex,
  task,
  deleteTask,
  submissions,
  graders,
  course,
  ...props
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const isDarkMode = useSelector(state => state.user.userProfile.darkMode);
  const [dueDay] = useConvertCourseTimezone(task.due_day);
  const [publishDay] = useConvertCourseTimezone(task.publish_date);
  console.log(task);
  // Redux State
  const rtl = useSelector(state => state.user.userProfile.rtl);

  // Ephemeral State
  // const [submissions, setSubmissions] = useState([]);

  const [isOpen, setIsOpen] = React.useState(props.singleItemView || false);

  const isDraft = task.status === "Draft";

  const handleClick = e => {
    setIsOpen(!isOpen);
    e.stopPropagation();
    e.preventDefault();
  };

  // Display the task's name and the status if aplicable
  function renderTaskName() {
    let target = "#";
    if (task.status === "Draft") {
      target = "/tasks/" + task.course_id + "/new/" + task.id;
    } else if (submissions && submissions.length) {
      target = "/tasks/course/" + task.course_id + "/task/" + task.id;
    }
    return (
      <Link
        to={target}
        component={RouterLink}
        // component="button"
        className={classes.taskName}
      >
        {task.name}
        {task.status === "Draft" && (
          <Typography
            className={classes.taskStatus}
            component="span"
            variant="body1"
          >
            <FormattedMessage id="Draft" defaultMessage="Draft" />
          </Typography>
        )}
      </Link>
    );
  }

  // display the avaliable action icons
  function renderTaskActions() {
    if (task.status === "Draft") {
      return (
        <Box className={classes.actionContainer}>
          <IconButton
            onClick={e => {
              deleteTask && deleteTask(task);
              e.preventDefault();
              e.stopPropagation();
            }}
            aria-label={`delete draft ${task.name}`}
          >
            <DeleteFilledIcon />
          </IconButton>
          <IconButton
            onClick={e => {
              history.push("/tasks/" + task.course_id + "/new/" + task.id, {
                task: task
              });

              e.stopPropagation();
              e.preventDefault();
            }}
            className={classes.actionIcon}
            aria-label={`edit draft ${task.name}`}
          >
            <EditIcon />
          </IconButton>
        </Box>
      );
    } else {
      return (
        <Box className={classes.actionContainer}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={intl.formatMessage({
              id: "task.taskmanager.viewTaskForm",
              defaultMessage: "View task form"
            })}
            arrow
            placement={rtl ? "left" : "right"}
          >
            <IconButton
              onClick={e => {
                history.push("/tasks/" + task.course_id + "/edit/" + task.id, {
                  task: { ...task, submissions: submissions },
                  course: course
                });

                e.stopPropagation();
                e.preventDefault();
              }}
              className={classes.actionIcon}
              aria-label="View task form"
            >
              <InsertDriveFileIcon />
            </IconButton>
          </Tooltip>

          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={intl.formatMessage({
              id: "task.taskmanager.taskStats",
              defaultMessage: "View task's statistics"
            })}
            arrow
            placement={rtl ? "left" : "right"}
          >
            <IconButton
              onClick={e => {
                history.push("/stats/task/" + task.id);
                e.stopPropagation();
                e.preventDefault();
              }}
              className={classes.actionIcon}
              aria-label="task's statistics"
            >
              <InsertChartIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    }
  }

  const getItemStyle = (isDragging, draggableStyle, isDarkMode) => {
    return {
      // styles we need to apply on draggables
      ...draggableStyle,

      ...(isDragging && {
        display: "table",
        background: isDarkMode ? "rgb(5,15,15)" : "rgb(235,235,235)"
      })
    };
  };

  // Render
  return (
    <>
      {props.singleItemView ? (
        <>
          <Box
            className={clsx(classes.task, classes.taskHeader)}
            disableGutters={true}
          >
            <Box disableTypography={true} className={classes.taskSummary}>
              {renderTaskName()}
              {isOpen && !isDraft && (
                <Box className={classes.taskDates}>
                  <Typography
                    className={classes.taskPublish}
                    component="span"
                    variant="body1"
                  >
                    <FormattedMessage
                      id="tasks.published"
                      defaultMessage="Published"
                    />
                    {` ${publishDay}`}
                  </Typography>

                  <Typography
                    className={classes.taskDeadline}
                    component="span"
                    variant="body1"
                  >
                    <FormattedMessage
                      id="tasks.deadline"
                      defaultMessage="Deadline"
                    />
                    {` ${dueDay}`}
                  </Typography>
                </Box>
              )}
              {renderTaskActions()}
            </Box>
          </Box>
          <TaskListItemDetails
            taskIndex={taskIndex}
            course={course}
            task={task}
            submissions={submissions}
            graders={graders}
          />
        </>
      ) : (
        <Draggable draggableId={"task_" + task.id} index={taskIndex}>
          {(provided, snapshot) => (
            <>
              <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style,
                  isDarkMode
                )}
                className={classes.task}
                disableGutters={true}
              >
                <ListItemIcon onClick={handleClick}>
                  {task.status !== "Draft" && (
                    <ToggleTriangle isOpen={isOpen} />
                  )}
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  className={classes.taskSummary}
                >
                  {renderTaskName()}
                  {isOpen && !isDraft && (
                    <Box className={classes.taskDates}>
                      <Typography
                        className={classes.taskPublish}
                        component="span"
                        variant="body1"
                      >
                        <FormattedMessage
                          id="tasks.published"
                          defaultMessage="Published"
                        />
                        {` ${publishDay}`}
                      </Typography>

                      <Typography
                        className={classes.taskDeadline}
                        component="span"
                        variant="body1"
                      >
                        <FormattedMessage
                          id="tasks.deadline"
                          defaultMessage="Deadline"
                        />
                        {` ${dueDay}`}
                      </Typography>
                    </Box>
                  )}
                  {renderTaskActions()}
                </ListItemText>
              </ListItem>
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <TaskListItemDetails
                  taskIndex={taskIndex}
                  course={course}
                  task={task}
                  submissions={submissions}
                  graders={graders}
                />
              </Collapse>
            </>
          )}
        </Draggable>
      )}
    </>
  );
}
