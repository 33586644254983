import { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

export const CLOSED = 48;

export default function useGetSideBarWidth() {
  const [sideBarWidth, setSideBarWidth] = useState(CLOSED);
  const actionBar = useSelector(state => {
    return state.readerActions.persistentActionState.actionBar;
  });

  useLayoutEffect(() => {
    const $sideBarEl = document.getElementById("side-bar");
    setSideBarWidth($sideBarEl.clientWidth);
  }, []);

  useLayoutEffect(() => {
    const $sideBarEl = document.getElementById("side-bar");
    $sideBarEl.addEventListener("transitionend", e => {
      if (e.target === $sideBarEl) {
        setSideBarWidth($sideBarEl.clientWidth);
      }
    });
    // return () => {
    //   $sideBarEl.removeEventListener("transitionend", e => {
    //     if (e.target == $sideBarEl) {
    //       setSideBarWidth($sideBarEl.clientWidth);
    //     }
    //   });
    // };
  }, [actionBar]);

  return sideBarWidth;
}
