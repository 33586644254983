import {
  configureStore,
  getDefaultMiddleware,
  combineReducers
} from "@reduxjs/toolkit";
import { applyMiddleware, compose, createStore } from "redux";
import {
  getFirebase,
  actionTypes as rrfActionTypes
} from "react-redux-firebase";
import { createBrowserHistory } from "history";
import { constants as rfConstants, getFirestore } from "redux-firestore";
import { connectRouter, routerMiddleware } from "connected-react-router";
import readerActionsReducer from "./readerActionsSlice";
import highlightReducer from "./highlightSlice";
import themeReducer from "./themeSlice";
import grReducer from "./grSlice";
import linkSlice from "./LinkSlice";
import taskSlice from "./taskSlice";
import commentReducer from "./commentsSlice";
import textReducer from "./textSlice";
import userReducer from "./userSlice";
import coursesSlice from "./coursesSlice";
import firebaseMiddleware from "./firebaseMiddleware";
const extraArgument = {
  getFirebase,
  getFirestore
};

export const history = createBrowserHistory();

// const createRootReducer = history =>
//   combineReducers({
//     router: connectRouter(history),
//     readerActions: readerActionsReducer,
//     highlighter: highlightReducer,
//     themes: themeReducer,
//     gr: grReducer,
//     texts: textReducer,
//     task: taskSlice,
//     user: userReducer,
//     link: linkSlice,
//     comments: commentReducer,
//     courses: coursesSlice
//   });

const middleware = [
  firebaseMiddleware,
  routerMiddleware(history),
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        // just ignore every redux-firebase and react-redux-firebase action type
        ...Object.keys(rfConstants.actionTypes).map(
          type => `${rfConstants.actionsPrefix}/${type}`
        ),
        ...Object.keys(rrfActionTypes).map(
          type => `@@reactReduxFirebase/${type}`
        )
      ],
      ignoredPaths: ["firebase", "firestore"]
    },
    thunk: {
      extraArgument
    }
  })
];

export default configureStore({
  reducer: {
    router: connectRouter(history),
    readerActions: readerActionsReducer,
    highlighter: highlightReducer,
    themes: themeReducer,
    gr: grReducer,
    texts: textReducer,
    task: taskSlice,
    user: userReducer,
    link: linkSlice,
    comments: commentReducer,
    courses: coursesSlice
  },
  middleware
});

// export const store = function configureStore(preloadedState) {
//   const store = createStore(
//     createRootReducer(history),
//     preloadedState,
//     compose(
//       applyMiddleware(
//         firebaseMiddleware,
//         routerMiddleware(history),
//         ...getDefaultMiddleware({
//           serializableCheck: {
//             ignoredActions: [
//               // just ignore every redux-firebase and react-redux-firebase action type
//               ...Object.keys(rfConstants.actionTypes).map(
//                 type => `${rfConstants.actionsPrefix}/${type}`
//               ),
//               ...Object.keys(rrfActionTypes).map(
//                 type => `@@reactReduxFirebase/${type}`
//               )
//             ],
//             ignoredPaths: ["firebase", "firestore"]
//           },
//           thunk: {
//             extraArgument
//           }
//         })
//       )
//     )
//   );
//   return store;
// };
