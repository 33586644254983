// Dependencies
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDrag } from "react-dnd";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import { updateSq3r, updateTask } from "../../redux/firebaseMiddleware";
import { setQuestions } from "../../redux/grSlice";
import { setLocation } from "../../redux/readerActionsSlice";

// Components
import QuestionTitle from "../SharedComponents/InlineEditTextarea";
import useGetTheme from "../../hooks/useGetTheme";
import CitationDialog from "../Tasks/CreateTask/CitationDialog";

// Material UI
import DeleteFilledIcon from "@material-ui/icons/Delete";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import {
  Link,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  card: {
    flexBasis: "100%"
  },
  CardContent: {
    paddingBottom: 0
  },
  quoteTitle: {
    minHeight: "36px",
    padding: "0",
    fontFamily: '"Open Sans Hebrew", "Rosario", "Helvetica", "Arial"',
    fontSize: "14px",
    background: "none",
    opacity: "0.7",
    marginBottom: theme.spacing(1)
  },
  quoteText: {
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"',
    fontSize: "16px",
    maxHeight: "148px",
    "-webkit-line-clamp": "4",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    textAlign: "start"
  },
  cardActions: {
    justifyContent: "space-between"
  },
  showQuoteBtn: { padding: theme.spacing(1) }
}));

export function QuestionQuoteCard({
  item,
  question,
  index,
  onDelete,
  isDragDisabled = false,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useGetTheme();
  const [showCitation, setShowCitation] = useState(false);

  // Redux State
  const highlights = useSelector(state => state.gr.highlights);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const questions = useSelector(state => state.gr.questions);
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const task = useSelector(state => state.task.taskRow);
  const taskAnswers = useSelector(state => state.task.answers);
  const selectedText = useSelector(state => state.texts.selectedText);
  const showAnswers = useSelector(state => state.gr.showAnswers);

  //Variables
  const cardItem = item;
  const ANSWER_ITEM = "GR.Answer";

  // Behavior
  const removeAnswer = (question, quote) => {
    let items = question.answers.filter(a => a.cfi !== quote.cfi);
    let updatedQuestions = questions.map(el => {
      return el.id === question.id ? { ...question, answers: items } : el;
    });

    onDelete(question, quote, updatedQuestions);
  };

  const [, drag] = useDrag({
    end(item, monitor) {},
    start(item, monitor) {},
    item: {
      type: ANSWER_ITEM,
      id: cardItem.cfi,
      index,
      item: cardItem,
      questionId: question.id
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const onTitleChange = (question, item, value) => {
    const updatedAnswers = question.answers.map(ans => {
      return ans.cfi === item.cfi
        ? Object.assign({}, ans, { title: value })
        : ans;
    });

    let updatedQuestions = questions.map(q =>
      q.id === question.id ? { ...q, answers: updatedAnswers } : q
    );
    const updatedTaskAnswers = taskAnswers.map((tAns, i) => ({
      ...tAns,
      quotes: question.id === i ? updatedAnswers : tAns.quotes
    }));
    // update redux store first in order to avoide flickering text
    dispatch(setQuestions(updatedQuestions));
    // then update firestore
    dispatch(
      task.task_type === "guidedReading"
        ? updateTask({
            answers: updatedTaskAnswers,
            grHighlights: highlights
          })
        : updateSq3r({
            textId: selectedTextId,
            questions: updatedQuestions,
            highlights: highlights
          })
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Card ref={drag} className={classes.card} elevation={1}>
        <CardContent className={classes.CardContent}>
          {/* TODO: ↓ this was creating a div nested within a p tag. need to refactore the html structure */}
          <Typography component="div" className={classes.quoteTitle}>
            <QuestionTitle
              text={item.title ? item.title : ""}
              onChange={value => onTitleChange(question, item, value)}
              placeholder={intl.formatMessage({
                id: "card.title",
                defaultMessage: "title"
              })}
            />
          </Typography>
          <Typography dir="auto" className={classes.quoteText}>
            {item.text}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Link
            color="secondary"
            component="button"
            className={classes.showQuoteBtn}
            onClick={() => {
              switch (true) {
                case grMode === "full" && stage < 4:
                case grMode === "light" && stage === 1 && !showAnswers:
                  dispatch(setLocation({ ...item, questionId: question.id }));
                  break;
                case grMode === "full" && stage == 4:
                case grMode === "light" && stage == 1 && showAnswers:
                case grMode === "light" && stage == 2:
                  setShowCitation(item);
                  break;
                default:
                  break;
              }
            }}
          >
            {intl.formatMessage({
              id: "show.text",
              defaultMessage: "show in text"
            })}
          </Link>
          {showCitation && (
            <CitationDialog
              color="green"
              setOpenDialog={setShowCitation}
              url={selectedText.link}
              location={selectedText.file_location}
              highlights={[showCitation]}
              openDialog={!!showCitation}
              context="Answers"
              removeHighlight={() => {
                removeAnswer(question, item);
                setShowCitation(false);
              }}
            />
          )}
          {stage < 4 && (
            <IconButton
              onClick={e => {
                removeAnswer(question, item);
                e.stopPropagation();
              }}
              className={classes.deleteBtn}
              aria-label="delete quote"
            >
              <DeleteFilledIcon />
            </IconButton>
          )}
        </CardActions>
      </Card>
    </ThemeProvider>
  );
}
