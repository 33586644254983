import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Epub from "epubjs/lib/index";
import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import EpubCifi from "epubjs/lib/epubcfi";
import { motion } from "framer-motion";
import {
  openAnnotatorBar,
  closeAnnotatorBar
} from "../../../redux/highlightSlice";

global.ePub = Epub; // Fix for v3 branch of epub.js -> needs ePub to by a global var

const ViewDiv = styled(Box)({
  height: "100%",
  width: "100%",
  // overflowY: "auto",
  "& svg": {
    zIndex: "-1"
  },
  "& .epub-container": {
    display: "flex",
    justifyContent: "center"
  }
});

const mapStateToProps = state => ({
  darkMode: state.user.userProfile.darkMode,
  //FIXME: This will add a label as long as theres a title in redux. It won't work for the reader that opens up in create new task
  textTitle: state.texts?.selectedText?.name,
  courseName: state.texts?.selectedText?.course_name,
  textLanguage: state.texts?.selectedText?.text_language,
  rtl: state.user.userProfile.rtl,
  isAnnotatorBarOpen: state.highlighter.isAnnotatorBarOpen
});

const mapDispatchToProps = dispatch => {
  return {
    closeAnnotatorBar: () => dispatch(closeAnnotatorBar()),
    openAnnotatorBar: () => dispatch(openAnnotatorBar())
  };
};

class EpubView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      mouseEvent: {},
      toc: [],
      uxDelayLoad: true,
      monitorLocationChange: false,
      mouseClicked: false
    };
    this.viewerRef = React.createRef();
    this.location = props.location;
    this.fullsize = props.fullSize;
    this.book = this.rendition = this.prevPage = this.nextPage = null;
    this.darkMode = props.darkMode;
    this.textTitle = props.textTitle;
    this.courseName = props.courseName;
    this.fontSize = props.fontSize;
  }

  componentDidMount() {
    this._ismounted = true;

    this.initBook(true);
    document.addEventListener("keyup", this.handleKeyPress, false);
    this.to = setTimeout(() => {
      this.setState({ uxDelayLoad: false });
      this.forceUpdate();
    }, 1500);
  }

  initBook(first) {
    const { url, tocChanged, epubInitOptions } = this.props;
    if (this.book) {
      this.book.destroy();
    }
    this.book = new Epub(url, epubInitOptions);
    this.book.loaded.navigation.then(({ toc }) => {
      this.setState(
        {
          isLoaded: true,
          toc: toc
        },
        () => {
          tocChanged && tocChanged(toc);
          this.initReader();
        }
      );
    });
  }

  componentWillUnmount() {
    this.book.destroy();
    this.book = this.rendition = this.prevPage = this.nextPage = null;
    document.removeEventListener("keyup", this.handleKeyPress, false);
    clearTimeout(this.to);
  }

  shouldComponentUpdate(nextProps) {
    return (
      !this.state.isLoaded ||
      nextProps.url !== this.props.url ||
      nextProps.location !== this.props.location ||
      // nextProps.rtl !== this.props.rtl ||
      nextProps.darkMode !== this.props.darkMode
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.darkMode !== this.props.darkMode ||
      // prevProps.rtl !== this.props.rtl ||
      prevProps.url !== this.props.url
    ) {
      this.initBook();
    }

    if (
      this.rendition &&
      prevProps.location !== this.props.location &&
      this.location !== this.props.location
    ) {
      this.rendition.display(this.props.location);
    }
  }

  initReader() {
    const { toc } = this.state;
    const {
      location,
      epubOptions,
      getRendition,
      handleKeyPress,
      rtl,
      darkMode,
      textTitle,
      courseName,
      bodyClassName
    } = this.props;
    // let darkMode_postfix = darkMode ? "_dark" : "";
    // let rtl_postfix = rtl ? "_rtl" : "_ltr";
    // let style_file = `/resources/epub/${this.props.context}/${
    //   this.props.rtl ? "rtl" : "ltr"
    // }_${this.props.dark ? "dark" : "light"}.css`;
    // const node = this.viewerRef.current;

    let darkMode_postfix = darkMode ? "_dark" : "";
    let rtl_postfix = this.props.textLanguage === "he" ? "_rtl" : "_ltr";
    let style_file =
      "/resources/epub_1" + darkMode_postfix + rtl_postfix + ".css";
    const node = this.viewerRef.current;

    if (node) {
      let bookProps = {
        contained: true,
        width: "100%",
        height: "100%",
        stylesheet: style_file,
        view: "iframe",
        defaultDirection: rtl ? "rtl" : "ltr",
        ignoreClass: "pangea-elem",
        fullsize: false, // this.fullsize,
        allowScriptedContent: true,
        ...epubOptions
      };

      this.rendition = this.book.renderTo(node, bookProps);

      if (bodyClassName) {
        this.rendition.hooks.content.register(function (contents, view) {
          const element = contents.document.querySelector("body");
          element.classList.add(bodyClassName);
        });
      }

      let located = 0;
      this.rendition.themes.default(this.fontSize);
      // this.rendition.on("selected", this.handleSelection);
      this.rendition.on("locationChanged", this.onLocationChange);
      this.rendition.on("keyup", handleKeyPress || this.handleKeyPress);
      this.rendition.on("mouseup", this.handleMouseUp);
      this.rendition.on("mousedown", this.handleMouseDown);

      //this.rendition.on("mousemove",this.handleMouseUp);

      getRendition && getRendition(this.rendition);

      this.rendition.on("rendered", (section, view) => {
        if (view.iframe) {
          if (courseName && textTitle) {
            view.iframe.setAttribute(
              "title",
              `Pangea Reader, currently viewing ${textTitle} from ${courseName} course`
            );
          } else {
            view.iframe.setAttribute("title", `Pangea Reader`);
          }
        }
        this.props.onRenditionCreated &&
          this.props.onRenditionCreated(this.rendition);
      });

      this.rendition.display(
        typeof location === "string" || typeof location === "number"
          ? location
          : toc[0].href
      );

      if (this.rendition) {
        setTimeout(() => {
          if (!this.rendition) return;
          let loc =
            typeof location === "string" || typeof location === "number"
              ? location
              : toc[0].href;
          this.rendition.display(loc);
        }, 400);
        setTimeout(() => {
          this.setState({ monitorLocationChange: true });
        }, 1000);
      }
    }
  }

  // handleSelection = (cfirange, contents) => {
  //   if (cfirange) this.showAnnotator(cfirange);
  //   else this.props.closeAnnotatorBar();
  // };

  handleMouseDown = () => {
    this.mouseClicked = true;
  };

  // Using mouseup instead of rendition.selected because it uses a timeout for ending the seleciton which is problematic for trackpads
  handleMouseUp = evt => {
    const content = this.rendition.getContents()[0];
    const selection = content.window.getSelection();
    if (selection.rangeCount > 0 && !selection.isCollapsed) {
      let range = selection.getRangeAt(0);
      let cfirange = new EpubCifi(range, content.cfiBase).toString();
      this.showAnnotator(cfirange);
    } else {
      this.props.closeAnnotatorBar();
    }
  };

  // setTimeout(() => {
  // setTimeout is for fixing racing condtion regarding selection range
  // let content = this.rendition.getContents()[0];
  // if (
  //   content.window &&
  //   content.window.getSelection() &&
  //   this.props.handleTextSelected
  // ) {
  //   const selection = content.window.getSelection();
  //   if (selection.rangeCount > 0 && !selection.isCollapsed) {
  //     let range = selection.getRangeAt(0);
  //     // cfirange = this.section.cfiFromRange(range);
  //     let cfirange = new EpubCifi(range, content.cfiBase).toString();
  //     this.showAnnotator(cfirange);
  //   } else {
  //     this.props.handleTextSelected &&
  //       this.props.handleTextSelected({
  //         shouldClose: true
  //       });
  //   }
  // } else {
  //   this.props.handleTextSelected &&
  //     this.props.handleTextSelected({
  //       shouldClose: true
  //     });
  //  dispatch(closeAnnotatorBar();
  // }
  // }, 0);
  // };

  showAnnotator = selection => {
    const { handleTextSelected } = this.props;
    if (!handleTextSelected) return;

    let contents = this.rendition.getContents();
    let cifi = new EpubCifi(selection);
    contents.forEach(content => {
      let range = content.range(cifi);
      if (range) {
        let text = range.toString();

        let pos = this.rendition.getContents()[0].locationOf(selection);
        if (pos.x !== 0 || pos.y !== 0) {
          let clientRect = range.getBoundingClientRect();
          // const section = this.book.spine.get(cifi)
          const frameBounds =
            content.document.defaultView.frameElement.getBoundingClientRect();
          // `target` can be an element or a range
          let newRect = {
            x: clientRect.x + frameBounds.x,
            y: clientRect.y + frameBounds.y,
            width: clientRect.width,
            height: clientRect.height,
            top: clientRect.top + frameBounds.y,
            left: clientRect.left + frameBounds.x,
            bottom: clientRect.top + frameBounds.y + clientRect.height,
            right: clientRect.left + frameBounds.x + clientRect.width
          };

          handleTextSelected({
            selection: { text: text, cfi: selection },
            clientRect: newRect,
            pos: pos
          });
        }
      }
    });
  };

  onLocationChange = loc => {
    if (!this.state.monitorLocationChange) return;
    const { location, locationChanged } = this.props;
    const newLocation = loc && loc.start;
    if (location !== newLocation) {
      this.location = newLocation;
      locationChanged && locationChanged(newLocation);
    }
  };

  handleKeyPress = ({ key }) => {
    // key && key === "ArrowRight" && this.nextPage();
    //key && key === "ArrowLeft" && this.prevPage();
  };

  render() {
    const { isLoaded, uxDelayLoad } = this.state;
    return (
      <>
        <motion.div
          animate={{
            opacity: isLoaded && !uxDelayLoad ? 1 : 0,
            height: "100%"
          }}
        >
          <ViewDiv
            ref={this.viewerRef}
            // dir={this.props.textLguage === "he" ? "rtl" : "ltr"}
          />
        </motion.div>
        {!isLoaded ||
          (uxDelayLoad && (
            <lottie-player
              src="/loading_book_lottie.json"
              mode="bounce"
              background="transparent"
              speed="1"
              style={{
                width: "300px",
                height: "300px",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)"
              }}
              loop
              autoplay
            ></lottie-player>
          ))}
      </>
    );
  }
}

EpubView.defaultProps = {
  loadingView: null,
  locationChanged: null,
  tocChanged: null,
  classes: {},
  epubOptions: {},
  epubInitOptions: {}
};

EpubView.propTypes = {
  url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(ArrayBuffer)
  ]),
  loadingView: PropTypes.element,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationChanged: PropTypes.func,
  tocChanged: PropTypes.func,
  classes: PropTypes.object,
  epubInitOptions: PropTypes.object,
  epubOptions: PropTypes.object,
  getRendition: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleTextSelected: PropTypes.func
};

// export default EpubView;
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(EpubView);
