import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  Link,
  Typography,
  DialogActions
} from "@material-ui/core";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseApp, firestore } from "../../firebase";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProfile } from "../../redux/userSlice";

const useStyles = makeStyles(theme => ({
  dialogBtn: {
    backgroundColor: "#168fee",
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      background: "#1172be"
    }
  },
  dialogTitle: {
    textAlign: "center"
  }
}));

export function TermsDialog() {
  const dispatch = useDispatch();
  const [user, authLoading, error] = useAuthState(firebaseApp.auth());
  const [userProfileDoc, profileLoading, userProfileError] = useDocument(
    firestore.doc("users/" + user?.uid)
  );
  const [agree, setAgree] = useState(false);
  const [termsError, setTermsError] = useState("");
  const classes = useStyles();
  const history = useHistory();
  return (
    <Dialog
      open={Boolean(
        userProfileDoc?.exists && !userProfileDoc.data().acceptedTerms
      )}
      onClose={() => {}}
    >
      <DialogTitle className={classes.dialogTitle}>
        Terms and Conditions
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={agree}
              onChange={() => {
                setAgree(!agree);
              }}
              color="primary"
            />
          }
          error={termsError}
          label={
            <Typography>
              I accept the{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="/terms.html"
                className={classes.termsLink}
                variant="body1"
              >
                terms and conditions
              </Link>{" "}
              and{" "}
              <Link
                target="_blank"
                rel="noopener"
                href="/privacy.html"
                className={classes.termsLink}
                variant="body1"
              >
                privacy policy
              </Link>
            </Typography>
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          aria-label={"I accept"}
          className={classes.dialogBtn}
          onClick={() => {
            if (agree) {
              dispatch(
                setProfile({ ...userProfileDoc.data(), acceptedTerms: true })
              );
            } else {
              setTermsError(
                "you must accept the terms to continue using Pangea"
              );
            }
          }}
        >
          <FormattedMessage id="terms.accept" defaultMessage="Accept" />
        </Button>
        <Button
          onClick={() => {
            history.push("/logout");
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
