// Dependencies
import React, { useState } from "react";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { firebaseApp, firestore } from "../../../firebase";

// Redux Dependencies
import { useSelector } from "react-redux";

//Components
import TaskStatusChip from "./TaskStatusChip";
import PeerReviewStatusChip from "./PeerReviewStatusChip";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

import {
  Button,
  Link,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  tableContainer: {
    paddingLeft: "10%"
  },
  tableRow: {
    // Equelize the height of the table rows with and without action buttons
    "& > *": {
      padding: "8px",
      "& > *": {
        paddingTop: "12px",
        paddingBottom: "12px"
      }
    }
  },
  pointer: {
    cursor: "pointer"
  },
  chip: { margin: "-8px" },
  missed: {
    backgroundColor: "#f9413e"
  },
  late: {
    backgroundColor: "#fcb63f"
  },
  pending: {
    border: "1px solid black"
  },
  submitted: {
    backgroundColor: "transparent",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  graded: {
    backgroundColor: "#0eac72"
  },
  sent: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.primary
  },
  add: {
    border: `1px solid ${theme.palette.primary}`,
    color: theme.palette.primary
  },
  actions: {
    textAlign: "end",
    paddingRight: 0
  },
  link: {
    color: theme.palette.text.primary,
    display: "block"
  },
  actionIcon: {
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  grade: {
    textAlign: "center"
  }
}));

export default function TaskDetails({
  course,
  task,
  taskIndex,
  graders,
  submissions,
  ...props
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  // Firestore State

  const currentUser = useSelector(state => state.user.auth.uid);
  const isImpersonating = useSelector(state => state.user.original_auth !== -1);
  // Ephemeral State
  const [modalVisible, setModalVisible] = useState(false);

  // Variables
  const add = intl.formatMessage({
    id: "tasks.feedback.add",
    defaultMessage: "add"
  });
  const sent = intl.formatMessage({
    id: "tasks.feedback.sent",
    defaultMessage: "Sent"
  });

  // Behavior
  const closeModal = () => {
    setModalVisible(false);
  };

  // Display the status chips

  function getFeedback(submission) {
    if (submission && submission.is_checked) {
      return (
        <Chip
          color="primary"
          className={classes.chip}
          label={sent}
          size="small"
        />
      );
    } else {
      if (submission && submission.submission_date) {
        return (
          <Chip
            color="primary"
            variant="outlined"
            className={classes.chip}
            label={add}
            size="small"
            clickable={true}
          />
        );
      } else return null;
    }
  }

  function getGrade(submission) {
    const grade = submission.grade ? submission.grade : "";
    if (submission.totalpoints) {
      return `${grade}/${submission.totalpoints}`;
    } else if (submission.is_checked) {
      return intl.formatMessage({
        id: "tasks.see.feedback",
        defaultMessage: "See feedback"
      });
    } else return "-";
  }
  const wrapLink = (submission, value) => {
    if (
      submission.submission_date &&
      (submission.is_checked ||
        !graders ||
        !graders[submission.submission_id] ||
        graders[submission.submission_id].grader === currentUser)
    ) {
      let linkTarget =
        "/feedback/task/" + task.id + "/submission/" + submission.submission_id;

      return (
        <Link className={classes.link} component={RouterLink} to={linkTarget}>
          {value}
        </Link>
      );
    } else return value;
  };

  const navigateToSubmission = submission => {
    // console.log(submission);
    if (submission.is_checked) {
      history.push(
        "/feedback/task/" + task.id + "/submission/" + submission.submission_id
      );
    } else if (submission.submission_date) {
      if (
        graders &&
        graders[submission.submission_id] &&
        graders[submission.submission_id].grader !== currentUser
      ) {
        setModalVisible(submission);
      } else {
        let docId = `taskGraders/${course.id}/graders/${submission.submission_id}`;
        !isImpersonating && firestore.doc(docId).set({ grader: currentUser });
        history.push(
          "/feedback/task/" +
            task.id +
            "/submission/" +
            submission.submission_id
        );
      }
    }
  };

  const navigateToCollaboration = submission => {
    // If pending - block
    if (!submission.collaboration_submission_date) {
      return null;
    } else {
      history.push(
        "/feedback/task/" +
          task.id +
          "/submission/" +
          submission.collaboration_submission_id
      );
    }
  };

  // Render
  return (
    <>
      <Dialog
        open={!!modalVisible}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ className: classes.modal }}
      >
        <ClearIcon className={classes.modalCancel} onClick={closeModal} />
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Another teacher already started grading this submissions, Do you
            wish to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          <Button
            onClick={closeModal}
            className={clsx(classes.button, classes.buttonDiscard)}
          >
            Close
          </Button>

          <Button
            className={classes.button}
            onClick={() => {
              setModalVisible(false);
              history.push(
                "/feedback/task/" +
                  task.id +
                  "/submission/" +
                  modalVisible.submission_id
              );
            }}
            color="primary"
            autoFocus
          >
            Continue anyway
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage
                    id="tasks.student"
                    defaultMessage="Student"
                  />
                </Typography>
              </TableCell>
              {task.task_type === "peerReview" ? (
                <>
                  <TableCell>
                    <Typography color="textSecondary">Reflection</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="textSecondary">Review</Typography>
                  </TableCell>
                </>
              ) : (
                <TableCell>
                  <Typography color="textSecondary">
                    <FormattedMessage
                      id="tasks.status"
                      defaultMessage="Status"
                    />
                  </Typography>
                </TableCell>
              )}
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage
                    id="tasks.feedback"
                    defaultMessage="Feedback"
                  />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="textSecondary">
                  <FormattedMessage id="tasks.grade" defaultMessage="Grade" />
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {submissions.map((submission, index) => {
              return (
                <TableRow
                  key={submission.submission_id}
                  className={clsx(
                    classes.tableRow,
                    submission.submission_date && classes.pointer
                  )}
                  // onClick={()=>{navigateToSubmission(submission)}}
                >
                  {/* <TableCell component="th" scope="row">
                    {wrapLink(
                      submission,
                      <Typography>{submission.student_name}</Typography>
                    )}
                  </TableCell> */}

                  <TableCell component="th" scope="row">
                    <Link
                      component="button"
                      className={classes.link}
                      onClick={() => {
                        navigateToSubmission(submission);
                      }}
                    >
                      {submission.student_name}
                    </Link>
                  </TableCell>

                  {task.task_type === "peerReview" ? (
                    <>
                      <TableCell>
                        <PeerReviewStatusChip
                          {...submission}
                          statusFor="reflection"
                          userRole={course.course_role}
                          clickable={Boolean(submission.submission_date)}
                          onClick={() => {
                            navigateToSubmission(submission);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <PeerReviewStatusChip
                          {...submission}
                          // overrides submission.submission_date
                          submission_date={
                            submission.collaboration_submission_date
                          }
                          // overrides submission.due_date
                          due_day={submission.accept_day}
                          userRole={course.course_role}
                          statusFor="response"
                          clickable={Boolean(
                            submission.collaboration_submission_date
                          )}
                          onClick={() => {
                            navigateToCollaboration(submission);
                          }}
                        />
                      </TableCell>
                    </>
                  ) : (
                    <TableCell>
                      <TaskStatusChip
                        {...submission}
                        // statusFor="collaborator"
                        userRole={course.course_role}
                      />
                    </TableCell>
                  )}

                  <TableCell>
                    {wrapLink(submission, getFeedback(submission))}
                  </TableCell>
                  <TableCell className={classes.grade}>
                    {wrapLink(
                      submission,
                      <Typography>{getGrade(submission)}</Typography>
                    )}
                  </TableCell>
                  <TableCell className={classes.actions}>
                    {submission?.submission_date &&
                      task?.task_type !== "peerReview" && (
                        <IconButton
                          className={classes.actionIcon}
                          aria-label="display student's task"
                        >
                          <InsertDriveFileIcon />
                        </IconButton>
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
