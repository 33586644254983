// Dependencies
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import moment from "moment";

// Redux dependencies
import { useDispatch } from "react-redux";
import { enqueueFlashMessage } from "../../../redux/userSlice";

// Components
import NavigationButtons from "./NavigationButtons";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, Divider, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

//Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  paperClass: {
    marginTop: "20px",
    borderRadius: "0px",
    paddingBottom: "16px",
    textAlign: "left",

    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#168FEE"
    },
    "& .MuiInput-underline": {
      "&:after": {
        borderBottom: "2px solid #168FEE"
      }
    },
    "&:after": {
      borderBottom: "2px solid #168FEE"
    },
    "&:focused::after": {
      borderBottom: "2px solid #168FEE"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#168FEE"
    }
  },
  divider: {
    backgroundColor: "#dedede",
    marginBottom: "8px",
    marginTop: "8px"
  },
  taskDesc: {
    width: "90%",
    marginBottom: "16px"
  },
  paddedContent: {
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "16px"
  },
  marginContent: {
    marginTop: "24px",
    marginBottom: "30px"
  },
  deadline: {
    "& .MuiPickersDay-current": {
      color: "#168fee"
    },
    "& .MuiPickersDay-daySelected": {
      color: "#fff"
    }
  },
  acceptUntil: {
    // position: "absolute",
    // right: "24px",
    "& .MuiPickersDay-current": {
      color: "#168fee"
    },
    "& .MuiPickersDay-daySelected": {
      color: "#fff"
    }
  },
  deadlinesContainer: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

export default function StepSubmitTask(props) {
  //Hooks
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  //Variables
  const deadlineValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterDeadline",
    defaultMessage: "Set a deadline for this task"
  });
  const deadlineValueValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.deadlinePast",
    defaultMessage: "The deadline you selected has already passed"
  });
  const deadlineOrderValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.deadlineOrder",
    defaultMessage:
      "Please make sure that the secound deadline is after the first deadline"
  });

  // Behavior
  function validateForm() {
    let message = "";
    // For standart tasks, check if there is a deadline
    if (props.taskType === "standard" && !props.deadline) {
      message = deadlineValidationMessage;
    } else if (
      props.taskType === "peerReview" &&
      (!props.deadline || !props.acceptUntil)
    ) {
      message = deadlineValidationMessage;
      // If there is a deadline...
    } else {
      // Check if the deadline's day is today or after
      if (moment().isSameOrAfter(moment(props.deadline), "day")) {
        message = deadlineValueValidationMessage;
      }
      // Check if the secound deadline's day is today or after
      if (
        props.acceptUntil &&
        moment().isSameOrAfter(moment(props.deadline), "day")
      ) {
        message = deadlineValueValidationMessage;
      }
      // Check if the first deadline is before the secound deadline
      if (
        props.acceptUntil &&
        moment(props.deadline).isSameOrAfter(moment(props.acceptUntil), "day")
      ) {
        message = deadlineOrderValidationMessage;
      }
    }

    if (message === "") {
      return true;
    } else {
      dispatch(
        enqueueFlashMessage({
          message: message,
          duration: 3000,
          severity: "error"
        })
      );
      return false;
    }
  }

  //Render
  return (
    <>
      <Paper elevation={1} className={clsx(classes.paperClass)}>
        <Typography className={classes.paddedContent} variant="h6">
          <FormattedMessage defaultMessage="Time Frame" id="task.timeFrame" />
        </Typography>
        <Divider className={classes.divider} />
        <Box
          className={clsx(
            classes.deadlinesContainer,
            classes.paddedContent,
            classes.marginContent
          )}
        >
          <DatePicker
            id="deadline"
            readOnly={props.readOnly}
            label={
              props.taskType === "peerReview"
                ? // Peer Review
                  intl.formatMessage({
                    id: "tasks.responderDeadline",
                    defaultMessage: "Reflection deadline"
                  })
                : // All other Tasks
                  intl.formatMessage({
                    id: "tasks.deadline",
                    defaultMessage: "Deadline"
                  })
            }
            openTo="date"
            className={classes.deadline}
            DialogProps={{ className: classes.deadline }}
            format="DD/MM/yyyy"
            views={["year", "month", "date"]}
            value={props.deadline}
            emptyLabel={intl.formatMessage({
              id: "tasks.pickDate",
              defaultMessage: "Choose Date"
            })}
            onChange={e => {
              props.setDeadline(e);
            }}
            leftArrowButtonProps={{ "aria-label": "previous month" }}
            rightArrowButtonProps={{ "aria-label": "next month" }}
          />
          {(props.deadline || props.taskType === "peerReview") && (
            <DatePicker
              id="latestDelivery"
              minDate={props.deadline}
              readOnly={props.readOnly}
              className={classes.acceptUntil}
              DialogProps={{ className: classes.deadline }}
              label={
                props.taskType === "peerReview"
                  ? // Peer Review
                    intl.formatMessage({
                      id: "tasks.reviewerDeadline",
                      defaultMessage: "Review deadline"
                    })
                  : // All other Tasks
                    intl.formatMessage({
                      id: "tasks.acceptUntil",
                      defaultMessage: "Latest delivery"
                    })
              }
              openTo="date"
              format="DD/MM/yyyy"
              views={["year", "month", "date"]}
              value={props.acceptUntil}
              emptyLabel={intl.formatMessage({
                id: "tasks.pickDate",
                defaultMessage: "Choose Date"
              })}
              onChange={e => {
                props.setAcceptUntil(e);
              }}
              leftArrowButtonProps={{ "aria-label": "previous month" }}
              rightArrowButtonProps={{ "aria-label": "next month" }}
            />
          )}
        </Box>
      </Paper>
      {props.saveTask && (
        <NavigationButtons
          activeStep={props.activeStep}
          setActiveStep={props.setActiveStep}
          saveTask={props.saveTask}
          validateForm={validateForm}
        />
      )}
    </>
  );
}
