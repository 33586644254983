// Dependencies
import React, { useEffect } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { updateSq3r, updateTask } from "./redux/firebaseMiddleware";
import { firebaseApp } from "./firebase";
// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { getSelectedQuestion } from "./redux/grSlice";

// Components
import ReaderView from "./ReaderView";
import PocFeedback from "./components/poc/PocFeedback";
import TaskFeedback from "./components/Tasks/TaskFeedback/taskFeedback";
import TaskStatsView from "./components/Tasks/Stats/TaskStatsView";
import TasksAdminView from "./components/Tasks/Admin/TasksAdminView";
import TaskEvaluation from "./components/Tasks/Admin/TaskEvaluation";

import CreateTaskScreen from "./components/Tasks/CreateTask/CreateTaskScreen";

import Library from "./components/library/Library";
import ContactUs from "./ContactUs";
import PermissionDenied from "./components/auth/PermissionDenied";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import Tasks from "./components/Tasks/Tasks";
import Logout from "./components/auth/Logout";
import PlainTextExample from "./poc";
import Notifications from "./Notifications";
export function ScrollToTopOnMount() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

export default function Router({ ...props }) {
  const dispatch = useDispatch();
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const userRole = useSelector(
    state => state.user.userProfile.selectedCourse.course_role
  );
  const questions = useSelector(state => state.gr.questions);
  const selectedQuestion = useSelector(getSelectedQuestion);
  const taskAnswers = useSelector(state => state.task.answers);
  const grHighlights = useSelector(state => state.gr.highlights);
  const grAsTaskHighlights = useSelector(state => state.task.grHighlights);
  const grMode = useSelector(state => state.gr.mode);
  const stage = useSelector(state => state.gr.stage);
  const selectedQuestionIndex = useSelector(
    state => state.task.selectedQuestionIndex
  );
  const annotatorMode = useSelector(
    state => state.readerActions.persistentActionState.annotatorMode
  );

  const toggleBookHighlight =
    ((grMode === "full" && stage < 2) || (grMode === "light" && stage < 1)) &&
    annotatorMode === "poc";
  const toggleAnswerHighlight = Boolean(
    ((grMode === "full" && (stage === 2 || stage === 3)) ||
      (grMode === "light" && stage === 1)) &&
      selectedQuestion
  );

  function removeGrHighlightCb(highlight) {
    let questionsCopy = [...questions];
    let grHighlightsCopy = [...grHighlights];
    switch (true) {
      case toggleBookHighlight: {
        grHighlightsCopy = grHighlights.filter(el => el.cfi !== highlight.cfi);
        break;
      }
      case toggleAnswerHighlight: {
        let answers = selectedQuestion.answers.filter(
          a => a.cfi !== highlight.cfi
        );
        let question = { ...selectedQuestion, answers: answers };

        questionsCopy = questions.map(el => {
          if (el.id !== selectedQuestion.id) return el;
          else return question;
        });
        break;
      }
      default:
        break;
    }
    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: questionsCopy,
        highlights: grHighlightsCopy
      })
    );
  }

  function removeGrTaskHighlightCb(highlight) {
    let grHighlightsCopy = [...grAsTaskHighlights];
    let answersCopy = [...taskAnswers];
    switch (true) {
      case toggleBookHighlight: {
        grHighlightsCopy = grAsTaskHighlights.filter(
          el => el.cfi !== highlight.cfi
        );
        break;
      }
      case toggleAnswerHighlight: {
        if (typeof answersCopy[selectedQuestionIndex] === "undefined") {
          answersCopy[selectedQuestionIndex] = [];
        }
        answersCopy[selectedQuestionIndex] = {
          ...answersCopy[selectedQuestionIndex],
          quotes: answersCopy[selectedQuestionIndex].quotes.filter(
            el => el.cfi !== highlight.cfi
          )
        };
        break;
      }
      default:
        break;
    }
    dispatch(
      updateTask({
        answers: answersCopy,
        grHighlights: grHighlightsCopy
      })
    );
  }

  //Render
  return (
    <Switch>
      <Route exact path="/">
        <Library />
      </Route>
      <Route exact path="/reader/text/:text_id">
        <ReaderView
          grSettings={{
            showSelfFeedback: true,
            removeHighlightCb: removeGrHighlightCb
          }}
        />
      </Route>
      <Route exact path="/feedback/text/:text_id/submission/:submission_id">
        <ScrollToTopOnMount />
        <PocFeedback />
      </Route>
      <Route exact path="/feedback/task/:task_id/submission/:submission_id">
        <ScrollToTopOnMount />
        <TaskFeedback />
      </Route>
      <Route
        exact
        path="/feedback/task/:task_id/submission/:submission_id/question_id/:question_id"
      >
        <ScrollToTopOnMount />
        <TaskFeedback />
      </Route>

      <Route exact path="/stats/task/:task_id">
        <ScrollToTopOnMount />
        <TaskStatsView />
      </Route>
      <Route exact path="/reader/task/:task_id">
        <ReaderView
          grSettings={{
            showSelfFeedback: false,
            removeHighlightCb: removeGrTaskHighlightCb
          }}
        />
      </Route>
      <Route exact path="/reader/task/:task_id/submission/:submission_id">
        <ReaderView
          grSettings={{
            showSelfFeedback: false,
            removeHighlightCb: removeGrTaskHighlightCb
          }}
        />
      </Route>

      <Route
        exact
        path="/tasks/:course_id/new/:task_id"
        render={({ match }) =>
          userRole === "Teacher" ? (
            <>
              <ScrollToTopOnMount />
              <CreateTaskScreen />
            </>
          ) : (
            <Redirect to={`/tasks/${match.params.course_id}`} />
          )
        }
      ></Route>

      <Route
        exact
        path="/tasks/:course_id/new"
        render={({ match }) =>
          userRole === "Teacher" ? (
            <>
              <ScrollToTopOnMount />
              <CreateTaskScreen />
            </>
          ) : (
            <Redirect to={`/tasks/${match.params.course_id}`} />
          )
        }
      ></Route>
      <Route path="/tasks">
        <ScrollToTopOnMount />
        <Tasks />
      </Route>
      <Route exact path="/adminEvaluation/:submission_id">
        <ScrollToTopOnMount />
        <TaskEvaluation />
      </Route>
      <Route exact path="/adminTaskView/:task_id">
        <ScrollToTopOnMount />
        <TasksAdminView />
      </Route>

      <Route exact path="/test" component={PlainTextExample} />
      <Route exact path="/library" component={Library} />
      <Route exact path="/library/:course_id" component={Library} />
      <Route exact path="/contactUs" component={ContactUs} />
      <Route exact path="/profile">
        <ProfilePage />
      </Route>
      <Route exact path="/notifications" component={Notifications} />
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/permissionDenied" component={PermissionDenied} />
    </Switch>
  );
}
