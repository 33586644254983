// Dependencies
import React, { useEffect, useState } from "react";
import flatten from "flat";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { create } from "jss";
import rtl from "jss-rtl";

// Firebase
import {
  firebaseApp,
  firestore,
  firebaseFunctions,
  isEmulated,
  crossed
} from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocument } from "react-firebase-hooks/firestore";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { userNavigated } from "./redux/firebaseMiddleware";
import { setAuth, defaultProfile, setProfile } from "./redux/userSlice";
import {
  setReaderActionData,
  defaultPersisentActionState
} from "./redux/readerActionsSlice";

// Components
import "./App.css";
import Layout from "./Layout";
import useGetTheme from "./hooks/useGetTheme";
import SignIn from "./components/auth/SignIn";
import PangeaSpinner from "./components/SharedComponents/PangeaSpinner";
import { TermsDialog } from "./components/auth/TermsDialog";
import { OfflineDialog } from "./components/SharedComponents/OfflineDialog";

// Intl
import Hebrew from "./translations/he.js";
import English from "./translations/en";

// Material UI
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";

function App() {
  //Hooks
  const dispatch = useDispatch();
  const locale = useSelector(state => state.user.userProfile.language);
  const location = useLocation();
  const history = useHistory();
  const theme = useGetTheme();
  const [tokenRedirect, setTokenRedirect] = useState(false);
  const [user, authLoading, error] = useAuthState(firebaseApp.auth());
  const [userProfileDoc] = useDocument(firestore.doc("users/" + user?.uid));

  //Redux State
  const rtl_state = useSelector(state => state.user.userProfile.rtl);
  const auth = useSelector(state => state.user.auth);
  const readerActionsData = useSelector(
    state => state.readerActions.persistentActionState
  );

  //Ephemeral State

  // Derived state
  const isLoggedin = Boolean(firebaseApp.auth().currentUser); // There is a problem with the current logout flow which created a bug where there is a userId for a moment after loggin out. using firebase directly to overcome this until there's a better fix.

  const userUid = user ? user.uid : null;
  const userDisplayName = user ? user.displayName : null;
  const userPhotoUrl = user ? user.photoURL : null;
  const userEmail = user ? user.email : null;

  let lang;
  switch (locale) {
    case "en":
      lang = English;
      break;
    case "he":
      lang = Hebrew;
      break;
    default:
      lang = English;
  }

  // Configure JSS
  const jss = create({ plugins: [...jssPreset().plugins, rtl_state && rtl()] });

  useEffect(() => {
    if (!isLoggedin) return;

    firestore
      .doc("users/" + userUid)
      .get()
      .then(doc => {
        if (doc.exists) {
          let data = doc.data();
          if (!tokenRedirect && location.pathname === "/") {
            history.push(data.location);
          }

          dispatch(setProfile(data));

          if (data?.readerActionData?.highlightColor) {
            // override the default setting with the saved ones in case simething is added to the default
            const readerActionData = {
              ...defaultPersisentActionState,
              ...data.readerActionData
            };
            dispatch(setReaderActionData(readerActionData));
          } else {
            dispatch(setReaderActionData(defaultPersisentActionState));
          }
        } else {
          dispatch(setReaderActionData(defaultPersisentActionState));

          dispatch(setProfile(defaultProfile));
          if (!tokenRedirect && location.pathname === "/") {
            console.log("going to library");
            history.push("/library");
          }
        }
      })
      .catch(err => console.log("err fetcfhing user profile", err));
  }, [isLoggedin]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //save location, selectedCourse
    if (
      userUid &&
      !location.pathname.includes("logout") &&
      location.pathname !== "/"
    ) {
      firestore
        .doc("users/" + userUid)
        .update({ location: location.pathname })
        .catch(err => console.log("Error updating firestore", err));
    }

    if (userUid) dispatch(userNavigated({ location: location }));
  }, [dispatch, location, userUid]);

  useEffect(() => {
    if (readerActionsData && readerActionsData.highlightColor && userUid) {
      try {
        firestore
          .doc("users/" + userUid)
          .update({ readerActionData: readerActionsData });
      } catch (err) {
        console.log("error in readerACtion Data update ", err);
      }
    }
  }, [readerActionsData, userUid]);

  useEffect(() => {
    if (!isLoggedin) return;

    dispatch(
      setAuth({
        displayName: userDisplayName,
        photoURL: userPhotoUrl,
        uid: userUid,
        email: userEmail
      })
    );
  }, [dispatch, isLoggedin, userDisplayName, userEmail, userPhotoUrl, userUid]);

  useEffect(() => {
    let sp = new URLSearchParams(location.search);
    let token = sp.get("token");
    let path = sp.get("redirect");
    let course = sp.get("course_id");
    if (token) {
      setTokenRedirect(true);
      firebaseApp
        .auth()
        .signInWithCustomToken(token)
        .then(() => {
          var getMyCourses = firebaseFunctions.httpsCallable(
            "courses-getMyCourses"
          );

          getMyCourses().then(response => {
            let courseDetail = response.data.filter(e => e.id === course);
            if (courseDetail && courseDetail.length && userProfileDoc?.exists) {
              dispatch(
                setProfile({
                  ...userProfileDoc.data(),
                  selectedCourse: courseDetail[0]
                })
              );
            }
            history.push(path);
          });
        });
    } else {
      if (firebaseApp.auth().isSignInWithEmailLink(window.location.href)) {
        //we saved the email in local storage when generated,
        //but if the user used another browser it is not available , so promprt them.
        var savedEmail = window.localStorage.getItem("emailForSignIn");
        if (!savedEmail) {
          savedEmail = window.prompt(
            "Please provide your email for confirmation"
          );
        }
        // The client SDK logs in the user based on link
        firebaseApp
          .auth()
          .signInWithEmailLink(savedEmail, window.location.href)
          .then(() => {
            // Clear email from storage.
            window.localStorage.removeItem("emailForSignIn");
          })
          .catch(error => {
            console.log("error", error);
            setAuth(0);
          });
      }
    }
  }, [dispatch, history, location, userProfileDoc]);

  const renderApp = () => {
    //adding token to if for LTI - sometimes slowness cause the login screen to appear when laoder should
    //still be visible  need to verify this doesn't break anything
    let sp = new URLSearchParams(location.search);
    let token = sp.get("token");
    if (
      // This whole flow need to be refactored
      isLoggedin &&
      auth &&
      user &&
      readerActionsData &&
      readerActionsData.highlightColor
    ) {
      return (
        <>
          <TermsDialog />
          <Layout />
        </>
      );
    } else if (!user && !token && !authLoading) {
      if (error) console.log("error", error);
      return <SignIn />;
    } else return <PangeaSpinner />;
  };

  const [isOffline, setIsOffline] = useState(
    window.Offline && window.Offline.state !== "up"
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <IntlProvider
        locale={locale}
        messages={flatten(lang, {
          safe: true
        })}
      >
        <DndProvider backend={HTML5Backend}>
          <StylesProvider jss={jss}>
            <div className="App" dir={rtl_state ? "rtl" : "ltr"}>
              {!isOffline && renderApp()}
            </div>
            {isEmulated ? (
              <div
                style={{
                  background: "red",
                  textAlign: "center",
                  position: "fixed",
                  top: "0px",
                  zIndex: 1000000,
                  width: "100vw"
                }}
              >
                Emulated
              </div>
            ) : crossed ? (
              <div
                style={{
                  background: "darkorange",
                  textAlign: "center",
                  position: "fixed",
                  top: "0px",
                  zIndex: 1000000,
                  width: "100vw"
                }}
              >
                crossed with <strong>{crossed}</strong> backend
              </div>
            ) : (
              ""
            )}
            <OfflineDialog isOffline={isOffline} setIsOffline={setIsOffline} />
          </StylesProvider>
        </DndProvider>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default withRouter(App);
