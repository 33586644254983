// Dependencies
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { updateSq3r, getNextId } from "../../../redux/firebaseMiddleware";

// Components
import EditingButton from "../../EditingButton";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  liLtr: {
    "& button": {
      right: "0px"
    }
  },
  editmodeLine: {
    paddingLeft: theme.spacing(2),
    minHeight: "40px"
  },
  selectedQuestion: {
    backgroundColor: theme.palette.secondary.main,
    color: "black"
  }
}));

function NewQuestion({ newQuestionMode, setNewQuestionMode, scrollToBottom }) {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();

  //Redux state
  const questions = useSelector(state => state.gr.questions);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const highlights = useSelector(state => state.gr.highlights);

  //Behavior
  useEffect(() => {
    // Useing an onBlur instead of a clickOutside becouse clicking on the reader's Iframe won't trigger a click outside
    window.addEventListener("blur", onBlur);
    function onBlur() {
      addQuestion();
    }
    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, []);

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      addQuestion();
      e.preventDefault();
    }
  }

  function addQuestion() {
    if (newQuestionMode.value) {
      let newId = getNextId(questions);
      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: [
            ...questions,
            {
              id: newId,
              question: newQuestionMode.value,
              answers: []
            }
          ],
          highlights: highlights
        })
      );
      setNewQuestionMode({
        adding: true,
        value: "",
        addValue: newQuestionMode.value,
        shouldAdd: true
      });
      //   setPreviousSelectedQuestion(newId);
      scrollToBottom();
    } else {
      setNewQuestionMode({
        adding: false,
        value: "",
        addValue: "",
        shouldAdd: false
      });
    }
  }

  return (
    <Box
      // ref={clickOutsideRef}
      className={clsx(
        classes.liLtr,
        classes.editmodeLine,
        classes.selectedQuestion
      )}
    >
      <EditingButton
        editIcon={<React.Fragment />}
        colorClass="black"
        onFocusOut={item => {
          if (item) {
            let newId = getNextId(questions);
            setNewQuestionMode({
              adding: false,
              value: "",
              addValue: item,
              shouldAdd: true
            });
            dispatch(
              updateSq3r({
                textId: selectedTextId,
                questions: [
                  ...questions,
                  { id: newId, question: item, answers: [] }
                ],
                highlights: highlights
              })
            );
            // setPreviousSelectedQuestion(newId);
          } else
            setNewQuestionMode({
              adding: false,
              value: "",
              addValue: "",
              shouldAdd: false
            });
        }}
        multiline={true}
        key={questions.length}
        text={newQuestionMode.value}
        onChange={e => {
          setNewQuestionMode({ adding: true, value: e.target.value });
        }}
        editingMode={true}
        onKeyPress={handleKeyPress}
      />
    </Box>
  );
}

NewQuestion.propTypes = {
  newQuestionMode: PropTypes.object.isRequired,
  setNewQuestionMode: PropTypes.func.isRequired,
  scrollToBottom: PropTypes.func.isRequired
};

export default NewQuestion;
