import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Redux Dependencies

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  scrollBox: {
    width: "100%",
    height: "100%",
    // display: "flex", Can't use this! this will flex shrink child elements.
    overflow: "auto",
    paddingInline: theme.spacing(2)
  }
}));

// This is a presentational component that adds a overflow auto and padding on the scroller side

// Requierd props: children
// Optinal props:  flexDirection - overrides flex direction
//                 alignItems - overrides flex align items. Defaults to column

const ScrollBox = forwardRef(
  ({ children, flexDirection = "column", alignItems, className }, ref) => {
    //Hooks
    const classes = useStyles();

    return (
      <Box
        ref={ref}
        style={{ flexDirection, alignItems }}
        className={clsx(classes.scrollBox, className)}
      >
        {children}
      </Box>
    );
  }
);

ScrollBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  flexDirection: PropTypes.string,
  alignItems: PropTypes.string,
  className: PropTypes.string
};

ScrollBox.displayName = "ScrollBox";

export default ScrollBox;
