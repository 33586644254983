// Dependencies
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { motion } from "framer-motion";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { useGetTheme } from "../../hooks";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setSelectedQuestion } from "../../redux/grSlice";
import { updateSq3r } from "../../redux/firebaseMiddleware";
// Components
import QuestionEditMenu from "../menus/QuestionEditMenu";
import EditingButton from "../EditingButton";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  li: {
    display: "flex",
    fontSize: "16px",
    lineHeight: "24px",
    position: "relative",
    cursor: "pointer",
    "&:hover button, &:focus-within button": {
      visibility: "visible"
    }
  },
  liLtr: {
    textAlign: "left",
    "& button": {
      right: "0px"
    }
  },
  liRtl: {
    textAlign: "right",
    "& button": {
      left: "0px"
    }
  },
  questionText: {
    fontSize: "16px",
    flex: 1,
    textAlign: "initial",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingInlineEnd: theme.spacing(0.5),
    paddingInlineStart: theme.spacing(0.5),
    color: "inherit"
  }
}));

function QuestionBox({
  icon,
  editable = true,
  question,
  index,
  onDelete,
  color
}) {
  // Hooks
  const inputRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useGetTheme({ alwase: "dark" });

  // Redux state
  const highlights = useSelector(state => state.gr.highlights);
  const questions = useSelector(state => state.gr.questions);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const selectedQuestionId = useSelector(state => state.gr.selectedQuestionId);
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);

  // Ephemeral state
  const [mousePos, setMousePos] = useState({});
  const [editingMode, setEditingMode] = useState(null);

  useEffect(() => {
    if (editingMode && inputRef.current) {
      inputRef.current.focus();
      if (inputRef.current.setSelectionRange) {
        //  tempRef.current.setSelectionRange(text.length, text.length);
      }
    }
  }, [editingMode, inputRef]);

  const handleQuestionChange = item => event => {
    let updatedQuestions = questions.map(el => {
      if (el.id === item.id) {
        return { ...el, question: event.target.value };
      } else return el;
    });

    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: updatedQuestions,
        highlights: highlights
      })
    );
  };

  const renderEditControl = () => {
    return (
      <EditingButton
        rows={2}
        multiline={true}
        text={question.question}
        onChange={handleQuestionChange(question)}
        editingMode={question.id === editingMode}
        editIcon={<span />}
        onFocusOut={value => {
          if (!value) {
            dispatch(
              updateSq3r({
                textId: selectedTextId,
                questions: questions.filter(el => el.id !== question.id),
                highlights: highlights
              })
            );
          }
          setEditingMode(null);
        }}
        onKeyPress={ev => {
          if (ev.key === "Enter") {
            // Do code here
            setEditingMode(null);
            if (!ev.target.value) {
              dispatch(
                updateSq3r({
                  textId: selectedTextId,
                  questions: questions.filter(el => el.id !== question.id),
                  highlights: highlights
                })
              );
            } else dispatch(setSelectedQuestion({ id: question.id }));

            ev.preventDefault();
          }
        }}
      />
    );
  };

  const enableQuestionSelection =
    (grMode === "light" && stage !== 0) || (grMode === "full" && stage > 1);

  // Render
  return (
    <motion.div
      className={clsx(classes.li, classes.liLtr)}
      initial={
        (selectedQuestionId === question.id ||
          Boolean(editingMode === question.id)) &&
        enableQuestionSelection
          ? "selected"
          : "default"
      }
      variants={{
        selected: {
          backgroundColor: theme.palette[color].main,
          color: "#000"
        },
        default: {
          backgroundColor: alpha(theme.palette[color].main, 0),
          color: "#FFF"
        }
      }}
      animate={
        (selectedQuestionId === question.id ||
          Boolean(editingMode === question.id)) &&
        enableQuestionSelection
          ? "selected"
          : "default"
      }
      style={{
        cursor: editable ? "inherit" : "pointer"
      }}
      transition={{
        duration:
          selectedQuestionId === question.id || Boolean(editingMode) ? 0.3 : 0
      }}
      onClick={() => {
        if (!enableQuestionSelection) return;
        if (editable) {
          if (
            editingMode !== question.id &&
            selectedQuestionId !== question.id
          ) {
            dispatch(setSelectedQuestion(question));
          } else if (
            selectedQuestionId === question.id &&
            editingMode !== question.id
          )
            dispatch(setSelectedQuestion(false));
        } else {
          if (selectedQuestionId !== question.id) {
            dispatch(setSelectedQuestion(question));
          } else {
            dispatch(setSelectedQuestion(false));
          }
        }
      }}
      // role="button"
      // tabIndex="0"
      aria-pressed={selectedQuestionId === question.id && "true"}
    >
      {icon}
      {editable && editingMode === question.id ? (
        renderEditControl()
      ) : (
        <Typography dir="auto" className={clsx(classes.questionText)}>
          {question.question}
        </Typography>
      )}
      {editable && (
        <QuestionEditMenu
          selected={
            selectedQuestionId === question.id && enableQuestionSelection
          }
          open={"X" in mousePos}
          handleClose={() => {
            setMousePos({});
          }}
          onDelete={e => {
            dispatch(
              updateSq3r({
                textId: selectedTextId,
                questions: questions.filter(el => el.id !== question.id),
                highlights: highlights
              })
            );
            setMousePos({});
            onDelete && onDelete(question, index);
          }}
          onEdit={e => {
            dispatch(setSelectedQuestion({ id: question.id }));
            setEditingMode(question.id);
            setMousePos({});
          }}
          mouseX={mousePos ? mousePos.X : null}
          mouseY={mousePos ? mousePos.Y : null}
        />
      )}
    </motion.div>
  );
}

QuestionBox.propTypes = {
  icon: PropTypes.node,
  editable: PropTypes.bool,
  question: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
  color: PropTypes.oneOf(["secondary", "primary"]).isRequired
};

export default QuestionBox;
