// Dependencies
import React, { useState, useEffect, useRef } from "react";
import ePub from "epubjs";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  getCommentsDict,
  setActiveComment
} from "../../../redux/commentsSlice";
import { setClearSelection } from "../../../redux/readerActionsSlice";
import { deleteSelectedAnswer } from "../../../redux/grSlice";
import {
  updateHighlights,
  updateTask,
  updateThemes,
  updateSq3r,
  getNextId
} from "../../../redux/firebaseMiddleware";
import {
  closeAnnotatorBar,
  openAnnotatorBar,
  toggleAnnotatorBar
} from "../../../redux/highlightSlice";
import {
  getHighlights,
  shouldShowHighlights
} from "../../../redux/highlightsGetter";

// Components
import { EpubView } from "..";
import ReactReaderActions from "../../annotations/ReactReaderActions";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import { getHighlightColor } from "../../../utils/colors";
import { useGetSideBarWidth } from "../../../hooks";
import { CLOSED } from "../../../hooks/useGetSideBarWidth";
// Material UI
import { Box } from "@material-ui/core";

function ReactReader({ loadingView, locationChanged, ...props }) {
  const EpubCFI = new ePub.CFI();

  //Hooks
  const dispatch = useDispatch();
  const highlightRef = useRef(null);
  const containerRef = useRef(null);

  //Redux State

  const rtlValue = useSelector(state => state.user.userProfile.rtl);
  const highlightColor = useSelector(
    state => state.readerActions.persistentActionState.highlightColor
  );
  const showHighlights = useSelector(shouldShowHighlights);
  const clearSelection = useSelector(
    state => state.readerActions.clearSelection
  );
  const action = useSelector(
    state => state.readerActions.persistentActionState.actionBar
  );
  const comments = useSelector(getCommentsDict);
  const darkMode = useSelector(state => state.user.userProfile.darkMode);
  const annotatorMode = useSelector(
    state => state.readerActions.persistentActionState.annotatorMode
  );
  const grMode = useSelector(state => state.gr.mode);
  const stage = useSelector(state => state.gr.stage);
  const themes = useSelector(state => state.themes.themes);
  const selectedThemeId = useSelector(state => state.themes.selectedThemeId);
  const selectedQuestionIndex = useSelector(
    state => state.task.selectedQuestionIndex
  );
  const taskAnswers = useSelector(state => state.task.answers);
  const fontSizeValue = useSelector(state => state.user.userProfile.fontSize);
  const fontSize = useSelector(state => state.user.fontSizeOptions);
  const shownLocation = useSelector(state => {
    return state.readerActions.shownLocation;
  });
  const selectedTextId = useSelector(state => {
    return state.texts.selectedTextId;
  });

  const highlights = useSelector(state =>
    getHighlights(state, props.task, showHighlights)
  );

  /** save ref of higlights for updates state to be used
   * in a callback function for a rendition event */
  const highlightsRef = useRef(highlights);

  const textLocation = useSelector(state => {
    return state.readerActions.selectedLocation;
  });
  const selectedQuestion = useSelector(state => {
    let filtered =
      state.gr.questions &&
      state.gr.questions.filter &&
      state.gr.questions.filter(q => q.id === state.gr.selectedQuestionId);
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });
  const questions = useSelector(state => state.gr.questions);
  const grHighlights = useSelector(state => state.gr.highlights);
  const grAsTaskHighlights = useSelector(state => state.task.grHighlights);
  const sideBarWidth = useGetSideBarWidth();
  const isAnnotatorBarOpen = useSelector(
    state => state.highlighter.isAnnotatorBarOpen
  );

  //Ephemeral State
  const [underlinedElement, setUnderlinedElement] = useState(null);
  const [underlineCommentElements, setUnderlineCommentElements] = useState([]);
  const [rendition, setRendition] = useState(false);

  //Behavior

  // If user clicks outside, close the popup menu
  useOnClickOutside(containerRef, () => {
    if (isAnnotatorBarOpen) dispatch(closeAnnotatorBar());
  });

  // Close popup manu on any scroll in document
  useEffect(() => {
    document.addEventListener("scroll", onScroll, true);
    function onScroll() {
      if (isAnnotatorBarOpen) dispatch(closeAnnotatorBar());
    }
    return () => document.removeEventListener("scroll", onScroll, true);
  }, [dispatch, isAnnotatorBarOpen]);

  useEffect(() => {
    if (!rendition) return;
    /** Close anotation bar on every mousedown */
    rendition.on("mousedown", handleMouseDown);
    function handleMouseDown(e) {
      dispatch(closeAnnotatorBar());
      highlightRef.current = e.target;
    }
    /** Open anotation bar when clicking on a highlght */
    rendition.on("markClicked", cfi => {
      const contents = rendition.getContents();
      let pos = rendition.getContents()[0].locationOf(cfi);
      if (pos.x !== 0 || pos.y !== 0 || pos.left) {
        let updatedRange = rendition.getRange(cfi);

        let clientRect = updatedRange.getBoundingClientRect();
        const frameBounds =
          contents[0].document.defaultView.frameElement.getBoundingClientRect();
        // `target` can be an element or a range
        let newRect = {
          x: clientRect.x + frameBounds.x,
          y:
            clientRect.y + frameBounds.y > 60
              ? clientRect.y + frameBounds.y
              : 60,
          width: clientRect.width,
          height: clientRect.height,
          top:
            clientRect.top + frameBounds.y > 60
              ? clientRect.top + frameBounds.y
              : 60,
          left: clientRect.left + frameBounds.x,
          bottom: clientRect.top + frameBounds.y + clientRect.height,
          right: clientRect.left + frameBounds.x + clientRect.width
        };
        if (!props.readOnly || isAnnotatorBarOpen) {
          const highlight = highlightsRef.current.find(
            highlight => highlight.cfi === cfi
          );
          if (!highlight) return;
          dispatch(
            openAnnotatorBar({
              clientRectangle: newRect,
              selectedText: highlight,
              isHover: true,
              minimal: highlight.minimal
            })
          );
        }
      }
    });
  }, [rendition, dispatch]); // eslint-disable-line

  useEffect(() => {
    /** update ref of higlights for updates state to be used in
     * a callback function for a rendition event */
    highlightsRef.current = highlights;
    const higlightFound = highlights.find(
      highlight => highlight.cfi === underlinedElement
    );
    /** Clear underline if highlight was deleted   */
    if (!higlightFound && rendition) {
      rendition.annotations.remove(underlinedElement, "underline");
      setUnderlinedElement(null);
    }
  }, [JSON.stringify(highlights)]); // eslint-disable-line

  React.useEffect(() => {
    rendition && rendition.themes.default(fontSize[fontSizeValue]);
  }, [fontSize, fontSizeValue, rendition]);

  useEffect(() => {
    // setTimeout is fixing an issue where the resize is not setting the correct size when starting with the sidebar closed
    setTimeout(() => {
      if (rendition) {
        rendition.resize();
      }
    }, 0);
  }, [sideBarWidth]); // eslint-disable-line

  useEffect(() => {
    const shownCfi = shownLocation?.cfi;
    if (!shownCfi) return;
    rendition.annotations.remove(underlinedElement, "underline");
    setUnderlinedElement(shownCfi);
  }, [shownLocation?.cfi]); // eslint-disable-line

  useEffect(() => {
    if (underlinedElement) {
      rendition.annotations.underline(
        underlinedElement,
        {},
        () => {},
        undefined,
        {
          stroke: "none",
          "z-index": -1
        }
      );
    }
  }, [underlinedElement]); //eslint-disable-line

  const [highlightsElements, setHighlightsElements] = useState([]);

  //clear selection in book when an action was chosen in reader aciton
  useEffect(() => {
    let content = rendition && rendition.getContents()[0];
    let selection = content && content.window && content.window.getSelection();
    if (selection && selection.empty && clearSelection) {
      selection.empty();
      dispatch(setClearSelection(false));
    }
  }, [rendition, clearSelection, dispatch]);

  //focus on displayed location
  useEffect(() => {
    if (textLocation && rendition) {
      if (textLocation.cfi) {
        rendition.display(textLocation.cfi);
        //dispatch(setLocation(false));
      }
      // dispatch(setLocation(false));
    }
  }, [textLocation, rendition]);

  const rendManager = rendition && rendition.manager;

  //Effect for comments
  useEffect(() => {
    const setExistingCommentElements = items => {
      let toRemove = [];
      underlineCommentElements &&
        Object.entries(underlineCommentElements).forEach(([cfi, elem]) => {
          let commentEntry = comments[cfi];

          if (commentEntry) {
            // elem && elem.mark && elem.mark.element && (elem.mark.element.style.stroke = commentEntry[0].ref.color);
          } else {
            toRemove.push(cfi);
            rendition.annotations.remove(cfi, "underline");
          }
        });
      if (toRemove.length > 0) {
        const filtered = Object.keys(items)
          .filter(key => !toRemove.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: items[key]
            };
          }, {});

        return filtered;
      } else return items;
    };

    const addMissingComments = items => {
      let timeAdding = new Date().getTime();
      comments &&
        rendition &&
        rendition.manager &&
        Object.entries(comments).forEach(([cfi, comment]) => {
          let range = rendition.getRange(cfi);
          if (range && !(cfi in items)) {
            let hlStyleObj = {
              stroke: "none",
              underlineColor: "#168FEE",
              "z-index": 12,
              "stroke-opacity": 1
            };
            let addedComment = rendition.annotations.underline(
              cfi,
              {},
              e => {
                let timeClick = new Date().getTime();
                if (timeClick - timeAdding < 500) return;
                let contents = rendition.getContents();
                let foundSelection = false;
                contents.forEach(content => {
                  if (
                    content.window &&
                    content.window.getSelection() &&
                    content.window.getSelection().type === "Range"
                  ) {
                    foundSelection = true;
                  }
                });

                if (!foundSelection) {
                  dispatch(setActiveComment(comment));
                }
              },
              "underlineClass",
              hlStyleObj
            );

            items[cfi] = { ...addedComment, comment: comment };
            //addedHighlight.mark.element.style.visibility = showHighlights ? "visible":"hidden";

            addedComment.mark.element.style.cursor = "pointer";
            addedComment.mark.element.style.pointerEvents = "none";
          }
        });
      return items;
    };

    const renderComments = () => {
      let items = underlineCommentElements;

      items = setExistingCommentElements(items);
      //add new highlighyts
      items = addMissingComments(items);

      setUnderlineCommentElements(items);
    };
    renderComments();
  }, [
    comments,
    rendition,
    rendManager,
    annotatorMode,
    underlineCommentElements,
    dispatch
  ]);

  //end effect for comments
  useEffect(() => {
    const setExistingElements = items => {
      let toRemove = [];
      highlightsElements &&
        Object.entries(highlightsElements).forEach(([cfi, elem]) => {
          let highlightColl =
            highlights &&
            highlights.filter &&
            highlights.filter(hl => elem.highlight.cfi === hl.cfi);
          if (highlightColl && highlightColl[0]) {
            elem &&
              elem.mark &&
              elem.mark.element &&
              (elem.mark.element.style.fill = getHighlightColor(
                highlightColl[0].color,
                darkMode
              ));
          } else {
            toRemove.push(elem.highlight.cfi);
            rendition.annotations.remove(elem.highlight.cfi, "highlight");
          }
        });
      if (toRemove.length > 0) {
        const filtered = Object.keys(items)
          .filter(key => !toRemove.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: items[key]
            };
          }, {});

        return filtered;
      } else return items;
    };

    const addMissingHighlights = items => {
      highlights &&
        rendition &&
        rendition.manager &&
        highlights.forEach(highlight => {
          try {
            let range = highlight.cfi && rendition.getRange(highlight.cfi);
            if (range && !(highlight.cfi in items)) {
              let hlStyleObj = {
                "z-index": 10,
                "mix-blend-mode": "multiply",
                "fill-opacity": 0.8,
                fill: getHighlightColor(highlight.color, darkMode)
              };
              let addedHighlight = rendition.annotations.highlight(
                highlight.cfi,
                {},
                () => {},
                "highlightClass",
                hlStyleObj
              );
              items[highlight.cfi] = {
                ...addedHighlight,
                highlight: highlight
              };
            }
          } catch (error) {
            console.log("error adding highlight", error);
          }
        });
      return items;
    };

    const renderHighlights = () => {
      if (
        !showHighlights &&
        annotatorMode !== "poc" &&
        highlightsElements &&
        highlightsElements.length
      ) {
        //hide all highlights in some case
        highlights &&
          highlights.forEach(highlight => {
            rendition.annotations.remove(highlight.cfi, "highlight");
          });
        setHighlightsElements([]);
      } else {
        let items = highlightsElements;

        items = setExistingElements(items);
        //add new highlighyts
        items = addMissingHighlights(items);

        setHighlightsElements(items);
      }
    };
    rendition && rendition.book && renderHighlights();
  }, [
    highlights,
    showHighlights,
    rendition,
    rendManager,
    annotatorMode,
    highlightsElements,
    dispatch,
    darkMode,
    props.readOnly
  ]);

  const addTextToTheme = params => {
    if (!selectedThemeId) {
      return;
    }
    let modifiedThemes = themes.map(elem => {
      if (elem.id === selectedThemeId) {
        let quotes = elem.quotes ? elem.quotes : [];
        let highlight = {
          id: getNextId(quotes),
          cfi: params.cfi,
          source: "theme",
          text: params.text,
          ref: "",
          color: params.color
        };
        let newQuotes = [...quotes];
        if (
          newQuotes.filter(quote => quote.cfi === highlight.cfi).length === 0
        ) {
          newQuotes.push(highlight);
        }
        return { ...elem, quotes: newQuotes };
      } else return elem;
    });
    dispatch(updateThemes({ textId: selectedTextId, themes: modifiedThemes }));
  };

  const addTextToQuestion = params => {
    let items = selectedQuestion.answers
      ? selectedQuestion.answers.filter(a => a.cfi !== params.cfi)
      : [];

    let answers = [...items];
    let i = 0;
    let insertAt = answers.length;
    for (i = 0; i < answers.length; i++) {
      if (EpubCFI.compare(answers[i].cfi, params.cfi) > 0) {
        insertAt = i;
        break;
      }
      //Do something
    }
    answers.splice(insertAt, 0, {
      ...params,
      id: getNextId(answers),
      source: "grQuestion"
    });
    let question = { ...selectedQuestion, answers: answers };

    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: questions.map(el => {
          if (el.id === question.id) return question;
          else return el;
        }),
        highlights: grHighlights
      })
    );
  };

  const onTextSelected = params => {
    switch (true) {
      case props.readOnly:
        return;
      case (props.task.task_type === "guidedReading" &&
        grMode === "full" &&
        stage === 2) ||
        (grMode === "light" && stage === 1 && !selectedQuestion):
        return;
      case props.task.task_type === "guidedReading" &&
        grMode === "light" &&
        stage === 0:
      case props.task.task_type === "guidedReading" &&
        grMode === "full" &&
        stage < 2:
      case props.task.task_type === "guidedReading" &&
        selectedQuestion === false: {
        let updatedGrHighlights = [
          ...grAsTaskHighlights.filter(el => el.cfi !== params.selection.cfi)
        ];
        let i = 0;
        let insertAt = updatedGrHighlights.length;
        for (i = 0; i < updatedGrHighlights.length; i++) {
          if (
            EpubCFI.compare(updatedGrHighlights[i].cfi, params.selection.cfi) >
            0
          ) {
            insertAt = i;
            break;
          }
        }
        updatedGrHighlights.splice(insertAt, 0, {
          ...params.selection,
          source: "task",
          id: getNextId(grHighlights),
          color: "#00B9FB"
        });
        dispatch(setClearSelection(true));
        dispatch(
          updateTask({
            answers: [...taskAnswers],
            grHighlights: updatedGrHighlights
          })
        );
        break;
      }
      case action === "task" && props.task && props.task.id > 0: {
        let answersCopy = [...taskAnswers];

        const exsistingQoutes = answersCopy[selectedQuestionIndex].quotes || [];

        const newQuote = {
          ...params.selection,
          id: `${selectedQuestion.id}.${
            answersCopy[selectedQuestionIndex].quotes
              ? answersCopy[selectedQuestionIndex].quotes.length
              : 0
          }`,
          minimal: true,
          color: "#00B9FB"
        };

        answersCopy[selectedQuestionIndex] = {
          ...answersCopy[selectedQuestionIndex],
          quotes: [...exsistingQoutes, newQuote]
        };
        dispatch(setClearSelection(true));
        dispatch(
          updateTask({
            answers: answersCopy,
            grHighlights: grAsTaskHighlights
          })
        );
        break;
      }

      case annotatorMode === "highlight": {
        addHighlightToBook(params.selection);
        dispatch(setClearSelection(true));
        break;
      }

      case action === "themes" && annotatorMode === "themes": {
        dispatch(setClearSelection(true));
        addTextToTheme({ ...params.selection, color: highlightColor });
        break;
      }

      case action === "poc" && annotatorMode === "poc": {
        // if (params.selection.text === "") return;
        if (!params.selection.text || !params.selection.text.trim()) return;
        if (
          ((grMode === "full" && stage === 2) ||
            (grMode === "light" && stage === 1)) &&
          !selectedQuestion
        )
          return;
        if (
          ((grMode === "full" && stage === 2) ||
            (grMode === "light" && stage === 1)) &&
          selectedQuestion
        ) {
          dispatch(setClearSelection(true));
          addTextToQuestion({ ...params.selection, color: highlightColor });
        } else if (stage === 0) {
          let updatedGrHighlights = [
            ...grHighlights.filter(el => el.cfi !== params.selection.cfi)
          ];
          let i = 0;
          let insertAt = updatedGrHighlights.length;
          for (i = 0; i < updatedGrHighlights.length; i++) {
            if (
              EpubCFI.compare(
                updatedGrHighlights[i].cfi,
                params.selection.cfi
              ) > 0
            ) {
              insertAt = i;
              break;
            }
          }
          updatedGrHighlights.splice(insertAt, 0, {
            ...params.selection,
            source: "poc",
            id: getNextId(grHighlights),
            color: highlightColor
          });
          dispatch(setClearSelection(true));
          dispatch(
            updateSq3r({
              textId: selectedTextId,
              questions: questions,
              highlights: updatedGrHighlights
            })
          );
        } else {
          if (params.selection && params.selection.text) {
            dispatch(
              toggleAnnotatorBar({
                clientRectangle: params.clientRect,
                selectedText: params.selection
              })
            );
          }
        }
        break;
      }

      case !annotatorMode: {
        if (params.selection && params.selection.text) {
          dispatch(
            toggleAnnotatorBar({
              clientRectangle: params.clientRect,
              selectedText: params.selection
            })
          );
        }
        break;
      }
    }
  };

  const onRenditionCreated = rendition => {
    setHighlightsElements([]);
    setUnderlineCommentElements([]);
    setRendition(rendition); // TODO: Why is the rendition saved in state and not a variable?
    props.renditionCreated && props.renditionCreated(rendition);
  };

  const addHighlightToBook = (highlight, color, shouldDelete = false) => {
    if (annotatorMode === "poc") {
      let updatedGrHighlights = [
        ...grHighlights.filter(el => el.cfi !== highlight.cfi),
        {
          ...highlight,
          source: "poc",
          color: highlightColor
        }
      ];
      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: questions,
          highlights: updatedGrHighlights
        })
      );
    } else {
      let adjustedHighlightColor = color ? color : highlightColor;

      let adjustedHighlight = {
        ...highlight,
        source: "highlight",
        color: adjustedHighlightColor
      };
      let highlightColl = highlights.filter(
        el => el.source === "highlight" && el.cfi !== highlight.cfi
      );
      if (!shouldDelete) {
        highlightColl.push(adjustedHighlight);
      }

      dispatch(
        updateHighlights({ textId: selectedTextId, highlights: highlightColl })
      );
    }
  };

  const setHighlightColorCb = (highlight, color) => {
    if (highlight.source === "poc" && annotatorMode === "poc") {
      let updatedGrHighlights = [
        ...grHighlights.filter(el => el.cfi !== highlight.cfi),
        {
          ...highlight,
          source: "poc",
          color: color
        }
      ];
      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: questions,
          highlights: updatedGrHighlights
        })
      );
    } else if (highlight.source === "grQuestion" && annotatorMode === "poc") {
      let item = selectedQuestion.answers.filter(
        a => a.cfi === highlight.cfi
      )[0];
      let answers = [
        ...selectedQuestion.answers.filter(a => a.cfi !== highlight.cfi),
        { ...item, color: color }
      ];
      let question = { ...selectedQuestion, answers: answers };

      let updatedQuestions = questions.map(q => {
        if (q.id === selectedQuestion.id) {
          return question;
        } else return q;
      });

      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: updatedQuestions,
          highlights: grHighlights
        })
      );
    } else if (annotatorMode === "themes") {
      //get the current selected theme
      const selectedTheme = themes.find(theme => theme.id === selectedThemeId);

      // update the selected qoute with the selected color
      const updatedQuotes = selectedTheme.quotes.map(quote => {
        if (quote.cfi === highlight.cfi) {
          return { ...quote, color: color };
        } else {
          return quote;
        }
      });

      // clone themes, replacing the updated quotes
      const updatedThemes = themes.map(theme => {
        if (theme.id === selectedThemeId) {
          return { ...theme, quotes: updatedQuotes };
        } else {
          return theme;
        }
      });

      // update Redux store
      dispatch(
        updateThemes({
          textId: selectedTextId,
          themes: updatedThemes
        })
      );
    } else addHighlightToBook(highlight, color);
  };

  const removeHighlightCb = highlight => {
    const toggleBookHighlight =
      (grMode === "full" && (stage < 2 || selectedQuestion === false)) ||
      (grMode === "light" && (stage < 1 || selectedQuestion === false));
    const toggleAnswerHighlight = Boolean(
      ((grMode === "full" && stage === 2) ||
        (grMode === "light" && stage === 1)) &&
        (annotatorMode === "poc" || props.task.task_type === "guidedReading") &&
        selectedQuestion
    );

    if (shownLocation.cfi === highlight.cfi) {
      dispatch(deleteSelectedAnswer());
    }

    switch (true) {
      case annotatorMode === "themes": {
        // filter out the selected highlight from the quotes array
        const filteredQuotes = highlights.filter(
          el => el.cfi !== highlight.cfi
        );

        // clone themes, leaving out the filltered quote from the selected theme
        const updatedThemes = themes.map(theme => {
          if (theme.id === selectedThemeId) {
            return { ...theme, quotes: filteredQuotes };
          } else {
            return theme;
          }
        });

        // update Redux store
        dispatch(
          updateThemes({
            textId: selectedTextId,
            themes: updatedThemes
          })
        );
        return;
      }
      case highlight.source === "highlight": {
        dispatch(
          updateHighlights({
            textId: selectedTextId,
            highlights: highlights.filter(el => el.cfi !== highlight.cfi)
          })
        );
        return;
      }
    }
    let filtered = highlights.filter(el => el.cfi === highlight.cfi);
    if (!filtered || !filtered.length) return;

    const isTask = Boolean(props.task && props.task.id > 0);
    const isGrTask = isTask && props.task.task_type === "guidedReading";

    if (isTask) {
      let answers = [...taskAnswers];

      if (isGrTask) {
        let grHighlights = grAsTaskHighlights
          ? [...grAsTaskHighlights]
          : undefined;

        switch (true) {
          case toggleBookHighlight: {
            grHighlights = grAsTaskHighlights.filter(
              el => el.cfi !== highlight.cfi
            );
            break;
          }
          case toggleAnswerHighlight: {
            if (typeof answers[selectedQuestionIndex] === "undefined") {
              answers[selectedQuestionIndex] = [];
            }
            answers[selectedQuestionIndex] = {
              ...answers[selectedQuestionIndex],
              quotes: answers[selectedQuestionIndex].quotes.filter(
                el => el.cfi !== highlight.cfi
              )
            };
            break;
          }
          default:
            break;
        }
        dispatch(
          updateTask({
            answers,
            grHighlights
          })
        );
        // is task && !grTask
      } else {
        if (typeof answers[selectedQuestionIndex] === "undefined") {
          answers[selectedQuestionIndex] = [];
        }
        answers[selectedQuestionIndex] = {
          ...answers[selectedQuestionIndex],
          quotes: answers[selectedQuestionIndex].quotes.filter(
            el => el.cfi !== highlight.cfi
          )
        };

        dispatch(updateTask({ answers }));
      }
    } else {
      let questionsCopy = [...questions];
      let grHighlightsCopy = [...grHighlights];
      switch (true) {
        case toggleBookHighlight: {
          grHighlightsCopy = grHighlightsCopy.filter(
            el => el.cfi !== highlight.cfi
          );
          break;
        }
        case toggleAnswerHighlight: {
          let answers = selectedQuestion.answers.filter(
            a => a.cfi !== highlight.cfi
          );
          let question = { ...selectedQuestion, answers: answers };

          questionsCopy = questionsCopy.map(el => {
            if (el.id !== selectedQuestion.id) return el;
            else return question;
          });
          break;
        }
        default:
          break;
      }
      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: questionsCopy,
          highlights: grHighlightsCopy
        })
      );
    }
  };

  return (
    <Box ref={containerRef} style={{ height: "100%" }}>
      <ReactReaderActions
        id="reactReaderActions"
        minimal={props.minimalBar}
        highlightFunc={addHighlightToBook}
        removeHighlightCb={removeHighlightCb}
        setHighlightColor={setHighlightColorCb}
      >
        <EpubView
          // ref={epubViewRef}
          fullSize={true}
          fontSize={fontSize[fontSizeValue]}
          epubOptions={{ flow: "scrolled-doc" }}
          loadingView={loadingView}
          darkMode={darkMode}
          rtl={rtlValue}
          {...props}
          locationChanged={locationChanged}
          handleTextSelected={onTextSelected}
          onRenditionCreated={onRenditionCreated}
          bodyClassName="gr"
        />
      </ReactReaderActions>
    </Box>
  );
}

export default ReactReader;
