// Dependencies
import React, { useRef, useEffect } from "react";
import clsx from "clsx";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// Components
import HeaderToolbar from "./components/menus/HeaderToolbar";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { validateFeedback } from "./redux/taskSlice";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
  AppBar,
  Button,
  Toolbar,
  Link,
  Box,
  Breadcrumbs
} from "@material-ui/core";
//import { GridColumnsToolbarButton } from "@material-ui/data-grid";
import {
  setBreadcrumbsClicked,
  // setShowDoneButton,
  setSubmitButtonClicked
} from "./redux/readerActionsSlice";
// Styles
const useStyles = makeStyles(theme => {
  return {
    selected: { color: theme.palette.secondary.main },

    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      backgroundColor: "black",
      color: "white",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    readerToolbar: {
      height: "56px",
      minHeight: "56px",
      display: "flex",
      position: "relative",
      alignItems: "center",
      paddingLeft: "20px"
    },

    breadcrumbs: {
      height: "56px",
      display: "flex",
      overflow: "auto",
      scrollbarWidth: "0px",
      scrollbarHeight: "0px",
      "& ol": {
        height: "100%",
        flexWrap: "nowrap"
      },
      "&::-webkit-scrollbar": {
        width: "0",
        height: "0" /* Remove scrollbar space */,
        background: "transparent" /* Optional: just make scrollbar invisible */
      }
    },

    crimsonText: {
      fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"'
    },
    brightText: {
      color: "#ffffff"
    },
    darkText: {
      color: "#000000"
    },
    pointer: {
      cursor: "pointer"
    },
    readerBar: {
      backgroundColor: "#5ec891",

      position: "sticky",
      top: "0",
      zIndex: 0
      // zIndex: 1300,
    },
    blueBg: {
      backgroundColor: "#168FEE"
    },
    headerActionsContainer: {
      position: "absolute",
      right: "24px"
    },
    submitButton: {
      paddingLeft: "16px",
      paddingRight: "16px",
      marginLeft: "24px",
      fontSize: "13px",
      fontWeight: "bold",
      letterSpacing: "1px",
      backgroundColor: "#ffffff",
      color: "#168FEE",
      "&:hover": {
        backgroundColor: "#E8E8E8",
        color: "#168FEE"
      },
      "&:disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
        color: theme.palette.text.disabled
      }
    }
  };
});

export default function TopBar({ setModalVisible, ...props }) {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  //const location = useLocation();
  const dispatch = useDispatch();
  const appBarRef = useRef(null);

  //Redux State
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const task = useSelector(state => state.task.taskRow);
  const feedbackTask = useSelector(state => state.task.feedbackTask);
  const currentUser = useSelector(state => state.user.auth.uid);

  //const selectedText = useSelector((state) => state.texts.selectedText);
  const action = useSelector(
    state => state.readerActions.persistentActionState.actionBar
  );
  const selectedCourse = useSelector(
    state => state.user.userProfile.selectedCourse
  );

  const breadcrumbs = useSelector(state => state.readerActions.breadcrumbs);
  const showSubmitTask = useSelector(
    state => state.readerActions.showSubmitTask
  );
  const showSendFeedback = useSelector(
    state => state.readerActions.showSendFeedback
  );

  const showTextMenu = useSelector(state => state.readerActions.showTextMenu);
  const headerMenuRef = useRef();

  const blueBreadcrumbs = useSelector(
    state => state.readerActions.blueBreadcrumbs
  );
  // Variables

  const getMenuWidth = () => {
    let rect =
      headerMenuRef &&
      headerMenuRef.current &&
      headerMenuRef.current.getBoundingClientRect();

    return rect && rect.width ? "" + (rect.width + 32) + "px" : "0px";
  };

  const renderBreadcrumbs = () => {
    let menuWidth = getMenuWidth();
    let styleObj = { maxWidth: "calc(100vw - " + menuWidth + ")" };
    return (
      breadcrumbs && (
        <Breadcrumbs
          className={classes.breadcrumbs}
          style={styleObj}
          separator={
            rtl ? (
              <ChevronLeftIcon
                fontSize="small"
                className={clsx(
                  !blueBreadcrumbs && classes.darkText,
                  blueBreadcrumbs && classes.brightText
                )}
              />
            ) : (
              <ChevronRightIcon
                fontSize="small"
                className={clsx(
                  !blueBreadcrumbs && classes.darkText,
                  blueBreadcrumbs && classes.brightText
                )}
              />
            )
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs
            .filter(a => a?.text)
            .map((part, i) => (
              <Link
                component={RouterLink}
                to={part.url ? part.url : "#"}
                key={i}
                className={clsx(
                  classes.crimsonText,
                  classes.pointer,
                  !blueBreadcrumbs && classes.darkText,
                  blueBreadcrumbs && classes.brightText
                )}
                onClick={() => {
                  /*  part.resetCourse && selectCourse(false);
                part.course && selectCourse(part.course);
                part.url && history.push(part.url);*/
                  dispatch(setBreadcrumbsClicked(part));
                }}
                color="textPrimary"
                role="link" //FIXME: Change this element to a link and get rid of role and tabindex
                tabIndex="0"
                aria-current={i === breadcrumbs.length - 1 ? "page" : false} // TODO: find better way to check if this is the current page
              >
                {part.text}
              </Link>
            ))}
        </Breadcrumbs>
      )
    );
  };

  return (
    <AppBar
      component="div"
      className={clsx(
        classes.readerBar,
        // task && action === "task" && classes.blueBg
        blueBreadcrumbs && classes.blueBg
      )}
      ref={appBarRef}
      elevation={1}
      dir={rtl ? "rtl" : "ltr"}
    >
      <Toolbar className={classes.readerToolbar}>
        {renderBreadcrumbs()}

        <Box ref={headerMenuRef} className={classes.headerActionsContainer}>
          {/* <HeaderToolbar color={task && action == "task" ? "white" : "black"} /> */}
          <HeaderToolbar
            textMenu={showTextMenu}
            color={blueBreadcrumbs ? "white" : "black"}
          />
          {showSubmitTask &&
            action === "task" &&
            task &&
            task.task_type !== "peerReview" &&
            selectedCourse &&
            selectedCourse.course_role.toLowerCase() === "student" &&
            task.submission_status !== "Submitted" && (
              <Button
                className={classes.submitButton}
                size="small"
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                <FormattedMessage
                  id="task.submit"
                  defaultMessage="Submit task"
                />
              </Button>
            )}
          {showSubmitTask &&
            (action === "" || action === "task") &&
            task &&
            task.task_type === "peerReview" &&
            task.owner === currentUser &&
            selectedCourse &&
            selectedCourse.course_role.toLowerCase() === "student" &&
            (task.submission_status === "Active" ||
              task.submission_status === "Pending") && (
              <Button
                className={classes.submitButton}
                size="small"
                onClick={() => {
                  dispatch(setSubmitButtonClicked(true));
                  // setModalVisible(true);
                }}
              >
                <FormattedMessage id="task.share" defaultMessage="Share" />
              </Button>
            )}
          {showSubmitTask &&
            (action === "" || action === "task") &&
            task &&
            task.task_type === "peerReview" &&
            task.owner === currentUser &&
            selectedCourse &&
            selectedCourse.course_role.toLowerCase() === "student" &&
            task.submission_status === "pendingResponse" && (
              <Button
                className={classes.submitButton}
                size="small"
                onClick={() => {
                  history.push(`/tasks/${task.course_id}`);
                  // setModalVisible(true);
                }}
              >
                <FormattedMessage id="gr.confirm.btn" defaultMessage="Done" />
              </Button>
            )}
          {showSubmitTask &&
            task?.owner === currentUser &&
            (action === "" || action === "task") &&
            task?.task_type === "peerReview" &&
            selectedCourse?.course_role?.toLowerCase() === "student" &&
            (task?.submission_status === "Submitted" ||
              task?.submission_status === "Graded") &&
            task.collaboration_status !== "Answered" && (
              <Button
                className={classes.submitButton}
                size="small"
                onClick={() => {
                  dispatch(setSubmitButtonClicked(true));
                }}
              >
                <FormattedMessage id="task.share" defaultMessage="Reply" />
              </Button>
            )}
          {showSubmitTask &&
            task.task_type === "peerReview" &&
            task.owner !== currentUser &&
            selectedCourse?.course_role?.toLowerCase() === "student" &&
            selectedCourse &&
            task.submission_status === "pendingResponse" && (
              <Button
                className={classes.submitButton}
                size="small"
                onClick={() => {
                  dispatch(setSubmitButtonClicked(true));

                  // setModalVisible(true);
                }}
              >
                <FormattedMessage id="task.submit" defaultMessage="Submit" />
              </Button>
            )}

          {showSubmitTask &&
            task.task_type === "peerReview" &&
            selectedCourse?.course_role === "Teacher" &&
            selectedCourse &&
            task.submission_status === "pendingResponse" && (
              <Button
                className={classes.submitButton}
                size="small"
                onClick={() => {
                  history.push(`/tasks/${task.course_id}`);
                }}
              >
                <FormattedMessage id="gr.confirm.btn" defaultMessage="Done" />
              </Button>
            )}
          {showSubmitTask &&
            task.task_type === "peerReview" &&
            task.owner !== currentUser &&
            selectedCourse.course_role.toLowerCase() === "student" &&
            selectedCourse &&
            task.submission_status === "Submitted" && (
              <Button
                className={classes.submitButton}
                size="small"
                onClick={() => {
                  history.push(`/tasks/${task.course_id}`);
                }}
              >
                <FormattedMessage id="gr.confirm.btn" defaultMessage="Done" />
              </Button>
            )}
          {showSubmitTask &&
            task.task_type === "peerReview" &&
            task.owner === currentUser &&
            selectedCourse.course_role.toLowerCase() === "student" &&
            selectedCourse &&
            task.collaboration_status === "Answered" && (
              <Button
                className={classes.submitButton}
                size="small"
                onClick={() => {
                  history.push(`/tasks/${task.course_id}`);
                }}
              >
                <FormattedMessage id="gr.confirm.btn" defaultMessage="Done" />
              </Button>
            )}
          {showSubmitTask &&
            action === "task" &&
            task &&
            selectedCourse &&
            task.task_type !== "peerReview" &&
            selectedCourse.course_role.toLowerCase() === "student" &&
            task.submission_status === "Submitted" && (
              <Button
                className={classes.submitButton}
                size="small"
                onClick={() => {
                  history.push(`/tasks/${task.course_id}`);
                }}
              >
                <FormattedMessage id="gr.confirm.btn" defaultMessage="Done" />
              </Button>
            )}

          {showSendFeedback &&
            feedbackTask &&
            selectedCourse &&
            selectedCourse.course_role.toLowerCase() === "teacher" &&
            !feedbackTask.is_checked && (
              <Button
                className={classes.submitButton}
                size="small"
                onClick={() => {
                  dispatch(validateFeedback(true));
                }}
              >
                <FormattedMessage
                  id="task.feedback.submit"
                  defaultMessage="Send feedback"
                />
              </Button>
            )}

          {showSendFeedback && feedbackTask && feedbackTask.is_checked && (
            <Button
              className={classes.submitButton}
              size="small"
              onClick={() => {
                selectedCourse.course_role === "Teacher"
                  ? history.push(
                      `/tasks/course/${feedbackTask.course_id}/task/${feedbackTask.id}`
                    )
                  : history.push(`/tasks/${feedbackTask.course_id}`);
              }}
            >
              <FormattedMessage id="gr.confirm.btn" defaultMessage="Done" />
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
