// Dependencies
import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import ePub from "epubjs";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { enqueueFlashMessage, undo } from "../../redux/userSlice";
import { updateThemes } from "../../redux/firebaseMiddleware";

// Components
import ThemeQuoteCard from "./ThemeQuoteCard";

// Material UI
import { List, ListItem, Collapse } from "@material-ui/core";

export default function ThemeQuotesList({ theme, openThemes }) {
  //Hooks
  const dispatch = useDispatch();
  const intl = useIntl();

  //Redux State
  const themes = useSelector(state => state.themes.themes);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Ephemeral State
  const [undoData, setUndoData] = useState(null);
  const [quotes, setQuotes] = useState(null);

  //Variables
  var EpubCFICompare = new ePub.CFI();

  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoDeleteQuestion = () => {
      if (undoData?.type === "delAnswer") {
        const themeId = undoData.previusCollectionState.id;
        const qouteToReinsert = undoData.previusItemState;
        // Get the current instanse of the theme object
        let currentTheme = themes.find(theme => theme.id === themeId);
        // Get the previus quotes array (before one was deleted)
        const previusQuotes = undoData.previusCollectionState.quotes;

        let reinsertAt = previusQuotes.length;
        for (let i = 0; i < previusQuotes.length; i++) {
          if (
            EpubCFICompare.compare(previusQuotes[i].cfi, qouteToReinsert.cfi) >
            0
          ) {
            reinsertAt = i;
            break;
          }
        }

        const updatedQuotes = [...currentTheme.quotes];
        updatedQuotes.splice(reinsertAt, 0, qouteToReinsert);
        const updatedTheme = { ...currentTheme, quotes: updatedQuotes };

        dispatch(
          updateThemes({
            textId: undoData.selectedTextId,
            themes: themes.map(theme => {
              if (theme.id === themeId) return updatedTheme;
              else return theme;
            })
          })
        );
      }
    };

    if (shouldUndo) {
      undoDeleteQuestion();
      dispatch(undo(false));
      // OPTION: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [shouldUndo, dispatch, EpubCFICompare, undoData, themes]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "delete.card",
            defaultMessage: "Card deleted"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undoData, dispatch, intl]);

  const removeQuote = (theme, quote) => {
    let modeifiedQuotes = theme.quotes.filter(a => a.cfi !== quote.cfi);
    dispatch(
      updateThemes({
        textId: selectedTextId,
        themes: themes.map(item => {
          if (item.id === theme.id) {
            return { ...item, quotes: modeifiedQuotes };
          } else return item;
        })
      })
    );
    setUndoData({
      type: "delAnswer",
      previusItemState: quote,
      previusCollectionState: theme,
      selectedTextId: selectedTextId
    });
  };

  useEffect(() => {
    if (theme.quotes) {
      setQuotes(
        theme.quotes
          .slice()
          .filter(el => el.cfi)
          .sort(function (a, b) {
            return EpubCFICompare.compare(a.cfi, b.cfi);
          })
      );
    }
    // adding EpubCFICompare to the dependancy list crushes the app
  }, [theme.quotes]);

  // Render
  return (
    <Collapse in={openThemes.includes(theme.id)} timeout="auto" unmountOnExit>
      <List>
        {quotes &&
          quotes.map((quote, index) => {
            return (
              <ListItem key={index}>
                <ThemeQuoteCard
                  quote={quote}
                  index={index}
                  group={theme}
                  // themes={themes}
                  onDelete={removeQuote}
                />
              </ListItem>
            );
          })}
      </List>
    </Collapse>
  );
}
