import { firebaseApp, firestore, firebaseFunctions, auth } from "../firebase";
import { createAction } from "@reduxjs/toolkit";

export const getNextId = collection => {
  if (!collection || collection.length === 0) return 1;
  return (
    Math.max.apply(
      Math,
      collection.map(t => {
        return t.id && Number.isInteger(t.id) ? t.id : 0;
      })
    ) + 1
  );
};

const saveTaskState = firebaseFunctions.httpsCallable("courses-setStatus");

const gotHighlights = createAction("gotHighlights");
const gotComments = createAction("gotComments");
const gotPublicComments = createAction("gotPublicComments");
const gotAnswers = createAction("gotAnswers");
// This is a dummy action that only logs user navigation actions
export const userNavigated = createAction("userNavigated");

export const updateHighlights = createAction("updateHighlights");
export const updateThemes = createAction("updateThemes");
export const updatePrivateComments = createAction("updatePrivateComments");
export const updatePublicComments = createAction("updatePublicComments");
export const addPublicComments = createAction("addPublicComments");
export const deletePublicComments = createAction("deletePublicComments");
export const editReplyPublicComments = createAction("editReplyPublicComments");
export const addReplyPublicComments = createAction("addReplyPublicComments");
export const deleteReplyPublicComments = createAction(
  "deleteReplyPublicComments"
);
export const editPublicComments = createAction("editPublicComments");
const gotThemes = createAction("gotThemes");
export const updateSq3r = createAction("updateSq3r");
export const addHighlightsToGrTask = createAction("addHighlightsToGrTask");
export const saveGRState = createAction("saveGRState");
export const updateTask = createAction("updateTask");
export const mergeGrs = createAction("mergeGrs");
const gotSq3r = createAction("gotSq3r");

const gotNotifications = createAction("gotNotifications");
export const markNotificationAsRead = createAction("markNotificationAsRead");
export const resetNotificationBedge = createAction("resetNotificationBedge");
export const updateNotificationBedge = createAction("updateNotificationBedge");

const gotActiveTask = createAction("gotActiveTask");
export const setActiveTaskPerText = createAction("setActiveTaskPerText");
export const removeActiveTaskPerText = createAction("removeActiveTaskPerText");
//firestore.doc("/texts/14").set({"questions":[{"question":"מה הסוגיה המרכזית בטקסט?","text":"מה הסוגיה המרכזית בטקסט?","id":1},{"text":"מה עמדת המחבר בנוגע לסוגיה זאת?","question":"מה עמדת המחבר בנוגע לסוגיה זאת?","id":2},{"text":"מהן הטענות המרכזיות בטקסט?","question":"מהן הטענות המרכזיות בטקסט?","id":3},{"text":"כיצד מדגימ/ה המחבר/ת את הטענות?","id":4,"question":"כיצד מדגימ/ה המחבר/ת את הטענות?"},{"id":5,"text":"מהם המושגים או הדמויות המרכזיים בטקסט?","question":"מהם המושגים או הדמויות המרכזיים בטקסט?"}]});
const listenerUnsubscribeList = [];
const taskUList = [];
export function fetchTask(storeAPI, taskId) {
  taskUList.forEach(a => {
    a();
  });
  taskUList.splice(0, taskUList.length);
  if (taskId > 0) {
    let docId =
      "tasks/" + firebaseApp.auth().currentUser.uid + "/task/" + taskId;
    const unsubscribe = firestore.doc(docId).onSnapshot(
      snapshot => {
        let coll = snapshot.exists
          ? snapshot.data()
          : { answers: [], selectedQuestion: 0, status: "Pending" };
        storeAPI.dispatch(gotAnswers({ ...coll, taskId }));
      },
      error => {
        console.log("fetchFirebaseError tasks", error);
      }
      //   dispatch(fetchFirebaseError(error)) },
    );
    taskUList.push(unsubscribe);
  }
}

export function fetchFirebase(storeAPI, textId, selectedText = null) {
  if (firebaseApp.auth().currentUser) {
    listenerUnsubscribeList.forEach(a => a && a());
    listenerUnsubscribeList.splice(0, listenerUnsubscribeList.length);

    firestore
      .collection(
        "notifications/" +
          firebaseApp.auth().currentUser.uid +
          "/userNotifications"
      )
      .where("showToUser", "==", true)
      .onSnapshot(snapshot => {
        let notifications = [];
        snapshot.forEach(doc => {
          notifications.push({ id: doc.id, ...doc.data() });
        });
        storeAPI.dispatch(gotNotifications(notifications));
      });

    const unsubscribePrivateComments = firestore
      .doc(
        "comments/" + firebaseApp.auth().currentUser.uid + "/texts/" + textId
      )
      .onSnapshot(
        snapshot => {
          let coll = snapshot.exists ? snapshot.data().comments : [];
          storeAPI.dispatch(gotComments(coll));
        },
        error => {
          console.log("fetchFirebaseError themes", error);
        }
        //   dispatch(fetchFirebaseError(error)) },
      );
    listenerUnsubscribeList.push(unsubscribePrivateComments);

    if (textId > 0) {
      // dispatch(fetchFirebasePending());
      const unsubscribeHighlights = firestore
        .doc(
          "highlights/" +
            firebaseApp.auth().currentUser.uid +
            "/texts/" +
            textId
        )
        .onSnapshot(
          snapshot => {
            let coll = snapshot.exists ? snapshot.data().highlights : [];
            storeAPI.dispatch(gotHighlights({ coll: coll, textId: textId }));
          },
          error => {
            console.log("fetchFirebaseError highlights", error);
          }
          //   dispatch(fetchFirebaseError(error)) },
        );
      listenerUnsubscribeList.push(unsubscribeHighlights);

      const unsubscribeActiveTask = firestore
        .doc(
          "tasks/" +
            firebaseApp.auth().currentUser.uid +
            "/activeTaskPerText/" +
            textId
        )
        .onSnapshot(
          snapshot => {
            let activeTask = snapshot.exists
              ? snapshot.data().activeTask
              : null;
            storeAPI.dispatch(gotActiveTask({ activeTask }));
          },
          error => {
            console.log("fetchFirebaseError activeTask", error);
          }
          //   dispatch(fetchFirebaseError(error)) },
        );
      listenerUnsubscribeList.push(unsubscribeActiveTask);

      const unsubscribePrivateComments = firestore
        .doc(
          "comments/" + firebaseApp.auth().currentUser.uid + "/texts/" + textId
        )
        .onSnapshot(
          snapshot => {
            // let coll = snapshot.exists ? snapshot.data().comments : [];
            // storeAPI.dispatch(gotComments(coll));
          },
          error => {
            // console.log("fetchFirebaseError themes", error);
          }
          //   dispatch(fetchFirebaseError(error)) },
        );
      listenerUnsubscribeList.push(unsubscribePrivateComments);

      const unsubscribePublicComments = firestore
        .collection("publicComments/" + textId + "/comments2/")
        .onSnapshot(querySnapshot => {
          const tempDoc = querySnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id };
          });

          storeAPI.dispatch(gotPublicComments(tempDoc));
        });

      listenerUnsubscribeList.push(unsubscribePublicComments);

      const unsubscribeThemes = firestore
        .doc(
          "themes/" + firebaseApp.auth().currentUser.uid + "/texts/" + textId
        )
        .onSnapshot(
          snapshot => {
            let coll = snapshot.exists ? snapshot.data().themes : [];
            storeAPI.dispatch(gotThemes(coll));
          },
          error => {
            console.log("fetchFirebaseError themes", error);
          }
          //   dispatch(fetchFirebaseError(error)) },
        );
      listenerUnsubscribeList.push(unsubscribeThemes);
      // This is a band-aid for a larger issue: There are two compeating data structures, task.questions in pgsql returns an object {question: []}, while grTasks returns the array []. if possible, this needs to be fixed in the psql db.
      let defaultQuestions = [];

      if (selectedText && Array.isArray(selectedText)) {
        defaultQuestions = selectedText;
      } else if (selectedText && typeof selectedText === "object") {
        defaultQuestions = selectedText.questions;
      } else if (
        storeAPI.getState().texts.selectedText &&
        storeAPI.getState().texts.selectedText?.questions
      ) {
        defaultQuestions = storeAPI.getState().texts.selectedText?.questions;
      }

      // const textObj = selectedText;
      //   ? selectedText
      //   : storeAPI.getState().texts.selectedText;
      let userId = firebaseApp.auth().currentUser.uid;
      //'xIZ2HmYhONMaeKTYhdDHGZzRS522';

      const unsubscribeSq3r = firestore
        //firebaseApp.auth().currentUser.uid
        .doc("sq3r/" + userId + "/texts/" + textId)
        .onSnapshot(
          snapshot => {
            if (snapshot.exists) {
              storeAPI.dispatch(
                gotSq3r({ ...snapshot.data(), textId: textId })
              );
            } else {
              const emptyGEdoc = {
                updatedAt: new Date().toISOString(),
                highlights: [],
                // questions: defaultQuestions || [],
                questions: [],
                status: "Active",
                grMode: "light",
                grStage: 0,
                grQuestionId: false,
                grShowAnswers: false,
                grShowHighlights: false
              };

              firestore
                .doc(
                  `sq3r/${firebaseApp.auth().currentUser.uid}/texts/${textId}`
                )
                .set(emptyGEdoc);
            }
          },
          error => {
            console.log("fetchFirebaseError sq3r", error);
          }
          //   dispatch(fetchFirebaseError(error)) },
        );
      listenerUnsubscribeList.push(unsubscribeSq3r);
    }
  }
  auth.onAuthStateChanged(function (user) {
    if (!user) {
      listenerUnsubscribeList.forEach(a => a && a());
      listenerUnsubscribeList.splice(0, listenerUnsubscribeList.length);
    }
  });
}

function handlePublicCommentsActions(storeAPI, firestore, action) {
  if (action.type === "addPublicComments") {
    firestore
      .collection("publicComments/" + action.payload.textId + "/comments2")
      .add(action.payload.comment);
    //set userActions
  }
  if (action.type === "deletePublicComments") {
    firestore
      .collection("publicComments/" + action.payload.textId + "/comments2")
      .doc(action.payload.comment.id)
      .delete();
    //set userActions
  }
  if (action.type === "editPublicComments") {
    return firestore.runTransaction(transaction => {
      let docRef = firestore
        .collection("publicComments/" + action.payload.textId + "/comments2/")
        .doc(action.payload.commentId);

      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(docRef).then(sfDoc => {
        if (sfDoc.exists) {
          transaction.update(docRef, {
            content: action.payload.content,
            title: action.payload.title ? action.payload.title : "",
            updatedAt: new Date().toISOString()
          });
          //set userActions
        }
      });
    });
  }

  if (action.type === "editReplyPublicComments") {
    return firestore.runTransaction(transaction => {
      let docRef = firestore
        .collection("publicComments/" + action.payload.textId + "/comments2/")
        .doc(action.payload.commentId);

      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(docRef).then(sfDoc => {
        if (sfDoc.exists) {
          let replies =
            sfDoc.data().replies && sfDoc.data().replies.length
              ? sfDoc.data().replies
              : [];
          // Add one person to the city population.
          // Note: this could be done without a transaction
          //       by updating the population using FieldValue.increment()
          let newReplies = replies.map(r => {
            if (r.id === action.payload.replyId) {
              return {
                ...r,
                text: action.payload.text,
                updatedAt: new Date().toISOString()
              };
            } else return r;
          });

          transaction.update(docRef, { replies: newReplies });
          //set userActions
        }
      });
    });
  }
  if (action.type === "addReplyPublicComments") {
    return firestore.runTransaction(transaction => {
      let docRef = firestore
        .collection("publicComments/" + action.payload.textId + "/comments2/")
        .doc(action.payload.commentId);

      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(docRef).then(sfDoc => {
        if (sfDoc.exists) {
          let replies =
            sfDoc.data().replies && sfDoc.data().replies.length
              ? sfDoc.data().replies
              : [];
          // Add one person to the city population.
          // Note: this could be done without a transaction
          //       by updating the population using FieldValue.increment()
          let newReply = { ...action.payload.reply, id: getNextId(replies) };

          transaction.update(docRef, { replies: [...replies, newReply] });
          //set userActions
        }
      });
    });
  }

  if (action.type === "deleteReplyPublicComments") {
    return firestore.runTransaction(transaction => {
      let docRef = firestore
        .collection("publicComments/" + action.payload.textId + "/comments2/")
        .doc(action.payload.commentId);

      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(docRef).then(sfDoc => {
        if (sfDoc.exists) {
          var replies = sfDoc.data().replies.filter(rep => {
            return rep.id !== action.payload.replyId;
          });

          transaction.update(docRef, { replies: replies });
          //set userActions
        }
      });
    });
  }
}

const removeEmpty = obj => {
  let newObj = {};
  if (obj) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
      else if (obj[key] !== undefined) newObj[key] = obj[key];
    });
  }
  return newObj;
};
const logAction = (storeAPI, action, textId) => {
  let time = new Date().toISOString();
  if (
    storeAPI.getState().user &&
    firebaseApp.auth().currentUser &&
    storeAPI.getState().user.original_auth === -1
  ) {
    let actionRef =
      "userActions/" +
      firebaseApp.auth().currentUser.uid +
      "/logs/" +
      time.split("T")[0] +
      "/actions/" +
      action.type.replace("/", "_") +
      "/calls/";
    let actionObj = removeEmpty(action);
    if (textId) {
      actionObj = { textId: textId, ...actionObj };
    }
    firestore
      .collection(actionRef)
      .add({ ...actionObj, time: time })
      .catch();
  }
};

function firebaseMiddleware(storeAPI) {
  return function wrapDispatch(next) {
    return async function handleAction(action) {
      if (!action) return;

      firebaseApp.analytics().logEvent(action.type, action.payload);
      logAction(storeAPI, action, action.payload?.textId);
      if (action.type === "user/setAuth") {
        //unsubscribe from all collections
        fetchFirebase(storeAPI, 0);
        // fetchTask(storeAPI, 0);
      }
      if (action.type === "texts/setSelectedText") {
        fetchFirebase(storeAPI, action.payload);

        //set userActions
      }
      if (action.type === "texts/setPangeaText") {
        fetchFirebase(storeAPI, action.payload.id, action.payload);
      }
      if (action.type === "task/setSelectedTask") {
        fetchTask(storeAPI, action.payload);
      }
      if (
        storeAPI.getState().user &&
        storeAPI.getState().user.original_auth === -1
      ) {
        if (action.type === "updateHighlights") {
          firestore
            .doc(
              "highlights/" +
                firebaseApp.auth().currentUser.uid +
                "/texts/" +
                action.payload.textId
            )
            .set({
              highlights: action.payload.highlights,
              updatedAt: new Date().toISOString()
            });
        }

        if (action.type === "updateSq3r") {
          // const reduxState = storeAPI.getState();
          const currentUser = firebaseApp.auth().currentUser;
          let status =
            "status" in action.payload
              ? action.payload.status
              : storeAPI.getState().gr.status;
          firestore
            .doc("sq3r/" + currentUser.uid + "/texts/" + action.payload.textId)
            .update({
              updatedAt: new Date().toISOString(),
              highlights: action.payload.highlights || [],
              questions: action.payload.questions || [],
              status: status
              // grQuestionId:
              //   action.payload.selectedQuestionId === undefined
              //     ? reduxState.gr.selectedQuestionId
              //     : action.payload.selectedQuestionId
            })

            .catch(err => {
              console.log("failed", err);
            });
        }
        if (action.type === "mergeGrs") {
          const sq3rDocRef = firestore.doc(
            "sq3r/" +
              firebaseApp.auth().currentUser.uid +
              "/texts/" +
              action.payload.textId
          );
          const sq3rDoc = await sq3rDocRef.get();
          if (sq3rDoc.exists) {
            const esxistingQuestions = sq3rDoc.data().questions || [];
            const esxistingHighlights = sq3rDoc.data().highlights || [];
            let newId = 0;
            switch (true) {
              case !esxistingQuestions.length:
                newId = 0;
                break;
              case esxistingQuestions.length === 1:
                newId = esxistingQuestions[0].id + 1;
                break;
              default:
                newId =
                  esxistingQuestions.reduce(function (acc, q) {
                    return Math.max(acc, q.id);
                  }, -1) + 1;
                break;
            }
            const mergedQuestions = esxistingQuestions
              .concat(action.payload.questions)
              .reduce((acc, question) => {
                const questionsText = acc.map(q =>
                  q.question.toLowerCase().trim()
                );
                if (
                  !questionsText.includes(
                    question.question.toLowerCase().trim()
                  )
                ) {
                  acc.push(
                    Object.assign({}, question, {
                      id: question.id === undefined ? newId++ : question.id
                    })
                  );
                } else {
                  const index = questionsText.findIndex(
                    text => text === question.question.toLowerCase().trim()
                  );
                  acc[index].answers = acc[index].answers.concat(
                    question.answers
                  );
                  acc[
                    index
                  ].summary = `${acc[index].summary}\n\n${question.summary}`;
                }
                return acc;
              }, []);
            sq3rDocRef
              .update({
                questions: mergedQuestions,
                highlights: esxistingHighlights.concat(
                  action.payload.highlights
                )
              })
              .catch(err => {
                console.log("failed", err);
              });
          }
        }
        if (action.type === "saveGRState") {
          const reduxState = storeAPI.getState();
          const currentUser = firebaseApp.auth().currentUser;
          if (!reduxState.gr.textId || !currentUser) return;
          firestore
            .doc(
              "sq3r/" +
                firebaseApp.auth().currentUser.uid +
                "/texts/" +
                reduxState.gr.textId
            )
            .update({
              grMode: reduxState.gr.mode,
              grStage: reduxState.gr.stage,
              grShowHighlights: reduxState.gr.showHighlights || false,
              grShowAnswers: reduxState.gr.showAnswers || false,
              grQuestionId:
                reduxState.gr.selectedQuestionId === undefined
                  ? null
                  : reduxState.gr.selectedQuestionId
            })
            .then(() => {})
            .catch(err => {
              console.log("failed", err);
            });
        }

        if (action.type === "user/setProfile") {
          //console.log("set profile", action);
          let uid = firebaseApp.auth()?.currentUser?.uid;
          if (uid) {
            let docId = "users/" + firebaseApp.auth()?.currentUser?.uid;
            firestore.doc(docId).set(action.payload);
          }
        }
        if (action.type === "updateTask") {
          const reduxState = storeAPI.getState();
          const currentUser = firebaseApp.auth().currentUser;
          let docId =
            "tasks/" +
            currentUser.uid +
            "/task/" +
            reduxState.task.selectedTaskId;

          const updateObj = {
            answers: action.payload.answers,
            status: "Active",
            selectedQuestion:
              action.payload.selectedQuestion >= 0 // if question id is passed overide reading from task slice (uesed for gr tasks)
                ? action.payload.selectedQuestion
                : reduxState.task.selectedQuestionIndex,
            updatedAt: new Date().toISOString()
          };

          if (reduxState.task.taskRow.task_type === "guidedReading") {
            updateObj.grStep = reduxState.gr.stage || 0;
            updateObj.grShowAnswers = reduxState.gr.showAnswers || false;
            updateObj.grShowHighlights = reduxState.gr.showHighlights || false;
            updateObj.grHighlights = action.payload.grHighlights;
          }

          firestore.doc(docId).set(updateObj);

          saveTaskState({ task: action.payload.taskId });
        }
        if (action.type === "updateThemes") {
          firestore
            .doc(
              "themes/" +
                firebaseApp.auth().currentUser.uid +
                "/texts/" +
                action.payload.textId
            )
            .set({
              updatedAt: new Date().toISOString(),
              themes: action.payload.themes
            });
        }
        if (action.type === "updatePrivateComments") {
          firestore
            .doc(
              "comments/" +
                firebaseApp.auth().currentUser.uid +
                "/texts/" +
                action.payload.textId
            )
            .set({
              updatedAt: new Date().toISOString(),
              comments: action.payload.comments
            });
        }

        if (action.type === "markNotificationAsRead") {
          const userId = action.payload.userId;
          const notificationId = action.payload.notificationId;
          firestore
            .doc(`notifications/${userId}/userNotifications/${notificationId}`)
            .update({
              isRead: true
            });
        }
        // Update the isRead property based on the current url
        if (action.type === "updateNotificationBedge") {
          const userId = firebaseApp.auth().currentUser?.uid;
          const currentLocation = action.payload.currentLocation;

          // get userNotification collection for current user
          const notifications = firestore.collection(
            `notifications/${userId}/userNotifications`
          );

          // query for relevent notification from the collection
          const query = notifications
            .where("isRead", "==", false)
            .where("link", "==", currentLocation);

          // query DB and update the isRead attribute
          query.get().then(querySnapshot => {
            querySnapshot.forEach(docRef => {
              notifications
                .doc(docRef.id)
                .update({ isRead: true, includeInCounter: false });
            });
          });
        }
        if (action.type === "resetNotificationBedge") {
          const userId = firebaseApp.auth().currentUser?.uid;

          // get userNotification collection for current user
          const notifications = firestore.collection(
            `notifications/${userId}/userNotifications`
          );

          // query for relevent notification from the collection
          const query = notifications.where("includeInCounter", "==", true);

          // query DB and update the isRead attribute
          query.get().then(querySnapshot => {
            querySnapshot.forEach(docRef => {
              notifications.doc(docRef.id).update({ includeInCounter: false });
            });
          });
        }
        if (action.type === "setActiveTaskPerText") {
          const userId = firebaseApp.auth().currentUser?.uid;
          const textId = action.payload.textId;
          const taskUrl = action.payload.taskUrl;

          firestore.doc(`tasks/${userId}/activeTaskPerText/${textId}`).set({
            activeTask: taskUrl
          });
        }

        if (action.type === "removeActiveTaskPerText") {
          const userId = firebaseApp.auth().currentUser?.uid;
          const textId = action.payload.textId;
          firestore.doc(`tasks/${userId}/activeTaskPerText/${textId}`).set({
            activeTask: null
          });
        }

        handlePublicCommentsActions(storeAPI, firestore, action);
      }
      // Do anything here: pass the action onwards with next(action),
      // or restart the pipeline with storeAPI.dispatch(action)
      // Can also use storeAPI.getState() here
      return next(action);
    };
  };
}

export default firebaseMiddleware;
