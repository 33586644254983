// Dependencies
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ThemeProvider } from "@material-ui/core/styles";
import useGetTheme from "../../../hooks/useGetTheme";
import clsx from "clsx";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { updateTask } from "../../../redux/firebaseMiddleware";
import { setLocation } from "../../../redux/readerActionsSlice";
import { taskSelectors } from "../../../redux/taskSlice";

// Components
import CitationDialog from "../CreateTask/CitationDialog";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import DeleteFilled from "@material-ui/icons/Delete";
import {
  Divider,
  Box,
  List,
  ListItem,
  IconButton,
  Card,
  Typography
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  card: {
    position: "relative",
    padding: "8px",
    paddingBottom: "16px",
    width: "100%",
    textTransform: "none",
    "& button": {
      // visibility: "hidden",
      fontSize: "16px",
      position: "absolute",
      bottom: "2px",
      right: "2px"
    }
    // "&:hover": {
    // " & button": {
    // visibility: "visible"
    // }
    // }
  },
  quoteRef: {
    marginTop: "16px",
    fontSize: "12px",
    color: "#168fee",
    cursor: "pointer"
  },
  questionTitle: {
    fontSize: "0.75rem",
    paddingLeft: "16px",
    paddingRight: "16px",
    margin: "20px 0",
    textTransform: "uppercase",
    color: "#BCD9F1"
  },
  divider: {
    backgroundColor: "#616161",
    marginBottom: "12px"
  },
  divider2: {
    backgroundColor: "#616161",
    marginBottom: "16px",
    marginTop: "16px"
  },
  panelHeader: {
    color: "white",
    width: "100%",
    textTransform: "uppercase",
    marginTop: "16px"
  },
  panelHeaderLtr: {
    textAlign: "left"
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  question: {
    fontSize: "16px",
    marginTop: "8px",
    marginBottom: "16px",
    textTransform: "none",
    whiteSpace: "pre-wrap",
    textAlign: "initial",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  tabHeader: {
    minWidth: "20%",
    width: "50%",
    color: "#e0e0e0",
    borderBottom: "1px solid #616161",
    textTransform: "none",
    "&.MuiTab-textColorSecondary.Mui-selected": {
      color: "#e0e0e0"
    }
  },
  removeQuoteLtr: {
    right: "24px"
  },
  indicator: {
    background: "#e0e0e0"
  },
  quoteText: {
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"',
    textAlign: "start"
  }
}));

export default function QuestionPanel({
  // index,
  task,
  readText,
  questionsOrder,
  selected,
  ...props
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useGetTheme();

  // Redux state
  const taskAnswers = useSelector(state => state.task.answers);
  const selectedTaskId = useSelector(state => state.task.selectedTaskId);
  const question = useSelector(taskSelectors.question);
  const selectedQuestionIndex = useSelector(
    state => state.task.selectedQuestionIndex
  );
  // const answer = useSelector(taskSelectors.answer);
  const answer = useSelector(state => {
    if (
      state.task.answers &&
      typeof state.task.answers[selectedQuestionIndex] !== "undefined"
    ) {
      return state.task.answers[selectedQuestionIndex];
    } else {
      let mode =
        question.type === 0 || question.includeCitation
          ? "Citations"
          : "Answer";
      return { choice: -1, text: "", comment: 0, mode: mode, quotes: [] };
    }
  });

  const showCitationForQuestion = useSelector(
    state => state.task.showCitationForQuestion
  );

  const sourceTextLanguage = useSelector(
    state => state.texts.selectedText?.text_language
  );

  // Ephemeral State
  const [showCitation, setShowCitation] = useState(false);

  // Variables

  // Behavior
  const removeQuote = (quote, answerIndex) => {
    dispatch(
      updateTask({
        taskId: selectedTaskId,
        answers: taskAnswers.map((item, index) => {
          if (answerIndex !== index) {
            return item;
          } else {
            return {
              ...item,
              quotes: item.quotes.filter(el => el.cfi !== quote.cfi)
            };
          }
        }),
        selectedQuestion: selectedQuestionIndex
      })
    );
  };

  const renderAnswer = () => {
    if (question.type === 0 || question.includeCitation) {
      return (
        <ThemeProvider theme={theme}>
          <List>
            {answer.quotes &&
              answer.quotes.map((item, index) => {
                return (
                  <ListItem
                    key={index}
                    ContainerComponent="li"
                    className={classes.li}
                  >
                    <Card
                      className={clsx(classes.card, classes.cardLtr)}
                      elevation={1}
                    >
                      <Typography
                        className={classes.quoteText}
                        style={{
                          direction: sourceTextLanguage === "he" ? "rtl" : "ltr"
                        }}
                      >
                        {item.text}
                      </Typography>
                      <Typography
                        onClick={() => {
                          console.log(question);
                          if (question.type !== 0 && !showCitationForQuestion) {
                            setShowCitation(item);
                          } else {
                            dispatch(setLocation({ ...item }));
                          }
                        }}
                        className={clsx(classes.quoteRef, classes.left)}
                      >
                        <FormattedMessage
                          id="show.text"
                          defaultMessage="Show in text"
                        />
                        {task.submission_status !== "Submitted" && (
                          <IconButton
                            onClick={e => {
                              removeQuote(item, selectedQuestionIndex);
                              e.stopPropagation();
                            }}
                            className={clsx(
                              classes.removeQuote,
                              classes.removeQuoteLtr
                            )}
                          >
                            <DeleteFilled />
                          </IconButton>
                        )}
                      </Typography>
                    </Card>
                  </ListItem>
                );
              })}
          </List>
        </ThemeProvider>
      );
    }
    return "";
  };

  const getQuestionTypeText = () => {
    // const currentQuestion = questionsOrder[selected];
    let result = "";
    if (!question) {
      return result;
    } else if (question.includeCitation && showCitationForQuestion) {
      result = intl.formatMessage({
        id: "task.findCitations",
        defaultMessage: "Find relevant citations"
      });
    } else {
      result = questionTypes.find(el => el.id === question.type)?.txt;
    }
    return result;
  };

  const questionTypes = [
    {
      id: 0,
      txt: intl.formatMessage({
        id: "task.type.find",
        defaultMessage: "Find in Text"
      })
    },
    {
      id: 1,
      txt: intl.formatMessage({
        id: "task.type.open",
        defaultMessage: "In your own words"
      })
    },
    {
      id: 2,
      txt: intl.formatMessage({
        id: "task.type.multiple",
        defaultMessage: "Multiple Choice"
      })
    }
  ];
  // Render
  return question && answer ? (
    <>
      <div
        role="tabpanel"
        id={`simple-tabpanel-${selectedQuestionIndex}`}
        aria-labelledby={`simple-tab-${selectedQuestionIndex}`}
      >
        <Box className={classes.taskPane}>
          <Box
            className={clsx(classes.panelHeader, classes.panelHeaderLtr)}
            id="panel1a-header"
          >
            <Typography className={classes.questionTitle} variant="h6">
              {getQuestionTypeText()}
            </Typography>
            <Box className={classes.panelStageName}>
              <Typography
                dir="auto"
                variant="body1"
                className={clsx(classes.question)}
              >
                {question.question}
              </Typography>

              <Divider className={classes.divider2} />
              {renderAnswer()}
            </Box>
          </Box>
          {showCitation && (
            <CitationDialog
              setOpenDialog={setShowCitation}
              url={readText.url}
              location={readText.file_location}
              highlights={[showCitation]}
              openDialog={!!showCitation}
              removeHighlight={
                task.submission_status !== "Submitted"
                  ? () => {
                      removeQuote(showCitation, selectedQuestionIndex);
                      setShowCitation(false);
                    }
                  : false
              }
            />
          )}
        </Box>
      </div>
    </>
  ) : null;
}
