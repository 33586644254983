// Dependencies
import React, { useRef, useState, useEffect } from "react";
import { firebaseFunctions } from "../../../firebase";
import clsx from "clsx";
import { useHistory, useParams, useLocation } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";

import { updateCourses } from "../../../redux/coursesSlice";
// import { sendNotification } from "./redux/firebaseMiddleware";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Components
import CreateTask from "./CreateTask";
import { Autocomplete } from "@material-ui/lab";
import { ScrollBox } from "../../SharedComponents";

const useStyles = makeStyles(theme => {
  return {
    readerViewContainer: {
      position: "relative",
      width: "100%",
      // height: "max-content",
      minHeight: "calc(100vh - 120px)"
    },
    drawerContainer: {
      position: "relative",
      width: "100%",
      // height: "max-content",
      minHeight: "100%"
    },
    dirLtr: {
      direction: "ltr"
    },

    bookContainerActionOpen: {
      gridColumn: " 7 / 17 "
    },
    bookContainerNoSidebat: {
      gridColumn: " 5 / 15 "
    },

    bookContainer: {
      // position: "relative",
      // height: "auto",
      // minHeight: "100%",
      width: "50%",
      margin: "0 auto",
      paddingBottom: theme.spacing(2)
    }
  };
});

function CreateTaskScreen(props) {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { course_id, task_id } = useParams();
  const course = useSelector(
    state => state.courses.courses.filter(el => el.id == course_id)?.[0]
  );

  const location = useLocation();

  // Ephemeral State
  const [task, setTask] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  //Variables

  useEffect(() => {
    if (!course) {
      //TODO move to some common place
      const makeCancellable = promise => {
        let hasCanceled_ = false;

        const wrappedPromise = new Promise((resolve, reject) => {
          promise.then(
            val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            error =>
              hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
          );
        });
        return {
          promise: wrappedPromise,
          cancel() {
            hasCanceled_ = true;
          }
        };
      };

      var getMyCourses = firebaseFunctions.httpsCallable(
        "courses-getMyCourses"
      );

      // console.log("getting course texts");
      const cancellable = makeCancellable(getMyCourses());

      cancellable.promise.then(response => {
        // console.log("got texts ", response);

        const classesByYear = response.data.reduce((result, item) => {
          if (item.academic_year in result) {
            result[item.academic_year].push(item);
          } else {
            result[item.academic_year] = [item];
          }
          return result;
        }, {});
        let items = Object.keys(classesByYear).map(function (key) {
          return [key, classesByYear[key]];
        });
        let sorted = items.sort(function (first, second) {
          return second[0].localeCompare(first[0]);
        });
        // Sort the array based on the second element
        dispatch(updateCourses(response.data));
      });

      const cancel = () => {
        //console.log("cancelling");
        cancellable.cancel();
      };
      return cancel;
    }
  }, [course, course_id]);

  useEffect(() => {
    if (task_id) {
      if (!history.location.state?.task) {
        let getAllTasks = firebaseFunctions.httpsCallable("courses-getTasks");
        getAllTasks({ course: course_id }).then(response => {
          setTask(response.data.filter(t => t.id == task_id)?.[0]);
        });
      } else setTask(history.location.state?.task);
    } else if (!task_id) {
      setTask({
        placeholder: "New Task",
        course_id: course_id
      });
    }
  }, [task_id, course_id]);

  return (
    <ScrollBox className={clsx(classes.readerViewContainer, classes.dirLtr)}>
      <div className={classes.drawerContainer} id="drawer-container">
        <div
          id="readerViewBookContainer"
          className={clsx(
            classes.bookContainer,
            location.pathname !== "/tasks/new" &&
              classes.bookContainerActionOpen,
            location.pathname === "/tasks/new" && classes.bookContainerNoSidebat
          )}
        >
          {course && task && (
            <CreateTask
              course={course}
              task={task}
              isDirty={isDirty}
              setIsDirty={setIsDirty}
            />
          )}
        </div>
      </div>
    </ScrollBox>
  );
}

export default CreateTaskScreen;
