import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import PeerReviewSidebar from "../PeerReview/PeerReviewSidebar";
import { updateTask } from "../../../redux/firebaseMiddleware";
import SubmitTaskModal from "./SubmitTaskModal";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Box, Typography } from "@material-ui/core";
import TaskSideBarQuestions from "../TaskSideBarQuestions";
import { PocBar } from "../../poc";
import { taskSelectors } from "../../../redux/taskSlice.js";

const useStyles = makeStyles(theme => ({
  questionIndexContainer: {
    display: "flex",
    alignItems: "center"
  },
  carouselWrapper: {
    overflow: "hidden",
    flex: 1
  },
  carousel: {
    display: "flex",
    alignItems: "center"
  },
  questionIndex: {
    width: 48,
    height: 48,
    padding: 0,
    flexShrink: 0,
    marginLeft: "calc((25% - 48px) / 2)",
    marginRight: "calc((25% - 48px) / 2)",
    color: "white"
  },
  alignToStart: {
    marginRight: 0,
    marginLeft: theme.spacing(2)
  },
  selected: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main
  },
  number: {
    fontFamily: "Crimson Pro",
    fontSize: 23,
    height: 38,
    color: "inherit"
  },
  carouselArrow: {
    color: "white",
    "&.Mui-disabled": {
      color: "white",
      opacity: 0.2
    }
  },
  root: {
    display: "flex",
    flexFlow: "column nowrap",
    height: "100%",
    background: "#333333"
  },
  panelSubtitle: {
    color: "white",
    whiteSpace: "pre-wrap"
  },
  taskTitle: {
    fontSize: "20px",
    marginBottom: "20px"
  },
  panelHeader: {
    color: "white",
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    textAlign: "left"
  },
  divider: {
    backgroundColor: "#616161",
    margin: "16px 0"
  },
  panelTitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    lineHeight: "24px",
    marginTop: "8px",
    textTransform: "uppercase"
  },
  nextButton: {
    marginTop: "16px",
    fontSize: "13px",
    fontWeight: "bold",
    letterSpacing: "1px",
    color: "#ffffff",
    backgroundColor: "#168fee",
    "&:hover": {
      backgroundColor: "#1172be"
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.text.disabled
    }
  },
  questionTitle: {
    fontSize: "0.75rem",
    paddingLeft: "16px",
    paddingRight: "16px",
    margin: "20px 0",
    textTransform: "uppercase",
    color: "#BCD9F1"
  }
}));

function StandardSideBar({ readText }) {
  const task = useSelector(state => state.task.taskRow);
  const classes = useStyles();

  return (
    <>
      <Box className={classes.panelHeader}>
        <Typography className={classes.panelTitle} color="textPrimary">
          <FormattedMessage id="appBar.task" defaultMessage="task" />
        </Typography>
        <Typography
          className={clsx(classes.taskTitle, classes.panelSubtitle)}
          color="textPrimary"
        >
          {task.name}
        </Typography>

        <Typography className={classes.panelSubtitle} color="textPrimary">
          {task.task_description}
        </Typography>
      </Box>
      <TaskSideBarQuestions readText={readText} />
    </>
  );
}

export default function TaskSideBar({ readText, ...props }) {
  const classes = useStyles();
  const task = useSelector(state => state.task.taskRow);
  const taskAnswers = useSelector(state => state.task.answers);
  const dispatch = useDispatch();
  const selectedQuestionIndex = useSelector(
    state => state.task.selectedQuestionIndex
  );
  const selectedTaskId = useSelector(state => state.task.selectedTaskId);
  const selectedGrQuestionId = useSelector(
    state => state.gr.selectedQuestionId
  );
  const grAsTaskHighlights = useSelector(state => state.task.grHighlights);
  const [modalVisible, setModalVisible] = useState(false);
  const [displayTip, setDisplayTip] = useState(true);
  // TODO: This should not be in ephemeral state
  const [shouldShowTip, setShouldShowTip] = useState(
    useSelector(state => state.user.userProfile.showCitationsTip)
  );

  useEffect(() => {
    if (
      shouldShowTip &&
      task?.questions?.questions[selectedQuestionIndex]?.includeCitation
    ) {
      setDisplayTip(true);
    } else {
      setDisplayTip(false);
    }
  }, [selectedQuestionIndex, shouldShowTip, task]);

  return (
    <>
      <SubmitTaskModal
        task={task}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        selectedTaskId={selectedTaskId}
        taskAnswers={taskAnswers}
      />
      <Paper className={classes.root} elevation={0}>
        {task.task_type === "peerReview" && <PeerReviewSidebar />}
        {task.task_type === "standard" && (
          <StandardSideBar readText={readText} />
        )}
        {task.task_type === "guidedReading" && (
          <PocBar
            color="primary"
            editable={false}
            persistState={() => {
              dispatch(
                updateTask({
                  answers: taskAnswers,
                  grHighlights: grAsTaskHighlights
                    ? [...grAsTaskHighlights]
                    : [],
                  selectedQuestion: selectedGrQuestionId
                })
              );
            }}
            onAnswerCardDelete={updatedQuestions => {
              dispatch(
                updateTask({
                  answers: updatedQuestions.reduce((acc, item) => {
                    acc.push({
                      quotes: item.answers || []
                    });
                    return acc;
                  }, []),
                  grHighlights: grAsTaskHighlights
                    ? [...grAsTaskHighlights]
                    : []
                })
              );
            }}
          />
        )}
      </Paper>
    </>
  );
}
