export default {
  "appBar.library": "Library",
  "appBar.reader": "Reader",
  "appBar.tasks": "Tasks",
  "appBar.task": "Task",
  "card.title": "Title",
  "gr.light.stage.1": "Survey and Questions",
  "gr.light.stage.1.instructions":
    "Read and highlight the title, subheadings, and the first sentence of each paragraph. Then come up with questions regarding these highlights. ",
  "gr.light.stage.2": "Read and answer",
  "gr.light.stage.2.instructions":
    "Read the text while actively seeking answers to your questions. Whenever you find one - simply click on the question and then highlight the relevant passage . ",
  "gr.light.stage.3": "Exercise",
  "gr.light.stage.3.instructions":
    "Answer the questions in your own words. once done give yourself some feedback. ",
  "gr.full.stage.1": "Survey",
  "gr.full.stage.1.instructions":
    "To get a general sense of the text, read and highlight the title, subheadings, abstract, or introduction. Then go through the text, from top to bottom, focusing only on the opening sentence of each paragraph. Highlight these sentences, as well as any phrase or concept that catches your attention. ",
  "gr.full.stage.2": "My Questions",
  "gr.full.stage.2.instructions":
    "Go over your highlights and read the general questions listed below. Come up with questions of your own and add them to the list. Asking questions about the text helps you stay focused while reading it. ",
  "gr.full.stage.3": "Read and Answer",
  "gr.full.stage.3.instructions":
    "Read the text at your own pace from start to finish, while actively seeking answers to your questions. Whenever you find one - simply click on the question and then highlight the relevant passage. This will automatically create an ‘answer card’ and link it to the question. To keep things organized - give a title to every answer card you create. Reading texts in the aim of looking for answers makes for a deeper and more efficient process.",
  "gr.stage.4": "Review",
  "gr.stage.4.instructions":
    "Go over the answer cards of every question and examine them. Try to create a clear flow that answers the question sufficiently and accurately. You can edit titles and rearrange or hide cards as you see fit.",
  "gr.stage.5": "Excercise",
  "gr.stage.5.instructions":
    "In order to retain the knowledge in your memory try answering the questions in your own words. You can take a look at the answer cards at any time. Once done,  give yourself some feedback. It is for your benefit only, no one else can see it.",
  "gr.highlightsView": "Highlight view",
  "gr.answersView": "Answer view",
  "tasks.manager": "Task Manager",
  "tasks.create": "Add new task",

  "feedback.textRef": "text reference",
  Draft: "Draft",
  Submitted: "Submitted",
  gr: {
    misc: {
      learnMore: {
        close: "Got it"
      }
    },
    light: {
      step: {
        1: {
          learnMore: `<h2>Surveying the text</h2>
          <p>In order to easily understand the text, it is important to first get a general sense of the topic, the arguments, and the conclusion. Select the titles, the first sentence of each paragraph, and all concepts that seem important. Every passage you select will be automatically highlighted. To remove a highlight click on the highlighted text and then on the highlight icon that appears above it.</p>
          <h2>Tips</h2>
          <ul>
            <li>Try to complete the process without stopping in the middle.</li>
            <li>Don’t start summarising or worry about the details just yet.</li>
            <li>Take the time to properly understand each sentence you highlight. The key word here is efficiency, don’t rush yourself and try not to linger.</li>
          </ul>
          <h2>Asking questions</h2>
          <p>After surveying the text come up with questions about the things you found most important. Reading the text while actively seeking for answers will focus your reading, reduce time and effort, and help you to gain better understanding. To add questions, just click the “Add question” button and type your question. You can edit and delete questions by using the question menu.</p>
          <h2>Tips</h2>
          <ul>
            <li>The more specific and focused your questions are - the better your understanding will be.</li>
            <li>Use “Highlight view” to easily browse through your highlights.</li>
          </ul>`
        },
        2: {
          learnMore: `<h2>Reading while looking for answers</h2>
          <p>Using the “Read and Answer” method makes your reading more focused and effective. It saves time and provides a better understanding of the text. On top of that, your answer cards are saved and can be used as an index of the most important parts of the text.</p>
          <h2>How to create answer cards</h2>
          <p>When coming across an answer in your reading, first click on the question and then select the relevant passage in the text. The text will be highlighted and automatically saved as an answer card. You can view your answers by clicking on the question’s expand arrow icon or by switching to ‘Answer view’. You can link one answer to more than one question by dragging the card from one answer to another. The card will be automatically saved under both questions. </p>
          <h2>Tips</h2>
          <ul>
            <li>Try to make it a habit to give your answer cards titles. It will help you organise your materials, and will surprisingly increase the chances of retaining the answers in your memory.</li>
            <li>Use "answers view" to arrange your cards in a way that makes sense to you. Hide cards that seem less relevant by using the eye icon, go over your titles and readjust them.</li>
          </ul>`
        },
        3: {
          learnMore: `<h2>The purpose of exercising</h2>
              <p>The point of this step is to help you decide how much of the text you actually understand and what’s left for you to figure out. There’s no better way to do so than testing yourself. After having completed this third and final step you will be more than ready to be tested, write a paper or take part in class discussions.</p>
              <h2>How to exercise</h2>
              <p>Select a question by clicking on it and enter your answer in the box. You can take a look at the answer cards but try sticking to your own words instead of copying phrases from the text. When done, give yourself feedback and move on to the next question. You can always go back and change an answer until you feel comfortable with it.</p>
              <h2>Tips</h2>
              <ul>
                <li>Feedback helps you decide for yourself what needs a bit more work. Use it to get better, and don’t worry, no one else can see it.</li>
              </ul>`
        }
      }
    },
    full: {
      step: {
        1: {
          learnMore: `<h2>Why survey?</h2>
          <p>In order to easily understand the text, it is important to first get a general sense of the topic, the main issues, the arguments, and the conclusion. Surveying the text before reading makes the actual reading simpler and saves time.</p>
          <h2>How to use survey</h2>
          <p>Simply highlight the first sentence of each paragraph, and any other phrase or concept that looks important. You can always change your mind and remove highlight - Just click on the text and use the highlight icon that appears above it.</p>
          <h2>Tips</h2>
          <ul>
            <li>Try to complete the process without stopping in the middle. Don’t start summarizing or worry about the details just yet.</li>
            <li>Take the time to properly understand each sentence you highlight, but without lingering. The key word here is efficiency.</li>
            <li>This process may raise some questions in your mind - that’s good! Save them for the next step.</li>
          </ul>`
        },
        2: {
          learnMore: `<h2>Why questions?</h2>
          <ul>
            <li>Asking questions is the basis of every meaningful scientific project. This is your chance to sharpen this crucial tool.</li>
            <li>Reading the text while seeking answers will focus your reading, reduce time and effort, and will allow you better understanding.</li>
            <li>Composing questions ahead of your reading stimulates your curiosity and brings your own unique experiences into your studies.</li>
          </ul>
          <h2>How to use questions</h2>
          <ul>
            <li>To add questions, just click the “Add question” button and type your question.</li>
            <li>You can edit and delete questions using the question menu.</li>
            <li>To rearrange the list just drag a question up or down.</li>
          </ul>
          <h2>Tips</h2>
          <ul>
            <li>Use your natural curiosity, your fields of interests, even things that bother you, as inspiration for your questions.</li>
            <li>The more specific and focused your questions are - the better your understanding will be.</li>
          </ul>`
        },
        3: {
          learnMore: `<h2>Why read and answer?</h2>
              <p>Using the “read and answer” method makes your reading more focused and effective. It saves time and provides a better understanding of the text. On top of that, your answer cards are saved and can be used as an index of the most important parts of the text.</p>
              <h2>How to read and answer</h2>
              <p>Create as many answer cards as you like. You can link one answer card to more than one question. You can then give titles or delete cards by clicking on the bin icon. To review your answer cards, just click on the arrow next to every question.</p>
              <h2>Tips</h2>
              <ul>
                <li>Try to make it a habit to give your answer cards titles. It will help you organizing your materials, and will surprisingly increase the chances of retaining the answers in your memory.</li>
                <li>In some cases you might find it necessary to make some changes to your questions. You are encouraged to so. Simply click on the menu icon and select edit.</li>
              </ul>`
        },
        4: {
          learnMore: `<h2>Why review?</h2>
              <p>Now, that the hard work is behind you, it is time to organize, prioritize, and make your discoveries clear for yourself. Reviewing your work promises a ‘big picture’ understanding of the text. it exposes the interconnections between the different questions and answers you’ve gathered.{br}As simple as it may seem, this step utilizes higher-order cognitive skills and is proven to increase chances of knowledge retention.</p>
              <h2>How to review</h2>
              <p>Arrange your cards in a way that makes sense to you{br}Hide cards that seem less relevant using the eye icon{br}Go over your titles and readjust them{br}To remove a card completely click “Show in text” and then use the bin icon</p>`
        },
        5: {
          learnMore: `<h2>Why excercise?</h2>
              <p>The point of this step is to let you determine for yourself whether or not you feel confident about your understanding. There’s no better way to do so then testing yourself and giving yourself feedback. When completing this step you are more than likely ready to be tested, write a paper or engage in discussions.</p>
              <h2>How to excercise</h2>
              <p>Select a question by clicking on it and write your answer in the box. You can take a look at the answer cards but try sticking to your own words instead of copying phrases from the text. When done, save your answer, give yourself some feedback and move on to the next question. You can always go back and change an answer until you feel comfortable with it.</p>
              <h2>Tips</h2>
              <p>Feedback helps you decide for yourself what needs a bit more work. Use it to get better, and don’t worry, no one else can see it.</p>`
        }
      }
    }
  }
};
