import { createSlice } from "@reduxjs/toolkit";

const loadTextsState = defaultObj => {
  try {
    let obj = defaultObj;
    const serializedState = localStorage.getItem("state.text");
    if (serializedState === null) {
      return defaultObj;
    }

    obj = { serializedState, ...JSON.parse(serializedState) };
    const seralizedLocation = localStorage.getItem(
      "state.text.location." + obj.selectedTextId
    );

    if (seralizedLocation) {
      obj.lastLocation = seralizedLocation;
    }

    return obj;
  } catch (err) {
    return defaultObj;
  }
};

export const saveTextsState = state => {
  try {
    const serializedState = JSON.stringify({
      selectedTextId: state.selectedTextId
    });
    localStorage.setItem("state.text", serializedState);
  } catch {
    // ignore write errors
  }
};

const initialState = loadTextsState({
  texts: [
    {
      id: 1,
      author: "Andrew Delbanco", //'René Descartes',
      title: "What Is College For",
      lastLocation: "",
      defaultLocation: "index.html", //"Text/Med1.html",
      categories: ["Education"], //Philosophy"],
      questions: [],
      //link:"https://pangea-291110.ew.r.appspot.com/resources/descartes1641.epub"}],
      link: "/resources/delbanco.epub"
    }
  ],
  selectedTextId: null,
  selectedText: null,
  activeTask: null
});

export const textSlice = createSlice({
  name: "texts",
  initialState,
  extraReducers: {
    gotActiveTask: (state, value) => {
      state.activeTask = value.payload.activeTask;
    },
    "user/logout": () => initialState
  },
  reducers: {
    setTextLocation: (state, value) => {
      state.lastLocation = value.payload;
    },
    setPangeaText: (state, value) => {
      let text_id = 0;
      if (value.payload.text_id) {
        text_id = value.payload.text_id;
      } else {
        text_id = value.payload.id;
      }
      if (text_id !== state.selectedTextId) state.lastLocation = "";
      state.selectedTextId = text_id;

      const serializedState = localStorage.getItem(
        "state.text.location." + text_id
      );

      if (serializedState === null) {
        state.lastLocation =
          value.payload.file_location || value.payload.defaultLocation;
      } else state.lastLocation = serializedState;

      state.selectedText = {
        ...value.payload,
        file_location: state.lastLocation,
        defaultLocation: state.lastLocation
      };
    },
    setSelectedTextId: (state, value) => {
      if (value.payload !== state.selectedTextId) {
        const serializedState = localStorage.getItem(
          "state.text.location." + value.paylod
        );

        if (serializedState === null) {
          state.lastLocation = "";
        } else state.lastLocation = serializedState;
        state.selectedTextId = value.payload;

        saveTextsState(state);
      }
    },
    setSelectedText: (state, value) => {
      state.selectedTextId = value.payload;
      const serializedState = localStorage.getItem(
        "state.text.location." + value.paylod
      );
      if (serializedState === null) {
        state.lastLocation = "";
      } else state.lastLocation = serializedState;
      state.selectedText = state.texts.filter(el => el.id === value.payload)[0];
    },
    removeText: (state, value) => {
      state.texts = state.text.filter(el => el.id !== value.payload);
    },
    addText: (state, value) => {
      state.texts.push(value.payload);
      saveTextsState(state);
    },
    setActiveTask: (state, value) => {
      state.activeTask = value.payload;
    }
  }
});

export const {
  addText,
  setSelectedText,
  setLastLocation,
  removeText,
  setPangeaText,
  setSelectedTextId,
  setActiveTask,
  setTextLocation
} = textSlice.actions;

export default textSlice.reducer;
