import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  highlights: [],
  underlines: [],
  clientRectangle: {},
  selectedText: 0,
  selectedHighlight: {},
  minimal: false,
  isHover: false,
  isAnnotatorBarOpen: false
};

export const highlightSlice = createSlice({
  name: "highlight",
  initialState,
  extraReducers: {
    gotHighlights: (state, value) => {
      state.highlights = value.payload.coll;
      state.selectedText = value.payload.textId;
    },
    "user/logout": () => initialState
  },
  reducers: {
    toggleAnnotatorBar: (state, value) => {
      if (state.isAnnotatorBarOpen === false) {
        const { clientRectangle, selectedText, isHover, minimal } =
          value.payload;

        state.clientRectangle = clientRectangle;
        state.isAnnotatorBarOpen = true;
        state.minimal = minimal;
        state.selectedText = selectedText;
        state.isHover = isHover;
      } else {
        state.isAnnotatorBarOpen = false;
      }
    },

    closeAnnotatorBar: state => {
      state.isAnnotatorBarOpen = false;
    },
    openAnnotatorBar: (state, value) => {
      const { clientRectangle, selectedText, isHover, minimal } = value.payload;

      state.clientRectangle = clientRectangle;
      state.isAnnotatorBarOpen = true;
      state.minimal = minimal;
      state.selectedText = selectedText;
      state.isHover = isHover;
    },

    removeHighlight: (state, value) => {
      let newHighlights = current(state.highlights).filter(el => {
        return (
          el.cfi !== value.payload.cfi || el.textId !== value.payload.textId
        );
      });
      state.highlights = newHighlights;
    },
    setHighlightColor: (state, value) => {
      let highlightsFiltered = current(state.highlights).filter(el => {
        return (
          el.cfi !== value.payload.cfi || el.textId !== value.payload.textId
        );
      });
      highlightsFiltered.push(value.payload);
      state.highlights = highlightsFiltered;
    }
  }
});

export const {
  toggleAnnotatorBar,
  closeAnnotatorBar,
  openAnnotatorBar,
  onBarShown,
  onBarClosed
} = highlightSlice.actions;

export default highlightSlice.reducer;
