import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialState = {
  courses: [],
  lastUpdate: 0
};
export const coursesSlice = createSlice({
  name: "comments",
  initialState,
  extraReducers: {
    "user/setAuth": (state, value) => {
      state.courses = [];
      state.lastUpdate = 0;
    },
    "user/logout": () => initialState
  },
  reducers: {
    updateCourses: (state, value) => {
      state.courses = value.payload;
      state.lastUpdate = new Date().getTime();
    }
  }
});

export const { updateCourses } = coursesSlice.actions;

export const selectCurrentCourse = createSelector(
  // selectCurrentCourseTimezone returns the timezone of the current selected course
  state => state.courses.courses,
  state => state.user.userProfile.selectedCourse.id,
  (courses, selectedCourse) => {
    return courses.find(course => course.id === selectedCourse);
  }
);

export default coursesSlice.reducer;
