import React, { useState, useEffect } from "react";
import ReactReader from "./ReactReader/ReactReader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { setTextLocation } from "../../redux/textSlice";

// Fix for v3 branch of epub.js -> needs ePub to by a global var
//const storage = global.localStorage || null;

const useStyles = makeStyles(theme => ({
  bookView: {
    flexGrow: "1",
    flexDirection: "column",
    position: "relative",
    height: "100%",
    width: "100%"
    // overflowY: "auto"
  },
  bookHeight: {
    maxHeight: "100vh"
  }
}));

function BookView({
  readText,
  task,
  readOnly,
  rtl,
  dark,
  scrollY,
  onTextSelected,
  minimalBar
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedTextId = useSelector(state => {
    return state.texts.selectedTextId;
  });
  const lastLocation = useSelector(state => {
    return state.texts.lastLocation;
  });
  const _onTextSelected = params => {
    onTextSelected && onTextSelected(params);
  };

  const selectedText = useSelector(state => {
    return state.texts.selectedText;
  });

  const selectedLink =
    selectedText && selectedText.link
      ? selectedText.link
      : selectedText && selectedText.url;

  function onLocationChanged(locationCFI) {
    localStorage.setItem("state.text.location." + selectedTextId, locationCFI);
    const serializedState = JSON.stringify({
      selectedTextId: selectedTextId
    });
    localStorage.setItem("state.text", serializedState);
    dispatch(setTextLocation(locationCFI));
  }

  return (
    <div className={clsx(classes.bookView)}>
      {((readText && readText.url) || (selectedText && selectedText.link)) && (
        <ReactReader
          minimalBar={minimalBar}
          task={task}
          readOnly={readOnly}
          url={task && task.id > 0 && readText ? readText.url : selectedLink}
          title={""}
          location={lastLocation || selectedText.defaultLocation}
          locationChanged={onLocationChanged}
          onTextSelected={_onTextSelected}
        />
      )}
    </div>
  );
}

export default BookView;
