// Dependencies
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { ScrollBox } from "./components/SharedComponents";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setBreadcrumbs } from "./redux/readerActionsSlice";
import { markNotificationAsRead } from "./redux/firebaseMiddleware";
// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    paddingInline: "25vw"
    // margin: "0 auto"
  },
  header: {
    position: "relative"
  },
  navigateBack: {
    top: "15px",
    left: "-77px",
    position: "absolute"
  },
  heading: {
    margin: "24px 0"
  },
  notificaiton: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E8E8E8"
    }
  },
  notificationContent: {
    display: "flex",
    flexDirection: "column"
  },
  isRead: {
    color: theme.palette.text.secondary
  },
  courseName: {
    fontWeight: "bold"
  },
  time: {
    color: theme.palette.text.secondary
  }
}));

export default function Notifications({ ...props }) {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  // Redux state
  const notifications = useSelector(state => state.user.notifications);
  const userId = useSelector(state => state.user.auth.uid);

  // Ephemeral state
  const [sortedNotifications, setSortedNotifications] = useState([]);

  // Variables

  //Behavior
  //Set breadcrumbs
  useEffect(() => {
    let parts = [];
    parts.push({
      text: intl.formatMessage({
        id: "appBar.notifications",
        defaultMessage: "Notifications"
      })
    });

    dispatch(setBreadcrumbs({ breadcrumbs: parts }));
  }, []);

  // Sorting the notifications based on time created from newest to oldest
  useEffect(() => {
    if (notifications) {
      setSortedNotifications(
        [...notifications].sort((a, b) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        })
      );
    }
  }, [notifications]);

  function markAsRead(notificationId) {
    dispatch(
      markNotificationAsRead({
        notificationId: notificationId,
        userId: userId
      })
    );
  }

  // Render
  return (
    <ScrollBox className={classes.container}>
      <Box component="header" className={classes.header}>
        <IconButton
          size="large"
          className={classes.navigateBack}
          onClick={() => {
            history.goBack();
          }}
          aria-label="back to previous page"
        >
          <ArrowBackIcon fontSize="large" />
        </IconButton>
      </Box>
      <Typography variant="h4" component="h1" className={classes.heading}>
        Notifications
      </Typography>
      <TableContainer>
        <Table>
          <TableBody>
            {sortedNotifications?.map(notification => {
              return (
                <TableRow
                  key={notification.id}
                  className={classes.notificaiton}
                  onClick={() => {
                    markAsRead(notification.id);
                    notification.link && history.push(notification.link);
                  }}
                >
                  <TableCell>
                    <Box
                      className={clsx(
                        classes.notificationContent,
                        notification.isRead && classes.isRead
                      )}
                    >
                      <Typography
                        component="span"
                        variant="subtitle2"
                        className={classes.courseName}
                      >
                        {notification.course}
                      </Typography>

                      <Typography
                        component="span"
                        variant="subtitle2"
                        className={classes.notification}
                      >
                        {notification.content}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip
                      color="primary"
                      // className={clsx(classes.chip)}
                      label="task"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      className={classes.time}
                    >
                      {moment(notification.createdAt).fromNow()}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ScrollBox>
  );
}
