// Dependencies
import React, { useRef, useState, useEffect } from "react";
import { convertFromRaw, EditorState } from "draft-js";
import { firebaseApp, firestore, firebaseFunctions } from "../../../firebase";
import { FormattedMessage, injectIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import clsx from "clsx";
import ePub from "epubjs";
import { stemmer } from "stemmer";
import FlexSearch from "flexsearch";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setPangeaText } from "../../../redux/textSlice";
import {
  sendFeedback,
  setFeedbackTask,
  setSentFeedbackTask,
  setSelectedText
} from "../../../redux/taskSlice";
import { setProfile } from "../../../redux/userSlice";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";

// Components
import TaskFeedbackSidebar from "./TaskFeedbackSidebar";
import FeedbackScreen from "./FeedbackScreen";
import { getHighlightColor } from "../../../utils/colors";
import { ScrollToTopOnMount } from "../../../Router";
import FeedbackSubmmisionModal from "./FeedbackSubmissionModal";
import PeerReview from "../PeerReview/PeerReview";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";

//Styles
const useStyles = makeStyles(theme => {
  return {
    readerViewContainer: {
      position: "relative",
      width: "100%",
      height: "max-content",
      minHeight: "calc(100vh - 120px)"
    },
    drawerContainer: {
      position: "relative",
      width: "100%",
      // height: "max-content",
      // minHeight: "calc(100vh - 120px)"
    },
    btnContainer: {
      position: "absolute",
      right: "16px"
    },
    actions: {
      padding: "16px",
      position: "relative",
      display: "block",
      minHeight: "64px"
    },
    pointsMsg: {
      display: "inline-block",
      lineHeight: "34px"
    },
    points: {
      display: "inline-block",
      border: "1px solid #168fee",
      borderRadius: "4px",
      paddingLeft: "8px",
      paddingRight: "8px",
      width: "64px",
      "& input": {
        textAlign: "center"
      }
    },
    adornment: {
      color: "#168fee"
    },
    eval: {
      color: "#168fee",
      fontSize: "20px",
      lineHeight: "32px",
      "& ::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "#168fee",
        opacity: 1
      },
      "& button": {
        visibility: "hidden"
      },
      "&:hover button": {
        visibility: "visible"
      }
    },
    writenEval: {
      "& :focus::placeholder": {
        color: "#bdc1c9"
      },
      "& ::placeholder": {
        color: "#9197a3"
      }
    },
    hidden: {
      visibility: "hidden",
      fontSize: "20px",
      position: "absolute",
      maxWidth: "95%"
    },
    dirLtr: {
      direction: "ltr"
    },
    content: {
      position: "relative",
      width: "100%",
      height: "max-content",
      minHeight: "calc(100vh - 120px)",
      display: "grid"
    },
    contentActionOpen: {
      gridTemplateColumns: "repeat(20,1fr)"
    },
    bookContainer: {
      gridColumn: " 7 / 21 ",
      position: "relative",
      height: "max-content",
      minHeight: "100%"
    },
    buttonCancel: {
      color: "#787877"
    },
    button: { textTransform: "none" }
  };
});
const TaskFeedback = ({ intl, ...props }) => {
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const evalTitleSpanRef = useRef();
  const editTitleRef = useRef();
  let { submission_id, question_id } = useParams();

  // Redux state
  const userProfile = useSelector(state => state.user.userProfile);

  const shouldSendFeedback = useSelector(state => state.task.sendFeedback);
  const selectedCourse = useSelector(
    state => state.user.userProfile.selectedCourse
  );
  const rtlValue = useSelector(state => state.user.userProfile.rtl);
  const darkMode = useSelector(state => state.user.userProfile.darkMode);
  const task = useSelector(state => state.task.feedbackTask);

  // Ephemeral state
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [localQuestionIndex, setLocalQuestionIndex] = useState(
    question_id ? question_id : 0
  );
  const [rendition] = useState(null);
  const [teacherHighlightElements, setTeacherHighlightElements] = useState([]);
  const [studentHlElements, setStudentHlElements] = useState([]);
  const [readText, setReadText] = useState("");
  const [courses, setCourses] = useState(false);
  const [evalTitle, setEvalTitle] = useState("");
  const [editingTitle, setEditingTitle] = useState(false);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [grade, setGrade] = useState(0);
  const [openEval, setOpenEval] = useState(false);
  const [showSubmissionDialog, setShowSubmissionDialog] = useState(false);
  const [validationResult, setValidationResult] = useState({
    grade: true,
    comment: true
  });

  const [reflection, setReflection] = useState(null);
  const [response, setResponse] = useState(null);
  const [reply, setReply] = useState(null);
  const [fetchedFeedback, setFetchedFeedback] = useState(null);

  // Variables
  const currentUser = firebaseApp.auth().currentUser;
  const titlePlaceholder = intl.formatMessage({
    defaultMessage: "Enter evaluation title",
    id: "task.title.placeholder"
  });
  const selectedQuestion =
    task && task.is_checked
      ? localQuestionIndex
      : task &&
        feedback &&
        task.teacher &&
        task.teacher.uid === currentUser.uid &&
        feedback.teacherSelectedQuestion;

  // Behavior

  //set Breadcrumbs
  useEffect(() => {
    let parts = [];
    let course = false;
    let filteredCourses =
      task && courses && courses.filter(el => el.id === task.course_id);
    if (filteredCourses && filteredCourses.length) {
      course = filteredCourses[0];
    }
    parts.push({
      course: course,
      url: "/tasks",
      text: intl.formatMessage({ id: "appBar.tasks", defaultMessage: "Tasks" })
    });

    task &&
      task.course_name &&
      parts.push({
        course: course,
        url: `/tasks/${task.course_id}`,
        text: task.course_name
      });
    task &&
      task.name &&
      parts.push({
        course: course,
        url:
          "/tasks/course/" +
          task.course_id +
          "/task/" +
          task.id +
          "/submission/" +
          submission_id,

        text: task.name
      });

    task &&
      task.student &&
      task.student.uid !== currentUser.uid &&
      parts.push({
        course: course,
        url:
          "/tasks/course/" +
          task.course_id +
          "/task/" +
          task.id +
          "/submission/" +
          submission_id,
        text: task.student_name
      });
    dispatch(
      setBreadcrumbs({ breadcrumbs: parts, blue: true, showSendFeedback: true })
    );
  }, [task, courses, dispatch, intl, submission_id]);

  //get courses
  useEffect(() => {
    var getMyCourses = firebaseFunctions.httpsCallable("courses-getMyCourses");

    getMyCourses().then(response => {
      setCourses(response.data);
    });

    return function cleanup() {
      dispatch(setFeedbackTask(false));
    };
  }, [dispatch]);

  //get selected course
  useEffect(() => {
    if (
      task &&
      courses &&
      (!selectedCourse || !selectedCourse.id !== task.course_id)
    ) {
      let filteredCourses =
        task && courses && courses.filter(el => el.id === task.course_id);
      if (filteredCourses && filteredCourses.length) {
        dispatch(
          setProfile({ ...userProfile, selectedCourse: filteredCourses[0] })
        );
      }
    }
  }, [task, courses, selectedCourse, dispatch]);

  useEffect(() => {
    const setExistingElements = (items, highlights) => {
      let toRemove = [];
      items &&
        Object.entries(items).forEach(([cfi, elem]) => {
          let highlightColl =
            highlights &&
            highlights.filter &&
            highlights.filter(hl => elem.highlight.cfi === hl.cfi);
          if (!highlightColl || !highlightColl[0]) {
            toRemove.push(elem.highlight.cfi);
            rendition.annotations.remove(elem.highlight.cfi, "highlight");
          }
        });
      if (toRemove.length > 0) {
        const filtered = Object.keys(items)
          .filter(key => !toRemove.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: items[key]
            };
          }, {});

        return [filtered, toRemove.length > 0];
      } else return [items, toRemove.length > 0];
    };

    const addMissingHighlights = (items, highlights, color) => {
      let add = false;

      highlights &&
        rendition &&
        rendition.manager &&
        highlights.forEach(highlight => {
          let range = rendition.getRange(highlight.cfi);
          if (range && !(highlight.cfi in items)) {
            let hlStyleObj = {
              "z-index": 10,
              "mix-blend-mode": "multiply",
              "fill-opacity": 1,
              fill: getHighlightColor(color, darkMode)
            };
            let addedHighlight = rendition.annotations.highlight(
              highlight.cfi,
              {},
              e => {},
              "highlightClass",
              hlStyleObj
            );

            add = true;
            items[highlight.cfi] = { ...addedHighlight, highlight: highlight };
            //addedHighlight.mark.element.style.visibility = showHighlights ? "visible":"hidden";

            addedHighlight.mark.element.style.cursor = "pointer";
            addedHighlight.mark.element.style.pointerEvents = "visible";
          }
        });
      return [items, add];
    };

    const renderHighlights = () => {
      let items = teacherHighlightElements;
      let changed = false;
      let added = false;
      [items, changed] = setExistingElements(
        items,
        task.answers.answers[selectedQuestion].quotes
      );
      //add new highlighyts
      [items, added] = addMissingHighlights(
        items,
        task.answers.answers[selectedQuestion].quotes,
        "#168fee"
      );
      if (added || changed) {
        setTeacherHighlightElements(items);
      }
      items = studentHlElements;
      [items, changed] = setExistingElements(
        items,
        task.student_task_row.answers[selectedQuestion].quotes
      );
      //add new highlighyts
      [items, added] = addMissingHighlights(
        items,
        task.student_task_row.answers[selectedQuestion].quotes,
        "#ff80ff"
      );
      if (added || changed) {
        setStudentHlElements(items);
      }
    };

    if (task && rendition) {
      renderHighlights();
    }
  }, [
    task,
    teacherHighlightElements,
    studentHlElements,
    rendition,
    darkMode,
    selectedQuestion
  ]);

  useEffect(() => {
    //console.log("use effect for loading", [submission_id, rtlValue, task.feedbackcomment]);

    var EpubCFI = new ePub.CFI();

    let flexIndex = new FlexSearch({
      filter: [
        /* 
      "a",
      "about",
      "above",
      "after",
      "again",
      "against",
      "all",
      "also",
      "am",
      "an",
      "and",
      "any",
      "are",
      "aren't",
      "as",
      "at",
      //"back",
      "be",
      "because",
      "been",
      "before",
      "being",
      "below",
      //"between",
      "both",
      "but",
      "by",
      "can",
      "cannot",
      "can't",
      "come",
      "could",
      "couldn't",
      //"day",
      "did",
      "didn't",
      "do",
      "does",
      "doesn't",
      "doing",
      "dont",
      "down",
      "during",
      "each",
      "even",
      "few",
      "first",
      "for",
      "from",
      "further",
      "get",
      //"give",
      "go",
      //"good",
      "had",
      "hadn't",
      "has",
      "hasn't",
      "have",
      "haven't",
      "having",
      "he",
      "hed",
      //"hell",
      "her",
      "here",
      "here's",
      "hers",
      "herself",
      "hes",
      "him",
      "himself",
      "his",
      "how",
      "how's",
      "i",
      "id",
      "if",
      "ill",
      "im",
      "in",
      "into",
      "is",
      "isn't",
      "it",
      "it's",
      "itself",
      "i've",
      "just",
      "know",
      "let's",
      "like",
      //"look",
      "make",
      "me",
      "more",
      "most",
      "mustn't",
      "my",
      "myself",
      "new",
      "no",
      "nor",
      "not",
      "now",
      "of",
      "off",
      "on",
      "once",
      //"one",
      "only",
      "or",
      "other",
      "ought",
      "our",
      "our's",
      "ourselves",
      "out",
      "over",
      "own",
      //"people",
      "same",
      "say",
      "see",
      "shan't",
      "she",
      "she'd",
      "shell",
      "shes",
      "should",
      "shouldn't",
      "so",
      "some",
      "such",
      //"take",
      "than",
      "that",
      "that's",
      "the",
      "their",
      "theirs",
      "them",
      "themselves",
      "then",
      "there",
      "there's",
      "these",
      "they",
      "they'd",
      "they'll",
      "they're",
      "they've",
      //"think",
      "this",
      "those",
      "through",
      "time",
      "to",
      "too",
      //"two",
      //"under",
      "until",
      "up",
      "us",
      //"use",
      "very",
      "want",
      "was",
      "wasn't",
      "way",
      "we",
      "wed",
      "well",
      "were",
      "weren't",
      "we've",
      "what",
      "what's",
      "when",
      "when's",
      "where",
      "where's",
      "which",
      "while",
      "who",
      "whom",
      "who's",
      "why",
      "why's",
      "will",
      "with",
      "won't",
      //"work",
      "would",
      "wouldn't",
      //"year",
      "you",
      "you'd",
      "you'll",
      "your",
      "you're",
      "your's",
      "yourself",
      "yourselves",
      "you've"*/
      ],

      /**
       * @type {Object<string, string>}
       * @export
       */

      stemmer: function (value) {
        // apply some replacements
        // ...

        return stemmer(value);
      },
      encode: rtlValue ? false : "balance",
      rtl: rtlValue,
      split: rtlValue ? /\s+/ : /\W+/
    });

    const subCompare = (needle, haystack, min_substring_length = 1) => {
      // Search possible substrings from largest to smallest:
      for (var i = needle.length; i >= min_substring_length; i--) {
        for (var j = 0; j <= needle.length - i; j++) {
          var substring = needle.substr(j, i);
          var k = haystack.indexOf(substring);
          if (k !== -1) {
            return {
              found: 1,
              substring: substring,
              needleIndex: j,
              haystackIndex: k
            };
          }
        }
      }
      return {
        found: 0
      };
    };

    const calcScore = (question, answer, teacherAnswer) => {
      let tempScore = 0;

      if (!answer.quotes || !answer.quotes.length) return 0;
      if (!teacherAnswer.quotes || !teacherAnswer.quotes.length) return 1;
      let teacherSortedQuotes = [...teacherAnswer.quotes].sort(function (a, b) {
        return EpubCFI.compare(a.cfi, b.cfi);
      });

      let studentSorted = [...answer.quotes].sort(function (a, b) {
        return EpubCFI.compare(a.cfi, b.cfi);
      });

      let studentInd = 0;
      let teacherInd = 0;

      while (
        studentInd < studentSorted.length &&
        teacherInd < teacherSortedQuotes.length
      ) {
        let cfiParts = studentSorted[studentInd].cfi.split(","); // cfiBase:  cfiParts[0]
        let studentStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
        let studentEndCfi = cfiParts[0] + cfiParts[2];

        cfiParts = teacherSortedQuotes[teacherInd].cfi.split(","); // cfiBase:  cfiParts[0]
        let teacherStartCfi = cfiParts[0] + cfiParts[1] + ")"; // start: 'epubcfi(/6/4[chap01ref]!/4[body01]/10[para05]/2/1:1)'
        let teacherEndCfi = cfiParts[0] + cfiParts[2];
        if (EpubCFI.compare(studentEndCfi, teacherStartCfi) <= 0) {
          studentInd++;
        } else if (EpubCFI.compare(teacherEndCfi, studentStartCfi) <= 0) {
          teacherInd++;
        } else {
          let needle = studentSorted[studentInd].text;
          let haystack = teacherSortedQuotes[teacherInd].text;
          if (haystack.length < needle.length) {
            haystack = needle;
            needle = teacherSortedQuotes[teacherInd].text;
          }
          let retVal = subCompare(needle, haystack);
          if (retVal.found) {
            //console.log("found common substring", retVal.found);
            tempScore += retVal.substring.length;
          }

          if (EpubCFI.compare(studentEndCfi, teacherEndCfi) >= 0) {
            studentInd++;
          } else {
            teacherInd++;
          }
        }
      }
      let totalTeacher = teacherSortedQuotes.reduce(
        (acc, curr) => acc + curr.text.length,
        0
      );

      return (1.0 * tempScore) / totalTeacher;
    };

    const createQuestionFeedback = (task, question, index) => {
      let teacherAnswer = task.answers.answers[index];
      let studentAnswer =
        task.task_type !== "peerReview"
          ? task.student_answers.answers[index]
          : task.student_answers.answers;
      let text = studentAnswer && studentAnswer.text;
      if (studentAnswer && studentAnswer.comment) {
        try {
          text = EditorState.createWithContent(
            convertFromRaw(studentAnswer.comment)
          )
            .getCurrentContent()
            .getPlainText("\u0001");
        } catch (error) {
          console.log("error in loading comment", error);
        }
      }

      flexIndex.add(index, text);

      /*

    {comment:'', points:-1, title:''
    */
      return {
        question: question,
        comment: "",
        points: -1,
        title: "",
        match: calcScore(question, studentAnswer, teacherAnswer),
        concepts:
          teacherAnswer.concepts && teacherAnswer.concepts.map
            ? teacherAnswer.concepts.map((concept, i) => {
                let results = flexIndex.search(stemmer(concept));
                let exactResults = flexIndex.search(concept);
                return {
                  concept: concept,
                  found: results.includes(index) || exactResults.includes(index)
                };
              })
            : [],
        selectedChoice:
          teacherAnswer.shouldSelect >= 0 ? teacherAnswer.shouldSelect : -1,
        correctChoice: studentAnswer.choice >= 0 ? studentAnswer.choice : -1
      };
    };

    const getTaskById = firebaseFunctions.httpsCallable(
      "courses-getTaskSubmission"
    );
    let unsubscribe = null;
    getTaskById({ submission: submission_id }).then(response => {
      let taskRow = response.data[0];

      firebaseApp
        .storage()
        .ref("courseTexts/" + taskRow.course_id)
        .child(taskRow.file_url)
        .getDownloadURL()
        .then(url => {
          dispatch(
            setSelectedText({ url: url, file_location: taskRow.file_location })
          );
          setReadText({ url: url, file_location: taskRow.file_location });

          dispatch(setFeedbackTask(taskRow));

          if (taskRow.is_checked) {
            setOpenEval(
              taskRow.grade ||
                (taskRow.totalpoints && taskRow.grade === 0) ||
                taskRow.evaltitle ||
                taskRow.feedbackcomment
            );
            setLocalQuestionIndex(0);
          }
          dispatch(
            setPangeaText({
              ...taskRow,
              link: url,
              defaultLocation: taskRow.file_location
            })
          );

          let docId =
            "tasks_feedback/" +
            taskRow.id +
            "/teacher/" +
            taskRow.teacher.uid +
            "/students/" +
            taskRow.student.uid;

          if (taskRow.is_checked) {
            setFeedback(taskRow.feedback);
          } else {
            unsubscribe = firestore.doc(docId).onSnapshot(
              snapshot => {
                let coll = snapshot.exists
                  ? snapshot.data()
                  : {
                      teacherSelectedQuestion: 0,
                      studentSelectedQuestion: 0,
                      grade: 0,
                      totalPoints: taskRow.questions.questions.reduce(function (
                        accumulator,
                        current
                      ) {
                        return accumulator + parseInt(current.points);
                      },
                      0),
                      questions: taskRow.questions.questions.map(
                        (question, index) =>
                          createQuestionFeedback(taskRow, question, index)
                      )
                    };
                if (
                  coll.questions.length !== taskRow.questions.questions.length
                ) {
                  coll = {
                    ...coll,
                    questions: taskRow.questions.questions.map(
                      (question, index) =>
                        createQuestionFeedback(taskRow, question, index)
                    )
                  };
                }
                setFeedback({
                  teacherSelectedQuestion: 0,
                  studentSelectedQuestion: 0,
                  ...coll
                });
              },
              error => {
                console.log("fetchFirebaseError tasks", error);
              }
              //   dispatch(fetchFirebaseError(error)) },
            );
          }
        });
    });

    return function cleanup() {
      // console.log("unsubscribe", submission_id);
      unsubscribe && unsubscribe();
    };
  }, [submission_id, dispatch, rtlValue]);

  useEffect(() => {
    if (!openEval && feedback) {
      setGrade(
        feedback.questions.reduce((acc, curr) => acc + parseInt(curr.points), 0)
      );
    }
  }, [openEval, feedback]);

  useEffect(() => {
    if (shouldSendFeedback) {
      setOpenEval(true);
    }
  }, [shouldSendFeedback]);

  const updateFeedback = (feedback, questionIndex) => {
    if (task.is_checked) {
      if (questionIndex >= 0) {
        setLocalQuestionIndex(questionIndex);
      }
      return;
    }
    if (questionIndex < 0) {
      questionIndex = selectedQuestion;
    }
    let selectedQuestionPart = {};
    if (
      task &&
      feedback &&
      task.teacher &&
      task.teacher.uid === currentUser.uid
    ) {
      selectedQuestionPart = {
        teacherSelectedQuestion: questionIndex ? questionIndex : 0
      };
    } else {
      selectedQuestionPart = {
        studentSelectedQuestion: questionIndex ? questionIndex : 0
      };
    }

    let docId =
      "/tasks_feedback/" +
      task.id +
      "/teacher/" +
      task.teacher.uid +
      "/students/" +
      task.student.uid;
    if (feedback) {
      firestore.doc(docId).set({ ...feedback, ...selectedQuestionPart });
    }
  };

  const titleEditMargin = () => {
    let marginVal = "0px";
    marginVal =
      evalTitleSpanRef && evalTitleSpanRef.current
        ? evalTitleSpanRef.current.getBoundingClientRect().width + "px"
        : evalTitle * 0.7 + "ch";

    if (rtlValue) {
      return { marginRight: marginVal };
    } else {
      return { marginLeft: marginVal };
    }
  };

  const renderPoints = grade => {
    if (
      (task.totalpoints || feedback.totalPoints) &&
      (!task.is_checked || task.grade >= 0)
    ) {
      return (
        <>
          <TextField
            className={classes.points}
            id="standard-number"
            type="number"
            InputProps={{
              disableUnderline: true,
              readOnly: !!task.is_checked
            }}
            inputProps={{
              "aria-label": `Points out of ${
                task.is_checked ? task.totalpoints : feedback.totalPoints
              }`
            }}
            defaultValue={
              task.is_checked ? (task.totalpoints ? task.grade : "") : grade
            }
            onChange={e => {
              setGrade(e.target.value);
            }}
          />
          <Typography
            className={classes.pointsMsg}
            variant="body1"
            component="span"
          >
            {" "}
            <FormattedMessage
              defaultMessage="Points out of"
              id="task.points_of"
            />{" "}
            {task.is_checked ? task.totalpoints : feedback.totalPoints}
          </Typography>
        </>
      );
    }
  };

  const getDialogTitle = () => {
    if (!task.is_checked || task.feedbackcomment || task.evaltitle) {
      return (
        <FormattedMessage
          id="tasks.general_evaluation"
          defaultMessage="Overall evaluation"
        />
      );
    } else
      return (
        <FormattedMessage
          id="tasks.general_evaluation_score"
          defaultMessage="Your score"
        />
      );
  };

  function validateForm() {
    let gradePresent = true;
    let commentPresent = true;

    if (Number.isNaN(grade) || grade === "") {
      gradePresent = false;
    }
    if (evalTitle === "" && feedbackComment === "") {
      commentPresent = false;
    }
    setValidationResult({ grade: gradePresent, comment: commentPresent });

    if (!gradePresent || !commentPresent) {
      setShowSubmissionDialog(true);
    } else {
      submitFeedback();
    }
    // }
  }

  function submitFeedback() {
    setIsLoading(true);
    let func = firebaseFunctions.httpsCallable("courses-gradeTask");
    let myGrade = grade;
    if (isNaN(grade) || grade === "") {
      myGrade = null;
    }
    let totalPoints = task.is_checked ? task.totalpoints : feedback.totalPoints;
    if (isNaN(totalPoints)) totalPoints = 0;
    let data = {
      submission_id: task.submission_id,
      student: task.student.uid,
      collaborator: task.collaborator_id || null,
      task_id: task.id,
      taskType: task.task_type,
      courseName: selectedCourse.name,
      course_id: selectedCourse.id,
      grade: myGrade,
      evalTitle: evalTitle,
      feedbackComment: feedbackComment,
      feedback: {
        ...feedback,
        grade: myGrade,
        totalPoints: totalPoints
      },
      totalPoints: totalPoints
    };
    func(data).then(response => {
      dispatch(setSentFeedbackTask(submission_id));
      setIsLoading(false);
      history.push(
        "/tasks/course/" +
          task.course_id +
          "/task/" +
          task.id +
          "/submission/" +
          submission_id
      );
    });
  }

  const renderDialog = () => {
    if (!task) return <></>;

    return (
      <Dialog
        open={openEval}
        PaperProps={{
          style: {
            width: "70%",
            direction: rtlValue ? "rtl" : "ltr"
          }
        }}
        onClose={() => {
          dispatch(sendFeedback(false));
          setOpenEval(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{getDialogTitle()}</DialogTitle>
        <Divider />
        <DialogContent className={classes.dialog}>
          <TextField
            inputRef={editTitleRef}
            fullWidth
            inputProps={{
              "aria-label": `total points`
            }}
            InputProps={{
              readOnly: !editingTitle,
              disableUnderline: true,

              startAdornment: !editingTitle && !task.is_checked && (
                <InputAdornment
                  style={{ ...titleEditMargin(), position: "absolute" }}
                  position="start"
                >
                  <IconButton
                    className={classes.adornment}
                    onClick={e => {
                      setEditingTitle(true);
                      editTitleRef.current.focus();
                      if (editTitleRef.current.setSelectionRange && evalTitle) {
                        editTitleRef.current.setSelectionRange(
                          evalTitle.length,
                          evalTitle.length
                        );
                      }
                      e.stopPropagation();
                    }}
                    aria-label="edit"
                  >
                    <EditIcon />
                  </IconButton>
                </InputAdornment>
              ),
              className: classes.eval
            }}
            className={classes.eval}
            label=""
            placeholder={task.is_checked ? "" : titlePlaceholder}
            defaultValue={task.is_checked ? task.evaltitle : evalTitle}
            onChange={e => {
              setEvalTitle(e.target.value);
            }}
            onKeyPress={e => {
              if (e.key === "Enter") {
                setEditingTitle(false);
              }
            }}
            onBlur={() => {
              setEditingTitle(false);
            }}
          />

          {(!task.is_checked || task.feedbackcomment) && (
            <TextField
              className={classes.writenEval}
              multiline={true}
              InputProps={{
                readOnly: !!task.is_checked
              }}
              fullWidth
              defaultValue={
                task.is_checked ? task.feedbackcomment : feedbackComment
              }
              onChange={e => {
                setFeedbackComment(e.target.value);
              }}
              placeholder={
                !task.is_checked &&
                intl.formatMessage({
                  id: "task.title.placeholder",
                  defaultMessage: "Write the general task evaluation"
                })
              }
            />
          )}
        </DialogContent>
        {(!task.is_checked || task.feedbackcomment || task.evaltitle) && (
          <Divider />
        )}
        <DialogActions className={classes.actions}>
          <Box className={classes.btnContainer}>
            {!task.is_checked && (
              <Button
                className={clsx(classes.button, classes.buttonCancel)}
                onClick={() => {
                  setOpenEval(false);
                  dispatch(sendFeedback(false));
                }}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </Button>
            )}
            {!task.is_checked && (
              <Button
                color="primary"
                className={classes.button}
                onClick={() => {
                  // setShowSubmissionDialog(true);
                  validateForm();
                  setOpenEval(false);
                }}
              >
                <FormattedMessage
                  id="task.feedback.submit"
                  defaultMessage="Send feedback"
                />
              </Button>
            )}
            {!!task.is_checked && (
              <Button
                onClick={() => {
                  setOpenEval(false);
                  dispatch(sendFeedback(false));
                }}
                variant="contained"
                color="primary"
              >
                <FormattedMessage id="close" defaultMessage="Close" />
              </Button>
            )}
          </Box>
          {renderPoints(grade)}
        </DialogActions>
      </Dialog>
    );
  };

  //Render
  if (isLoading || task.submission_id != submission_id || !feedback) {
    return <PangeaSpinner />;
  }

  if (task.task_type === "peerReview" && task.submission_id == submission_id) {
    return (
      <PeerReview
        reflection={reflection}
        setReflection={setReflection}
        response={response}
        setResponse={setResponse}
        reply={reply}
        setReply={setReply}
        readText={readText}
        setReadText={setReadText}
        fetchedFeedback={fetchedFeedback}
        setFetchedFeedback={setFetchedFeedback}
        showSubmissionDialog={showSubmissionDialog}
        submitFeedback={submitFeedback}
        updateFeedback={updateFeedback}
        feedback={feedback}
        index={selectedQuestion}
      />
    );
  }
  return (
    <>
      {/* <div className={clsx(classes.readerViewContainer, classes.dirLtr)}> */}
      {/* <ScrollToTopOnMount /> */}
      <FeedbackSubmmisionModal
        grade={grade}
        evalTitle={evalTitle}
        feedbackComment={feedbackComment}
        showSubmissionDialog={showSubmissionDialog}
        setShowSubmissionDialog={setShowSubmissionDialog}
        validationResult={validationResult}
        openEval={openEval}
        setOpenEval={setOpenEval}
        submitFeedback={submitFeedback}
      />
      {feedback && (
        <TaskFeedbackSidebar
          editable={
            task &&
            task.teacher &&
            task.teacher.uid === currentUser.uid &&
            !task.is_checked
          }
          onSubmit={() => setOpenEval(true)}
          onQuestionIndex={i => {
            updateFeedback(feedback, i);
          }}
          isTeacher={
            task && task.teacher && task.teacher.uid === currentUser.uid
          }
          showMatch={
            task &&
            task.teacher &&
            task.teacher.uid === currentUser.uid &&
            task.task_type !== "guidedReading"
          }
          selectedQuestionIndex={selectedQuestion}
          task={task}
          feedback={feedback}
          questions={task && task.questions}
        />
      )}
      <div className={classes.drawerContainer} id="drawer-container">
        {/* <div
          className={clsx(
            classes.content,
            darkMode && classes.darkMode,
            classes.contentActionOpen
          )}
        > */}
        {/* <div
            id="readerViewBookContainer"
            className={clsx(classes.bookContainer)}
          > */}
        {task && feedback && (
          <FeedbackScreen
            showMatch={
              task && task.teacher && task.teacher.uid === currentUser.uid
            }
            readText={readText}
            updateFeedback={updateFeedback}
            feedback={feedback}
            isTeacher={
              task && task.teacher && task.teacher.uid === currentUser.uid
            }
            onQuestionIndex={i => {
              updateFeedback(feedback, i);
            }}
            selectedQuestionIndex={selectedQuestion}
            task={task}
          />
        )}
        {/* </div> */}
        {/* </div> */}
      </div>
      {openEval && task && feedback && renderDialog()}
      <span ref={evalTitleSpanRef} className={classes.hidden}>
        {!evalTitle.length ? titlePlaceholder : evalTitle}
      </span>

      {/* </div> */}
    </>
  );
};

export default injectIntl(TaskFeedback);
