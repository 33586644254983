// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";

// Redux Dependencies
import { useSelector } from "react-redux";

//Components
import SummaryActions from "./SummaryActions";
import { RichTextEditor } from "../../SharedComponents";
import EmptyState from "../EmptyState";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  editor: {
    marginTop: theme.spacing(5),
    borderRadius: 8,
    overflow: "hidden",
    position: "relative",
    border: "1px solid",
    borderColor: theme.palette.grey.main,
    height: "100%",
    width: "100%",
    lineHeight: "32px",
    fontSize: "22px"
  },
  container: {
    width: 640,
    height: "calc(100vh - 120px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingTop: "5vh",
    paddingBottom: "5vh"
  },
  title: {
    marginTop: "16px",
    marginBottom: "10px",
    fontSize: 16
  },
  btn: {
    marginRight: "8px"
  }
}));

function SummaryManager({
  isTaskSubmitted,
  saveSummary,
  showSelfFeedback = true
}) {
  // Hooks
  const classes = useStyles();
  const intl = useIntl();

  //Redux State
  const questions = useSelector(state => {
    return state.gr.questions;
  });
  const selectedQuestion = useSelector(state => {
    let filtered = state.gr?.questions?.filter(
      q => q.id === state.gr.selectedQuestionId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });

  // Variables
  const myAnswer = intl.formatMessage({
    id: "my.answer",
    defaultMessage: "My Answer"
  });

  return selectedQuestion ? (
    <Box className={classes.container}>
      <Box>
        <Typography variant="h4">
          <FormattedMessage
            id="gr.stage.5.ownWords"
            defaultMessage="In my own words"
          />
        </Typography>
        <Typography variant="h6"> {selectedQuestion.question}</Typography>
      </Box>
      <RichTextEditor
        className={classes.editor}
        placeholder={myAnswer}
        readOnly={isTaskSubmitted}
        text={selectedQuestion.summary || ""}
        returnType="plainText"
        showWordCount
        resetContentonChangeOf={selectedQuestion.id}
        subcribeToUnload
        onChange={plainText => {
          let modQ = {
            ...selectedQuestion,
            summary: plainText
          };
          let updatedQuestions = questions.map(el =>
            el.id === selectedQuestion.id ? modQ : el
          );
          saveSummary(updatedQuestions);
        }}
      />
      {showSelfFeedback && <SummaryActions />}
    </Box>
  ) : (
    <EmptyState />
  );
}

SummaryManager.propTypes = {
  isTaskSubmitted: PropTypes.bool,
  saveSummary: PropTypes.func.isRequired,
  showSelfFeedback: PropTypes.bool
};

export default SummaryManager;
