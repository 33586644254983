import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { DialogContentText } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  disacalmer: {
    fontSize: 14,
    opacity: 0.5,
    marginTop: theme.spacing(1.5),
    fontStyle: "italic"
  },
  list: {
    padding: 0,
    margin: 0,
    listStyle: "none"
  }
}));

export default function GrSubmitTaskDialogContent() {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <ul className={classes.list}>
      <li>
        <DialogContentText id="alert-dialog-description">
          {`${intl.formatMessage({
            id: "task.submission.grConfirmation",
            defaultMessage: "You are about to submit your guided reading task."
          })}`}
        </DialogContentText>
      </li>
      <li>
        <DialogContentText id="alert-dialog-sync-with-personal-gr">
          {`${intl.formatMessage({
            id: "task.submission.addGrWork",
            defaultMessage:
              "Do you want to copy all your work to your personal library?"
          })}`}
        </DialogContentText>
      </li>
    </ul>
  );
}
