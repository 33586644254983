// Dependencies
import React, { useState, useEffect, useCallback, useRef } from "react";
import clsx from "clsx";
import { useParams, useHistory } from "react-router-dom";
import { firebaseFunctions } from "../../../firebase";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import {
  validateFeedback,
  setSubmittedStudentReply
} from "../../../redux/taskSlice";
import {
  setBreadcrumbs,
  setSubmitButtonClicked
} from "../../../redux/readerActionsSlice";
import { enqueueFlashMessage } from "../../../redux/userSlice";

// Components
import RichTextEditor from "../../SharedComponents/RichTextEditor";
import PeerReviewMissingReviewModal from "./PeerReviewMissingReviewModal";
import PeerReviewFeedbackValidationModal from "./PeerReviewFeedbackValidationModal";
import PeerReviewTabs from "./PeerReviewTabs";
import PeerReviewReply from "./PeerReviewReply";
import PeerReviewTabPanels from "./PeerReviewTabPanels";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
import ScrollBox from "../../SharedComponents/ScrollBox";
import RenderEditorContent from "../../SharedComponents/RenderEditorContent";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    columnGap: theme.spacing(2)
  },

  tabs: {
    gridColumnEnd: "span 12",
    zIndex: 2
  },
  grid: {
    height: "calc(100% - 50px)", //subtract the tabs
    width: `calc(100% - ${theme.spacing(3)}px)`, //subtract the margins
    marginInlineStart: theme.spacing(3),
    flexWrap: "nowrap"
  },
  textContainer: {
    gridColumnEnd: "span 6",
    fontSize: "22px",
    height: "calc(100vh - 164px)",
    paddingInlineStart: theme.spacing(5)
  },
  feedbackContainer: {
    gridColumnEnd: "span 3",
    background: "rgba(0,0,0, 0.08)",
    height: "calc(100vh - 164px)"
  },
  responseContainer: {
    gridColumnEnd: "span 3",
    height: "calc(100vh - 164px)",
    "& $header": { color: theme.palette.text.blue }
  },
  feedback: {
    marginBlockStart: theme.spacing(4.5),
    color: theme.palette.text.blue,
    "& $header": { paddingInlineStart: theme.spacing(2.5) }
  },

  response: {
    marginBlockStart: theme.spacing(4.5)
    // paddingInlineStart: theme.spacing(2)
  },

  header: {
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "uppercase",
    letterSpacing: "1px"
  },
  userInput: {
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"',
    fontSize: 16,
    lineHeight: "24px"
  },

  paddedScrollBox: {
    paddingInlineStart: 0
  }
}));

export default function PeerReviewFeedback({
  index,
  feedbackComment,
  submitFeedback,
  feedback,
  updateFeedback,
  fetchedFeedback,
  reflection,
  response,
  reply,
  setReply,
  readText,
  currentTab,
  setCurrentTab,
  zenMode,
  setZenMode,
  rendition,
  onRenditionCreated,
  saveCurrentTabLocation,
  textBookmark,
  answerBookmark,
  answerRef
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const editorRef = useRef();
  const { submission_id } = useParams();
  const history = useHistory();

  // Redux state
  const task = useSelector(state => state.task.taskRow);
  const currentUser = useSelector(state => state.user.auth);
  const feedbackTask = useSelector(state => state.task.feedbackTask);
  const readerActions = useSelector(state => state.readerActions);

  const userRole = useSelector(
    state => state.user.userProfile.selectedCourse.course_role
  );

  const submissionStatus = useSelector(
    state => state.task.taskRow.submission_status
  );

  const collaborationStatus = useSelector(
    state => state.task.taskRow.collaboration_status
  );

  const showValidateFeedback = useSelector(
    state => state.task.validateFeedback
  );

  const selectedCourse = useSelector(
    state => state.user.userProfile.selectedCourse
  );

  const submitButtonClicked = useSelector(
    state => state.readerActions.submitButtonClicked
  );

  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  // Ephemeral state
  const [modalVisible, setModalVisible] = useState(false); // Modal for Missing Review
  const [showValidationDialog, setShowValidationDialog] = useState(false);
  const [validationResult, setValidationResult] = useState({
    comment: []
  });

  // Variables
  let formattedAcceptDate =
    feedbackTask?.accept_date &&
    getDate(feedbackTask?.accept_date).setHours(24);

  const today = new Date();

  function getDate(str) {
    let b = str.split(/\D/);
    let d = new Date(b[0], b[1] - 1, b[2]);
    return d;
  }
  // Behavior
  // set breadcrumbs
  useEffect(() => {
    dispatch(
      setBreadcrumbs({
        ...readerActions,
        blue: true,
        showTextMenu: true,
        showSendFeedback:
          submissionStatus === "Submitted" ||
          submissionStatus === "Graded" ||
          (submissionStatus === "pendingResponse" &&
            formattedAcceptDate < today) ||
          (collaborationStatus === "Answered" &&
            task.owner !== currentUser.uid),

        showSubmitTask:
          (submissionStatus === "Graded" &&
            collaborationStatus !== "Answered" &&
            task.owner === currentUser.uid) ||
          (submissionStatus !== "Graded" &&
            selectedCourse.course_role.toLowerCase() === "student") ||
          (submissionStatus === "pendingResponse" &&
            selectedCourse.course_role === "Teacher" &&
            formattedAcceptDate > today)
      })
    );
  }, [submissionStatus, selectedCourse, formattedAcceptDate, today]);

  const validateForm = useCallback(() => {
    // Return and object with arrays of invalid answers and citations
    const comment = [];

    if (feedback) {
      feedback.questions.forEach((question, index) => {
        // the rich text editor returns an array of text lines
        // checkling to see if at least one line isn't empty
        let commentIsValid = question.comment?.blocks?.some(
          element => element.text !== ""
        );

        if (!commentIsValid && (!question.title || question.title === "")) {
          comment.push(index);
        }
      });
      setValidationResult({ comment });

      // if something failed validation - open the dialog
      if (comment.length > 0) {
        setShowValidationDialog(true);
      } else {
        submitFeedback();
      }
    }
  }, [feedback, submitFeedback]);

  useEffect(() => {
    if (showValidateFeedback) {
      validateForm();
      dispatch(validateFeedback(false));
    }
  }, [showValidateFeedback, dispatch, validateForm]);

  function updateEditor(comment, index) {
    let newFeedbackComment = comment;
    let newFeedback = {
      ...feedback,
      questions: feedback.questions.map((q, i) => {
        if (i === index) {
          return { ...q, comment: newFeedbackComment };
        } else return q;
      })
    };
    updateFeedback(newFeedback, index);
  }

  useEffect(() => {
    if (
      submission_id == task.submission_id &&
      selectedCourse.course_role === "Teacher" &&
      submissionStatus === "pendingResponse" &&
      formattedAcceptDate > today
    )
      setModalVisible(true);
  }, [
    selectedCourse.course_role,
    submissionStatus,
    submission_id,
    task.submission_id
  ]);

  useEffect(() => {
    if (reply && submitButtonClicked) {
      const submitReply = firebaseFunctions.httpsCallable(
        "courses-submitPeerReviewReply"
      );
      submitReply({
        task: task.id,
        reply: reply,
        collaboration: task.collaboration_id,

        // for notification
        reviewer: task.collaborator_id,
        courseId: selectedCourse.id,
        courseName: selectedCourse.name,
        submissionId: task.submission_id,
        studentName: currentUser.displayName
      }).then(response => {
        dispatch(setSubmittedStudentReply(task.submission_id));

        dispatch(
          enqueueFlashMessage({
            message: "Reply was sent successfully",
            duration: alertsDuration,
            undoButton: true
          })
        );
      });
      // );
      dispatch(setSubmitButtonClicked(false));
      history.push("/tasks/" + selectedCourse.id);
    }
  }, [
    currentUser,
    reply,
    selectedCourse,
    task,
    submissionStatus,
    submitButtonClicked
  ]);

  if (!reflection && !response && !feedbackComment) {
    return <PangeaSpinner />;
  } else {
    return (
      <>
        <PeerReviewMissingReviewModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
        <PeerReviewFeedbackValidationModal
          feedback={feedback}
          showValidationDialog={showValidationDialog}
          setShowValidationDialog={setShowValidationDialog}
          submitFeedback={submitFeedback}
          validationResult={validationResult}
        />
        <Box className={classes.container}>
          <Box className={classes.tabs}>
            <PeerReviewTabs
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              answerBookmark={answerBookmark}
              zenMode={zenMode}
              saveCurrentTabLocation={saveCurrentTabLocation}
              classes={{
                item: classes.tabPanels
              }}
            />
          </Box>

          {/* <Box className={classes.grid}> */}
          <Box className={classes.textContainer}>
            <PeerReviewTabPanels
              reflection={reflection}
              readText={readText}
              currentTab={currentTab}
              zenMode={zenMode}
              setZenMode={setZenMode}
              rendition={rendition}
              onRenditionCreated={onRenditionCreated}
              textBookmark={textBookmark}
              answerRef={answerRef}
            />
          </Box>

          <Box className={classes.responseContainer}>
            <ScrollBox className={classes.paddedScrollBox}>
              <Box
                className={clsx(
                  classes.response,
                  currentUser.uid === task.owner &&
                    (task.submission_status === "Submitted" ||
                      task.submission_status === "Graded")
                )}
              >
                <Box className={classes.header}>
                  {currentUser.uid === task.collaborator_id && `My review`}
                  {currentUser.uid !== task.collaborator_id &&
                    (response?.displayName
                      ? `${response?.displayName}'s review`
                      : `review`)}
                </Box>
                <Box className={classes.userInput}>
                  <RenderEditorContent>{response?.data}</RenderEditorContent>
                </Box>
              </Box>
              <PeerReviewReply reply={reply} setReply={setReply} />
            </ScrollBox>
          </Box>

          <Box
            className={classes.feedbackContainer}
            onClick={() => {
              editorRef?.current && editorRef.current.focus();
            }}
          >
            <ScrollBox>
              <Box className={classes.feedback}>
                {userRole === "Teacher" ? (
                  <Box className={classes.header}>My feedback</Box>
                ) : (
                  <Box className={classes.header}>Teacher feedback</Box>
                )}

                <Box className={classes.userInput}>
                  {(userRole === "Teacher" &&
                    submissionStatus === "Submitted") ||
                  (userRole === "Teacher" &&
                    submissionStatus === "pendingResponse") ? (
                    <RichTextEditor
                      showWordCount={false}
                      autoFocus={true}
                      subcribeToUnload={true}
                      readOnly={false}
                      onChange={updateEditor}
                      index={index}
                      placeholder={false}
                      comment={feedback?.questions[index]?.comment}
                      wordLimt={false}
                      editorRef={editorRef}
                    />
                  ) : (
                    fetchedFeedback && (
                      <RichTextEditor
                        readOnly={true}
                        comment={fetchedFeedback?.questions[0].comment}
                        wordLimt={false}
                      />
                    )
                  )}
                </Box>
              </Box>
            </ScrollBox>
          </Box>
        </Box>
        {/* </Box> */}
      </>
    );
  }
}
