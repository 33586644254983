// Dependencies
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

// Redux Dependencies
import { useSelector, useDispatch } from "react-redux";
import { setLocation } from "../../redux/readerActionsSlice";
import {
  setStage,
  setExpand,
  setDetailedInstructions
} from "../../redux/grSlice";

//Components
import QuestionManager from "./questionManager/QuestionManager";
import SubmitDialog from "./SubmitDialog";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
  Divider,
  Button,
  Box,
  IconButton,
  Typography
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: "#616161",
    marginBottom: "12px"
  },
  divider2: {
    backgroundColor: "#616161",
    marginBottom: "16px",
    marginTop: "16px"
  },
  expand: {
    color: "#bdbdbd",
    transform: "translateY(2px)",
    marginLeft: theme.spacing(1)
  },
  panelHeader: {
    color: "white",
    width: "100%",
    textTransform: "uppercase",
    overscrollBehavior: "none",

    position: "relative"
  },
  panelHeaderLtr: {
    textAlign: "left",

    paddingLeft: "16px"
  },
  panelHeaderRtl: {
    textAlign: "right",

    paddingRight: "16px"
  },
  white: {
    "&.Mui-disabled": {
      color: "white"
    },
    color: "white"
  },
  panelTitle: {
    color: "#bdbdbd",
    fontSize: "12px",
    fontWeight: "300",
    fontStyle: "normal",
    letterSpacing: "1px",
    marginTop: "8px",
    display: "block",
    lineHeight: "32px",
    textTransform: "uppercase"
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  nextButton: {
    textTransform: "none",
    fontSize: "16px",
    lineHeight: "32px"
  },
  panelDetails: {},

  panelDetailsLtr: {
    paddingLeft: "16px",
    marginRight: "16px"
  },
  stageTitle: {
    display: "inline-block",
    textTransform: "none",
    fontSize: "20px",
    lineHeight: "32px"
  },
  panelStageName: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1)
  },
  learnMoreBtn: {
    padding: "0px",
    textTransform: "none"
  },
  instructions: {
    marginTop: "16px",
    fontSize: "16px",
    color: "#e0e0e0"
  }
}));

export default function MyDefaultPanel({
  index,
  openQuestions,
  setOpenQuestions,
  color = "secondary",
  editable,
  onAnswerCardDelete,
  ...other
}) {
  //Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const panelRef = useRef();

  //Redux State
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const expanded = useSelector(state => {
    return state.gr.expand;
  });
  const detailedInstructions = useSelector(
    state => state.gr.detailedInstructions
  );

  //Ephemeral State
  const [openSubmit, setOpenSubmit] = useState(false);

  // Variables
  const panels = [
    {
      title: `gr.${grMode}.stage.1`,
      defaultMessage: grMode === "full" ? "Survey" : "Survey and Questions",
      details: (
        <React.Fragment>
          <Typography className={clsx(classes.instructions)}>
            <FormattedMessage id={`gr.${grMode}.stage.1.instructions`} />
          </Typography>
        </React.Fragment>
      ),
      actions:
        grMode === "full" ? undefined : (
          <React.Fragment>
            {" "}
            {renderSecondDevider()}
            <QuestionManager
              openQuestions={openQuestions}
              setOpenQuestions={setOpenQuestions}
              scrollToBottom={() => {
                panelRef.current.scrollTop = panelRef.current.scrollHeight;
              }}
              color={color}
              editable={editable}
              onAnswerCardDelete={onAnswerCardDelete}
            />
          </React.Fragment>
        )
    },
    {
      title: `gr.${grMode}.stage.2`,
      defaultMessage: grMode === "full" ? "My Questions" : "Read and ansewr",
      details: (
        <React.Fragment>
          <Typography className={clsx(classes.instructions)}>
            <FormattedMessage id={`gr.${grMode}.stage.2.instructions`} />
          </Typography>
        </React.Fragment>
      ),
      actions: (
        <React.Fragment>
          {" "}
          {renderSecondDevider()}
          <QuestionManager
            openQuestions={openQuestions}
            setOpenQuestions={setOpenQuestions}
            scrollToBottom={() => {
              panelRef.current.scrollTop = panelRef.current.scrollHeight;

              //  panelRef.current.scrollTop +
              //   Math.max(0, offset - panelRef.current.scrollTop + panelRef.current.offsetHeight );
            }}
            color={color}
            editable={editable}
            onAnswerCardDelete={onAnswerCardDelete}
          />
        </React.Fragment>
      )
    },
    {
      title: `gr.${grMode}.stage.3`,
      defaultMessage: grMode === "full" ? "Read and Answer" : "Exercise",
      details: (
        <React.Fragment>
          <Typography className={clsx(classes.instructions)}>
            <FormattedMessage id={`gr.${grMode}.stage.3.instructions`} />
          </Typography>
        </React.Fragment>
      ),
      actions: (
        <React.Fragment>
          {" "}
          {renderSecondDevider()}
          <QuestionManager
            openQuestions={openQuestions}
            setOpenQuestions={setOpenQuestions}
            color={color}
            editable={editable}
            onAnswerCardDelete={onAnswerCardDelete}
          />
          {grMode === "light" ? (
            <SubmitDialog
              open={openSubmit}
              onClose={() => setOpenSubmit(false)}
            />
          ) : null}
        </React.Fragment>
      )
    },
    {
      title: "gr.stage.4",
      defaultMessage: "Recite",
      details: (
        <React.Fragment>
          <Typography className={clsx(classes.instructions)}>
            <FormattedMessage id="gr.stage.4.instructions" />
          </Typography>
        </React.Fragment>
      ),
      actions: (
        <React.Fragment>
          {renderSecondDevider()}
          <QuestionManager
            openQuestions={openQuestions}
            setOpenQuestions={setOpenQuestions}
            color={color}
            editable={editable}
            onAnswerCardDelete={onAnswerCardDelete}
          />
        </React.Fragment>
      )
    },
    {
      title: "gr.stage.5",
      defaultMessage: "Review",
      details: (
        <React.Fragment>
          <Typography className={clsx(classes.instructions)}>
            <FormattedMessage id="gr.stage.5.instructions" />
          </Typography>
        </React.Fragment>
      ),
      actions: (
        <React.Fragment>
          {" "}
          {renderSecondDevider()}
          <QuestionManager
            openQuestions={openQuestions}
            setOpenQuestions={setOpenQuestions}
            color={color}
            editable={editable}
            onAnswerCardDelete={onAnswerCardDelete}
          />
          <SubmitDialog
            open={openSubmit}
            onClose={() => setOpenSubmit(false)}
          />
        </React.Fragment>
      )
    }
  ];

  // Behavior
  function renderSecondDevider() {
    if (expanded === stage || expanded === true) {
      return <Divider className={classes.divider2} />;
    } else return "";
  }

  const renderActions = () => {
    if (panels[stage].actions) {
      return panels[stage].actions;
    } else return "";
  };

  const renderDetails = () => {
    //console.log("stage = "+stage+" expanded ="+expanded);
    if (expanded) {
      return (
        <Box
          className={clsx(
            classes.panelDetails,
            classes.panelDetailsLtr,
            classes.white,
            classes.left
          )}
        >
          {panels[stage].details}
          <Button
            className={classes.learnMoreBtn}
            color={color}
            onClick={() => {
              dispatch(setDetailedInstructions(!detailedInstructions));
            }}
          >
            <FormattedMessage id="learn.more" defaultMessage="Learn more" />
          </Button>
        </Box>
      );
    } else return "";
  };

  const renderExpandIcon = () => {
    if (expanded) return <ExpandLessIcon />;
    else return <ExpandMoreIcon />;
  };

  const renderNext = () => {
    if ((grMode === "full" && stage < 4) || (grMode === "light" && stage < 2)) {
      return (
        <Button
          className={clsx(classes.nextButton)}
          color={color}
          size="small"
          onClick={() => {
            //console.log("setStage",stage);
            //     dispatch(setExapnd(!exapnded));
            dispatch(setLocation(false));
            dispatch(setStage({ stage: stage + 1 }));
          }}
        >
          <FormattedMessage id="button.next" defaultMessage="Next" />
        </Button>
      );
    }
    // Remove finish button so student can't submit
    // else if (editable) {
    //   return (
    //     <Button
    //       variant="contained"
    //       className={clsx(classes.nextButton, classes.nextButtonLtr)}
    //       color={color}
    //       size="small"
    //       onClick={() => {
    //         //console.log("setStage",stage);
    //         setOpenSubmit(true);
    //         //  dispatch(setAnnotatorMode(''));
    //         //  dispatch(setAction(''))
    //       }}
    //     >
    //       <FormattedMessage id="gr.button.finish" defaultMessage="Finish" />
    //     </Button>
    //   );
    // }
  };

  return (
    <div
      ref={panelRef}
      role="tabpanel"
      hidden={stage !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {stage === index && (
        <>
          <Box
            className={clsx(classes.panelHeader, classes.panelHeaderLtr)}
            id="panel1a-header"
          >
            <Typography
              component="span"
              className={clsx(classes.panelTitle, classes.left)}
            >
              <FormattedMessage
                id="actionBar.tooltip.gr"
                defaultMessage="Guided Reading"
              />
            </Typography>
            <Box className={classes.panelStageName}>
              <Typography variant="h6" className={classes.stageTitle}>
                <FormattedMessage
                  id={panels[stage].title}
                  defaultMessage={panels[stage].defaultMessage}
                />
              </Typography>
              <IconButton
                onClick={() => {
                  dispatch(setExpand(!expanded));
                }}
                className={classes.expand}
                aria-label={
                  expanded
                    ? `expand guided reading instructions`
                    : `hide guided reading instructions`
                }
              >
                {renderExpandIcon()}
              </IconButton>

              {renderNext()}
            </Box>
          </Box>
          <Divider className={classes.divider} />
          {renderDetails()}
          {renderActions()}
        </>
      )}
    </div>
  );
}

MyDefaultPanel.propTypes = {
  color: PropTypes.oneOf(["secondary", "primary"]).isRequired,
  editable: PropTypes.bool,
  onAnswerCardDelete: PropTypes.func.isRequired
};
