// Dependencies
import React, { useEffect, useState } from "react";
import { firebaseFunctions, firebaseApp } from "../../../firebase";
import clsx from "clsx";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { useHistory } from "react-router-dom";

// Redux dependencies
import { useDispatch, useSelector } from "react-redux";
import { setBreadcrumbs } from "../../../redux/readerActionsSlice";
import { enqueueFlashMessage } from "../../../redux/userSlice";

// Components
import SelectReading from "./StepSelectReadings";
import StepAddQuestions from "./StepAddQuestions";
import SubmitTask from "./StepSubmitTask";
import TaskStepper from "./TaskStepper";
import RouteLeavingGuard from "./RouteLeavingGuard";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Tooltip
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";

// Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  taskHeader: {
    position: "relative"
  },
  navigateBack: {
    top: "-8px",
    left: "-60px",
    position: "absolute"
  },
  tasksHeader: {
    marginTop: "40px",
    width: "100%",
    position: "relative"
  },
  blueBg: {
    backgroundColor: "#168FEE",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#1172be"
    }
  },

  stepper: {
    marginTop: "20px",
    width: "100%",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  btnContainer: {
    marginTop: "40px",
    marginBottom: "40px"
  },
  btn: {
    borderRadius: "8px",
    marginLeft: "4px",
    marginRight: "4px"
  },
  saveDraftlBtn: {
    position: "absolute"
  },
  saveDraftlBtnRtl: {
    left: "0px"
  },
  saveDraftlBtnLtr: {
    right: "0px"
  },
  stepLabel: {
    fontSize: "24px"
  },
  stepRtl: {
    "& .MuiStepLabel-iconContainer": {
      paddingLeft: "8px",
      paddingRight: "0px"
    }
  },
  stepFirstLtr: {
    paddingLeft: "0px"
  },
  stepFirstRtl: {
    paddingRight: "0px"
  },
  stepLast: {
    paddingRight: "0px"
  },
  step: {},
  stepIcon: {
    width: "36px",
    height: "36px"
  }
}));

function CreateTask(props) {
  //Hooks
  //const tempRef = React.useRef();
  const classes = useStyles();
  let history = useHistory();
  const { task } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  //Ephemeral State
  //const [openTasks,setOpenTasks] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [id, setId] = useState(0);
  const [texts, setTexts] = useState([]);
  const [text, setText] = useState(0);
  const [taskTitle, setTaskTitle] = useState(
    props.task && props.task.name ? props.task.name : ""
  );
  const [taskType, setTaskType] = useState(
    props?.task.task_type ? props.task.task_type : ""
  );
  const [taskDescription, setTaskDescription] = useState(
    props.task && props.task.task_description ? props.task.task_description : ""
  );
  //const [editingMode, setEditingMode] = useState(false);
  const [deadline, setDeadline] = useState(null);
  const [acceptUntil, setAcceptUntil] = useState(null);
  const questionsInitialState = [
    {
      question: "",
      includeCitation: false,
      type: "",
      points: 0,
      wordLimit: -1,
      options: []
    }
  ];
  const [questions, setQuestions] = useState(questionsInitialState);
  const [answers, setAnswers] = useState([
    { shouldSelect: -1, wordLimit: -1, quotes: [], concepts: [] } //Fixme: check if safe and remove wordlimit from this object.
  ]);
  const [showSpinner, setShowSpinner] = useState(false); // When true a spinner will show to indicate that the task is saving
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Variables
  const TaskContext = React.createContext();

  //Behavior
  useEffect(() => {
    if (!task) {
      setTexts([]);
    } else {
      if (task.due_date) {
        setDeadline(new Date(task.due_date));
      }

      if (task.accept_date) {
        setAcceptUntil(new Date(task.accept_date));
      }

      if (task.id) {
        setId(task.id);
      }

      if (task.questions) {
        setQuestions(task.questions.questions);
        setAnswers(task.answers.answers);
      }
    }
  }, [task]);

  const setWrapper = setFunc => state => {
    setFunc(state);
    props.setIsDirty(true);
  };

  useEffect(() => {
    if (!task) {
      setTexts([]);
    } else {
      let getAllTexts = firebaseFunctions.httpsCallable("courses-getTexts1");
      getAllTexts({ course: task.course_id }).then(response => {
        setTexts(response.data);
        if (task.text_id) {
          let texts = response.data.filter(el => el.id === task.text_id);
          if (texts && texts.length) {
            firebaseApp
              .storage()
              .ref("courseTexts/" + props.course.id)
              .child(texts[0].file_url)
              .getDownloadURL()
              .then(url =>
                setText({
                  id: texts[0].id,
                  author: texts[0].author,
                  name: texts[0].name,
                  url: url,
                  location: texts[0].file_location
                })
              );
          }
        }
      });
      let step = 0;
      if (task && task.text_id) {
        step = 1;
      }
      setActiveStep(step);
    }
  }, [task, props.course?.id, task?.text_id]);

  useEffect(() => {
    let parts = [];
    parts.push({
      url: "/tasks",
      resetCourse: true,
      text: intl.formatMessage({
        id: "appBar.tasks",
        resetTask: true,
        defaultMessage: "Tasks"
      })
    });
    props.course &&
      props.course.name &&
      parts.push({
        url: "/tasks",
        text: props.course.name,
        resetTask: true,
        course: props.course
      });
    taskTitle &&
      parts.push({
        url: "/tasks",
        resetTask: true,
        text: taskTitle
          ? taskTitle
          : intl.formatMessage({
              id: "new.task",
              defaultMessage: "New Task"
            })
      });
    dispatch(setBreadcrumbs({ breadcrumbs: parts, blue: true }));
  }, [taskTitle, props.course, dispatch, intl]);

  const saveTask = status => {
    const saveTask = firebaseFunctions.httpsCallable("courses-saveTask");
    let validQuestions = [];
    let validAnswers = [];
    for (var i = 0; i < questions.length; i++) {
      if (
        questions[i] &&
        questions[i].type >= 0 &&
        typeof answers[i] !== "undefined"
      ) {
        validQuestions.push(questions[i]);
        validAnswers.push(answers[i]);
      }
      //Do something
    }
    let task = {
      course: props.task.course_id,
      courseName: props.course.name,
      task_type: taskType,
      questions: validQuestions,
      answers: validAnswers,
      due_day: deadline ? moment(deadline).format("YYYY-MM-DD") : "",
      accept_day: acceptUntil ? moment(acceptUntil).format("YYYY-MM-DD") : "",

      deadline: deadline && deadline.toISOString ? deadline.toISOString() : "",
      acceptUntil:
        acceptUntil && acceptUntil.toISOString ? acceptUntil.toISOString() : "",
      description: taskDescription,
      task_num: 1,
      name: taskTitle,
      text_id: text && text.id ? text.id : null,
      id: id,
      status: status
    };

    setShowSpinner(true);

    saveTask(task).then(response => {
      props.setIsDirty(false);
      if (response.data && response.data.id) {
        setId(response.data.id);
        setShowSpinner(false);
      }
      if (status === "Submitted") {
        history.push("/tasks/" + props.task.course_id, {
          taskSaved: response.data.id
        });
        setShowSpinner(false);
      } else {
        dispatch(
          enqueueFlashMessage({
            message: intl.formatMessage({
              id: "task.savedDraft",
              defaultMessage: "Task saved as draft"
            }),
            duration: alertsDuration
          })
        );
        setShowSpinner(false);
      }
    });
  };

  const renderBody = () => {
    if (showSpinner) {
      // if (true) {
      return <PangeaSpinner />;
    }
    if (activeStep === 0) {
      return (
        <SelectReading
          course={props.course}
          texts={texts}
          text={text}
          setText={setText}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          saveTask={saveTask}
          setIsDirty={props.setIsDirty}
          resetQuestions={() => {
            // reset quotes when chaging text source
            setAnswers(answers => {
              return answers.reduce((acc, ans) => {
                if (ans.quotes) {
                  acc.push(Object.assign({}, ans, { quotes: [] }));
                }
                return acc;
              }, []);
            });
          }}
        />
      );
    }
    if (activeStep === 1) {
      return (
        <StepAddQuestions
          task={task}
          text={text}
          setQuestions={setWrapper(setQuestions)}
          questions={questions}
          answers={answers}
          setAnswers={setWrapper(setAnswers)}
          taskTitle={taskTitle}
          setTaskTitle={setWrapper(setTaskTitle)}
          taskType={taskType}
          setTaskType={setWrapper(setTaskType)}
          taskDescription={taskDescription}
          setTaskDescription={setWrapper(setTaskDescription)}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          saveTask={saveTask}
        />
      );
    }
    if (activeStep === 2) {
      return (
        <SubmitTask
          taskType={taskType}
          deadline={deadline}
          setDeadline={setWrapper(setDeadline)}
          acceptUntil={acceptUntil}
          setAcceptUntil={setWrapper(setAcceptUntil)}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          saveTask={saveTask}
          setIsDirty={props.setIsDirty}
        />
      );
    }
  };

  //const [openTexts, setOpenTexts] = React.useState(false);
  // var addCourseText = firebaseFunctions.httpsCallable('adminFunctions-addCourseText');

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <RouteLeavingGuard
        setIsDirty={props.setIsDirty}
        isDirty={props.isDirty}
        selectCourse={props.selectCourse}
        setTask={props.setTask}
        // Start blocking navigation when text is selected
        when={props.isDirty}
        // Navigation function provided by react router's useHistory
        navigate={path => history.push(path)}
        // adds a third button which will run a function before navigating
        additionalStep={() => {
          saveTask("Draft");
        }}
        additionalStepButton={intl.formatMessage({
          id: "tasks.create.SaveDraft",
          defaultMessage: "Save draft"
        })}
      />
      <Box className={classes.taskHeader}>
        <Tooltip title="Back to tasks" placement="left" arrow>
          <IconButton
            className={classes.navigateBack}
            onClick={() => {
              // props.setTask(false);
              history.push("/tasks/" + props.course.id);
            }}
          >
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Tooltip>
        <Typography
          className={clsx(classes.tasksHeader, classes.left)}
          variant="h4"
        >
          {taskTitle ? (
            taskTitle
          ) : (
            <FormattedMessage id="new.task" defaultMessage="New Task" />
          )}

          <Button
            variant="outlined"
            // className={classes.btn}
            className={clsx(classes.saveDraftlBtn, classes.saveDraftlBtnLtr)}
            onClick={() => {
              saveTask("Draft");
            }}
          >
            <FormattedMessage defaultMessage="Save draft" id="save.draft" />
          </Button>
        </Typography>

        <TaskContext.Provider>
          <TaskStepper rtl={false} activeStep={activeStep} />
        </TaskContext.Provider>
      </Box>
      {renderBody()}
    </MuiPickersUtilsProvider>
  );
}

export default CreateTask;
