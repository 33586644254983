// Dependencies
import React, { useState, useEffect, useRef } from "react";
import { useRouteMatch } from "react-router-dom";
import { useParams } from "react-router-dom";
import { firebaseFunctions, firebaseApp } from "../../../firebase";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setSelectedTask, setSelectedTaskRow } from "../../../redux/taskSlice";
import { setAction } from "../../../redux/readerActionsSlice";
import { setProfile } from "../../../redux/userSlice";
import { setPangeaText } from "../../../redux/textSlice";

// Components
import PeerReviewFeedback from "./PeerReviewFeedbak";
import PeerReviewReflection from "./PeerReviewReflection";
import PeerReviewResponse from "./PeerReviewResponse";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";
// import useRefWidth from "../../../hooks/useRefWidth";

// Material UI
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Styles
const useStyles = makeStyles(theme => ({
  peerReviewContainer: {
    // flex: 1,
    height: "100%",
    width: "inherit" // Parent div is set by getSideBarWidth hook. this is needed to avoid EpubJS rendering issue when resizeing
    // display: "flex",
    // display: "grid"

    // flexFlow: "column", // This is importent for peer review layout.
    // alignContent: "flex-start" // This is importent for peer review layout.
  }
}));

export default function PeerReview({
  feedbackComment,
  showSubmissionDialog,
  submitFeedback,
  index,
  feedback,
  updateFeedback,
  readText,
  setReadText,
  reflection,
  setReflection,
  response,
  setResponse,
  reply,
  setReply,
  fetchedFeedback,
  setFetchedFeedback,
  ...props
}) {
  // Hooks

  const classes = useStyles();
  const { task_id, submission_id } = useParams();
  const dispatch = useDispatch();
  const answerRef = useRef(); // used to get the scroll position of the reflection

  const isReflection = useRouteMatch({
    path: "/reader/task/:task_id/",
    exact: true
  });
  const isResponse = useRouteMatch({
    path: "/reader/task/:task_id/submission/:submission_id",
    exact: true
  });
  const isFeedback = useRouteMatch({
    path: "/feedback/task/:task_id/submission/:submission_id",
    exact: true
  });

  // Redux state
  const task = useSelector(state => state.task.taskRow);
  const isLoading = useSelector(state => state.readerActions.isLoading);
  const userProfile = useSelector(state => state.user.userProfile);
  const submissionStatus = useSelector(
    state => state.task.taskRow.submission_status
  );

  // Ephemeral state
  const [currentTab, setCurrentTab] = useState(0);
  const [zenMode, setZenMode] = useState(false);
  const [rendition, setRendition] = useState(null);
  const [textBookmark, SetTextBookmark] = useState(null);
  const [answerBookmark, SetAnswerBookmark] = useState(null);

  // Variables

  // Behavior

  // Populate store with text and colaborateor submission and
  useEffect(() => {
    if (isFeedback && task_id > 0) {
      dispatch(setSelectedTask(task_id));

      const getTask = !submission_id
        ? firebaseFunctions.httpsCallable("courses-getTaskById")
        : firebaseFunctions.httpsCallable("courses-getTaskForCollaboration");

      // getTaskForColaboration({
      getTask({
        task: task_id,
        submission: submission_id
      }).then(result => {
        let taskRow = result?.data[0];
        if (taskRow?.course_id) {
          dispatch(setAction("task"));
          firebaseApp
            .storage()
            .ref("courseTexts/" + taskRow.course_id)
            .child(taskRow.file_url)
            .getDownloadURL()
            .then(url => {
              if (userProfile.selectedCourse.id !== taskRow.course_id) {
                dispatch(
                  setProfile({
                    ...userProfile,
                    selectedCourse: {
                      id: taskRow.course_id,
                      name: taskRow.course_name,
                      course_role: "student"
                    }
                  })
                );
              }
              setReflection(taskRow?.answers?.answers);
              setFetchedFeedback(taskRow?.feedback);

              setReadText({
                course_id: taskRow.course_id,
                textUrl: taskRow.file_url,
                url: url,
                file_location: taskRow.file_location
              });

              dispatch(
                setPangeaText({
                  ...taskRow,
                  ...taskRow.sq3r,
                  id: taskRow.text_id,
                  url: url,
                  defaultLocation: taskRow.file_location
                })
              );
              // setTask(taskRow);

              dispatch(setSelectedTaskRow(taskRow));
            });
        }
      });
    }
  }, [userProfile, task_id, submission_id, task.submission_id]);

  useEffect(() => {
    if (submissionStatus === "Submitted" || submissionStatus === "Graded") {
      const getCollaborationSubmissions = firebaseFunctions.httpsCallable(
        "courses-getCollaborationSubmissions"
      );

      getCollaborationSubmissions({
        submission: submission_id
      }).then(result => {
        setResponse(result.data[0]?.answers?.answers);
        // for the scenario where a student reply after there's a feedback
        if (task.collaboration_status === "Answered") {
          setReply(result.data[0]?.reply);
        }
      });
      return function cleanup() {
        setResponse(null);
        setReply(null);
      };
    }
  }, [submissionStatus, submission_id, task.collaboration_status]);

  // Scroll to saved reflection position
  useEffect(() => {
    if (answerRef.current && answerBookmark && (currentTab === 0 || !zenMode)) {
      answerRef.current.scrollTo(0, answerBookmark);
    }
  }, [currentTab, zenMode, answerBookmark, answerRef]);

  const saveCurrentTabLocation = () => {
    if (currentTab === 0) {
      saveAnswerLocation();
    } else {
      saveTextLocation();
    }
  };

  const saveTextLocation = () => {
    if (rendition) {
      let location = rendition.currentLocation();
      if (location && location.start && location.start.cfi) {
        SetTextBookmark(location.start.cfi);
      }
    }
  };

  const saveAnswerLocation = () => {
    console.log(answerRef.current);
    SetAnswerBookmark(answerRef.current.scrollTop);
  };

  const onRenditionCreated = rendition => {
    setRendition(rendition);
  };

  // Render
  if (isLoading) {
    return <PangeaSpinner />;
  } else if (isReflection) {
    return (
      <PeerReviewReflection
        reflection={reflection}
        setReflection={setReflection}
        readText={readText}
        rendition={rendition}
        onRenditionCreated={onRenditionCreated}
        saveTextLocation={saveTextLocation}
        textBookmark={textBookmark}
        setCurrentTab={setCurrentTab}
        answerBookmark={answerBookmark}
      />
    );
    // }
  } else if (isResponse) {
    return (
      <PeerReviewResponse
        reflection={reflection}
        response={response}
        setResponse={setResponse}
        reply={reply}
        setReply={setReply}
        readText={readText}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        zenMode={zenMode}
        setZenMode={setZenMode}
        rendition={rendition}
        onRenditionCreated={onRenditionCreated}
        saveCurrentTabLocation={saveCurrentTabLocation}
        textBookmark={textBookmark}
        answerRef={answerRef}
      />
    );
    // }
  } else if (isFeedback) {
    return (
      // <Box className={classes.peerReviewContainer}>
      <PeerReviewFeedback
        index={index}
        showSubmissionDialog={showSubmissionDialog}
        submitFeedback={submitFeedback}
        feedback={feedback}
        updateFeedback={updateFeedback}
        fetchedFeedback={fetchedFeedback}
        reflection={reflection}
        response={response}
        setResponse={setResponse}
        reply={reply}
        setReply={setReply}
        readText={readText}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        zenMode={zenMode}
        setZenMode={setZenMode}
        rendition={rendition}
        onRenditionCreated={onRenditionCreated}
        saveCurrentTabLocation={saveCurrentTabLocation}
        textBookmark={textBookmark}
        answerBookmark={answerBookmark}
        answerRef={answerRef}
      />
      // </Box>
    );
  } else return null;
}
