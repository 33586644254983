import { createTheme } from "@material-ui/core/styles";

const PaletteLight = {
  primary: { main: "#168FEE" },
  secondary: { main: "#5ec891" },
  warning: { main: "#dd4b39" },
  greenText: { main: "#226341" },
  app: {
    background: {
      main: "#e0e0e0"
    }
  },
  card: {
    background: {
      main: "#e0e0e0",
      hover: "#f5f5f5",
      disabled: "#EEEEEE"
    }
  },
  text: {
    blue: "#168FEE",
    green: "#467fcf",
    secondary: "#545454"
  },
  blue: {
    main: "#168fee"
  },

  lightText: {
    main: "#000000"
  },
  grey: {
    main: "#c9c9c9"
  }
};

const PaletteDark = {
  primary: { main: "#168FEE" },
  secondary: { main: "#5ec891" },
  warning: { main: "#dd4b39" },

  app: {
    background: {
      main: "#303030"
    }
  },

  card: {
    background: {
      main: "#424242",
      hover: "#303030",
      disabled: "#212121"
    }
  },

  text: {
    blue: "#BCD9F1",
    green: "#467fcf"
  },

  blue: {
    main: "#168fee"
  },
  lightText: {
    main: "#000000"
  }
};

const Typography = {
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  small: {
    fontSize: 12
  }
};

const BaseStyles = {
  MuiButton: {
    root: {
      textTransform: "none"
    }
  },
  MuiStepper: {
    root: {
      backgroundColor: "transparent"
    }
  },
  MuiInputBase: {
    input: {
      "&[type=number]": {
        "-moz-appearance": "textfield"
      },
      "&::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      },
      "&::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0
      }
    }
  },
  MuiInput: {
    input: {
      "&::placeholder": {
        color: "white"
      }
    }
  },
  MuiStepLABEL: {
    fontSize: "24px"
  }
};

const Breakpoints = {
  values: {
    desktop: 1440,
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
};

export function getGlobalTheme() {
  const globalTheme = createTheme({
    overrides: {
      ...BaseStyles,
      MuiDataGridMenu: {
        root: {
          // backgroundColor: 'white',
          "& .MuiPaper-root": {
            // backgroundColor: 'white',
          }
        }
      },
      "MuiPickersBasePicker-container": {
        // backgroundColor: 'white',
      },
      "MuiDataGridMenu-root": {
        // backgroundColor: 'white',
      },
      MuiPickersDay: {
        day: {
          color: "black"
        },
        daySelected: {
          backgroundColor: "#33abb6"
        },
        dayDisabled: {
          color: "#ccc"
        },
        current: {
          color: "red"
        }
      },
      MuiPickersModal: {
        dialogAction: {
          color: "#33abb6"
        }
      },
      MuiSwitch: {
        track: {
          backgroundColor: "#cfcfcf"
        }
      },
      MuiButton: {
        root: {
          textTransform: "none"
        },
        outlinedSecondary: {
          color: "black"
        }
      },
      MuiListItemIcon: {
        root: {
          color: "#333"
        }
      }
    },
    palette: { ...PaletteLight },
    typography: {
      ...Typography,
      fontFamily: '"Rosario", "Helvetica", "Arial"'
    },
    breakpoints: Breakpoints
  });
  return globalTheme;
}

export function getHebrewTheme() {
  const hebrewTheme = createTheme({
    isRtl: true,
    direction: "rtl",
    overrides: {
      ...BaseStyles,
      MuiPopover: {
        root: {
          direction: "rtl"
        }
      },
      MuiButton: {
        root: {
          textTransform: "none"
        },
        outlinedSecondary: {
          color: "black"
        }
      },
      MuiListItemIcon: {
        root: {
          color: "#333"
        }
      }
    },
    palette: { ...PaletteLight },
    typography: {
      ...Typography,
      fontFamily: '"Open Sans Hebrew", "Rosario", "Helvetica", "Arial"'
    },
    breakpoints: Breakpoints
  });

  return hebrewTheme;
}

export function getGlobalDarkTheme() {
  const globalDarkTheme = createTheme({
    overrides: {
      ...BaseStyles,
      MuiButton: {
        root: {
          textTransform: "none"
        },
        outlinedSecondary: {
          color: "white"
        },
        outlinedPrimary: {
          color: "white"
        }
      },
      MuiListItemIcon: {
        root: {
          color: "white"
        }
      }
    },
    palette: {
      type: "dark",
      ...PaletteDark
    },
    typography: {
      ...Typography,
      fontFamily: '"Rosario", "Helvetica", "Arial"'
    },
    breakpoints: Breakpoints
  });
  return globalDarkTheme;
}

export function getHebrewDarkTheme() {
  const hebrewDarkTheme = createTheme({
    isRtl: true,
    direction: "rtl",
    overrides: {
      ...BaseStyles,
      MuiPopover: {
        root: {
          direction: "rtl"
        }
      },
      MuiButton: {
        root: {
          textTransform: "none"
        },
        outlinedSecondary: {
          color: "white"
        },
        outlinedPrimary: {
          color: "white"
        }
      },
      MuiPaper: {
        root: {
          backgroundColor: "white",
          color: "black"
        }
      },
      MuiListItemIcon: {
        root: {
          color: "white"
        }
      }
    },
    palette: {
      type: "dark",
      ...PaletteDark
    },
    typography: {
      ...Typography,
      fontFamily: '"Open Sans Hebrew", "Rosario", "Helvetica", "Arial"'
    },
    breakpoints: Breakpoints
  });
  return hebrewDarkTheme;
}
