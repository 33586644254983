import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/remote-config";
import "firebase/analytics";

const firebaseConfig = getFirebaseConfig();
export const crossed = firebaseConfig.crossed;
export const isEmulated = firebaseConfig.isEmulated;
firebase.initializeApp(firebaseConfig.options);

//domain issues with cookie
if (firebaseConfig.measurementId) {
  window.dataLayer = window.dataLayer || [];
  window.gtag = (...a) => window.dataLayer.push(a);
  window.gtag("config", firebaseConfig.measurementId, {
    cookie_domain: window.location.hostname,
    cookie_flags: "SameSite=None;Secure"
  });

  firebase.analytics();
}

export const firebaseApp = firebase;
export const auth = firebase.auth();
export const firebaseFunctions = firebase.app().functions("europe-west3");
if (isEmulated) firebaseFunctions.useEmulator("localhost", 5001);

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
googleAuthProvider.setCustomParameters({ prompt: "select_account" });

export const facebookAuthProvider = new firebase.auth.GoogleAuthProvider();
export const firestore = firebase.firestore();
export const remoteConfig = firebase.remoteConfig();

export const getRecaptchaKey = () =>
  firebase.remoteConfig().getValue("recaptcha_key").asString();
export const env = "staging";
remoteConfig.settings.minimumFetchIntervalMillis = 300000;
remoteConfig.defaultConfig = JSON.parse(
  localStorage.getItem("conf.lastRemoteConfig") || '{"version": "5"}'
);
remoteConfig.fetchAndActivate().then(() => {
  localStorage.setItem(
    "conf.lastRemoteConfig",
    JSON.stringify(
      Object.entries(remoteConfig.getAll()).reduce(
        (all, [k, { _value: v }]) => ({ ...all, [k]: v }),
        {}
      )
    )
  );
});

function getFirebaseConfig() {
  const firebaseConfig = {
    stage: {
      apiKey: "AIzaSyBIu1VSZ1DI_5TGkyHw-MJb2urhqVe7g1g",
      authDomain: "pangea-stage.firebaseapp.com",
      projectId: "pangea-stage",
      storageBucket: "pangea-stage.appspot.com",
      messagingSenderId: "247009010465",
      appId: "1:247009010465:web:7d44ef0cdda8ec5a8a5460"
    },
    demo: {
      apiKey: "AIzaSyAlz4k4hwaq_bPymSbJoPQPWr-xofqITUs",
      authDomain: "pangea-demo-3d15d.firebaseapp.com",
      projectId: "pangea-demo",
      storageBucket: "pangea-demo.appspot.com",
      messagingSenderId: "867375218514",
      appId: "1:867375218514:web:efca0d2a0f6124eea4448b"
    },
    prod: {
      apiKey: "AIzaSyAGE_qDDivIwcPYqlZYfVzqQjlE5K2fca4",
      authDomain: "pangea-291110.firebaseapp.com",
      projectId: "pangea-291110",
      storageBucket: "pangea-291110.appspot.com",
      messagingSenderId: "955244724934",
      appId: "1:955244724934:web:bb1a199712ffd291760dc7",
      measurementId: "G-92D9R6SNET"
    }
  };
  const isEmulated = getEmulated();

  //eslint-disable-line no-restricted-globals
  const { search, hostname } = window.location;

  //detect env from domain/subdomain, default to 'prod'
  let subdomain = (hostname.match(/^(demo|stage|local)/) || ["prod"])[0];
  if (subdomain == "local") subdomain = "stage";

  let backend = subdomain;

  //detect if developer/tester directs to override
  const qsBackend = (search.match(/[?&]backend=([^&]*)&?/) || [])[1];
  if (typeof qsBackend !== "undefined") {
    if (qsBackend == "" || qsBackend == backend) {
      localStorage.removeItem("conf.backend");
    } else {
      backend = qsBackend;
      localStorage.setItem("conf.backend", backend);
    }
  }

  backend = localStorage.getItem("conf.backend") || backend;

  //pull selected config-set, default to prod
  let envConfig = firebaseConfig[backend];
  if (!envConfig) {
    backend = "prod";
    envConfig = firebaseConfig[backend];
  }

  console.log(`base values for firebase - from ${backend} `);

  //pull local overrides from conf.dev
  const json = localStorage.getItem("conf.dev");
  let localOverrides = {};
  if (json) {
    try {
      localOverrides = JSON.parse(json);
      console.warn(
        "lodaded local overriding values for firebase",
        localOverrides
      );
    } catch (err) {
      console.error("could not parse conf.dev", { json, err });
    }
  }

  const options = {
    ...envConfig,
    ...localOverrides
  };

  return {
    options,
    measurementId: options.measurementId,
    crossed: backend != subdomain && backend,
    isEmulated
  };
}

function getEmulated() {
  //if found in QS - move it to cookie
  const [, emulated] =
    window.location.search.match(/[?&]emulated=(true|false)&?/) || [];
  if (emulated) localStorage.setItem("conf.emulated", emulated);

  //true only when the item has been set explicitly to "true"
  return "true" == localStorage.getItem("conf.emulated");
}
