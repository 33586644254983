import React, { useCallback, useState } from "react";
import { Cards } from "../poc/CardsManager";
import { useSelector, useDispatch } from "react-redux";
import { updateThemes } from "../../redux/firebaseMiddleware";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import update from "immutability-helper";
import { setThemeMode, setThemes } from "../../redux/themeSlice";
import { setAnnotatorMode } from "../../redux/readerActionsSlice";
import { ScrollBox } from "../SharedComponents";

import CitationDialog from "../Tasks/CreateTask/CitationDialog";
import { Box, IconButton } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  cardsContainer: {
    position: "relative",
    height: "100%"
  },
  header: {
    marginTop: "40px",
    marginBottom: "24px",
    textAlign: "left",
    fontSize: "24px",
    // color: "rgba(0,0,0,0.87)",
    paddingLeft: "60px"
  },

  themeView: {
    position: "absolute",
    // background: '#E8E8E8',
    zIndex: 1,
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px"
  }
}));

export function ThemeCardsManager(props) {
  const dispatch = useDispatch();
  const { readText } = props;
  const classes = useStyles();
  const ANSWER_ITEM = "Theme.Answer";
  const rtlValue = useSelector(state => state.user.userProfile.rtl);
  const [showCitation, setShowCitation] = useState(false);

  const themes = useSelector(state => {
    return state.themes.themes;
  });
  const selectedTextId = useSelector(state => state.texts.selectedTextId);

  const selectedTheme = useSelector(state => {
    let filtered = state.themes.themes.filter(
      q => q.id === state.themes.selectedThemeId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });

  const onTitleChange = item => newTitle => {
    let updatedThemes = themes.map(el => {
      if (el.id === selectedTheme.id) {
        return {
          ...el,
          quotes: el.quotes.map(ans => {
            if (ans.cfi === item.cfi) {
              return { ...ans, title: newTitle };
            } else return ans;
          })
        };
      } else return el;
    });
    dispatch(setThemes(updatedThemes));
    dispatch(updateThemes({ textId: selectedTextId, themes: updatedThemes }));
  };

  const quotes = selectedTheme && selectedTheme.quotes;
  const toggleQuoteHide = (item, hidden) => {
    let newAnswer = { ...item, hidden: hidden };
    let modifiedQuotes = quotes.map(el =>
      el.cfi === newAnswer.cfi ? newAnswer : el
    );
    let updatedThemes = themes.map(el => {
      return el.id === selectedTheme.id
        ? { ...selectedTheme, quotes: modifiedQuotes }
        : el;
    });
    // console.log("updatedQuestions", updatedQuestions);
    dispatch(updateThemes({ textId: selectedTextId, themes: updatedThemes }));
  };

  const visibleQuotes =
    quotes && quotes.filter && quotes.filter(el => !el.hidden);
  const hiddenQuotes =
    quotes && quotes.filter && quotes.filter(el => el.hidden);
  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      //console.log("dragCard = ",visibleAnswers[dragIndex])
      //console.log("hoverCArd = "+visibleAnswers[hoverIndex])
      const dragCard = visibleQuotes[dragIndex];

      let updatedQuotes = update(visibleQuotes, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      });
      //console.log("updatedAnswers", updatedAnswers);
      let updatedThemes = themes.map(el => {
        return el.id === selectedTheme.id
          ? { ...selectedTheme, quotes: [...updatedQuotes, ...hiddenQuotes] }
          : el;
      });
      // console.log("updatedQuestions", updatedQuestions);
      dispatch(updateThemes({ textId: selectedTextId, themes: updatedThemes }));
    },
    [
      dispatch,
      hiddenQuotes,
      selectedTextId,
      selectedTheme,
      themes,
      visibleQuotes
    ]
  );

  return (
    <ScrollBox className={classes.themeView}>
      <Box className={classes.header}>
        <Typography variant="h4" className={classes.headerTitle}>
          <IconButton
            className={classes.btn}
            onClick={() => {
              dispatch(setAnnotatorMode("themes"));
              dispatch(setThemeMode(""));
              props.back();
            }}
          >
            {rtlValue ? <ArrowForwardIcon /> : <ArrowBackIcon />}
          </IconButton>{" "}
          <FormattedMessage
            id="actions.theme.extended"
            defaultMessage="Extended View"
          />
        </Typography>
      </Box>
      <Box className={classes.cardsContainer}>
        <Cards
          cards={quotes}
          dragItemType={ANSWER_ITEM}
          onTitleChange={onTitleChange}
          moveCard={moveCard}
          hideCard={item => toggleQuoteHide(item, true)}
          showInText={item => {
            //  dispatch(selectTheme({id:theme.id}));
            setShowCitation(item);
          }}
          showCard={item => toggleQuoteHide(item, false)}
        />
      </Box>
      {showCitation && (
        <CitationDialog
          color="green"
          setOpenDialog={setShowCitation}
          url={readText.url}
          location={readText.file_location}
          highlights={[showCitation]}
          openDialog={!!showCitation}
          removeHighlight={() => {
            let updatedQuotes = quotes.filter(a => a.cfi !== showCitation.cfi);
            let updatedThemes = themes.map(el => {
              return el.id === selectedTheme.id
                ? { ...selectedTheme, quotes: [...updatedQuotes] }
                : el;
            });
            // console.log("updatedQuestions", updatedQuestions);
            dispatch(
              updateThemes({ textId: selectedTextId, themes: updatedThemes })
            );

            setShowCitation(false);
          }}
        />
      )}
    </ScrollBox>
  );
}
