import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  themes: [],
  themeMode: 0,
  selectedThemeId: false
};

export const themeSlice = createSlice({
  name: "themes",
  initialState,
  extraReducers: {
    gotThemes: (state, value) => {
      state.themes = value.payload;
    },
    "user/logout": () => initialState
  },
  reducers: {
    setThemes: (state, value) => {
      state.themes = value.payload;
    },
    selectTheme: (state, value) => {
      state.selectedThemeId = value.payload && value.payload.id;
    },
    setThemeMode: (state, value) => {
      state.themeMode = value.payload;
    }
  }
});

export const { setThemes, setThemeMode, selectTheme } = themeSlice.actions;

export default themeSlice.reducer;
