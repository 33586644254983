// TODO: this should be made into a reusable component and moved to the layout level

// Dependencies
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { firebaseFunctions } from "../../../firebase";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setSubmittedStudentTask } from "../../../redux/taskSlice";
import { setIsLoading } from "../../../redux/readerActionsSlice";
import {
  removeActiveTaskPerText,
  mergeGrs
} from "../../../redux/firebaseMiddleware";
import GrSubmitTaskDialogContent from "./GrSubmitTaskDialogContent";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  // this is styling the paper component within the dialog
  modal: {
    position: "relative",
    width: "50%",
    padding: "10px",
    textAlign: "center"
  },
  modalCancel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer"
  },
  modalActions: {
    justifyContent: "center",
    "& button": {
      fontSize: "inharit",
      fontWeight: "600"
    }
  },
  buttonDiscard: {
    color: "#787877"
  },
  button: { textTransform: "none" }
}));

export default function SubmitTaskModal(props) {
  //Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  //Redux state
  const userDisplayName = useSelector(state => state.user.auth.displayName);
  const grAsTaskHighlights = useSelector(state => state.task.grHighlights);
  const selectedCourse = useSelector(
    state => state.user.userProfile.selectedCourse
  );
  const rtl = useSelector(state => state.user.userProfile.rtl);

  //Ephemeral state
  const [validationResult, setValidationResult] = useState({
    answers: [],
    citations: []
  });

  const { task, taskAnswers } = props;

  //Behavior
  useEffect(() => {
    const validateForm = () => {
      // Return and object with arrays of invalid answers and citations
      const currentValidation = { answers: [], citations: [] };
      if (task && task.questions) {
        let currentQuestion = 1;

        taskAnswers.forEach((answer, index) => {
          // Open question and multiple choice questions with citations are split into 2 question, find citation and question

          //  Checking for if this is a question with citation and validation the citations part
          if (
            task.questions.questions[index]?.includeCitation &&
            (!answer.quotes || answer.quotes.length === 0)
          ) {
            currentValidation.citations.push(currentQuestion);
          }
          const questionType = task.questions.questions[index]?.type;

          // Checking if this is an open question and multiple choice questions and adjasting the question number accordingly
          if (task.questions.questions[index]?.includeCitation) {
            questionType !== 0 && (currentQuestion += 1);
          }

          // eslint-disable-next-line default-case
          switch (questionType) {
            // Validating the answers based on the question type
            case 0: // question type: find in text
              if (!answer || !answer.quotes || answer.quotes.length === 0) {
                currentValidation.answers.push(currentQuestion);
              }
              currentQuestion += 1;
              break;
            case 1: // question type: open ended
              // the rich text editor returns an array of text lines
              // checkling to see if atleast one line isn't empty
              let isValid =
                answer.comment &&
                answer.comment.blocks &&
                answer.comment.blocks.some(element => element.text !== "");
              if (!isValid) {
                currentValidation.answers.push(currentQuestion);
              }
              currentQuestion += 1;
              break;
            case 2: // question type: multiple choice
              if (!("choice" in answer)) {
                currentValidation.answers.push(currentQuestion);
              }
              currentQuestion += 1;
              break;
          }
        });
      }
      setValidationResult(currentValidation);
    };

    validateForm();
  }, [props.modalVisible, task, taskAnswers]);

  const closeModal = () => {
    props.setModalVisible(false);
  };

  const handleSubmit = () => {
    props.setModalVisible(false);
    dispatch(setIsLoading(true));
    const submitTask = firebaseFunctions.httpsCallable("courses-submitTask");

    submitTask({
      task: props.selectedTaskId,
      answers: props.taskAnswers,
      // for notification
      courseId: selectedCourse.id,
      courseName: selectedCourse.name,
      submissionId: task.submission_id,
      studentName: userDisplayName
    }).then(response => {
      dispatch(setSubmittedStudentTask(props.selectedTaskId));
      dispatch(setIsLoading(false));
      dispatch(removeActiveTaskPerText({ textId: task.text_id }));

      history.push("/tasks/" + selectedCourse.id);
    });
  };

  async function copyGrWorkToLibrary() {
    const questions = [];
    task.questions.questions.forEach((q, i) => {
      questions.push({
        answers: props.taskAnswers[i].quotes.map(quote => ({
          cfi: quote.cfi,
          color: quote.color,
          source: "grQuestion",
          text: quote.text
        })),
        question: q.question,
        summary: props.taskAnswers[i].summary || ""
      });
    });
    dispatch(
      mergeGrs({
        textId: task.text_id,
        questions,
        highlights: grAsTaskHighlights
      })
    );
  }

  function formatQuestionNumber(arrayOfIndexs) {
    let output = "";
    arrayOfIndexs.forEach((element, index) => {
      if (arrayOfIndexs.length === 1) {
        output += `${intl.formatMessage({
          id: "task.question",
          defaultMessage: "question"
        })} ${element}`;
      } else if (index === 0) {
        output += `  ${intl.formatMessage({
          id: "task.question",
          defaultMessage: "questions"
        })} ${element}, `;
      } else if (index === arrayOfIndexs.length - 1) {
        output += ` ${intl.formatMessage({
          id: "task.and",
          defaultMessage: "and "
        })} ${element}`;
      } else {
        output += `${element},`;
      }
    });
    return output;
  }

  function generateMessage() {
    if (validationResult) {
      const answers = validationResult.answers;
      const citations = validationResult.citations;
      if (answers.length > 0 && citations.length > 0) {
        return `${intl.formatMessage({
          id: "task.submission.didntAnswer",
          defaultMessage: "You didn't answer"
        })} ${formatQuestionNumber(answers)},  ${intl.formatMessage({
          id: "task.submission.andDidntAddCitations",
          defaultMessage: "and didn't add citations to "
        })}${formatQuestionNumber(citations)}. ${intl.formatMessage({
          id: "task.submission.confirmation",
          defaultMessage: "Are you sure you want to submit the task?"
        })}`;
      } else if (answers.length > 0) {
        return `${intl.formatMessage({
          id: "task.submission.didntAnswer",
          defaultMessage: "You didn't answer"
        })} ${formatQuestionNumber(answers)}. ${intl.formatMessage({
          id: "task.submission.confirmation",
          defaultMessage: "Are you sure you want to submit the task?"
        })}`;
      } else if (citations.length > 0) {
        return `${intl.formatMessage({
          id: "task.submission.didntAddCitations",
          defaultMessage: "You didn't add citations to "
        })}${formatQuestionNumber(citations)}. ${intl.formatMessage({
          id: "task.submission.confirmation",
          defaultMessage: "Are you sure you want to submit the task?"
        })}`;
      } else if (task?.task_type === "guidedReading") {
        return <GrSubmitTaskDialogContent />;
      } else {
        return `${intl.formatMessage({
          id: "task.submission.confirmation",
          defaultMessage: "Are you ready to submit the task?"
        })}`;
      }
    }
  }

  // Render
  return (
    <>
      <div>
        <Dialog
          open={props.modalVisible}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            className: classes.modal,
            style: {
              direction: rtl ? "rtl" : "ltr"
            }
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <FormattedMessage
              id="gr.button.finish"
              defaultMessage="Task Submission"
            />
          </DialogTitle>
          <DialogContent>{generateMessage()}</DialogContent>
          <DialogActions className={classes.modalActions}>
            <Button
              onClick={closeModal}
              className={clsx(classes.buttonDiscard, classes.button)}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </Button>
            {task?.task_type === "guidedReading" && (
              <Button
                className={classes.button}
                onClick={() => {
                  handleSubmit();
                  copyGrWorkToLibrary();
                }}
                color="primary"
                autoFocus
              >
                <FormattedMessage
                  id="task.submitAndCopy"
                  defaultMessage="Submit &amp; Copy"
                />
              </Button>
            )}
            <Button
              className={classes.button}
              onClick={handleSubmit}
              color="primary"
              autoFocus
            >
              {task?.task_type === "guidedReading" ? (
                <FormattedMessage
                  id="task.justSubmit"
                  defaultMessage="Just Submit"
                />
              ) : (
                <FormattedMessage id="task.submit" defaultMessage="Submit" />
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
