// Dependencies
import React, { useState, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import useConvertCourseTimezone from "../../../hooks/useConvertCourseTimezone";

// Redux

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  chip: {
    // marginInline: "-8px",
  },
  chipLink: {
    cursor: "pointer"
  },
  submittedAndPending: {
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: "transparent"
  },
  missed: {
    backgroundColor: "#f9413e",
    color: "currentColor"
  },
  late: {
    backgroundColor: "#fcb63f",
    "&:hover": {
      backgroundColor: "#fcb63f"
    }
  },
  pending: {
    border: "1px solid currentColor",
    backgroundColor: "transparent"
  },
  latePending: {
    border: "1px solid #fcb63f",
    backgroundColor: "transparent"
  },
  graded: {
    backgroundColor: "#0eac72"
  },
  onTime: {
    backgroundColor: "#5EC891",
    "&:hover": {
      backgroundColor: "#5EC891"
    },
    "&:active": {
      backgroundColor: "#5EC891"
    }
  },
  sent: {
    backgroundColor: theme.palette.primary,
    color: theme.palette.text.primary
  },
  add: {
    border: `1px solid ${theme.palette.primary}`,
    color: theme.palette.primary
  },
  reviewed: {
    backgroundColor: "#BDBDBD",
    color: "currentCollor"
  },
  chipContainer: {
    display: "flex",
    "& $chip": {
      marginInline: "4px"
    },
    "& $chip:first-of-type": {
      // marginInlineStart: "-8px",
    }
  }
}));

export default function TaskStatusChip({
  due_day,
  submission_date,
  accept_day,
  userRole,
  onClick,
  statusFor,
  clickable,
  ...props
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();

  // Ephemeral State
  const [label, setLabel] = useState("");
  const [chipStyle, setChipStyle] = useState([]);
  const [color, setColor] = useState("");

  const [, epochDueDay] = useConvertCourseTimezone(due_day);
  const [, epochAcceptDay] = useConvertCourseTimezone(accept_day);
  const [, epochSubmissionDay] = useConvertCourseTimezone(submission_date);
  // Redux state

  // Variables
  const submitted = intl.formatMessage({
    id: "tasks.status.submitted",
    defaultMessage: "Submitted"
  });
  const late = intl.formatMessage({
    id: "tasks.status.late",
    defaultMessage: "Late submission"
  });
  const sentLate = intl.formatMessage({
    id: "tasks.status.late",
    defaultMessage: "Sent late"
  });

  const missed = intl.formatMessage({
    id: "tasks.status.missed",
    defaultMessage: "Missed"
  });
  const pending = intl.formatMessage({
    id: "tasks.status.pending",
    defaultMessage: "Pending"
  });

  const today = useMemo(() => new Date(), []);

  // let epochDueDay = due_day && getDate(due_day).setHours(24);
  // let epochAcceptDay = accept_day && getDate(accept_day).setHours(24);
  // let epochSubmissionDay =
  // epochSubmissionDay && getDate(epochSubmissionDay).setHours(24);

  // this is the absolute final submmission date

  //Conditions
  const isTeacher = userRole === "Teacher";

  const isSubmitted = Boolean(epochSubmissionDay);

  const submittedOnTime = isSubmitted && epochSubmissionDay <= epochDueDay;

  const latePending =
    !isSubmitted && today > epochDueDay && today < epochAcceptDay;

  const submittedLate = isSubmitted && epochSubmissionDay > epochDueDay;

  const missedSubmission =
    // no extansion and no submitted
    (!isSubmitted && !epochAcceptDay && today > epochDueDay) ||
    // extansion and no submitted
    (!isSubmitted && epochAcceptDay && today > epochAcceptDay) ||
    // extansion and submitted late
    (isSubmitted && !epochAcceptDay && epochSubmissionDay > epochDueDay) ||
    // extansion and submitted late
    (isSubmitted && epochAcceptDay && epochSubmissionDay > epochAcceptDay);

  // Behavior

  // Set chip for standart tasks
  useEffect(() => {
    // Set the style
    switch (true) {
      case missedSubmission:
        setChipStyle("missed");
        break;

      case isTeacher && latePending:
        setChipStyle("latePending");
        break;

      case submittedLate:
        setChipStyle("late");
        break;

      case submittedOnTime:
        setChipStyle("onTime");

        break;

      default:
        setChipStyle("pending");
    }

    // Set the label
    switch (true) {
      case missedSubmission:
        setLabel(missed);
        break;

      case statusFor === "reflection" && isTeacher:
        setLabel("Reviewed");
        break;

      case submittedLate:
        setLabel(late);
        break;

      case submittedOnTime:
        setLabel(submitted);
        break;

      default:
        setLabel(pending);
    }
  }, [
    isTeacher,
    late,
    latePending,
    missed,
    missedSubmission,
    pending,
    statusFor,
    submitted,
    submittedLate,
    submittedOnTime
  ]);

  // Render
  return (
    <Chip
      className={clsx(
        classes.chip,
        chipStyle === "late" && classes.late,
        chipStyle === "missed" && classes.missed,
        chipStyle === "latePending" && classes.latePending,
        chipStyle === "pending" && classes.pending,
        chipStyle === "onTime" && classes.onTime
      )}
      label={label}
      size="small"
      onClick={onClick}
      clickable={clickable}
    />
  );
}
