// Dependencies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { useDrop } from "react-dnd";
import { Draggable } from "react-beautiful-dnd";
import { motion } from "framer-motion";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { updateSq3r } from "../../../redux/firebaseMiddleware";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";

// Components
import QuestionBox from "../QuestionBox";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { ArrowRight, ArrowLeft } from "@material-ui/icons";
import { ListItem, IconButton, Box } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  spacer: {
    width: theme.spacing(2),
    flexShrink: 0
  },
  questionIcon: {
    display: "inline-flex",
    transition: "0.3s ease-out",
    alignSelf: "flex-start"
  },
  questionText: {
    color: "#fafafa",
    fontSize: "16px"
  },
  selectedQuestion: {
    color: "black"
  },
  questionContainer: {
    padding: 0,
    flexGrow: 1,
    flexFlow: "column nowrap",
    alignItems: "flex-start"
  },
  rotateChevron: {
    transform: "rotate(90deg)"
  }
}));

function Question({
  question,
  index,
  openQuestions,
  onDelete,
  moveCard,
  editable = true,
  color = "secondary",
  toggleOpenQuestion,
  renderCards,
  epubCFI
}) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  //Redux State
  const selectedQuestionId = useSelector(state => state.gr.selectedQuestionId);
  const questions = useSelector(state => state.gr.questions);
  const highlights = useSelector(state => state.gr.highlights);
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  // Ephemeral state
  const [undoData, setUndoData] = useState(null);

  // Variables
  const ANSWER_ITEM = "GR.Answer";

  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoThemeCopy = () => {
      if (undoData?.type === "answerDropped") {
        dispatch(
          updateSq3r({
            textId: selectedTextId,
            questions: questions.map(el => {
              if (el.id === undoData.questionId) {
                return {
                  ...el,
                  answers: el.answers.filter(el => el.cfi !== undoData.quoteCfi)
                };
              } else return el;
            }),
            highlights: highlights
          })
        );
      }
    };

    if (shouldUndo) {
      undoThemeCopy();
      dispatch(undo(false));
      // TODO: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [shouldUndo, dispatch, highlights, questions, selectedTextId, undoData]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "sq3r.answer_dropped",
            defaultMessage: "Card copied to question"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [undoData, dispatch, intl, alertsDuration]);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ANSWER_ITEM,
    drop: (item, monitor) => {
      let items = question.answers
        ? question.answers.filter(a => a.cfi !== item.item.cfi)
        : [];
      let answers = [...items];
      let i = 0;
      let insertAt = answers.length;
      for (i = 0; i < answers.length; i++) {
        if (epubCFI.compare(answers[i].cfi, item.item.cfi) > 0) {
          insertAt = i;
          break;
        }
      }

      answers.splice(insertAt, 0, { ...item.item, source: "grQuestion" });
      let updatedQuestion = { ...question, answers: answers };

      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: questions.map(el => {
            if (el.id === updatedQuestion.id) return updatedQuestion;
            else return el;
          }),
          highlights: highlights
        })
      );

      setUndoData({
        type: "answerDropped",
        questionId: question.id,
        quoteCfi: item.item.cfi
      });
    },
    canDrop: (item, monitor) => {
      let retVal =
        !question.answers ||
        question.answers.filter(el => el.cfi === item.item.cfi).length <= 0;
      return retVal;
    },
    collect: (monitor, collectProps) => {
      let obj = {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        targetId: monitor.targetId
      };
      return obj;
    }
  });

  function ExpandCollapseBtn() {
    return (grMode === "full" && [2, 4].includes(stage)) ||
      (grMode === "light" && [1, 2].includes(stage)) ? (
      <IconButton
        color={color}
        onClick={e => {
          toggleOpenQuestion(question.id);
          e.stopPropagation();
        }}
        className={clsx(classes.questionText, classes.questionIcon, {
          [classes.rotateChevron]: openQuestions.includes(question.id),
          [classes.selectedQuestion]: selectedQuestionId === question.id
        })}
      >
        {rtl ? <ArrowLeft /> : <ArrowRight />}
      </IconButton>
    ) : (
      <Box className={classes.spacer} />
    );
  }

  return (
    <Box className={classes.questionContainer} key={question.id} ref={drop}>
      <motion.div
        animate={{
          background:
            isOver && canDrop
              ? "rgba(255, 255, 255, 0.1)"
              : "rgba(255, 255, 255, 0)"
        }}
      >
        {editable ? (
          <Draggable
            key={question.id}
            isDragDisabled={openQuestions.includes(question.id)}
            draggableId={question?.id.toString()}
            index={index}
          >
            {(provided, snapshot) => {
              return (
                <Box
                  style={{ width: "100%" }}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  tabIndex="-1"
                >
                  <QuestionBox
                    onDelete={onDelete}
                    key={question.id}
                    index={index}
                    question={question}
                    moveCard={moveCard}
                    icon={<ExpandCollapseBtn />}
                    color={color}
                  />
                </Box>
              );
            }}
          </Draggable>
        ) : (
          <QuestionBox
            key={question.id}
            index={index}
            question={question}
            editable={false}
            icon={<ExpandCollapseBtn />}
            color={color}
          />
        )}
        {/* TODO: Ofer - think of a way to incapsulate this condition */}
        {((grMode === "full" && [2, 4].includes(stage)) ||
          (grMode === "light" && [1, 2].includes(stage))) &&
          renderCards(question, index)}
      </motion.div>
    </Box>
  );
}

Question.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    summary: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  onDelete: PropTypes.func.isRequired
};

export default Question;
