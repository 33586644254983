import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import MyDefaultPanel from "./MyDefaultPanel";
import GrSteps from "./GrSteps";
import PangeaSpinner from "../SharedComponents/PangeaSpinner";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    background: "#333333",
    display: "flex",
    flexDirection: "column"
  },
  ripple: {
    width: "20px",
    height: "20px"
  }
}));

export default function PocBar({
  color = "secondary",
  editable = true,
  persistState,
  onAnswerCardDelete
}) {
  const classes = useStyles();
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const selectedQuestionId = useSelector(state => state.gr.selectedQuestionId);
  const showHighlights = useSelector(state => state.gr.showHighlights);
  const showAnswers = useSelector(state => state.gr.showAnswers);
  const loading = useSelector(state => state.gr.loading);
  const [openQuestions, setOpenQuestions] = useState([]);
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (stage === 4) {
      setOpenQuestions([]);
    }
  }, [stage]);

  useEffect(() => {
    // this conditions is nessary when exsitin gr task when it's in review feedback mode
    if (
      grMode === undefined ||
      stage === undefined ||
      showAnswers === undefined ||
      showHighlights === undefined ||
      selectedQuestionId === undefined ||
      !rendered
    )
      return;
    persistState();
  }, [grMode, stage, selectedQuestionId, showHighlights, showAnswers]);

  useEffect(() => {
    setRendered(true);
  }, []);

  return (
    <Paper className={classes.root} elevation={0}>
      {loading ? (
        <PangeaSpinner />
      ) : (
        <>
          <GrSteps color={color} />
          <MyDefaultPanel
            openQuestions={openQuestions}
            setOpenQuestions={setOpenQuestions}
            value={stage}
            index={0}
            color={color}
            editable={editable}
            onAnswerCardDelete={onAnswerCardDelete}
          />
          <MyDefaultPanel
            openQuestions={openQuestions}
            setOpenQuestions={setOpenQuestions}
            value={stage}
            index={1}
            color={color}
            editable={editable}
            onAnswerCardDelete={onAnswerCardDelete}
          />
          <MyDefaultPanel
            openQuestions={openQuestions}
            setOpenQuestions={setOpenQuestions}
            value={stage}
            index={2}
            color={color}
            editable={editable}
            onAnswerCardDelete={onAnswerCardDelete}
          />
          <MyDefaultPanel
            openQuestions={openQuestions}
            setOpenQuestions={setOpenQuestions}
            value={stage}
            index={3}
            color={color}
            editable={editable}
            onAnswerCardDelete={onAnswerCardDelete}
          />
          <MyDefaultPanel
            openQuestions={openQuestions}
            setOpenQuestions={setOpenQuestions}
            value={stage}
            index={4}
            color={color}
            editable={editable}
            onAnswerCardDelete={onAnswerCardDelete}
          />
        </>
      )}
    </Paper>
  );
}

PocBar.propTypes = {
  color: PropTypes.oneOf(["secondary", "primary"]),
  editable: PropTypes.bool,
  persistState: PropTypes.func.isRequired,
  onAnswerCardDelete: PropTypes.func.isRequired
};
