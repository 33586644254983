import React, { useEffect } from "react";
import { logout } from "../../redux/userSlice";
import { firebaseApp } from "../../firebase";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PangeaSpinner from "../SharedComponents/PangeaSpinner";

function Logout() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    firebaseApp
      .auth()
      .signOut()
      .then(() => {
        dispatch(logout());
        history.push("/");
      })
      .catch(err => {
        console.error(err);
      });
  }, [history, dispatch]);

  return <PangeaSpinner />;
}

export default Logout;
