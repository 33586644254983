// Dependencies
import React, { useEffect, useCallback, useState } from "react";
import { firebaseFunctions, firestore } from "../../../firebase";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
// import ClipLoader from "react-spinners/ClipLoader";
import { groupBy } from "lodash";

// Redux Dependencies
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useSelector, useDispatch } from "react-redux";
import {
  enqueueFlashMessage,
  undo,
  toggleOpenTextTask
  // undoActions,
} from "../../../redux/userSlice";

//Components
import TasksListItem from "./TasksListItem";

// import PangeaSpinner from "../../SharedComponents/PangeaSpinner";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Table,
  TableRow,
  IconButton,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Collapse,
  Typography,
  List
} from "@material-ui/core";
import PangeaSpinner from "../../SharedComponents/PangeaSpinner";

const useStyles = makeStyles(theme => ({
  wideCell: {
    width: "80%"
  },
  left: {
    textAlign: "left" // TODO: WTF?
  },
  tableRow: {
    height: "52px"
  },
  studenBox: {
    marginLeft: "56px"
  },
  tableContainer: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "48px",
    marginBottom: "24px"
  },
  tasksHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "60px 0"
  },
  tasks: {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      justifyContent: "space-between",
      order: 2
    }
  }
}));
function SubmissionRow({ submission, graders, ...props }) {
  //Hooks
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const submitted = intl.formatMessage({
    id: "tasks.status.submitted",
    defaultMessage: "Submitted"
  });
  const pending = intl.formatMessage({
    id: "tasks.status.pending",
    defaultMessage: "Pending"
  });

  const getDate = str => {
    let b = str.split(/\D/);
    let d = new Date(b[0], b[1] - 1, b[2]);
    return d;
  };

  return (
    <TableRow
      className={clsx(classes.tableRow)}
      onClick={() => {
        history.push(
          "/feedback/text/" +
            submission.text_id +
            "/submission/" +
            submission.submission_id
        );
      }}
      key={props.index}
    >
      <TableCell className={clsx(classes.left)} component="th" scope="row">
        {submission.student_name}
      </TableCell>
      <TableCell className={clsx(classes.left)}>
        {submission.submission_date ? submitted : pending}
      </TableCell>
      <TableCell className={clsx(classes.left)}>
        {submission.submission_date &&
          getDate(submission.submission_date).toLocaleDateString()}
      </TableCell>

      <TableCell className={clsx(classes.left)}></TableCell>
      <TableCell className={clsx(classes.left)}></TableCell>
    </TableRow>
  );
}

function TextRow(props) {
  const { text, index, submissions } = props;
  const dispatch = useDispatch();

  const open = useSelector(
    state => state.user.openTextTasks && state.user.openTextTasks.includes(text)
  );

  const setOpen = () => {
    dispatch(toggleOpenTextTask(text));
  };
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow key={index}>
        <TableCell
          className={clsx(classes.cell, classes.wideCell, classes.left)}
          component="th"
          scope="row"
        >
          {submissions[0].text_name}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen()}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.cell} align="right"></TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }}
          colSpan={2}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.studenBox} margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow className={clsx(classes.tableRow)}>
                    <TableCell className={clsx(classes.left)}>
                      <FormattedMessage
                        id="tasks.student"
                        defaultMessage="Student"
                      />
                    </TableCell>
                    <TableCell className={clsx(classes.left)}>
                      <FormattedMessage
                        id="tasks.status"
                        defaultMessage="Status"
                      />
                    </TableCell>
                    <TableCell className={clsx(classes.left)} align="left">
                      <FormattedMessage
                        id="tasks.submission_time"
                        defaultMessage="Submitted On"
                      />
                    </TableCell>
                    <TableCell className={clsx(classes.left)} align="left">
                      <FormattedMessage
                        id="tasks.feedback"
                        defaultMessage="Feedback"
                      />
                    </TableCell>
                    <TableCell className={clsx(classes.left)} align="left">
                      <FormattedMessage
                        id="tasks.grade"
                        defaultMessage="Grade"
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {submissions.map((submission, index) => {
                    return (
                      <SubmissionRow
                        submission={submission}
                        index={index}
                        key={index}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function TasksTeacherView(props) {
  const { course } = props;

  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux State
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  // const openTasks = useSelector((state) => state.user.openTasks);

  // Ephemeral State
  const [tasks, setTasks] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [textSubmissions, setTextSubmissions] = useState([]);
  const [taskGraders, setTaskGraders] = useState(false);
  const [undoData, setUndoData] = useState(null);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(tasks, result.source.index, result.destination.index);

    //call function for reorder:
    let func = firebaseFunctions.httpsCallable("courses-reorderTasks");

    func({ course: course.id, tasks: items })
      .then(response => {
        // setRows(response.data);
      })
      .catch(err => {
        console.log("eror in reorder", err);
      });

    setTasks(items);
  };

  const refreshTasks = useCallback(() => {
    let getAllTasks = firebaseFunctions.httpsCallable("courses-getTasks");
    getAllTasks({ course: props.course.id }).then(response => {
      setTasks(response.data);
    });
  }, [props.course]);

  // Behavior
  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoDeleteTask = () => {
      if (undoData?.type === "deleteTask") {
        let saveTask = firebaseFunctions.httpsCallable("courses-saveTask");
        saveTask({
          ...undoData.task,
          questions: undoData.task.questions.questions,
          answers: undoData.task.answers.answers,
          id: 0
        }).then(() => {
          refreshTasks();
        });
      }
    };

    if (shouldUndo) {
      undoDeleteTask();
      dispatch(undo(false));
      // OPTION: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [shouldUndo, dispatch, undoData, refreshTasks]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.deleted",
            defaultMessage: "Task deleted"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [undoData, dispatch, intl]);

  useEffect(() => {
    if (!props.course) {
      setTasks([]);
    } else {
      var getTextSubmissions = firebaseFunctions.httpsCallable(
        "courses-getTextSubmissions"
      );

      getTextSubmissions({ course: props.course.id }).then(response => {
        setTextSubmissions(response.data);
      });

      refreshTasks();

      var getAllSubmissions = firebaseFunctions.httpsCallable(
        "courses-getTaskSubmissions"
      );
      getAllSubmissions({ course: props.course.id }).then(response => {
        setSubmissions(response.data);
      });
      let docId = "taskGraders/" + props.course.id + "/graders/";

      const unsubscribe = firestore.collection(docId).onSnapshot(
        snapshot => {
          let graders = {};
          snapshot.forEach(doc => {
            graders[doc.id] = doc.data();
          });

          setTaskGraders(graders);
        },
        error => {
          console.log("fetchFirebaseError tasks", error);
        }
      );

      return function cleanup() {
        unsubscribe && unsubscribe();
      };
    }
  }, [course, props.refresh, props.course, refreshTasks]);

  const deleteTask = task => {
    var deleteTaskFunc = firebaseFunctions.httpsCallable("courses-deleteTask");

    deleteTaskFunc({ id: task.id }).then(response => {
      if (response.data && response.data > 0) {
        setUndoData({
          type: "deleteTask",
          task
        });
        refreshTasks();
      }
    });
  };

  // Render
  // const renderTextSubmission = () => {
  //   let subs = groupBy(textSubmissions, "text_id");

  //   return (
  //     <TableContainer
  //       className={classes.tableContainer}
  //       elevation={0}
  //       // component={Paper}
  //     >
  //       <Table className={classes.table} aria-label="simple table">
  //         <TableBody>
  //           {Object.keys(subs).map(function (key, index) {
  //             return (
  //               <TextRow
  //                 key={key}
  //                 index={index}
  //                 text={key}
  //                 submissions={subs[key]}
  //               />
  //             );
  //           })}
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //   );
  // };

  function renderTasks(tasks) {
    if (tasks.length <= 0) {
      return (
        <Typography variant="h6">
          There are currently no tasks for this course
        </Typography>
      );
    } else {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={"1"} direction="vertical">
            {provided => {
              return (
                <List
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={classes.tasks}
                >
                  {tasks.map((task, index) => {
                    return (
                      <TasksListItem
                        key={task.id}
                        course={course}
                        task={task}
                        taskIndex={index}
                        graders={taskGraders}
                        deleteTask={deleteTask}
                        submissions={submissions.filter(
                          e => e.task_id === task.id
                        )}
                      />
                    );
                  })}
                  {provided.placeholder}
                </List>
              );
            }}
          </Droppable>
        </DragDropContext>
      );
    }
  }

  if (!tasks) return <PangeaSpinner />;
  else
    return (
      <>
        <Box className={classes.tasksHeader}>
          <Typography component="h1" variant="h4">
            {props.course.name}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/tasks/" + props.course.id + "/new", {
                placeholder: "New Task",
                course_id: props.course.id
              });
            }}
          >
            <FormattedMessage id="tasks.create" defaultMessage="Add new Task" />
          </Button>
        </Box>
        {renderTasks(tasks)}
        {/* {renderTextSubmission()} */}
      </>
    );
}
