// Dependencies
import React, { useState, useEffect, useCallback, useMemo } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { useIntl, FormattedMessage } from "react-intl";
import ePub from "epubjs";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useGetTheme } from "../../../hooks";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { updateSq3r, getNextId } from "../../../redux/firebaseMiddleware";
import {
  setSelectedQuestion,
  deleteSelectedAnswer
} from "../../../redux/grSlice";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";
import { setShowAnswers, setShowHighlights } from "../../../redux/grSlice";

// Components
import { QuestionQuoteCard } from "../QuestionQuoteCard";
import Question from "./Question";
import NewQuestion from "./NewQuestion";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  Button,
  Collapse,
  Box,
  FormControlLabel,
  Switch
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  questionsManager: {
    flex: 1,
    overflowX: "hidden"
  },
  questionContainer: {
    height: "100%"
  },
  question: {
    paddingBlock: 0
  },
  panelHeader: {
    color: "white",
    width: "100%",
    marginBottom: "16px",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  spacer: {
    width: theme.spacing(2),
    flexShrink: 0
  },
  left: {
    textAlign: "left"
  },
  right: {
    textAlign: "right"
  },
  addThemeLtr: {
    justifyContent: "left"
  },
  questionIcon: {
    display: "inline-flex",
    transition: "0.3s ease-out",
    alignSelf: "flex-start"
  },
  questionText: {
    color: "#fafafa",
    fontSize: "16px"
  },
  selectedQuestion: {
    color: "black"
    // backgroundColor: theme.palette.secondary.main
  },
  listItem: {
    padding: 0,
    flexFlow: "column nowrap",
    alignItems: "flex-start",
    // FIXME: this is a hack
    "&>div": {
      width: "100%"
    }
  },
  rotateChevron: {
    transform: "rotate(90deg)"
  }
}));

export default function QuestionManager({
  scrollToBottom,
  color = "secondary",
  editable = true,
  openQuestions,
  setOpenQuestions,
  onAnswerCardDelete
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useGetTheme({ alwase: "dark" });

  // Redux State
  const questions = useSelector(state => state.gr.questions);
  const highlights = useSelector(state => state.gr.highlights);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const selectedQuestion = useSelector(state => {
    if (state.gr.questions?.length) {
      let filtered = state.gr.questions.filter(
        q => q.id === state.gr.selectedQuestionId
      );
      if (filtered && filtered.length) return filtered[0];
      else return false;
    }
  });
  const stage = useSelector(state => state.gr.stage);
  const grMode = useSelector(state => state.gr.mode);
  const showAnswers = useSelector(state => state.gr.showAnswers);
  const showHighlights = useSelector(state => state.gr.showHighlights);
  const selectedAnswer = useSelector(state => state.gr.selectedAnswer);
  const shownLocation = useSelector(state => {
    return state.readerActions.shownLocation;
  });
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  const renderAddBtn =
    (grMode === "full" && stage === 1) ||
    (grMode === "light" && [0, 1].includes(stage));

  // Ephemeral State
  const [newQuestionMode, setNewQuestionMode] = useState({
    adding: false,
    value: ""
  });
  // const [previousSelectedQuestion, setPreviousSelectedQuestion] = useState();
  const [tempQuestions, setTempQuestions] = useState(questions);
  const [undoData, setUndoData] = useState(null);

  const EpubCFI = new ePub.CFI();

  const addingNewQuestion =
    ((grMode === "full" && stage === 1) || (grMode === "light" && stage < 2)) &&
    newQuestionMode.adding;
  // Behavior
  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoDeleteQuestion = () => {
      console.log(undoData?.type);
      if (undoData?.type === "deleteQuestionTheme") {
        let relQuestion = questions.filter(
          el => el.id === undoData.question.id
        );

        if (relQuestion.length) {
          let question = relQuestion[0];
          let items = question.answers
            ? question.answers.filter(a => a.cfi !== undoData.quote.cfi)
            : [];
          let answers = [...items];
          let i = 0;
          let insertAt = answers.length;
          for (i = 0; i < answers.length; i++) {
            if (EpubCFI.compare(answers[i].cfi, undoData.quote.cfi) > 0) {
              insertAt = i;
              break;
            }
          }
          answers.splice(insertAt, 0, undoData.quote);
          let modQ = { ...question, answers: answers };

          dispatch(
            updateSq3r({
              textId: selectedTextId,
              questions: questions.map(el => {
                if (el.id === question.id) return modQ;
                else return el;
              }),
              highlights: highlights
            })
          );
        }
      } else if (undoData?.type === "deleteQuestion") {
        let id = undoData.question.id;
        id = getNextId(questions);

        let question = { ...undoData.question, id: id };
        let newQuestions = [...questions];
        newQuestions.splice(undoData.index, 0, question);

        dispatch(
          updateSq3r({
            textId: selectedTextId,
            questions: newQuestions,
            highlights: highlights
          })
        );
      }
    };

    if (shouldUndo) {
      undoDeleteQuestion();
      dispatch(undo(false));
      // TODO: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [
    shouldUndo,
    dispatch,
    EpubCFI,
    highlights,
    questions,
    selectedTextId,
    undoData
  ]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData?.type === "deleteQuestion") {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "delete.question",
            defaultMessage: "Question deleted"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    } else if (undoData?.type === "deleteQuestionTheme") {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "delete.card",
            defaultMessage: "Card deleted"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [undoData, alertsDuration]);

  useEffect(() => {
    setTempQuestions(questions);
  }, [questions]);

  const toggleOpenQuestion = id => {
    if (openQuestions.includes(id)) {
      setOpenQuestions(openQuestions.filter(el => el !== id));
    } else {
      setOpenQuestions([...openQuestions, id]);
    }
  };

  useEffect(() => {
    if (newQuestionMode.addValue && newQuestionMode.shouldAdd) {
      let id = getNextId(questions);

      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: [
            ...questions,
            { id: id, question: newQuestionMode.addValue, answers: [] }
          ],
          highlights: highlights
        })
      );
      setNewQuestionMode({
        value: "",
        adding: newQuestionMode.adding,
        addValue: "",
        shouldAdd: false
      });
      // setPreviousSelectedQuestion(id);
    }
  }, [newQuestionMode, dispatch, highlights, questions, selectedTextId]);

  // 02/20/22 - this effect was causing a loop when moving through states adding a new question.
  // useEffect(() => {
  //   if (!newQuestionMode.adding && previousSelectedQuestion) {
  //     dispatch(setSelectedQuestion({ id: previousSelectedQuestion }));
  //     setPreviousSelectedQuestion(0);
  //   }
  // }, [newQuestionMode, previousSelectedQuestion, dispatch]);

  const getAnswers = myAnswers => {
    if (!myAnswers || !myAnswers.filter) return myAnswers;
    if (stage < 3) {
      return myAnswers
        .slice()
        .filter(el => el.cfi)
        .sort(function (a, b) {
          return EpubCFI.compare(a.cfi, b.cfi);
        });
    } else return myAnswers.filter(el => !el.hidden);
  };

  const renderCards = (question, index) => {
    let sortedAnswers =
      !openQuestions.includes(question.id) &&
      question.id === selectedAnswer.questionId
        ? [selectedAnswer]
        : getAnswers(question.answers);

    return (
      <Collapse
        in={
          question.id === selectedAnswer.questionId ||
          openQuestions.includes(question.id)
        }
        timeout="auto"
        unmountOnExit
      >
        <List>
          {sortedAnswers &&
            sortedAnswers.map((item, index) => (
              <ListItem key={index} ContainerComponent="li">
                <QuestionQuoteCard
                  question={question}
                  isDragDisabled={!editable}
                  item={item}
                  index={index}
                  onDelete={(question, quote, updatedQuestions) => {
                    onAnswerCardDelete(updatedQuestions);
                    // TODO: Ofer move undo to onAnswerCardDelete callback
                    setUndoData({
                      type: "deleteQuestionTheme",
                      question,
                      quote
                    });
                    // TODO: Ofer I think this condition is alwayts false. maybe left-overs from old code ?
                    if (quote.cfi === shownLocation.cfi) {
                      dispatch(deleteSelectedAnswer());
                    }
                  }}
                />
              </ListItem>
            ))}
        </List>
      </Collapse>
    );
  };

  //const grid = 8;

  /*const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer

    // change background colour if dragging
    cursor: isDragging ? "grab" : "pointer",

    // styles we need to apply on draggables
    ...draggableStyle,
  });*/

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      tempQuestions,
      result.source.index,
      result.destination.index
    );

    setTempQuestions(items);

    dispatch(
      updateSq3r({
        textId: selectedTextId,
        questions: items,
        highlights: highlights
        // selectedQuestionId: updatedSelectedQuestionId
      })
    );
  };

  const onDelete = (question, index) => {
    setUndoData({
      type: "deleteQuestion",
      question,
      index
    });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const addQuestion = () => {
    scrollToBottom();
    selectedQuestion &&
      selectedQuestion.id &&
      // setPreviousSelectedQuestion(selectedQuestion.id);
      dispatch(setSelectedQuestion({ id: null }));

    if (!newQuestionMode.adding) {
      setNewQuestionMode({ adding: true, value: "" });
    } else {
      if (newQuestionMode.value) {
        dispatch(
          updateSq3r({
            textId: selectedTextId,
            questions: [
              ...questions,
              {
                id: getNextId(questions),
                question: newQuestionMode.value,
                answers: []
              }
            ],
            highlights: highlights
          })
        );

        setNewQuestionMode({ adding: true, value: "" });
      }
    }
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = questions[dragIndex];
      let updatedQuestions = update(questions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      });

      dispatch(
        updateSq3r({
          textId: selectedTextId,
          questions: updatedQuestions,
          highlights: highlights
        })
      );
    },
    [questions, dispatch, highlights, selectedTextId]
  );

  return (
    <Box className={classes.questionsManager}>
      {renderAddBtn && (
        <Box className={clsx(classes.panelHeader, classes.left)}>
          {editable && (
            <Button
              variant="outlined"
              color={color}
              onClick={addQuestion}
              className={clsx(classes.addTheme, classes.addThemeLtr)}
            >
              <FormattedMessage
                id="gr.addQuestion"
                defaultMessage="Add Question"
              />
            </Button>
          )}
          {grMode === "light" && stage === 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={showHighlights}
                  color={color}
                  onChange={e => {
                    dispatch(setShowHighlights(!showHighlights));
                  }}
                />
              }
              label={
                <FormattedMessage
                  id="gr.highlightsView"
                  defaultMessage="Highlight view"
                />
              }
            />
          )}
          {grMode === "light" && stage === 1 && (
            <FormControlLabel
              control={
                <Switch
                  color={color}
                  checked={showAnswers}
                  onChange={e => {
                    dispatch(setShowAnswers(!showAnswers));
                  }}
                />
              }
              label={
                <FormattedMessage
                  id="gr.answersView"
                  defaultMessage="Answer view"
                />
              }
            />
          )}
        </Box>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <List
              disablePadding
              dense
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={classes.questionContainer}
            >
              {tempQuestions.length > 0 &&
                tempQuestions.map((item, index) => {
                  return (
                    <ListItem
                      key={index}
                      disableGutters
                      className={classes.question}
                    >
                      <Question
                        index={index}
                        openQuestions={openQuestions}
                        editable={editable}
                        onDelete={onDelete}
                        moveCard={moveCard}
                        color={color}
                        question={item}
                        renderCards={renderCards}
                        toggleOpenQuestion={toggleOpenQuestion}
                        epubCFI={EpubCFI}
                      />
                    </ListItem>
                  );
                })}

              {addingNewQuestion && (
                <NewQuestion
                  newQuestionMode={newQuestionMode}
                  setNewQuestionMode={setNewQuestionMode}
                  scrollToBottom={scrollToBottom}
                />
              )}
              {/* {renderNewQuestion()} */}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
}

QuestionManager.propTypes = {
  color: PropTypes.oneOf(["secondary", "primary"]),
  scrollToBottom: PropTypes.func,
  editable: PropTypes.bool
};
