// Dependencies
import React, { useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { enqueueFlashMessage, undo } from "../../../redux/userSlice";

// Components
import TaskQuestionView from "./TaskQuestionView";
import NavigationButtons from "./NavigationButtons";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  FormControl,
  TextField,
  Typography,
  ListItemText,
  MenuItem,
  Divider,
  OutlinedInput,
  InputLabel
} from "@material-ui/core";

//Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  left: {
    textAlign: "left"
  },
  coloredUnderline: {
    " &:after": {
      borderBottom: "2px solid #168FEE"
    }
  },
  paperClass: {
    marginTop: "20px",
    borderRadius: "0px",
    paddingBottom: "16px",
    textAlign: "left",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#168FEE"
    },
    "& .MuiInput-underline": {
      "&:after": {
        borderBottom: "2px solid #168FEE"
      }
    },
    "&:after": {
      borderBottom: "2px solid #168FEE"
    },
    "&:focused::after": {
      borderBottom: "2px solid #168FEE"
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#168FEE"
    }
  },
  addQuestion: {
    marginTop: "20px",
    borderRadius: "0px",
    textAlign: "left",
    cursor: "pointer",
    color: theme.palette.text.disabled,
    "& :hover": {
      color: theme.palette.text.primary
    },
    "&:focus-visible": {
      color: theme.palette.text.primary
    }
  },
  paddedContent: {
    paddingLeft: "24px",
    paddingTop: "16px",
    paddingBottom: "16px"
  },
  taskDesc: {
    marginBottom: "16px"
  },

  paddedWholeContent: {
    display: "flex",
    flexFlow: "column nowrap",
    paddingInline: "24px",
    paddingBlock: "16px"
  },

  formRow: {
    marginBlockEnd: "16px",
    display: "flex",
    flexFlow: "row nowrap",
    "& .MuiFormControl-root": {
      flex: "1 1 0",
      marginInlineEnd: "8px"
    },
    "& .MuiFormControl-root:last-child": {
      marginInlineEnd: "0"
    },
    "& $taskTitle": {
      flexGrow: "2"
    }
  },
  // Dont delete, .taskTitle is referenced somewhere else
  taskTitle: {},
  listItem: {
    marginBottom: "0px",
    lineHeight: "1rem"
  },
  // for peer review
  questionContainer: {
    marginTop: "20px",
    borderRadius: "0px",
    textAlign: "left"
  },

  questionHeader: {
    paddingInline: "24px",
    paddingBlock: "16px"
  },
  questionBody: {
    display: "flex",
    flexFlow: "column nowrap",
    paddingInline: "24px",
    paddingBlock: "16px"
  },
  questionActions: {
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
    paddingInline: "24px",
    paddingBlock: "16px"
  },
  wordLimitInputRoot: {
    width: "60px",
    marginInlineEnd: "16px",
    "& input": {
      padding: "16px 8px",
      textAlign: "center"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#168fee"
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23) !important",
      borderWidth: "1px !important"
    }
  },
  wordLimitLabel: {
    cursor: "pointer"
  }
}));

export default function StepAddQuestions(props) {
  //Hooks
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const wordLimitRef = useRef();

  //Redux State
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Ephemeral State
  const [undoData, setUndoData] = useState(null);

  //Variables

  const taskTypes = [
    {
      value: "guidedReading",
      text: intl.formatMessage({
        id: "task.type.guidedReading",
        defaultMessage: "Guided Reading"
      })
    },
    {
      value: "standard",
      text: intl.formatMessage({
        id: "task.type.standard",
        defaultMessage: "Standard"
      })
    },
    {
      value: "peerReview",
      text: intl.formatMessage({
        id: "task.type.peerReview",
        defaultMessage: "Peer review"
      })
    }
  ];

  const titleValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterTitle",
    defaultMessage: "Enter a task title"
  });
  const questionValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterQuestion",
    defaultMessage: "Add a question"
  });
  const formulationValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.enterFormulation",
    defaultMessage: "Add question formulation to question"
  });
  const questionTypeValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.selectType",
    defaultMessage: `Select question type for question`
  });

  const optionsValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.optionsValue",
    defaultMessage: "Multiple choice question must have more than one option "
  });
  const optionsChoiceValidationMessage = intl.formatMessage({
    id: "tasks.create.validation.optionChoice",
    defaultMessage: "Please select correct answer choice for question"
  });
  const { questions, answers, setQuestions, setAnswers } = props;
  //Behavior
  useEffect(() => {
    const undoDeleteQuestion = () => {
      if (undoData.type === "deleteQuestion") {
        let currentQuestions = [...questions];
        let currentAnswers = [...answers];
        currentQuestions.splice(undoData.index, 0, undoData.question);
        currentAnswers.splice(undoData.index, 0, undoData.answer);

        setQuestions(currentQuestions);
        setAnswers(currentAnswers);
        setUndoData(null);
      }
    };

    if (shouldUndo) {
      undoDeleteQuestion();
      dispatch(undo(false));
    }
  }, [
    shouldUndo,
    dispatch,
    undoData,
    questions,
    answers,
    setAnswers,
    setQuestions
  ]);

  function handleTaskTypeChange(taskType) {
    // Clear all questions
    if (taskType === "peerReview") {
      // hard coding question type because peer review from doesn't have a question type dropdown
      setQuestions([
        {
          question: "",
          includeCitation: false,
          type: 3,
          points: 0,
          wordLimit: -1,
          options: []
        }
      ]);
    } else {
      setQuestions([
        {
          question: "",
          includeCitation: false,
          type: "",
          points: 0,
          wordLimit: -1,
          options: []
        }
      ]);
    }
    // Clear all answers
    setAnswers([{ shouldSelect: -1, wordLimit: -1, quotes: [], concepts: [] }]);
  }

  const updateQuestions = (question, answer, index) => {
    let itemQuestions = [...props.questions];
    let itemAnswers = [...props.answers];
    itemQuestions[index] = { ...itemQuestions[index], ...question };
    props.setQuestions && props.setQuestions(itemQuestions);

    itemAnswers[index] = answer;
    props.setAnswers && props.setAnswers(itemAnswers);
  };

  const addQuestion = () => {
    props.setQuestions([
      ...props.questions,
      {
        question: "",
        includeCitation: false,
        type: "",
        points: 0,
        options: []
      }
    ]);
    props.setAnswers([
      ...props.answers,
      { shouldSelect: -1, quotes: [], concepts: [] }
    ]);
  };

  const copyQuestion = (question, answer) => {
    props.setQuestions([...props.questions, question]);
    props.setAnswers([...props.answers, answer]);
  };

  const deleteQuestion = (question, index) => {
    let currentQuestions = [...props.questions];
    let currentAnswers = [...props.answers];
    if (currentQuestions.length > 1 && currentQuestions.length > index) {
      let deletedAnswer = currentAnswers[index];
      let deletedQuestion = currentQuestions[index];
      currentQuestions.splice(index, 1);
      currentAnswers.splice(index, 1);

      setUndoData({
        type: "deleteQuestion",
        question: deletedQuestion,
        answer: deletedAnswer,
        index: index
      });

      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "task.deletedQuestion",
            defaultMessage: "Deleted question"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );

      props.setQuestions(currentQuestions);
      props.setAnswers(currentAnswers);
    }
  };

  function validateForm() {
    let message = [];
    // Check if there is a title
    if (!props.taskTitle) {
      message.push(titleValidationMessage);
    }
    // Check that there is at least one question
    if (
      props.taskType !== "peerReview" &&
      props.questions.length <= 1 &&
      props.questions[0].question === ""
    ) {
      message.push(questionValidationMessage);
    } else {
      // if there are questions, check that each have...
      props.questions.forEach((question, index) => {
        // A question formulation
        if (
          props.taskType !== "peerReview" &&
          question.question.trim() === ""
        ) {
          message.push(`${formulationValidationMessage} ${index + 1}`);
        }
        // A type selected
        if (question.type === "" && props.taskType !== "guidedReading") {
          message.push(`${questionTypeValidationMessage} ${index + 1}`);
        }
        // Points value that is greater than zero
        /* if (question.points === "" || Number(question.points) === 0) {
          message.push(`${pointsValidationMessage} ${index + 1}`);
        } else {
          if (question.points < 0) {
            message.push(`${pointsValueValidationMessage} ${index + 1}`);
          }
        }*/
        if (question.type === 2) {
          if (
            !question.options ||
            !question.options.length ||
            question.options.length < 2
          ) {
            message.push(`${optionsValidationMessage} ${index + 1}`);
          }
          if (
            !props.answers ||
            !props.answers[index] ||
            props.answers[index].shouldSelect < 0 ||
            props.answers[index].shouldSelect >=
              (question.options && question.options.length) ||
            typeof props.answers[index].shouldSelect === "undefined"
          ) {
            message.push(`${optionsChoiceValidationMessage} ${index + 1}`);
          }
        }
      });
    }
    if (message.length === 0 || message === "") {
      return true;
    } else {
      dispatch(
        enqueueFlashMessage({
          message: message,
          duration: 3000,
          severity: "error"
        })
      );
    }
  }
  //Render
  return (
    <>
      <Paper
        elevation={1}
        className={clsx(classes.paperClass, classes.paddedWholeContent)}
      >
        <Box className={classes.formRow}>
          <FormControl className={clsx(classes.taskTitle, classes.taskDesc)}>
            <TextField
              id="taskTitle"
              autoFocus={!props.readOnly}
              disabled={props.readOnly}
              InputProps={{
                readOnly: props.readOnly
              }}
              label={intl.formatMessage({
                id: "task.title",
                defaultMessage: "Task Title"
              })}
              className={clsx(classes.coloredUnderline)}
              value={props.taskTitle}
              onChange={e => {
                props.setTaskTitle && props.setTaskTitle(e.target.value);
              }}
            />
          </FormControl>
          <FormControl className={clsx(classes.taskDesc)}>
            <TextField
              id={`taskType`}
              InputProps={{
                readOnly: props.readOnly
              }}
              label={intl.formatMessage({
                id: "task.type",
                defaultMessage: "Type of task"
              })}
              select
              value={props.taskType}
              dropDownMenuProps={{ classes: { paper: classes.menuBg } }}
              MenuProps={{ classes: { paper: classes.menuBg } }}
              onChange={e => {
                // Clear any currently saved questions and answers
                // TODO: Better define UX, alert user or save previus entries
                handleTaskTypeChange(e.target.value);
                // setTaskType in order to show the correct form
                props.setTaskType && props.setTaskType(e.target.value);
              }}
            >
              {taskTypes.map((item, index) => {
                return (
                  <MenuItem
                    className={classes.menu}
                    key={index}
                    value={item.value}
                  >
                    <ListItemText
                      disableTypography={true}
                      className={classes.listItem}
                    >
                      {item.text}
                    </ListItemText>
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>
        </Box>

        <FormControl className={classes.taskDesc}>
          <TextField
            id="taskDescription"
            readOnly={props.readOnly}
            InputProps={{
              readOnly: props.readOnly
            }}
            label={intl.formatMessage({
              id: "task.description",
              defaultMessage: "Task Description"
            })}
            className={classes.coloredUnderline}
            multiline={true}
            value={props.taskDescription}
            onChange={e => {
              props.setTaskDescription(e.target.value);
            }}
          />
        </FormControl>

        {props.taskType === "peerReview" && !props.readOnly && (
          <>
            {console.log(questions[0].wordLimit)}
            <Divider style={{ marginBlockStart: "24px" }} />
            <Box className={classes.questionActions}>
              <OutlinedInput
                id="word-limit"
                className={classes.wordLimitInput}
                classes={{
                  root: classes.wordLimitInputRoot
                }}
                inputRef={wordLimitRef}
                value={
                  questions[0].wordLimit !== -1 ? questions[0].wordLimit : ""
                }
                onChange={e => {
                  updateQuestions(
                    { ...questions[0], wordLimit: e.target.value },
                    { ...answers[0], wordLimit: e.target.value },
                    0 // Question index
                  );
                }}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    wordLimitRef.current.blur();
                  }
                }}
              />
              <InputLabel
                htmlFor="word-limit"
                disableAnimation
                className={classes.wordLimitLabel}
                onClick={e => {
                  wordLimitRef.current.focus();
                }}
              >
                Word limit
              </InputLabel>
            </Box>
          </>
        )}
      </Paper>
      {["standard", "guidedReading"].includes(props.taskType) &&
        props.questions.map((question, index) => {
          return (
            <Paper
              key={index}
              elevation={1}
              className={clsx(classes.paperClass, classes.paddedWholeContent)}
            >
              <TaskQuestionView
                text={props.text}
                readOnly={props.readOnly}
                shouldAutoFocus={
                  !props.readOnly &&
                  (index > 0 || (props.taskTitle && props.taskTitle.length))
                }
                key={index}
                questionIndex={index}
                {...question}
                answer={props.answers[index]}
                onUpdate={!props.readOnly && updateQuestions}
                onAdd={!props.readOnly && addQuestion}
                onCopy={!props.readOnly && copyQuestion}
                onDelete={() => {
                  !props.readOnly && deleteQuestion(question, index);
                }}
                showType={props.taskType === "standard"}
                showPoints={props.taskType === "standard"}
              />
            </Paper>
          );
        })}
      {["standard", "guidedReading"].includes(props.taskType) &&
        !props.readOnly && (
          <Paper
            elevation={1}
            className={classes.addQuestion}
            onClick={addQuestion}
            onKeyPress={e => e.key === "Enter" && addQuestion()}
            role="button"
            tabIndex="0"
          >
            <Typography className={classes.paddedContent} variant="h6">
              {`${intl.formatMessage({
                id: "task.question",
                defaultMessage: "Question"
              })} #${props.questions.length + 1} `}
            </Typography>
          </Paper>
        )}

      {!props.readOnly && props.setActiveStep && (
        <NavigationButtons
          activeStep={props.activeStep}
          setActiveStep={props.setActiveStep}
          saveTask={props.saveTask}
          validateForm={validateForm}
        />
      )}
    </>
  );
}
