// Dependencies
import React, { useState, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import { useDrag } from "react-dnd";

// Redux Dependencies
import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "../../redux/themeSlice";
import { setLocation } from "../../redux/readerActionsSlice";
import { setThemes } from "../../redux/themeSlice";
import { updateThemes } from "../../redux/firebaseMiddleware";

// Components
import useGetTheme from "../../hooks/useGetTheme";
import ThemeTitle from "../SharedComponents/InlineEditTextarea";
import CitationDialog from "../Tasks/CreateTask/CitationDialog";

// Material UI
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import DeleteFilledIcon from "@material-ui/icons/Delete";
import {
  Box,
  Link,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Typography
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  card: {
    flexBasis: "100%",
    // backgroundColor: theme.palette.card.background.main,
    "&:hover ,&:focus-within": {
      // background: theme.palette.card.background.hover,
      // "& $deleteBtn": { visibility: "visible" }
    }
  },

  CardContent: {
    paddingBottom: 0
  },

  quoteTitle: {
    minHeight: "36px",
    padding: "0",
    fontFamily: '"Open Sans Hebrew", "Rosario", "Helvetica", "Arial"',
    fontSize: "14px",
    background: "none",
    opacity: "0.7",
    marginBottom: "8px"
  },

  quoteText: {
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"',
    fontSize: "16px",
    maxHeight: "148px",
    "-webkit-line-clamp": "4",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    textAlign: "start"
  },
  cardActions: {
    justifyContent: "space-between"
  },
  showQuoteBtn: { padding: "8px" }

  // deleteBtn: { visibility: "hidden" }
}));

export default function ThemeQuoteCard({
  quote,
  index,
  group,
  onDelete,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const theme = useGetTheme();

  // Ephemeral State
  const [showCitation, setShowCitation] = useState(false);
  const [isDraggable, setIsDraggable] = useState(true);

  // Redux State
  const themeMode = useSelector(state => state.themes.themeMode);
  const themes = useSelector(state => state.themes.themes);
  const selectedText = useSelector(state => state.texts.selectedText);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const sourceTextLanguage = useSelector(
    state => state.texts.selectedText?.text_language
  );
  //Variables
  const ANSWER_ITEM = "Theme.Answer";

  // Behavior

  const [{ isDragging }, drag] = useDrag({
    end(item, monitor) {},
    start(item, monitor) {},
    canDrag(monitor) {
      return isDraggable;
    },
    item: { type: ANSWER_ITEM, id: quote.cfi, index, item: quote },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const attach = useCallback(
    domElement => {
      drag(domElement);
      domElement && domElement.setAttribute("draggable", isDraggable);
      // use dom element here for measuring
    },
    [isDraggable, drag]
  );

  useEffect(() => {
    if (attach && drag.attach) {
      attach.current.setAttribute("draggable", isDraggable);
    }
  }, [attach, isDraggable, drag]);

  const onTitleChange = (theme, item, value) => {
    let quotes = theme.quotes.map(el => {
      if (el.id === item.id) {
        return { ...el, title: value };
      } else return el;
    });

    let updatedThemes = themes.map(th => {
      if (th.id === theme.id) {
        return { ...th, quotes: quotes };
      } else return th;
    });
    // update redux store first in order to avoide flickering text
    dispatch(setThemes(updatedThemes));
    // then update firestore
    dispatch(updateThemes({ textId: selectedTextId, themes: updatedThemes }));
  };
  // Render
  return (
    <ThemeProvider theme={theme}>
      <Card ref={attach} elevation={1} className={classes.card}>
        <CardContent className={classes.CardContent}>
          <Typography component="div" className={classes.quoteTitle}>
            <ThemeTitle
              setIsDraggable={setIsDraggable}
              text={quote.title ? quote.title : ""}
              onChange={value => onTitleChange(group, quote, value)}
              placeholder={intl.formatMessage({
                id: "card.title",
                defaultMessage: "Click to edit The title"
              })}
            />
          </Typography>
          <Box
            className={classes.quoteText}
            style={{ direction: sourceTextLanguage === "he" ? "rtl" : "ltr" }}
          >
            {quote.text}
          </Box>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Link
            component="button"
            color="secondary"
            className={classes.showQuoteBtn}
            onClick={() => {
              dispatch(selectTheme({ id: group.id }));
              if (themeMode === "note" || themeMode === "extended") {
                setShowCitation(quote);
              } else dispatch(setLocation({ ...quote, themeId: group.id }));
            }}
          >
            {intl.formatMessage({
              id: "show.text",
              defaultMessage: "show in text"
            })}
          </Link>
          <IconButton
            className={classes.deleteBtn}
            onClick={e => {
              onDelete(group, quote);
              e.stopPropagation();
            }}
          >
            <DeleteFilledIcon />
          </IconButton>
        </CardActions>
      </Card>

      {showCitation && (
        <CitationDialog
          color="green"
          setOpenDialog={setShowCitation}
          url={selectedText.url || selectedText.link}
          location={selectedText.file_location}
          highlights={[showCitation]}
          openDialog={!!showCitation}
          removeHighlight={() => {
            onDelete(group, quote);
            setShowCitation(false);
          }}
        />
      )}
    </ThemeProvider>
  );
}
