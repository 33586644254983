import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  annotateBar: {},

  disabledBtn: {
    cursor: "not-allowed",
    paddingBlock: "12px",
    paddingInline: "16px",
    color: "#aaaaaa"
  },
  highlightBtn: {
    cursor: "pointer",
    paddingBlockStart: "12px",
    paddingInline: "8px",

    "& :hover": {
      color: "#91fcc2"
    }
  },
  minimal: {
    paddingInline: "4px",
    "&:first-child": {
      paddingLeft: "4px"
    }
  }
}));

const ReaderAction = React.forwardRef(
  (
    {
      Icon,
      selected,
      onClick,
      style,
      onMouseEnter,
      onMouseLeave,
      enabled = true,
      minimal = false
    },
    ref
  ) => {
    const classes = useStyles();
    const rtl = useSelector(state => state.user.userProfile.rtl);
    return (
      <Box
        className={clsx(
          enabled && classes.highlightBtn,
          !enabled && classes.disabledBtn,
          minimal && classes.minimal
        )}
        onClick={onClick}
        style={{
          backgroundColor: selected ? "rgba(255,255,255, 0.15)" : "transparent",
          borderRadius: 3,
          ...style
        }}
        onMouseEnter={() => {
          onMouseEnter && onMouseEnter();
        }}
        onMouseLeave={() => {
          onMouseLeave && onMouseLeave();
        }}
      >
        <Icon size={24} />
      </Box>
    );
  }
);

ReaderAction.displayName = "ReaderAction";

export default ReaderAction;
