import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectCurrentCourse } from "../redux/coursesSlice";
import moment from "moment-timezone";

// useConvertToCourseTimezone takes in a date as an argument and the course's timezone form redux
// It returns the adjasted time based on the time diffrance between the local and passed timezone
// IMPORTANT: currently the due dates are being saved as yyyy-mm-dd and calculated for end of the day on that date

export default function useConvertCourseTimezone(date) {
  const [epochDate, setEpochDate] = useState(null);
  const [displayDate, setDisplayDate] = useState(null);
  const currentCourse = useSelector(selectCurrentCourse);

  const timezone = currentCourse?.timezone;

  const getDateObj = useCallback(str => {
    let b = str.split(/\D/);
    let d = new Date(b[0], b[1] - 1, b[2]);
    return d;
  }, []);

  useEffect(() => {
    if (!date) return;

    if (!timezone) {
      setDisplayDate(date);
      setEpochDate(new Date(date).getTime());
    }

    const dateObj = getDateObj(date);
    const convertedDate = moment.tz(dateObj, timezone).endOf("day").toDate();

    const calculatedDisplayDate = moment
      .tz(convertedDate, timezone)
      .endOf("day")
      .format("MMM Do");
    setDisplayDate(calculatedDisplayDate);

    // setDisplayDate(convertedDate);

    const calculatedEpochDate = new Date(convertedDate).getTime();
    setEpochDate(calculatedEpochDate);
  }, [timezone, date, getDateObj]);

  return [displayDate, epochDate];
}
