// Dependencies
import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";

// Redux Dependencies

// Components
import useKeypress from "../../hooks/useKeyPress";
import useOnClickOutside from "../../hooks/useOnClickOutside";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextareaAutosize } from "@material-ui/core";
import { Box } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  inlineText: {
    display: "-webkit-box",
    width: "fit-content",
    maxHeight: "52px",
    cursor: "pointer",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "2",
    "-moz-user-select": "text",
    wordBreak: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
    outlineWidth: "0px",
    border: "1px solid #bdbdbd;",
    borderRadius: "2px",
    padding: "4px 8px",
    lineHeight: "1.5",
    textAlign: "left"
  },

  inlineTextInput: {
    width: "100%",
    font: "inherit",
    color: "inherit",
    background: "none",
    outline: "none",
    outlineWidth: "0px",
    border: "1px solid #bdbdbd;",
    borderRadius: "2px",
    padding: "4px 8px",
    lineHeight: "1.5"
  },

  hidden: {
    display: "none"
  }
}));

export default function InlineEditTextarea({
  text,
  onChange,
  placeholder,
  setIsDraggable,
  ...props
}) {
  // Hooks
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const textRef = useRef(null);
  const inputRef = useRef(null);
  const enter = useKeypress("Enter");

  // Ephemeral State
  const [isInputActive, setIsInputActive] = useState(false);
  const [inputValue, setInputValue] = useState(text);

  // Redux State

  // Behavior
  // focus the cursor in the input field on edit start
  useEffect(() => {
    if (isInputActive) {
      inputRef.current.focus();
    }
  }, [isInputActive]);

  // If user clicks outside, save and exit
  useOnClickOutside(wrapperRef, () => {
    if (isInputActive) {
      saveAndExit(inputValue);
    }
  });

  function saveAndExit(valueToSave) {
    onChange(valueToSave);
    setIsInputActive(false);
    setIsDraggable && setIsDraggable(true);
  }

  // Render
  return (
    <Box ref={wrapperRef}>
      <Button
        ref={textRef}
        onClick={() => {
          setIsInputActive(true);
          setIsDraggable && setIsDraggable(false);
        }}
        onKeyPress={() => {
          if (enter) {
            setIsInputActive(true);
            setIsDraggable && setIsDraggable(false);
          }
        }}
        className={clsx(classes.inlineText, {
          [classes.hidden]: isInputActive
        })}
        label={`${text}, click to edit` || "Click to Edit"}
      >
        {text || placeholder || "Click to Edit"}
      </Button>
      <TextareaAutosize
        value={inputValue}
        ref={inputRef}
        style={{ resize: "none" }}
        className={clsx(
          classes.inlineTextInput,
          !isInputActive && classes.hidden
        )}
        onChange={e => {
          setInputValue(e.target.value);
        }}
        onBlur={() => {
          // if Tab is pressed, save text
          saveAndExit(inputValue);
        }}
        onKeyPress={e => {
          // if Enter is pressed, save text
          if (enter) {
            saveAndExit(inputValue);
          }
        }}
        onKeyDown={e => {
          // if Escape is pressed, revert the text and exit
          if (e.key === "Escape") {
            saveAndExit(text);
          }
        }}
        maxRows={2}
      />
    </Box>
  );
}
