// Dependencies
import React, { useState, useEffect } from "react";
import { firebaseFunctions, firebaseApp } from "./firebase";
import { useParams } from "react-router-dom";
import { injectIntl } from "react-intl";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setPangeaText } from "./redux/textSlice";
// import { setSubmittedText, resetGRState } from "./redux/grSlice";
import {
  taskSelectors,
  setSelectedTask,
  setSelectedTaskRow
} from "./redux/taskSlice";
import { setProfile } from "./redux/userSlice";
import { setBreadcrumbs, setAction } from "./redux/readerActionsSlice";
import { updateSq3r, updateTask } from "./redux/firebaseMiddleware";

// Components
import { GrInstructions } from "./components/guidedReading/GrInstructions";
import CardsManager from "./components/poc/CardsManager";
import { ScrollBox } from "./components/SharedComponents";
import SummaryManager from "./components/poc/summary/SummaryManager";
import BookView from "./components/reader/BookView";
import { ThemeCardsManager } from "./components/themes/ThemeCardsManager";
import { ThemeNote } from "./components/themes/ThemeNote";
import { TaskOpenAnswer } from "./components/Tasks/TaskAnswer/TaskOpenAnswer";
import { TaskMultiChoiceAnswer } from "./components/Tasks/TaskAnswer/TaskMultiChoiceAnswer";
import PeerReview from "./components/Tasks/PeerReview/PeerReview";
import PangeaSpinner from "./components/SharedComponents/PangeaSpinner";
import { useGetSideBarWidth } from "./hooks";

//logic from Sbmitting a task
import SubmitTaskModal from "./components/Tasks/TaskAnswer/SubmitTaskModal";

// Material UI
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Styles
const useStyles = makeStyles(theme => {
  return {
    readerViewContainer: {
      position: "relative",
      flex: 1,
      height: "100%",
      display: "flex",
      justifyContent: "center"
      // overflowY: "auto"
    },
    dirLtr: {
      direction: "ltr"
    },
    instructionsWrapper: {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
      width: "100%",
      height: "100%"
    }
  };
});

const ReaderView = ({
  intl,
  grSettings = {
    showSelfFeedback: true,
    removeHighlightCb: () => {}
  },
  ...props
}) => {
  let { task_id, text_id, submission_id } = useParams();
  // Hooks
  const dispatch = useDispatch();
  const classes = useStyles();
  const sideBarWidth = useGetSideBarWidth();
  const action = useSelector(state => {
    return state.readerActions.persistentActionState.actionBar;
  });
  const showCitationForQuestion = useSelector(
    state => state.task.showCitationForQuestion
  );

  const isLoading = useSelector(state => {
    return state.readerActions.isLoading;
  });
  const isTaskSubmitted = useSelector(taskSelectors.isSubmitted);
  const selectedText = useSelector(state => state.texts.selectedText);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const showAnswers = useSelector(state => state.gr.showAnswers);
  const showHighlights = useSelector(state => state.gr.showHighlights);
  const grMode = useSelector(state => state.gr.mode);
  const stage = useSelector(state => state.gr.stage);
  const submittedTextId = useSelector(state => state.gr.submittedTextId);
  const questions = useSelector(state => state.gr.questions);
  const highlights = useSelector(state => state.gr.highlights);
  const actionBar = useSelector(
    state => state.readerActions.persistentActionState.actionBar
  );
  const selectedQuestion = useSelector(state => {
    let filtered = state.gr.questions.filter(
      q => q.id === state.gr.selectedQuestionId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });
  const answers = (selectedQuestion && selectedQuestion.answers) || [];
  const taskAnswers = useSelector(state => state.task.answers);
  const detailedInstructions = useSelector(
    state => state.gr.detailedInstructions
  );

  const themeMode = useSelector(state => state.themes.themeMode);
  const selectedTheme = useSelector(state => {
    let filtered = state.themes.themes.filter(
      q => q.id === state.themes.selectedThemeId
    );
    if (filtered && filtered.length) return filtered[0];
    else return false;
  });
  const selectedTaskId = useSelector(state => state.task.selectedTaskId);
  const userProfile = useSelector(state => state.user.userProfile);
  const selectedCourse = userProfile.selectedCourse;
  const rtl = userProfile.rtl;
  const [readText, setReadText] = useState("");
  const [task, setTask] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [reflection, setReflection] = useState(null);
  const [response, setResponse] = useState(null);
  const [reply, setReply] = useState(null);
  const [fetchedFeedback, setFetchedFeedback] = useState(null);

  // Derived State
  const isTask =
    (task && task.id && actionBar === "task") ||
    (task && task.task_type === "peerReview" && actionBar === "");

  //Set breadcrumbs effect
  const author =
    !task || !task.text_author
      ? selectedText && selectedText.author
      : task.text_author;

  const text_title =
    !task || !task.text_name
      ? selectedText && selectedText.name
      : task.text_name;

  const course =
    !task || !task.course_name
      ? selectedText && selectedText.course_name
      : task.course_name;

  const course_id =
    !task || !task.course_id
      ? selectedText && selectedText.course_id
      : task.course_id;

  useEffect(() => {
    let parts = [];
    task &&
      task.id &&
      actionBar === "task" &&
      parts.push({
        text: intl.formatMessage({
          id: "appBar.tasks",
          defaultMessage: "Tasks"
        }),
        url: "/tasks",
        brightText: !!task
      });
    parts.push({
      text: course,
      url: task ? "/tasks/" + course_id : "/library/" + course_id,
      brightText: !!task
    });

    task &&
      task.name &&
      actionBar === "task" &&
      parts.push({
        url: "/tasks/" + course_id,
        text: task.name,
        brightText: !!task
      });

    parts.push({
      url: task ? "/tasks/" + course_id : "/library/" + course_id,
      text: author + " - " + text_title,
      brightText: !!task
    });
    dispatch(
      setBreadcrumbs({
        breadcrumbs: parts,
        blue: isTask,
        showTextMenu: true,
        showSubmitTask: isTask
      })
    );
  }, [rtl, task, course, course_id, author, text_title, actionBar]);

  const getText = id => {
    var getTaskById = firebaseFunctions.httpsCallable(
      "courses-getTextByIdWithTasks"
    );
    getTaskById({ id }).then(response => {
      let textRow = response.data;

      firebaseApp
        .storage()
        .ref("courseTexts/" + textRow.course_id)
        .child(textRow.file_url)
        .getDownloadURL()
        .then(url => {
          dispatch(
            setPangeaText({
              ...textRow,
              link: url
              // file_location:location,
            })
          );
        })
        .catch(e => {
          console.log(e);
        });
    });
  };

  useEffect(() => {
    if (task_id > 0) {
      dispatch(setSelectedTask(task_id));

      const getTask = !submission_id
        ? firebaseFunctions.httpsCallable("courses-getTaskById")
        : firebaseFunctions.httpsCallable("courses-getTaskForCollaboration");

      getTask({ task: task_id, submission: submission_id }).then(response => {
        let taskRow = response.data[0];
        if (taskRow?.course_id) {
          firebaseApp
            .storage()
            .ref("courseTexts/" + taskRow.course_id)
            .child(taskRow.file_url)
            .getDownloadURL()
            .then(url => {
              if (selectedCourse.id != taskRow.course_id) {
                dispatch(
                  setProfile({
                    ...userProfile,
                    selectedCourse: {
                      id: taskRow.course_id,
                      name: taskRow.course_name,
                      course_role: "student"
                    }
                  })
                );
              }

              setReflection(taskRow?.answers?.answers);
              setFetchedFeedback(taskRow?.feedback);

              setReadText({
                course_id: taskRow.course_id,
                textUrl: taskRow.file_url,
                url: url,
                file_location: taskRow.file_location
              });

              dispatch(
                setPangeaText({
                  ...taskRow,
                  ...taskRow.sq3r,
                  id: taskRow.text_id,
                  url: url,
                  defaultLocation: taskRow.file_location
                })
              );
              setTask(taskRow);
              dispatch(setSelectedTaskRow(taskRow));
            });
        }
      });
    } else if (text_id > 0) {
      dispatch(setSelectedTask(task_id));
      setTask(null);
      if (action === "task") {
        dispatch(setAction(""));
      }
      getText(text_id);
    }
  }, [task_id, dispatch, action]); // eslint-disable-line

  // const undo = () => {
  //   let funcName = "courses-undoTextSq3rSubmission1";
  //   let textId = submittedTextId;

  //   let func = firebaseFunctions.httpsCallable(funcName);
  //   func({ text_id: textId }).then(response => {
  //     dispatch(setSubmittedText(false));
  //   });
  // };

  const renderNote = () => {
    return (
      <ThemeNote
        key={selectedTheme.id}
        readText={{
          url:
            (readText && readText.url) || (selectedText && selectedText.link),
          file_location:
            (readText && readText.file_location) ||
            (selectedText && selectedText.file_location)
        }}
        back={() => {}}
      />
    );
  };

  const renderCardsEditView = () => {
    return (
      <ThemeCardsManager
        readText={{
          url:
            (readText && readText.url) || (selectedText && selectedText.link),
          file_location:
            (readText && readText.file_location) ||
            (selectedText && selectedText.file_location)
        }}
        back={() => {}}
      />
    );
  };
  // const bookRef = useRef();

  // const currentTaskQuestionMode = useSelector(state => {
  //   if (
  //     state.task &&
  //     state.task.taskRow.questions &&
  //     state.task.taskRow.questions.questions[
  //       state.task.selectedQuestionIndex
  //     ] &&
  //     !state.task.taskRow.questions.questions[state.task.selectedQuestionIndex]
  //       .includeCitation
  //   ) {
  //     return "Answer";
  //   } else if (
  //     state.task &&
  //     state.task.answers[state.task.selectedQuestionIndex] &&
  //     state.task.answers[state.task.selectedQuestionIndex].mode
  //   ) {
  //     return state.task.answers[state.task.selectedQuestionIndex].mode;
  //   } else {
  //     return "Citations";
  //   }
  // });

  const currentQuestionIndex = useSelector(
    state => state.task && state.task.selectedQuestionIndex
  );
  const currentQuestion = useSelector(taskSelectors.question);

  const renderTasksMainScreen = () => {
    switch (true) {
      case currentQuestion?.type === 0:
      case currentQuestion?.type === 1 &&
        currentQuestion.includeCitation &&
        showCitationForQuestion:
      case currentQuestion?.type === 2 &&
        currentQuestion.includeCitation &&
        showCitationForQuestion:
        //FIND IN TEXT
        return (
          <BookView
            readOnly={
              action === "task" &&
              task &&
              task.submission_status === "Submitted"
            }
            minimalBar={action === "task" && task}
            task={action === "task" && task}
            readText={task ? readText : selectedText}
          />
        );
      case currentQuestion?.type === 1:
        return (
          <TaskOpenAnswer
            question={currentQuestion}
            task={task}
            index={currentQuestionIndex}
            key={currentQuestionIndex}
            readText={{
              url:
                (readText && readText.url) ||
                (selectedText && selectedText.link),
              file_location:
                (readText && readText.file_location) ||
                (selectedText && selectedText.file_location)
            }}
            back={() => {}}
          />
        );
      case currentQuestion?.type === 2:
        return (
          <TaskMultiChoiceAnswer
            key={currentQuestionIndex}
            question={currentQuestion}
            index={currentQuestionIndex}
            task={task}
            readText={{
              url:
                (readText && readText.url) ||
                (selectedText && selectedText.link),
              file_location:
                (readText && readText.file_location) ||
                (selectedText && selectedText.file_location)
            }}
            back={() => {}}
          />
        );
      case currentQuestion?.type === 3:
        return (
          <PeerReview
            reflection={reflection}
            setReflection={setReflection}
            response={response}
            setResponse={setResponse}
            reply={reply}
            setReply={setReply}
            readText={readText}
            setReadText={setReadText}
            fetchedFeedback={fetchedFeedback}
            setFetchedFeedback={setFetchedFeedback}
          />
        );
    }
  };

  function updateCardWith(context, item, update) {
    // Init copies.
    let updatedAnswers = [...answers];
    let updatedQuestions = [...questions];
    let updatedTaskAnswers = [...taskAnswers];
    let updatedHighlights = [...highlights];

    switch (context.toLowerCase()) {
      case "answers":
        // Find the item in the collection and apply the update
        updatedAnswers = answers.map(el => {
          return el.cfi === item.cfi ? Object.assign({}, el, update) : el;
        });
        // Build the GR questions array
        updatedQuestions = questions.map(el => {
          return el.id === selectedQuestion.id
            ? { ...selectedQuestion, answers: updatedAnswers }
            : el;
        });
        // For GR tasks build the answers array
        updatedTaskAnswers = taskAnswers.map((el, i) => ({
          ...el,
          quotes: selectedQuestion.id === i ? updatedAnswers : el.quotes
        }));
        break;
      case "highlights":
        // Find the item in the collection and apply the update
        updatedHighlights = highlights.map(el => {
          return el.cfi === item.cfi ? Object.assign({}, el, update) : el;
        });
        break;
      default:
        break;
    }

    // Dispatch action for correct context
    dispatch(
      task?.task_type === "guidedReading"
        ? updateTask({
            answers: updatedTaskAnswers,
            grHighlights: updatedHighlights
          })
        : updateSq3r({
            textId: selectedTextId,
            questions: updatedQuestions,
            highlights: updatedHighlights
          })
    );
  }

  const renderMainScreen = () => {
    if (task_id && !task) {
      return <PangeaSpinner />;
    }

    if (
      (action === "task" &&
        task &&
        task.id &&
        task?.task_type !== "guidedReading") || // Exclude guided reading task let the the gr mode to take over
      (task?.task_type === "peerReview" && action === "" && task && task.id)
    ) {
      return renderTasksMainScreen();
    }

    const themeView =
      action === "themes" &&
      (themeMode === "extended" || themeMode === "note") &&
      selectedTheme &&
      selectedTheme.id;

    switch (true) {
      case grMode === "full" && stage === 3 && actionBar !== "themes":
      case grMode === "light" &&
        stage === 1 &&
        showAnswers &&
        actionBar !== "themes":
        return (
          <ScrollBox>
            <CardsManager
              context="Answers" // TODO: find a way to remove this prop. verry confusing...
              removeHighlightCb={grSettings.removeHighlightCb}
              cards={answers}
              hideCardCb={item =>
                updateCardWith("answers", item, { hidden: true })
              }
              showCardCb={item =>
                updateCardWith("answers", item, { hidden: false })
              }
              onTitleChange={item => newTitile =>
                updateCardWith("answers", item, { title: newTitile })}
              readText={{
                url:
                  (readText && readText.url) ||
                  (selectedText && selectedText.link),
                file_location:
                  (readText && readText.file_location) ||
                  (selectedText && selectedText.file_location)
              }}
            />
          </ScrollBox>
        );

      case grMode === "light" &&
        stage === 0 &&
        showHighlights &&
        actionBar !== "themes":
      case grMode === "full" && stage === 1 && actionBar !== "themes":
        return (
          <ScrollBox>
            <CardsManager
              context="Highlight" // TODO: find a way to remove this prop. verry confusing...
              removeHighlightCb={grSettings.removeHighlightCb}
              disableDrag
              cards={highlights}
              hideCardCb={item => {
                return; // this is depricated for now.
                const updatedHighlights = highlights.reduce(
                  (acc, highlight) => {
                    if (highlight.id === item.id) {
                      acc.push(Object.assign({}, highlight, { hidden: true }));
                    } else {
                      acc.push(highlight);
                    }
                    return acc;
                  },
                  []
                );
                dispatch(
                  task.task_type === "guidedReading"
                    ? updateTask({
                        answers,
                        grHighlights: updatedHighlights
                      })
                    : updateSq3r({
                        textId: selectedTextId,
                        questions: questions,
                        highlights: updatedHighlights
                      })
                );
              }}
              showCardCb={item => {
                return; // this is depricated for now.
                const updatedHighlights = highlights.reduce(
                  (acc, highlight) => {
                    if (highlight.id === item.id) {
                      acc.push(Object.assign({}, highlight, { hidden: false }));
                    } else {
                      acc.push(highlight);
                    }
                    return acc;
                  },
                  []
                );
                dispatch(
                  // TODO: Ofer Add support for GR as Task here
                  updateSq3r({
                    textId: selectedTextId,
                    questions: questions,
                    highlights: updatedHighlights
                  })
                );
              }}
              onTitleChange={item => newTitile =>
                updateCardWith("highlights", item, { title: newTitile })}
              readText={{
                url:
                  (readText && readText.url) ||
                  (selectedText && selectedText.link),
                file_location:
                  (readText && readText.file_location) ||
                  (selectedText && selectedText.file_location)
              }}
            />
          </ScrollBox>
        );

      case grMode === "light" &&
        stage === 0 &&
        !showHighlights &&
        actionBar !== "themes":
      case grMode === "light" &&
        stage === 1 &&
        !showAnswers &&
        actionBar !== "themes":
      case grMode === "full" &&
        [0, 2].includes(stage) &&
        actionBar !== "themes":
      case actionBar === "themes" && !themeView:
        /**
         * delete this block of code if QA seems OK 15/2/2022
         */
        // case (task || selectedText) && // I think this condition can be broken down to simplere cases...
        //   ((grMode === "full" && stage < 3) ||
        //     (grMode === "light" && !showHighlights && stage === 0) ||
        //     (grMode === "light" && !showAnswers && stage === 1) ||
        //     (action !== "poc" && task?.task_type !== "guidedReading")) &&
        //   (!text_id ||
        //     text_id < 0 ||
        //     (readText && readText.id && text_id === readText.id.toString()) ||
        //     (selectedText &&
        //       selectedText.id &&
        //       text_id === selectedText.id.toString())) &&
        //   !themeView:
        return (
          <BookView
            readOnly={
              action === "task" &&
              task &&
              task.submission_status === "Submitted"
            }
            minimalBar={action === "task" && task}
            task={action === "task" && task}
            readText={task ? readText : selectedText}
          />
        );

      case action === "themes" &&
        themeMode === "extended" &&
        selectedTheme &&
        selectedTheme.id > 0:
        return renderCardsEditView();

      case action === "themes" &&
        themeMode === "note" &&
        selectedTheme &&
        selectedTheme.id > 0:
        return renderNote();

      case grMode === "full" &&
        stage === 4 &&
        (action === "poc" || task?.task_type === "guidedReading"):
      case grMode === "light" &&
        stage === 2 &&
        (action === "poc" || task?.task_type === "guidedReading"):
        return (
          <SummaryManager
            isTaskSubmitted={isTaskSubmitted}
            saveSummary={updatedQuestions => {
              if (task?.task_type === "guidedReading") {
                dispatch(
                  updateTask({
                    answers: updatedQuestions.map(q => ({
                      quotes: q.answers || [],
                      summary: q.summary
                    })),
                    grHighlights: highlights
                  })
                );
              } else {
                dispatch(
                  updateSq3r({
                    textId: selectedTextId,
                    questions: updatedQuestions,
                    highlights: highlights
                  })
                );
              }
            }}
            showSelfFeedback={grSettings.showSelfFeedback}
          />
        );

      default:
        return <PangeaSpinner />;
    }
  };

  return (
    <Box
      className={clsx(classes.readerViewContainer, classes.dirLtr)}
      style={{
        width: `calc(100vw - ${sideBarWidth}px)`
      }}
    >
      <SubmitTaskModal
        task={task}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        selectedTaskId={selectedTaskId}
        taskAnswers={taskAnswers}
      />

      {isLoading ? (
        <PangeaSpinner />
      ) : (
        <>
          <AnimatePresence>
            {detailedInstructions && (
              <motion.div
                className={classes.instructionsWrapper}
                initial={{ x: "-100%" }}
                animate={{ x: "0" }}
                exit={{ x: "-100%" }}
                transition={{ type: "spring", damping: 20 }}
              >
                <GrInstructions />
              </motion.div>
            )}
          </AnimatePresence>
          {(action !== "poc" || !detailedInstructions) && renderMainScreen()}
        </>
      )}
    </Box>
  );
};

export default injectIntl(ReaderView);
