import { createSlice, current } from "@reduxjs/toolkit";
export const defaultProfile = {
  darkMode: false,
  fontSize: "6",
  language: "en",
  rtl: false,
  location: "/",
  showCitationsTip: true,
  selectedCourse: false,
  alertsDuration: 5000
};

const initialState = {
  userProfile: defaultProfile,
  userProfileSet: false,
  snackbarContent: {},
  openTasks: [],
  openTextTasks: [],
  fontSizeOptions: {
    5: {
      h1: {
        "font-size": "32px !important",
        "line-height": "40px !important"
      },
      h4: {
        "font-size": "18px !important",
        "line-height": "26px !important"
      },
      h2: {
        "font-size": "22px !important",
        "line-height": "30px !important",
        "margin-top": "36px",
        "margin-bottom": "20px"
      },
      h3: {
        "font-size": "22px !important",
        "line-height": "22px !important"
      },
      "h1.h.sigil_not_in_toc": {
        "font-size": "18px !important",
        "line-height": "30px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "12px !important",
          "line-height": "30px !important"
        },
      body: {
        "font-size": "20px !important",
        "line-height": "30px !important"
      },
      ".info": {
        "font-size": "14px !important",
        "line-height": "22px !important",
        "margin-top": "40px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "22px !important",
        "line-height": "22px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "12px !important",
        "line-height": "20px !important"
      }
    },
    6: {
      h1: {
        "font-size": "34px !important",
        "line-height": "42px !important"
      },
      h4: {
        "font-size": "20px !important",
        "line-height": "28px !important"
      },
      h2: {
        "font-size": "24px !important",
        "line-height": "32px !important",
        "margin-top": "40px",
        "margin-bottom": "24px"
      },
      h3: {
        "font-size": "24px !important",
        "line-height": "24px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "14px !important",
          "line-height": "32px !important"
        },
      body: {
        "font-size": "22px !important",
        "line-height": "32px !important"
      },
      ".info": {
        "font-size": "16px !important",
        "line-height": "24px !important",
        "margin-top": "40px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "24px !important",
        "line-height": "24px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "14px !important",
        "line-height": "22px !important"
      }
    },
    7: {
      h1: {
        "font-size": "36px !important",
        "line-height": "44px !important"
      },
      h4: {
        "font-size": "22px !important",
        "line-height": "30px !important"
      },
      h2: {
        "font-size": "26px !important",
        "line-height": "34px !important",
        "margin-top": "48px",
        "margin-bottom": "32px"
      },
      h3: {
        "font-size": "26px !important",
        "line-height": "26px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "16px !important",
          "line-height": "34px !important"
        },
      body: {
        "font-size": "24px !important",
        "line-height": "34px !important"
      },
      ".info": {
        "font-size": "18px !important",
        "line-height": "26px !important",
        "margin-top": "48px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "26px !important",
        "line-height": "26px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "16px !important",
        "line-height": "24px !important"
      }
    },
    8: {
      h1: {
        "font-size": "38px !important",
        "line-height": "46px !important"
      },
      h4: {
        "font-size": "24px !important",
        "line-height": "32px !important"
      },
      h2: {
        "font-size": "28px !important",
        "line-height": "36px !important",
        "margin-top": "40px",
        "margin-bottom": "40px"
      },
      h3: {
        "font-size": "28px !important",
        "line-height": "28px !important"
      },
      ":not(blockquote) > p:not(.ignore-side)::before ,blockquote:not(.ignore-side)::before":
        {
          "font-size": "18px !important",
          "line-height": "36px !important"
        },
      body: {
        "font-size": "26px !important",
        "line-height": "36px !important"
      },
      ".info": {
        "font-size": "24px !important",
        "line-height": "28px !important",
        "margin-top": "48px",
        "margin-bottomn": "40px"
      },
      ".author": {
        "font-size": "228x !important",
        "line-height": "28px !important",
        "margin-top": "8px",
        "margin-bottomn": "48px"
      },
      ".endnotes": {
        "font-size": "18x !important",
        "line-height": "26px !important"
      }
    }
  },
  //...loadState(),
  offline: false,
  auth: -1,
  original_auth: -1,
  flashMessages: null,
  // This is a flag that can trigger componnent level logic after flash message closes
  flashClosed: false,
  // This is a flag that can trigger componnent level logic after undo
  undo: false,
  notifications: null
};

export const userSlice = createSlice({
  name: "user",
  initialState,

  extraReducers: {
    gotNotifications: (state, value) => {
      state.notifications = value.payload;
    }
  },
  reducers: {
    setOriginalUser: (state, value) => {
      let auth = current(state.auth);
      if (state.original_auth === -1 || value.payload === -1) {
        state.original_auth = value.payload;

        //don't allow override
      }

      if (value.payload !== -1) {
        state.auth = {
          displayName: auth.uid,
          photoURL: "",
          uid: auth.uid,
          email: auth.uid
        };
      }
    },

    setAuth: (state, value) => {
      state.auth = value.payload;
    },
    setProfile: (state, value) => {
      state.userProfile = value.payload;
      state.userProfileSet = true;
    },
    setSnackbarContent: (state, value) => {
      state.snackbarContent = value.payload;
    },
    toggleOpenTask: (state, value) => {
      let currentTasks = current(state.openTasks);

      if (currentTasks.includes(value.payload)) {
        state.openTasks = currentTasks.filter(e => e !== value.payload);
      } else state.openTasks = [...currentTasks, value.payload];
    },
    setOffline: (state, value) => {
      state.offline = value.payload;
    },
    logout: () => initialState,

    toggleOpenTextTask: (state, value) => {
      let currentTasks = current(state.openTextTasks);
      if (currentTasks.includes(value.payload)) {
        state.openTextTasks = currentTasks.filter(e => e !== value.payload);
      } else state.openTextTasks = [...currentTasks, value.payload];
    },
    enqueueFlashMessage: (state, value) => {
      state.flashMessages = value.payload;
      // Below enables multiple flash messages
      // let flashMessages = current(state.flashMessages);
      // state.flashMessages = [...flashMessages, value.payload];
    },
    dequeueFlashMessage: (state, value) => {
      state.flashMessages = null;

      // Below enables multiple flash messages
      // let flashMessages = current(state.flashMessages);
      // flashMessages = flashMessages.slice(1);
      // state.flashMessages = [...flashMessages];
    },
    flashClosed: (state, value) => {
      state.flashClosed = value.payload;
    },
    undo: (state, value) => {
      state.undo = value.payload;
    }
  }
});

export const {
  setProfile,
  setAuth,
  setOriginalUser,
  toggleOpenTask,
  toggleOpenTextTask,
  setOffline,
  logout,
  setSnackbarContent,
  enqueueFlashMessage,
  dequeueFlashMessage,
  flashClosed,
  undo,
  notifications
} = userSlice.actions;

export default userSlice.reducer;
