import React from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import { dequeueFlashMessage, flashClosed, undo } from "./redux/userSlice";
import { makeStyles } from "@material-ui/core/styles";
import { SnackbarContent, Snackbar, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  list: {
    padding: 0
  },
  listItem: {
    listStyle: "none",
    textAlign: "left"
  },
  undo: {
    textTransform: "uppercase"
  }
}));

export default function FlashMessages() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const rtl = useSelector(state => state.user.userProfile.rtl);
  const flashMessages = useSelector(state => state.user.flashMessages);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  function parseMessage(message) {
    if (Array.isArray(message)) {
      return (
        <ul className={classes.list}>
          {message.map(line => {
            return (
              <li key={line} className={classes.listItem}>
                {line}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return message;
    }
  }

  return flashMessages ? (
    <Snackbar
      open={Boolean(flashMessages)}
      autoHideDuration={parseInt(alertsDuration)}
      onClose={() => {
        dispatch(dequeueFlashMessage());
        dispatch(undo(false));
        dispatch(flashClosed(true));
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: rtl ? "left" : "right"
      }}
      ClickAwayListenerProps={{
        mouseEvent: "false",
        touchEvent: "false"
      }}
    >
      {flashMessages.severity ? (
        <Alert severity={flashMessages.severity} variant="filled" icon={false}>
          {parseMessage(flashMessages.message)}
        </Alert>
      ) : (
        <SnackbarContent
          className={classes.snackbarContent}
          message={flashMessages.message}
          action={
            flashMessages.undoButton &&
            flashMessages.undoButton === true && (
              <Button
                onClick={() => {
                  dispatch(undo(true));
                  dispatch(dequeueFlashMessage());
                }}
                className={clsx(classes.undo)}
                color="secondary"
                size="small"
              >
                {intl.formatMessage({ id: "undo", defaultMessage: "undo" })}
              </Button>
            )
          }
        />
      )}
    </Snackbar>
  ) : null;
}
