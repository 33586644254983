import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  questions: [],
  highlights: [],
  expand: true,
  stage: 0,
  submittedTextId: 0,
  selectedLocation: false,
  shownLocation: false,
  status: "active",
  forceHighlight: false,
  detailedInstructions: false,
  seenStages: [],
  textId: undefined,
  movedThroughState: [0],
  selectedQuestionId: false,
  selectedAnswer: false,
  mode: "light",
  showAnswers: false,
  showHighlights: false,
  loading: true,
  actionCopy: "poc"
};

export const grSlice = createSlice({
  name: "gr",
  initialState,
  extraReducers: {
    gotSq3r: (state, value) => {
      if (window.location.pathname.includes("task")) return; // Tasks is taking over this reducer do not update
      state.questions =
        value.payload.questions?.map((q, i) =>
          Object.assign(q, { id: q.id === undefined ? i : q.id })
        ) || [];
      state.highlights = value.payload.highlights;

      state.stage = value.payload.grStage || 0;
      state.mode = value.payload.grMode || "light";
      state.selectedQuestionId = value.payload.grQuestionId;
      state.showAnswers = value.payload.grShowAnswers || false;
      state.showHighlights = value.payload.grShowHighlights || false;
      state.textId = value.payload.textId;
      if (value.payload.status) {
        state.status = value.payload.status;
      }
      state.loading = false;
    },
    "readerActions/setAction": (state, value) => {
      // This is a hack - one of many that i believe will be redundant if we use the url as state manager when possible.
      switch (true) {
        case value.payload === "poc" && state.actionCopy === "task":
        case value.payload === "task" && state.actionCopy === "poc":
          state.loading = true;
          Object.assign(state, initialState);
          break;
        default:
          break;
      }
      state.actionCopy = value.payload;
    },
    "readerActions/setLocation": (state, value) => {
      if (value.payload && "questionId" in value.payload) {
        state.selectedQuestionId = value.payload.questionId;
      }
    },
    "task/setSelectedTaskRow": (state, value) => {
      if (value.payload.task_type !== "guidedReading") return;
      // GR is a task so take controll over this slice
      state.actionCopy = "task"; // since we are relying on the action_type from the readerActions slice we need to hanlde some racing conditions.
      // there is a racing condition between "setSelectedTaskRow" ans "gotAnswers" and we can't be sure who comes in first so we "hydraye" the questions object
      value.payload.questions.questions.forEach((q, i) => {
        state.questions[i] = Object.assign(state.questions[i] || {}, {
          question: q.question,
          id: q.id === undefined ? i : q.id,
          answers: state.questions[i]?.answers || [],
          summary: state.questions[i]?.summary || ""
        });
      });
      const length = value.payload.questions.questions.length;
      state.questions = state.questions.slice(0, length);
      state.loading = false;
    },
    gotAnswers: (state, value) => {
      if (!value.payload.answers) return;
      // GR is a task so take controll over this slice
      state.actionCopy = "task"; // since we are relying on the action_type from the readerActions slice we need to hanlde some racing conditions.
      if (state.selectedQuestionId === false) {
        state.selectedQuestionId = value.payload.selectedQuestion || 0;
      }
      state.stage = value.payload.grStep || 0;
      state.showHighlights = value.payload.grShowHighlights || false;
      state.showAnswers = value.payload.grShowAnswers || false;
      // there is a racing condition between "setSelectedTaskRow" ans "gotAnswers" and we can't be sure who comes in first so we "hydraye" the questions object
      value.payload.answers.forEach((answer, i) => {
        state.questions[i] = Object.assign(state.questions[i] || {}, {
          answers: answer.quotes,
          summary: answer.summary || "",
          question: state.questions[i]?.question || [],
          id: state.questions[i]?.id === undefined ? i : state.questions[i].id
        });
      });
      state.highlights = value.payload.grHighlights;
      state.loading = false;
    },
    "user/logout": () => initialState
  },
  reducers: {
    selectLocation: (state, value) => {
      if (value.payload) {
        if ("questionId" in value.payload) {
          state.selectedQuestionId = value.payload.questionId;
        }
        state.selectedLocation = true;
        state.selectedAnswer = value.payload;
        state.stage = state.grMode === "full" ? 2 : 1;
      }
    },
    setDetailedInstructions: (state, value) => {
      state.detailedInstructions = value.payload;
    },
    setForceHighlight: (state, value) => {
      state.forceHighlight = value.payload;
    },
    setGrMode: (state, value) => {
      state.mode = value.payload;
    },
    setShowAnswers: (state, value) => {
      state.showAnswers = value.payload;
    },
    setShowHighlights: (state, value) => {
      state.showHighlights = value.payload;
    },
    setStage: (state, value) => {
      if ("stage" in value.payload) {
        // auto-select question
        if (state.mode === "full") {
          if (
            value.payload.stage >= 3 &&
            state.questions &&
            state.questions[0] &&
            !state.selectedLocation &&
            !current(state.movedThroughState).includes(value.payload.stage)
          ) {
            state.selectedQuestionId = state.questions[0].id;
          }
        } else {
          // gr-mode is 'light'
          if (
            value.payload.stage >= 1 &&
            state.questions &&
            state.questions[0] &&
            !state.selectedLocation &&
            !current(state.movedThroughState).includes(value.payload.stage)
          ) {
            state.selectedQuestionId = state.questions[0].id;
          }
        }

        state.selectedAnswer = false;
        state.stage = value.payload.stage;
        if (!current(state.movedThroughState).includes(value.payload.stage))
          state.movedThroughState.push(value.payload.stage);
      }

      if (
        "lastStage" in value.payload && // TODO: Ofer - investigate this
        !(value.payload.lastStage in state.seenStages)
      ) {
        state.seenStages.push(value.payload.lastStage);
      }
    },
    setExpand: (state, value) => {
      // state.seenStages = state.seenStages.filter(el=> el !== value.payload.stage);
      state.expand = value.payload;
    },
    deleteSelectedAnswer: (state, value) => {
      state.selectedAnswer = false;
    },
    setSubmittedText: (state, value) => {
      state.submittedTextId = value.payload;
    },

    setSelectedQuestion: (state, value) => {
      state.selectedQuestionId = value.payload.id;
    },
    setQuestions: (state, value) => {
      state.questions = value.payload;
    },
    resetGRState: () => initialState
  }
});

export const {
  setDetailedInstructions,
  setStage,
  setGrMode,
  setExpand,
  setSubmittedText,
  deleteSelectedAnswer,
  setQuestions,
  setSelectedQuestion,
  setForceHighlight,
  selectLocation,
  setShowAnswers,
  setShowHighlights,
  resetGRState
} = grSlice.actions;

// Selectors
export const getSelectedQuestion = state => {
  let selectedQuestion = state.gr.questions.find(
    question => question.id === state.gr.selectedQuestionId
  );
  if (selectedQuestion) return selectedQuestion;
  else return false;
};

export default grSlice.reducer;
