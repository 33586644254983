// Dependencies
import React, { useEffect, useState, useRef } from "react";
import { useLayer } from "react-laag";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setClearSelection } from "../../redux/readerActionsSlice";
import { toggleAnnotatorBar } from "../../redux/highlightSlice";
import { enqueueFlashMessage, undo } from "../../redux/userSlice";
import { getNextId, updateThemes } from "../../redux/firebaseMiddleware";

// Components
import ReaderAction from "./ReaderAction";
import HighlightColor from "./HighlightColor";
import ThemeBox from "./ThemeBox";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import BorderColor from "@material-ui/icons/BorderColor";
import { Box, Divider } from "@material-ui/core";

//Styles
const useStyles = makeStyles(theme => ({
  themeContainer: {
    position: "fixed",
    width: "320px",
    maxHeight: "360px",
    backgroundColor: "#333333",
    zIndex: "1500"
  },
  highlightAction: {
    "& :hover": {
      color: "#91fcc2"
    }
  },
  divider: {
    color: "#757575",
    backgroundColor: "#757575",
    margin: 6
  }
}));

export default function ReactReaderActions(props) {
  // Hooks
  let ref = useRef(null);
  let highlightRef = useRef(null);
  const classes = useStyles();

  // Redux State
  const dispatch = useDispatch();

  const annotatorMode = useSelector(
    state => state.readerActions.persistentActionState.annotatorMode
  );
  const highlightColor = useSelector(
    state => state.readerActions.persistentActionState.highlightColor
  );
  const clientRectangle = useSelector(
    state => state.highlighter.clientRectangle
  );
  const selectedText = useSelector(state => state.highlighter.selectedText);
  const isAnnotatorBarOpen = useSelector(
    state => state.highlighter.isAnnotatorBarOpen
  );
  const minimalFromState = useSelector(state => state.highlighter.minimal);
  const isHover = useSelector(state => state.highlighter.isHover);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);

  const selectedQuestion = useSelector(state => {
    if (state.gr.questions?.length) {
      let filtered = state.gr.questions.filter(
        q => q.id === state.gr.selectedQuestionId
      );
      if (filtered && filtered.length) return filtered[0];
      else return false;
    }
  });

  const themes = useSelector(state => state.themes.themes);
  const rtlActual = useSelector(state => state.user.userProfile.rtl);
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  //Ephemeral State
  const [labelShow, setLabelShow] = useState(false);
  const [layerOpen, setLayerOpen] = useState(false);
  const [undoData, setUndoData] = useState(null);

  // Variabels
  const minimal = props.minimal || minimalFromState;
  let colors = [
    { color: "#91fcc2", text: "color.Green", hlColor: "#91fcc2" },
    { color: "#93d4d9", text: "color.Cyan", hlColor: "#93d4d9" },
    { color: "#ffa284", text: "color.Orange", hlColor: "#ffa284" },
    { color: "#ff8a8a", text: "color.Red", hlColor: "#ff8a8a" },
    { color: "#ffcb73", text: "color.Yellow", hlColor: "#ffcb73" }
  ];

  //Behavior
  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoAddTheme = () => {
      if (undoData?.type === "AddThemeQuoteViaPopup") {
        let modifiedThemes = themes.map(theme => {
          if (theme.id === undoData.themeId) {
            let quotes = [
              ...theme.quotes.filter(quote => quote.cfi !== undoData.cfi)
            ];

            return { ...theme, quotes: quotes };
          } else return theme;
        });
        dispatch(
          updateThemes({
            textId: undoData.selectedTextId,
            themes: modifiedThemes
          })
        );
      }
    };

    if (shouldUndo) {
      undoAddTheme();
      dispatch(undo(false));
      // TODO: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [shouldUndo, dispatch, undoData, themes]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData) {
      dispatch(
        enqueueFlashMessage({
          message: "Instance added to theme",
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [undoData, dispatch, alertsDuration]);

  const renderTheme = () => {
    if (labelShow)
      return (
        <ThemeBox
          className={classes.themeContainer}
          clientRect={clientRectangle}
          setTheme={theme => {
            if (theme && theme.id) {
              let modifiedThemes = themes.map(elem => {
                if (elem.id === theme.id) {
                  let quotes = [
                    ...elem.quotes.filter(el => el.cfi !== selectedText.cfi),
                    {
                      cfi: selectedText.cfi,
                      ref: "not avaialble",
                      source: "theme",
                      text: selectedText.text,
                      color: highlightColor
                    }
                  ];
                  dispatch(setClearSelection(true));
                  return { ...elem, quotes: quotes };
                } else return elem;
              });
              dispatch(
                updateThemes({ textId: selectedTextId, themes: modifiedThemes })
              );
            } else {
              dispatch(
                updateThemes({
                  textId: selectedTextId,
                  themes: [
                    ...themes,

                    {
                      name: theme.name,
                      id: getNextId(themes),
                      quotes: [
                        {
                          cfi: selectedText.cfi,
                          ref: "not avaialble",
                          source: "theme",
                          text: selectedText.text,
                          color: highlightColor
                        }
                      ]
                    }
                  ]
                })
              );
            }

            setLabelShow(false);

            setUndoData({
              type: "AddThemeQuoteViaPopup",
              selectedTextId: selectedTextId,
              themeId: theme.id,
              cfi: selectedText.cfi
            });

            dispatch(setClearSelection(true));
          }}
        />
      );
    else return "";
  };

  const renderHoveredToolbar = () => {
    if (isHover) {
      return (
        <React.Fragment>
          {colors.map((item, index) => (
            <HighlightColor
              key={index}
              color={item.color}
              onClick={() => {
                props.setHighlightColor &&
                  props.setHighlightColor(selectedText, item.hlColor);
                dispatch(toggleAnnotatorBar());
              }}
            />
          ))}
          <Divider
            className={classes.divider}
            orientation="vertical"
            flexItem
          />
        </React.Fragment>
      );
    } else return "";
  };
  const hoverWidth = "307px";
  const { layerProps, renderLayer } = useLayer({
    isOpen: layerOpen,
    // overflowContainer: false,
    trigger: {
      getBounds: () => {
        return clientRectangle;
      },
      getParent: () => {
        return ref.current;
      }
    },
    container: ref.current,
    onOutsideClick: e => {
      setLabelShow(false);
    },

    placement: "top-center",

    triggerOffset: 1
  });

  const rtlValue = rtlActual ? "rtl" : "auto";

  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y !== prevPos.y) {
      setLayerOpen(false);
      setLabelShow(false);
    }
  });

  useEffect(() => {
    if (
      isAnnotatorBarOpen &&
      !layerOpen &&
      (!annotatorMode ||
        isHover ||
        (annotatorMode === "poc" && !selectedQuestion))
    ) {
      setLabelShow(false);
      setLayerOpen(true);

      // dispatch(onBarShown());
    } else {
      if (!isAnnotatorBarOpen) {
        setLayerOpen(false);
        setLabelShow(false);
        // dispatch(onBarClosed());
      }
    }
  }, [
    annotatorMode,
    dispatch,
    isAnnotatorBarOpen,
    isHover,
    layerOpen,
    selectedQuestion
  ]);

  // Render
  return (
    <Box style={{ height: "100%" }} ref={ref}>
      <>
        {renderTheme()}

        {props.children}

        {layerOpen &&
          // renderLayer(layerInnerRender())
          renderLayer(
            <Box
              {...layerProps}
              style={{
                ...layerProps.style,
                // width: getWidth(),
                height: "58px",
                display: "flex",
                position: "fixed",
                zIndex: 1500,
                padding: 1
              }}
              onMouseDown={evt => {
                evt.preventDefault();
              }}
            >
              <Box
                className="triangle"
                style={{
                  borderRadius: 4,
                  width: "100%",
                  height: "48px",
                  paddingInline: 8,
                  backgroundColor: "#333333",
                  display: "flex",
                  direction: rtlValue,
                  color: "#fafafa",
                  zIndex: "100"
                }}
              >
                {!minimal && renderHoveredToolbar()}
                <ReaderAction
                  minimal={minimal}
                  ref={highlightRef}
                  Icon={BorderColor}
                  className={classes.highlightAction}
                  onClick={() => {
                    props.removeHighlightCb &&
                      isHover &&
                      props.removeHighlightCb(selectedText);
                    props.highlightFunc &&
                      !isHover &&
                      props.highlightFunc(selectedText, highlightColor);
                    setLayerOpen(!layerOpen);
                    setLabelShow(false);
                    dispatch(setClearSelection(true));
                    dispatch(toggleAnnotatorBar());
                  }}
                />
                {/* Theme button is depricated but leaving it in as a comment if somtime in the furure it will come back */}
                {/* {!minimal && (
                <ReaderAction
                  className={classes.highlightAction}
                  Icon={LabelImportant}
                  onClick={() => {
                    setLabelShow(!labelShow);
                    setLayerOpen(false);
                  }}
                />
              )} */}

                {/* {!minimal && ( // ***  Do not delete this is a feature that will come back ***
                <ReaderAction
                  className={classes.highlightAction}
                  Icon={InsertComment}
                  onClick={() => {
                    dispatch(setAction("comments"));
                    dispatch(
                      setComment({ ...selectedText, color: highlightColor })
                    );
                    dispatch(setClearSelection(true));
                    setLayerOpen(false);
                  }}
                />
              )} */}
              </Box>
            </Box>
          )}
      </>
    </Box>
  );
}
