// Dependencies
import React, { useRef, useState, useEffect } from "react";
import ePub from "epubjs";
import { useDrop } from "react-dnd";
import { useIntl } from "react-intl";
import clsx from "clsx";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setAnnotatorMode } from "../../redux/readerActionsSlice";
import { selectTheme } from "../../redux/themeSlice";
import { updateThemes } from "../../redux/firebaseMiddleware";
import { enqueueFlashMessage, undo } from "../../redux/userSlice";

// Components
import ToggleTriangle from "../SharedComponents/ToggleTriangle";
import { ThemeEditMenu } from "../menus/QuestionEditMenu";
import EditingButton from "../EditingButton";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => ({
  li: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    lineHeight: "24px",
    position: "relative",
    color: "#e0e0e0",
    borderBottom: "1px solid transparent",
    "&:hover": {
      borderBottom: "1px solid #5ec891"
    },

    "&:hover button, &:focus-within button": {
      visibility: "visible"
    }
  },
  liLtr: {
    textAlign: "left",
    "& button": {
      right: "0px"
    }
  },
  liRtl: {
    textAlign: "right",
    "& button": {
      left: "0px"
    }
  },
  placeholderText: {
    fontSize: "16px",
    color: "#aaaaaa",
    textAlign: "initial"
  },
  questionText: {
    fontSize: "16px",
    color: "#fafafa",
    flex: 1,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    textAlign: "initial"
  },
  selectedQuestionText: {
    color: "black"
  },
  selectedQuestionCell: {
    backgroundColor: "#5ec891",
    color: "black"
  },
  questionOver: {
    backgroundColor: "white",
    color: "#5ec891",
    "& p": {
      color: "#5ec891"
    },
    "& svg": {
      color: "#5ec891"
    }
  }
}));

export const ThemePanelBox = ({
  id,
  theme,
  isEditing,
  icon,
  selected,
  ...props
}) => {
  // Hooks
  const intl = useIntl();
  const EpubCFI = new ePub.CFI();
  const inputRef = useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  // Redux State
  const themes = useSelector(state => state.themes.themes);
  const selectedThemeId = useSelector(state => state.themes.selectedThemeId);
  const selectedTextId = useSelector(state => state.texts.selectedTextId);
  const shouldUndo = useSelector(state => state.user.undo);
  const alertsDuration = useSelector(
    state => state.user.userProfile.alertsDuration
  );

  // Ephemeral State
  const [mousePos, setMousePos] = useState({});
  const [editingMode, setEditingMode] = useState(0);
  const [undoData, setUndoData] = useState(null);

  // Variables
  const ANSWER_ITEM = "Theme.Answer";

  //Bahavior
  // Invoke the undo logic when the Redux undo flag is true
  useEffect(() => {
    const undoThemeCopy = () => {
      if (undoData?.type === "answerDropped") {
        dispatch(
          updateThemes({
            textId: undoData.selectedTextId,
            themes: themes.map(theme => {
              if (theme.id === undoData.themeId) {
                return {
                  ...theme,
                  quotes: theme.quotes.filter(e => e.cfi !== undoData.quoteCfi)
                };
              } else return theme;
            })
          })
        );
      }
    };

    if (shouldUndo) {
      undoThemeCopy();
      dispatch(undo(false));
      // OPTION: change this when implementing multiple undos
      setUndoData(null);
    }
  }, [shouldUndo, dispatch, undoData, themes, id]);

  // Show flash message when there is undo data
  useEffect(() => {
    if (undoData) {
      dispatch(
        enqueueFlashMessage({
          message: intl.formatMessage({
            id: "themes.quote_dropped",
            defaultMessage: "Card copied to theme"
          }),
          duration: alertsDuration,
          undoButton: true
        })
      );
    }
  }, [undoData, intl, dispatch]);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ANSWER_ITEM,
    drop: (item, monitor) => {
      let items = theme.quotes
        ? theme.quotes.filter(a => a.cfi !== item.item.cfi)
        : [];
      let quotes = [...items];
      if (theme.quotes && theme.quotes.length === items.length) {
        let i = 0;
        let insertAt = quotes.length;
        for (i = 0; i < quotes.length; i++) {
          if (EpubCFI.compare(quotes[i].cfi, item.item.cfi) > 0) {
            insertAt = i;
            break;
          }
        }
        quotes.splice(insertAt, 0, { ...item.item, source: "theme" });

        let updatedThemes = themes.map(el => {
          if (el.id === theme.id) {
            return { ...el, quotes: quotes };
          } else return el;
        });

        dispatch(
          updateThemes({ textId: selectedTextId, themes: updatedThemes })
        );

        setUndoData({
          type: "answerDropped",
          selectedTextId: selectedTextId,
          themeId: theme.id,
          quoteCfi: item.item.cfi
        });
      }
    },
    canDrop: (item, monitor) => {
      return true;
    },
    collect: (monitor, collectProps) => {
      let obj = {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      };
      return obj;
    }
  });

  useEffect(() => {
    if (isEditing && editingMode !== id) {
      setEditingMode(id);
    }
  }, [editingMode, isEditing, id]);

  useEffect(() => {
    if (editingMode && inputRef.current) {
      inputRef.current.focus();
      // if (inputRef.current.setSelectionRange) {
      //  tempRef.current.setSelectionRange(text.length, text.length);
      // }
    }
  }, [editingMode, inputRef]);

  const handleThemeChange = item => event => {
    let updatedThemes = themes.map(el => {
      if (el.id === item.id) {
        return { ...el, placeholder: false, name: event.target.value };
      } else return el;
    });
    dispatch(updateThemes({ textId: selectedTextId, themes: updatedThemes }));
  };

  const renderEditControl = () => {
    return (
      <EditingButton
        rows={2}
        multiline={true}
        text={theme.name}
        colorClass={selectedThemeId === theme.id ? "black" : false}
        onChange={handleThemeChange(theme)}
        editingMode={id === editingMode}
        editIcon={<span />}
        onFocusOut={value => {
          if (!value) {
            dispatch(
              updateThemes({
                textId: selectedTextId,
                themes: themes.filter(el => el.id !== id)
              })
            );
          }
          setEditingMode(0);
          props.onDoneEdit && props.onDoneEdit();
        }}
        onKeyPress={ev => {
          if (ev.key === "Enter") {
            // Do code here
            setEditingMode(0);
            // props.onDoneEdit && props.onDoneEdit(ev.target.value)
            if (!ev.target.value && !theme.placeholder) {
              props.onDelete(ev);
            } else {
              if (theme.placeholder && ev.target.value)
                props.changedPlaceholder && props.changedPlaceholder();
            }
            ev.preventDefault();
          }
        }}
      />
    );
  };

  // Render
  return (
    <Box
      ref={drop}
      className={clsx(
        classes.li,
        classes.liLtr,
        isOver && canDrop && classes.questionOver,
        { [classes.selectedQuestionCell]: selected }
      )}
      onClick={() => {
        //   let val = selectedQuestion && selectedQuestion.id === id ? 0 : props.question;
        if (editingMode !== id) {
          if (selectedThemeId === id) {
            dispatch(selectTheme(false));
            dispatch(setAnnotatorMode(""));
          } else {
            dispatch(selectTheme(theme));
            dispatch(setAnnotatorMode("themes"));
          }
        }
      }}
      role="button"
      tabIndex="0"
      aria-pressed={selectedThemeId === id && "true"}
    >
      <ToggleTriangle
        isOpen={props.openThemes.includes(theme.id)}
        onClick={e => {
          props.toggleOpenTheme(theme.id);
          e.stopPropagation();
        }}
      />
      {editingMode !== id && (
        <Typography
          dir="auto"
          className={clsx(
            classes.questionText,
            classes.liLtr,
            { [classes.selectedQuestionText]: selected }
            //   isOver && canDrop && classes.questionOver
          )}
        >
          {theme.name}
        </Typography>
      )}
      {editingMode !== id && !theme.name && (
        <Typography
          dir="auto"
          className={clsx(
            classes.placeholderText,
            selectedThemeId === id && classes.selectedQuestionText
            //   isOver && canDrop && classes.questionOver
          )}
        >
          {theme.placeholder}
        </Typography>
      )}
      {editingMode === id && renderEditControl()}
      <ThemeEditMenu
        theme={theme}
        open={"X" in mousePos}
        handleClose={() => {
          setMousePos({});
        }}
        onDelete={e => {
          props.onDelete(e);
          setMousePos({});

          //     e.stopPropagation();
        }}
        onEdit={e => {
          dispatch(setAnnotatorMode("themes"));
          dispatch(selectTheme(theme));
          setEditingMode(id);
          setMousePos({});

          //   e.stopPropagation();
        }}
        mouseX={mousePos ? mousePos.X : null}
        mouseY={mousePos ? mousePos.Y : null}
      />
    </Box>
  );
};
