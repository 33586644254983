// Dependencies
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { useRouteMatch } from "react-router-dom";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseApp, firestore, firebaseFunctions } from "../../../firebase";

// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../../redux/userSlice";

// Components

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";

// Styles
const useStyles = makeStyles(theme => {
  return {
    divider: {
      backgroundColor: theme.palette.grey["700"]
    },
    noCourseMsg: {
      padding: "16px",
      color: "white"
    },
    accordionHeader: {
      color: "white"
    },
    courseName: {
      width: "100%",
      color: theme.palette.grey["400"],
      "&:hover": {
        color: "white"
      }
    },
    highlight: {
      color: "white"
    }
  };
});

export default function Sidebar() {
  // Hooks
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let { path, url } = useRouteMatch();

  // Ephemeral State
  const [courses, setCourses] = useState([]);
  const [gotCourses, setGotCourses] = useState(false);
  const [openYears, setOpenYears] = useState([]);

  const [user, authLoading, error] = useAuthState(firebaseApp.auth());
  const [userProfileDoc, profileLoading, userProfileError] = useDocument(
    firestore.doc("users/" + user?.uid)
  );

  const userProfile = userProfileDoc?.exists && userProfileDoc.data();
  // Bahavior

  useEffect(() => {
    const makeCancelable = promise => {
      let hasCanceled_ = false;

      const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
          val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
          error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
        );
      });

      return {
        promise: wrappedPromise,
        cancel() {
          hasCanceled_ = true;
        }
      };
    };

    const getMyCourses = firebaseFunctions.httpsCallable(
      "courses-getMyCourses"
    );
    const cancellable = makeCancelable(getMyCourses());

    cancellable.promise.then(response => {
      const classesByYear = response.data.reduce((result, item) => {
        if (item.academic_year in result) {
          result[item.academic_year].push(item);
        } else {
          result[item.academic_year] = [item];
        }
        return result;
      }, {});
      let items = Object.keys(classesByYear).map(function (key) {
        return [key, classesByYear[key]];
      });
      let sorted = items.sort(function (first, second) {
        return second[0].localeCompare(first[0]);
      });
      // Sort the array based on the second element
      setCourses(sorted);
      setGotCourses(true);
    });

    const cancel = () => {
      cancellable.cancel();
    };
    return cancel;
  }, []);

  function toggleYear(year) {
    if (openYears.includes(year)) {
      setOpenYears(openYears.filter(el => el !== year));
    } else {
      setOpenYears([...openYears, year]);
    }
  }

  const isOpenYear = year => {
    return openYears.includes(year);
  };
  const sanitizeUrl = url => {
    if (url.slice(-1) === "/") {
      return url.slice(0, -1);
    } else return url;
  };

  // Render
  return (
    <>
      {courses.length <= 0 && gotCourses && (
        <Typography className={classes.noCourseMsg}>
          Please Contact an admin to register to a course
        </Typography>
      )}
      <List component="nav" className={classes.root} disablePadding>
        {courses.map((course, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem
                className={clsx(classes.accordionHeader)}
                button
                onClick={() => toggleYear(course[0])}
                aria-label={
                  openYears.includes(course[0])
                    ? `collapse class list from ${course[0]}`
                    : `expand class list from ${course[0]}`
                }
              >
                <ListItemText primary={course[0]} />
                {openYears.includes(course[0]) ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </ListItem>
              <Collapse in={isOpenYear(course[0])} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {course[1].map((course, index) => {
                    return (
                      <ListItem
                        key={index}
                        onClick={() => {
                          if (userProfileDoc?.exists) {
                          dispatch(
                            setProfile({
                              ...userProfileDoc.data(),
                              selectedCourse: course
                            })
                          );

                          }
                          history.push(`${sanitizeUrl(url)}/${course.id}`);
                        }}
                        button
                        className={classes.nested}
                      >
                        <Typography
                          className={clsx(
                            classes.courseName,
                            userProfile?.selectedCourse?.id === course.id &&
                              classes.highlight
                          )}
                        >
                          {course.name}
                        </Typography>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
              <Divider className={classes.divider} />
            </React.Fragment>
          );
        })}
      </List>
    </>
  );
}
