// Dependencies
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import clsx from "clsx";
// Redux dependencies
import { useSelector, useDispatch } from "react-redux";
import { setLinkType, setLinkContent } from "../../../redux/LinkSlice";

// Components
import OpenAnswerLinked from "./OpenAnswerLinked";
import ConceptChip from "../ConceptChip";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import FeedbackQuestionContainer from "./FeedbackQuestionContainer";
import {
  Paper,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Chip
} from "@material-ui/core";

//Styles
const useStyles = makeStyles(theme => ({
  right: {
    textAlign: "right"
  },
  correct: {
    textTransform: "none",
    position: "absolute",
    right: "8px",
    color: "white",
    backgroundColor: "#0eac72",
    "& .MuiChip-label": {
      paddingLeft: "8px",
      paddingRight: "8px"
    }
  },
  correctInfo: {
    position: "absolute",
    right: "8px",
    color: "#0eac72",
    border: "1px solid #0eac72"
  },
  incorrect: {
    position: "absolute",
    right: "8px",
    backgroundColor: "#f9413e"
  },
  container: {
    overflow: "auto",
    height: "100%",
    paddingTop: "40px",
    display: "grid",
    gridTemplateColumns: "repeat(10,1fr)"
  },
  findFeedback: {
    gridColumn: " 1 / 9 ",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  feedbackSide: {
    gridColumn: " 7 / 11 ",
    paddingRight: "32px"
  },
  paperContainer: {
    gridColumn: " 1 / 7 ",
    paddingLeft: "32px",
    paddingRight: "32px",
    textAlign: "left"
  },
  conceptContainer: {
    marginBotton: "8px",
    "& .MuiChip-root": {
      height: "24px",
      "&:first-child": {
        marginLeft: "0"
      }
    }
  },
  paper: {
    padding: "16px",
    gridColumn: " 2 / 11 ",
    height: "max-content",
    position: "relative",
    minHeight: "260px"
  },
  commentContainer: {
    fontSize: "20px",
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"'
  }
}));

function FeedbackScreen({
  task,
  isTeacher,
  readText,
  selectedQuestionIndex,
  feedback,
  updateFeedback,
  showMatch
}) {
  // Hooks
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  // Redux state
  const linkTarget = useSelector(state => state.link.linkTarget);
  const selected = useSelector(state => state.link.selected);
  // Ephemeral state
  const [readOnly] = useState(!!task.is_checked);

  // Variables
  const concepts =
    feedback &&
    feedback.questions &&
    feedback.questions[selectedQuestionIndex] &&
    feedback.questions[selectedQuestionIndex].concepts;
  const question =
    task && task.questions && task.questions.questions[selectedQuestionIndex];
  const teacherAnswer =
    task && task.answers && task.answers.answers[selectedQuestionIndex];
  const answer =
    task &&
    task.student_answers &&
    task.student_answers.answers[selectedQuestionIndex];

  // Behavior
  const renderMultiAnswer = () => {
    return (
      <Box className={classes.paperContainer}>
        <Paper className={classes.paper}>
          <Box className={classes.commentContainer}>
            <Typography>Choose the correct answer</Typography>
            <RadioGroup
              name="answer"
              value={answer.choice}
              // className={clsx()}
            >
              {question.options.map((opt, index) => {
                return (
                  <Box className={classes.choiceContainer} key={index}>
                    <FormControlLabel
                      className={clsx(classes.label, classes.multiChoice)}
                      value={index}
                      key={index}
                      control={
                        <Radio
                          className={clsx(classes.radio)}
                          checked={index === parseInt(answer.choice)}
                        />
                      }
                      label={opt}
                    />
                    {index === parseInt(answer.choice) &&
                      parseInt(teacherAnswer.shouldSelect) === index && (
                        <Chip
                          className={classes.correct}
                          label={intl.formatMessage({
                            id: "task.multi.correct",
                            defaultMessage: "Correct"
                          })}
                        />
                      )}
                    {index !== parseInt(answer.choice) &&
                      parseInt(teacherAnswer.shouldSelect) === index && (
                        <Chip
                          className={classes.correctInfo}
                          label={intl.formatMessage({
                            id: "task.multi.correct",
                            defaultMessage: "Correct"
                          })}
                        />
                      )}

                    {index === parseInt(answer.choice) &&
                      parseInt(teacherAnswer.shouldSelect) !== index && (
                        <Chip
                          className={classes.incorrect}
                          label={intl.formatMessage({
                            id: "task.multi.incorrect",
                            defaultMessage: "Incorrect"
                          })}
                        />
                      )}
                  </Box>
                );
              })}
            </RadioGroup>
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderOpenAnswer = () => {
    return (
      <Box className={classes.paperContainer}>
        <Box>
          <Paper className={classes.paper}>
            {isTeacher && concepts && concepts.length > 0 && (
              <Box className={classes.conceptContainer}>
                {concepts.map((concept, i) => {
                  return (
                    <ConceptChip
                      key={i}
                      found={concept.found}
                      concept={concept.concept}
                    />
                  );
                })}
              </Box>
            )}
            <Box className={classes.commentContainer}>
              <OpenAnswerLinked
                wordLimit={question.wordLimit}
                showWordCount={true}
                key={selectedQuestionIndex}
                componentName={"answer" + selectedQuestionIndex}
                readOnly={true}
                selectionEvent={selection => {
                  if (linkTarget === "editor" && selected) {
                    dispatch(setLinkType("editor"));
                    dispatch(
                      setLinkContent({
                        selection: selection,
                        name: "answer" + selectedQuestionIndex
                      })
                    );
                  }
                }}
                index={selectedQuestionIndex}
                comment={answer.comment}
                text={answer.text || answer.summary} // answser.summary is for gr tasks
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    );
  };

  // Render
  return (
    <Box className={classes.container}>
      {question && question.type === 1 && renderOpenAnswer()}
      {question && question.type === 2 && renderMultiAnswer()}
      {task && task.task_type === "guidedReading" && renderOpenAnswer()}
      <Box
        className={clsx(
          ((question && question.type > 0) ||
            (task && task.task_type === "guidedReading")) &&
            classes.feedbackSide,
          question && question.type === 0 && classes.findFeedback
        )}
      >
        {feedback && (
          <FeedbackQuestionContainer
            showMatch={showMatch}
            key={selectedQuestionIndex}
            readOnly={readOnly}
            index={selectedQuestionIndex}
            feedback={feedback}
            readText={readText}
            task={task}
            updateFeedback={updateFeedback}
          />
        )}
        <Box className={clsx(classes.action, classes.right)}></Box>
      </Box>
    </Box>
  );
}

FeedbackScreen.propTypes = {
  task: PropTypes.shape({
    task_type: PropTypes.string.isRequired
  })
};

export default FeedbackScreen;
