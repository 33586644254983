import React, { useRef, useState, useEffect } from "react";
import TaskFeedbackComment from "../TaskFeedbackComment";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import {
  setLinkType,
  resetLink,
  setLinkContent
} from "../../../redux/LinkSlice";
import { getHighlightColor } from "../../../utils/colors";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import ReactReaderActions from "../../annotations/ReactReaderActions";
import {
  openAnnotatorBar,
  closeAnnotatorBar
} from "../../../redux/highlightSlice";
import { makeStyles } from "@material-ui/core/styles";
import EpubView from "../../reader/EpubView/EpubView";
import { updateTask } from "../../../redux/firebaseMiddleware";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import LinkIcon from "@material-ui/icons/Link";
import {
  Box,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
  Tooltip
} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  cardsContainer: {
    position: "relative"
  },
  paperContainer: {
    height: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(12,1fr)"
  },
  paper: {
    gridColumn: " 2 / 11 ",
    marginLeft: "56px",

    height: "max-content",
    position: "relative"
  },
  header: {
    marginTop: "40px",
    marginBottom: "24px",
    textAlign: "left",
    fontSize: "24px",
    color: "rgba(0,0,0,0.87)",
    paddingLeft: "60px"
  },
  pointsContainer: {
    position: "relative",
    minHeight: "56px",
    padding: "4px"
  },
  feedbackBtns: {
    position: "absolute",
    right: "8px"
  },
  dialog: {
    zIndex: 10
  },
  dialogActions: {
    justifyContent: "left"
  },
  dialogActionsRtl: {
    justifyContent: "right"
  },
  dialogBtn: {
    position: "absolute",
    color: "#ffffff",
    right: "16px"
  },
  dialogTitle: {
    background: "#168FEE",
    color: "#ffffff"
  },
  commentContainer: {
    minHeight: "50vh",
    padding: "16px",
    fontSize: "20px",
    fontFamily: '"Crimson Pro" ,"Frank Ruhl Libre"'
  },
  themeView: {
    paddingTop: "64px",
    position: "absolute",
    // background: "#E8E8E8",
    zIndex: 1,
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    height: "100%",
    overflow: "auto"
  }
}));

export function TaskOpenAnswer(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  // const [numWords, setNumWords] = useState(0);
  const { index, question, task, readText } = props;

  const taskAnswers = useSelector(state => {
    let answers = state.task.answers;

    let answerAr = [];
    if (
      task.questions.questions &&
      task.questions.questions.length &&
      (!answers ||
        !answers.length ||
        answers.length !== task.questions.questions.length)
    ) {
      for (var i = 0; i < task.questions.questions.length; i++) {
        answerAr.push({});
      }
      return answerAr;
    } else return answers;
  });

  const selectedTaskId = useSelector(state => state.task.selectedTaskId);
  //console.log("selectedTaskId", selectedTaskId);
  const answer = useSelector(state => {
    if (
      state.task.answers &&
      typeof state.task.answers[index] !== "undefined"
    ) {
      // console.log("answer is ", state.task.answers[index]);
      return state.task.answers[index];
    } else {
      return { choice: -1, text: "", comment: 0, mode: "answer", quotes: [] };
    }
  });

  const rtlValue = useSelector(state => state.user.userProfile.rtl);

  const [openDialog, setOpenDialog] = useState(false);
  const epubElemRef = useRef();
  const epubRef = useRef();
  const [rendition, setRendition] = useState(null);
  //var EpubCFI = new ePub.CFI();
  const intl = useIntl();
  const fontSizeValue = useSelector(state => state.user.userProfile.fontSize);
  const selectedFeedbackText = useSelector(state => state.link.selected);
  const darkMode = useSelector(state => state.user.userProfile.darkMode);

  const fontSize = useSelector(state => state.user.fontSizeOptions);

  //  console.log("linkContent - 1", linkContent);
  const linkBtnRef = useRef();
  const [linksCfi, setLinksCfi] = useState([]);
  const addHighlightToAnswer = cfi => {
    setLinksCfi([...linksCfi, cfi]);
  };
  const removeHighlightFromAnswer = hl => {
    setLinksCfi(linksCfi.filter(el => el !== hl.cfi));
  };

  //effect to add highlights
  const [highlightsElements, setHighlightsElements] = useState([]);
  // const highlights = props.highlights;
  const [url, setUrl] = useState(false);

  useEffect(() => {
    if (readText) {
      /* firebaseApp
    .storage()
    .ref("courseTexts/"+props.readText.course_id)
    .child(props.readText.file_url)
    .getDownloadURL()
    .then(url => {
      setUrl(url);
    });
  }
  else if (readText.url ){*/
      setUrl(readText.url);
    }
  }, [readText]);

  useEffect(() => {
    const setExistingElements = items => {
      let toRemove = [];
      highlightsElements &&
        Object.entries(highlightsElements).forEach(([cfi, elem]) => {
          let highlightExist = linksCfi && linksCfi.includes(cfi);
          if (!highlightExist) {
            toRemove.push(cfi);
            rendition.annotations.remove(cfi, "highlight");
          }
        });
      if (toRemove.length > 0) {
        const filtered = Object.keys(items)
          .filter(key => !toRemove.includes(key))
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: items[key]
            };
          }, {});

        return filtered;
      } else return items;
    };

    const addMissingHighlights = items => {
      let timeAdding = new Date().getTime();

      // console.log("after conds");
      linksCfi &&
        rendition &&
        rendition.manager &&
        linksCfi.forEach(cfi => {
          let range = rendition.getRange(cfi);
          if (range && !(cfi in items)) {
            let addedHighlight = rendition.annotations.highlight(
              cfi,
              {},
              e => {
                let timeClick = new Date().getTime();
                if (timeClick - timeAdding < 500) return;
                let contents = rendition.getContents();

                contents.forEach(content => {
                  if (range) {
                    let pos = rendition.getContents()[0].locationOf(cfi);
                    if (pos.x !== 0 || pos.y !== 0) {
                      let clientRect = range.getBoundingClientRect();
                      const frameBounds =
                        content.document.defaultView.frameElement.getBoundingClientRect();
                      // `target` can be an element or a range
                      let newRect = {
                        x: clientRect.x + frameBounds.x,
                        y: clientRect.y + frameBounds.y,
                        width: clientRect.width,
                        height: clientRect.height,
                        top: clientRect.top + frameBounds.y,
                        left: clientRect.left + frameBounds.x,
                        bottom:
                          clientRect.top + frameBounds.y + clientRect.height,
                        right:
                          clientRect.left + frameBounds.x + clientRect.width
                      };
                      dispatch(
                        openAnnotatorBar({
                          clientRectangle: newRect,
                          selectedText: { cfi: cfi },
                          isHover: true
                        })
                      );
                    }
                  }
                });
              },
              "highlightClass",
              {
                "z-index": 10,
                "mix-blend-mode": "multiply",
                "fill-opacity": 1,
                fill: getHighlightColor("#ffa284", darkMode)
              }
            );

            items[cfi] = { ...addedHighlight, cfi: cfi };
            //addedHighlight.mark.element.style.visibility = showHighlights ? "visible":"hidden";

            addedHighlight.mark.element.style.cursor = "pointer";
            addedHighlight.mark.element.style.pointerEvents = "visible";
          }
        });
      return items;
    };

    const renderHighlights = () => {
      let items = highlightsElements;

      items = setExistingElements(items);
      items = addMissingHighlights(items);

      setHighlightsElements(items);
    };
    //  console.log("should addd highligh useEffect");
    if (rendition) {
      // console.log("rendition useEffect", rendition.manager);
    }
    if (openDialog === 1) renderHighlights();
  }, [linksCfi, openDialog, rendition, highlightsElements, dispatch, darkMode]);

  const updateTaskWithAnswer = answer => {
    //  console.log("updater task", answer);

    let newAnswers = [...taskAnswers];

    newAnswers[index] = answer;
    dispatch(
      updateTask({
        taskId: selectedTaskId,
        answers: newAnswers,
        selectedQuestion: index
      })
    );
  };

  const onRenditionCreated = rendition => {
    if (rendition) {
      answer &&
        answer.quotes &&
        answer.quotes.map((quote, i) => {
          let hlStyleObj = {
            "z-index": 10,
            "mix-blend-mode": "multiply",
            "fill-opacity": 1,
            fill: quote.color
          };
          try {
            rendition.annotations.highlight(
              quote.cfi,
              {},
              e => {},
              "highlightClass",
              hlStyleObj
            );
          } catch (err) {
            console.log("error in  hl", err);
            console.log("cfi ", quote.cfi);
          }

          return 1;
        });
    }
    setRendition(rendition);
  };

  const renderTextLinkDialog = () => {
    return (
      <Dialog
        open={Boolean(openDialog)}
        PaperProps={{
          style: {
            direction: rtlValue ? "rtl" : "ltr",

            // backgroundColor: "white",
            width: "90%",
            maxWidth: "90%"
          }
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
          <DialogActions
            className={clsx(
              classes.dialogActions,
              rtlValue && classes.dialogActionsRtl
            )}
          >
            <Typography className={classes.dialogHeader}></Typography>
            <Button
              variant="outlined"
              className={classes.dialogBtn}
              onClick={() => {
                setOpenDialog(false);
                if (linksCfi.length > 0) {
                  dispatch(setLinkType("text"));
                  // console.log("setLinkContent", linksCfi);
                  dispatch(setLinkContent({ cfis: linksCfi }));
                } else {
                  dispatch(resetLink());
                }
                setLinksCfi([]);

                //setHighlightsElements([]);
                epubRef.current && epubRef.current.book.destroy();
              }}
            >
              <FormattedMessage defaultMessage="Done" id="gr.confirm.btn" />
            </Button>
          </DialogActions>
        </DialogTitle>
        <DialogContent ref={epubElemRef} className={classes.dialog}>
          <ReactReaderActions
            id="reactReaderActions"
            minimal={true}
            highlightFunc={addHighlightToAnswer}
            removeHighlightCb={removeHighlightFromAnswer}
          >
            <div id="epubViewContainer" className={classes.epubViewContainer}>
              <EpubView
                fullSize={true}
                ref={epubRef}
                fontSize={fontSize[fontSizeValue]}
                epubOptions={{ flow: "scrolled-doc" }}
                // loadingView={inputRef}
                rtl={rtlValue}
                url={url}
                location={readText.file_location}
                // locationChanged={locationChanged}
                handleTextSelected={params => {
                  if (openDialog !== 1) return;
                  if (params.shouldClose) {
                    dispatch(closeAnnotatorBar());
                  } else {
                    addHighlightToAnswer(params.selection.cfi);
                    let content = rendition && rendition.getContents()[0];

                    if (
                      content &&
                      content.window &&
                      content.window.getSelection()
                    ) {
                      content.window.getSelection().empty();
                    }
                  }
                }}
                onRenditionCreated={rendition => {
                  onRenditionCreated(rendition);
                }}
              />
            </div>
          </ReactReaderActions>
        </DialogContent>
      </Dialog>
    );
  };

  const saveNote = content => {
    let updatedAnswer = { ...answer, comment: content };
    updateTaskWithAnswer(updatedAnswer);
  };

  return (
    <Box className={classes.themeView}>
      <Box className={classes.paperContainer}>
        <Paper className={classes.paper}>
          <Box className={classes.commentContainer}>
            <TaskFeedbackComment
              showWordCount={true}
              autoFocus={true}
              subcribeToUnload={true}
              showFeedbackLine={false}
              // readOnly={false}
              readOnly={task.submission_status === "Submitted" ? true : false}
              onChange={saveNote}
              index={index}
              placeholder={intl.formatMessage({
                id: "task.open.placeholder",
                defaultMessage: "Type your answer here"
              })}
              comment={answer.comment}
              text={answer.text}
              wordLimit={question.wordLimit}
            />
          </Box>

          <Divider />
          <Box className={classes.pointsContainer}>
            <Box className={classes.feedbackBtns}>
              <Tooltip
                title={intl.formatMessage({
                  id: "feedback.tooltip.link",
                  defaultMessage: "Add link"
                })}
                arrow
                placement={rtlValue ? "left" : "right"}
              >
                <span>
                  <IconButton
                    ref={linkBtnRef}
                    className={classes.textIcon}
                    disabled={!selectedFeedbackText}
                    onClick={() => {
                      setLinksCfi([]);
                      setOpenDialog(1);
                    }}
                    aria-label="add link"
                  >
                    <LinkIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip
                title={intl.formatMessage({
                  id: "feedback.tooltip.showText",
                  defaultMessage: "Show text"
                })}
                arrow
                placement={rtlValue ? "left" : "right"}
              >
                <span>
                  <IconButton
                    className={classes.textIcon}
                    onClick={() => setOpenDialog(2)}
                    aria-label="show text"
                  >
                    <LibraryBooksIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
        </Paper>
      </Box>
      {openDialog && renderTextLinkDialog()}
    </Box>
  );
}
