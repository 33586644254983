import {
  createSlice
  // current
} from "@reduxjs/toolkit";

const initialState = {
  comments: [],
  commentRef: false,
  activeComment: false,
  publicComments: [],
  mode: "private"
};

export const commentSlice = createSlice({
  name: "comments",
  initialState,

  extraReducers: {
    editPublicComments: (state, value) => {
      state.commentRef = false;
    },
    deleteReplyPublicComments: (state, value) => {
      state.commentRef = false;
    },
    updatePrivateComments: (state, value) => {
      state.commentRef = false;
    },
    addReplyPublicComments: (state, value) => {
      state.commentRef = false;
    },
    updatePublicComments: (state, value) => {
      state.commentRef = false;
    },
    deletePublicComments: (state, value) => {
      state.commentRef = false;
    },
    editReplyPublicComments: (state, value) => {
      state.commentRef = false;
    },
    addPublicComments: (state, value) => {
      state.commentRef = false;
    },
    gotComments: (state, value) => {
      //    console.log("setHighlights in slice");
      state.comments = value.payload;
    },
    gotPublicComments: (state, value) => {
      // console.log("gotPublicComments, v in slice",value);
      state.publicComments = value.payload;
    },
    "texts/setSelectedText": (state, value) => {
      state.activeComment = false;
      state.commentRef = false;
    },
    "texts/setSelectedTextId": (state, value) => {
      state.activeComment = false;
      state.commentRef = false;
    },
    "user/logout": () => initialState
  },
  reducers: {
    setComment: (state, value) => {
      state.commentRef = value.payload;
    },
    setMode: (state, value) => {
      state.mode = value.payload;
    },
    setActiveComment: (state, value) => {
      state.activeComment = value.payload;
    }
  }
});

export const { setComment, setMode, setActiveComment } = commentSlice.actions;
//   openAnnotatorBar  ,closeAnnotatorBar, onBarShown, onBarClosed} = highlightSlice.actions;

export default commentSlice.reducer;

export const getCommentsDict = state => {
  let commentDict = {};
  if (state.readerActions.persistentActionState.actionBar === "comments") {
    let allComments =
      state.comments.mode === "private"
        ? state.comments.comments
        : state.comments.publicComments;

    allComments.forEach(comment => {
      commentDict[comment.ref.cfi] = comment;
    });
  }
  return commentDict;
};
